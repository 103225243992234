import React, { useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { Button, Label, UncontrolledDropdown, DropdownToggle, InputGroup, InputGroupText } from "reactstrap"
import { DropdownItem, DropdownMenu } from "components/common/Dropdown"
import Input from "components/form/Input"
import PhotoSelector from "components/form/PhotoSelector"
import DatepickerInput from "components/form/DatepickerInput"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import Loading from "modules/loader-watchers/Loading"
import Icon from "components/common/Icon"
import InputError from "components/form/InputError"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { isEqual } from "lodash"
import useForm, { cleanNestedAttributes } from "hooks/useForm"
import moment from "moment/moment"

import { useDispatch, useSelector } from "react-redux"
import { parseExpenseReceipt, saveExpense } from "store/accounting"
import { metadataSelector } from "store/selectors"

import { CATEGORY_ICONS, RECURRING_TYPE } from "constants/accounting"
import { DATE_FORMAT } from "modules/datepicker/constants"

const CategoryItem = ({ category, className, color, ...rest }) => {
  const { t } = useTranslation()
  const isPersisted = !!category?.id

  const classes = ["d-inline-flex align-items-center gap-1 fs-6 fw-medium"]
  if (isPersisted) classes.push(`text-${color || "primary-second"}`)
  else classes.push(`text-${color || "gray-light"}`)
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} {...rest}>
      {category?.slug && <Icon iconName={CATEGORY_ICONS[category.slug]} size={15} className="my-n1" block />}
      <span>{category?.name || t("expense.category_placeholder")}</span>
    </div>
  )
}

CategoryItem.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    slug: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  className: PropTypes.string,
  color: PropTypes.string
}

const Form = ({ onChanged, closeHandler }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const expense = useSelector((store) => store.accounting.expense)
  const receiptParsing = useSelector((store) => store.accounting.receiptParsing)
  const metadata = useSelector(metadataSelector("accounting"))
  const isExpensePersisted = !!expense?.id
  const merchant_name = expense?.merchant_name
  const purchase_date = expense.purchase_date || moment().format(DATE_FORMAT)

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(
    { ...expense, category_id: expense.category.id, photos: expense.receipts, merchant_name, purchase_date },
    ["category_id", "merchant_id", "merchant_name", "amount", "purchase_date", "recurring_type", "recurring", "notes", "photos"]
  )
  const receipts = useMemo(() => form.photos?.filter(({ _destroy }) => !_destroy), [form.photos])

  const selectedCategory = metadata.expense_categories.find(({ id }) => +id === +form.category_id)

  submitCallback(() => {
    const receipts = form.photos.map((file) => ({ ...file, image: file.file }))
    const receipts_attributes = cleanNestedAttributes(receipts, ["image"])

    const expenseAttributes = { ...form, receipts_attributes }
    delete expenseAttributes.photos

    const formData = serialize({ expense: expenseAttributes }, { indices: true })

    dispatch(saveExpense(expense.id, formData)).then(closeHandler)
  })

  useEffect(() => {
    if (!receipts.length || isEqual(expense.receipts, receipts)) return
    const { file } = receipts[0]
    if (!file) return
    const formData = serialize({ file })
    dispatch(parseExpenseReceipt(formData)).then(({ data }) => {
      if (data.amount) changeHandler({ target: { name: "amount", value: data.amount } })
      if (data.purchase_date) changeHandler({ target: { name: "purchase_date", value: data.purchase_date } })
      if (data.merchant_name) changeHandler({ target: { name: "merchant_name", value: data.merchant_name } })
    })
  }, [receipts.length]) //eslint-disable-line

  useEffect(() => {
    if (typeof onChanged === "function") onChanged(isChanged)
  }, [isChanged, onChanged])

  return (
    <FirstLoading name="accounting.expense" new>
      <form onSubmit={submitHandler}>
        <div className="vstack gap-20">
          <div className="hstack align-items-center gap-20 bg-white rounded p-20 sticky-top">
            <h1 className="h3 fw-medium lh-1">{t(`expense.${isExpensePersisted ? "edit" : "add"}`)}</h1>
            <div className="hstack gap-10 my-n1 ms-auto">
              <Button color="primary" className="fs-7" type={"submit"} disabled={!isChanged || receiptParsing}>
                {t("global.save")}
              </Button>
            </div>
          </div>
          <div className="vstack gap-30 bg-white rounded p-20 ">
            <div className="vstack">
              <Label className="fs-6">{t("expense.upload_receipt")}</Label>
              <Loading loading={receiptParsing} className="me-auto">
                <PhotoSelector form={form} changeHandler={changeHandler} small start label={false} max={1} />
              </Loading>
            </div>
            <div className="grid grid-cols-1 grid-cols-xsm-2 gap-20">
              <div>
                <Label for="merchant_name" className="fs-6">
                  {t("expense.merchant_name")}
                </Label>
                <Input
                  id="merchant_name"
                  type="text"
                  name="merchant_name"
                  placeholder={""}
                  value={form.merchant_name || ""}
                  onChange={changeHandler}
                  className="fs-6"
                  withError
                />
              </div>
              <div>
                <Label for="amount" className="fs-6">
                  {t("expense.category")}
                </Label>
                <UncontrolledDropdown>
                  <DropdownToggle tag="div" className="cursor-pointer form-control hstack justify-content-between">
                    <CategoryItem category={selectedCategory} />
                    <Icon iconName="SidebarToggle" size={16} className={"text-dark text-opacity-50"} />
                  </DropdownToggle>
                  <DropdownMenu>
                    {metadata.expense_categories?.map((category) => (
                      <DropdownItem
                        key={category.id}
                        name="category_id"
                        onClick={changeHandler}
                        value={category.id}
                        className={[
                          "py-2 px-10",
                          selectedCategory?.id === category.id ? "text-white" : "text-primary-second text-white-active"
                        ].join(" ")}
                        active={selectedCategory?.id === category.id}
                      >
                        <CategoryItem category={category} className="pointer-events-none" color="current" />
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <InputError field="category" className="mt-1 mb-n3" />
              </div>
              <div>
                <Label for="amount" className="fs-6">
                  {t("expense.amount")}
                </Label>
                <InputGroup>
                  <InputGroupText className="fs-6 text-dark text-opacity-50 border-end-0 bg-white">$</InputGroupText>
                  <Input
                    id="amount"
                    type="number"
                    name="amount"
                    value={form.amount || ""}
                    onChange={changeHandler}
                    className="fs-6 border-start-0"
                  />
                </InputGroup>
                <InputError field="amount" className="mt-1 mb-n3" />
              </div>
              <div>
                <DatepickerInput
                  name="purchase_date"
                  label={t("expense.date_of_purchase")}
                  type="date"
                  restrictions={{
                    maxDate: global.dateTime().endOf("date")
                  }}
                  value={form.purchase_date}
                  onChange={changeHandler}
                  labelClassName="fs-6"
                  inputClassName="fs-6 fw-medium"
                />
              </div>
              <div className="g-start-sm-2 g-col-1 g-col-xsm-2 g-col-sm-1">
                <Label check className="hstack gap-10 d-inline-flex mt-2 fs-7 fw-normal">
                  <Input
                    type="checkbox"
                    name="recurring"
                    value="true"
                    checked={[true, "true"].includes(form.recurring)}
                    onChange={changeHandler}
                    className="mt-0"
                  />
                  <span className={`text-dark ${[true, "true"].includes(form.recurring) ? "" : "text-opacity-50"}`}>{"Recurring"}</span>
                </Label>
                {form.recurring && (
                  <div className="hstack gap-2">
                    {RECURRING_TYPE.map((type) => (
                      <Label key={type} check className="hstack gap-10 d-inline-flex mt-2 fs-7 fw-normal me-5">
                        <Input
                          type="radio"
                          name={`recurring_type`}
                          value={type.toLowerCase()}
                          checked={form.recurring_type === type.toLowerCase()}
                          onChange={changeHandler}
                          className="mt-0"
                        />
                        <span className={`text-dark ${form.recurring_type === type.toLowerCase() ? "" : "text-opacity-50"}`}>{type}</span>
                      </Label>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div>
              <Label for="notes" className="fs-6">
                {t("expense.notes")}
              </Label>
              <Input
                id="notes"
                type="textarea"
                name="notes"
                rows={10}
                placeholder={t("expense.add_notes")}
                value={form.notes || ""}
                onChange={changeHandler}
                className="fs-6"
                withError
              />
            </div>
          </div>
        </div>
      </form>
    </FirstLoading>
  )
}

Form.propTypes = {
  onChanged: PropTypes.func,
  closeHandler: PropTypes.func.isRequired
}

export default Form
