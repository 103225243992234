import React, { memo, useMemo } from "react"
import PropTypes from "prop-types"

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Filler, Tooltip } from "chart.js"
import { Chart as BaseChart } from "react-chartjs-2"

import { removeTooltip, tooltip } from "helpers/weather"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Title, Tooltip)

const chartData = ({ labels, data }, type, color = "50, 50, 50", fill = false) => ({
  labels,
  datasets: [
    {
      data,
      borderColor: `rgba(${color}, 1)`,
      pointBackgroundColor: "white",
      pointHoverBackgroundColor: "white",
      pointRadius: 0,
      pointHoverRadius: 6,
      barBorderRadius: 3,
      barPercentage: 0.8,
      fill,
      backgroundColor: (context) => {
        if (!context.chart.chartArea) return
        const {
          ctx,
          chartArea: { top, bottom }
        } = context.chart
        const gradient = ctx.createLinearGradient(0, top, 0, bottom + top)
        gradient.addColorStop(0, `rgba(${color}, .3)`)
        gradient.addColorStop(0.5, `rgba(${color}, .25)`)
        gradient.addColorStop(1, `rgba(${color}, .05)`)
        return type === "line" ? gradient : `rgb(${color})`
      },
      tension: 0.4,
      datalabels: { display: false }
    }
  ]
})

export const chartOptions = ({ yAxisKey, beginAtZero = false, yFormatOptions }) => {
  return {
    devicePixelRatio: 2,
    responsive: true,
    maintainAspectRatio: false,
    layout: { padding: 0 },
    plugins: {
      legend: false,
      tooltip: {
        enabled: false,
        external: tooltip,
        caretPadding: 15,
        xAlign: "center",
        yAlign: "bottom",
        callbacks: {
          ...((typeof yFormatOptions === "string" && { label: (label) => `${label.formattedValue}${yFormatOptions}` }) ||
            (typeof yFormatOptions === "function" && { label: (label) => yFormatOptions(label.formattedValue, label.raw) }))
        }
      }
    },
    interaction: { mode: "index", intersect: false },
    parsing: { yAxisKey },
    scales: {
      x: {
        grid: { display: false, drawBorder: false },
        ticks: {
          maxRotation: 0,
          color: "#14141480",
          font: { size: 12 }
        }
      },
      yAxes: {
        type: "linear",
        beginAtZero,
        ticks: {
          ...((typeof yFormatOptions === "string" && { callback: (value) => `${value}${yFormatOptions}` }) ||
            (typeof yFormatOptions === "function" && { callback: (value) => yFormatOptions(value, null) })),
          ...(!["string", "function"].includes(typeof yFormatOptions) && { format: yFormatOptions }),
          color: "#14141480",
          font: { size: 12 }
        },
        grid: {
          color: "#F2F2F2",
          drawBorder: false,
          borderDash: [8, 8]
        }
      }
    }
  }
}

Chart.propTypes = {
  hourlyData: PropTypes.objectOf(PropTypes.number).isRequired,
  type: PropTypes.oneOf(["line", "bar", "pie", "doughnut", "radar"]),
  color: PropTypes.string,
  fill: PropTypes.bool,
  yAxisKey: PropTypes.string,
  beginAtZero: PropTypes.bool,
  yFormatOptions: PropTypes.object,
  className: PropTypes.string
}
function Chart({ hourlyData = {}, type = "line", color, fill, yAxisKey, beginAtZero, yFormatOptions, className }) {
  const rawData = useMemo(
    () =>
      Object.entries(hourlyData).reduce(
        (data, [hour, value]) => {
          data.data.push({ x: hour, value })
          return data
        },
        { labels: [], data: [], backgroundColor: [], borderColor: [] }
      ),
    [hourlyData]
  )
  const data = useMemo(() => chartData(rawData, type, color, fill), [rawData, type, color, fill])
  const options = useMemo(() => chartOptions({ yAxisKey, beginAtZero, yFormatOptions }), [yAxisKey, beginAtZero, yFormatOptions])

  const classes = ["chart-container position-relative"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={{ height: 400 }}>
      <BaseChart type={type} data={data} options={options} plugins={[{ beforeDestroy: removeTooltip }]} />
    </div>
  )
}

export default memo(Chart)
