import React from "react"
import { Col, Row } from "reactstrap"
import PropTypes from "prop-types"
import TotalYear from "./charts/TotalYear"
import RangeSelector from "./RangeSelector"
import Profit from "./charts/Profit"
import Expenses from "./charts/Expenses"
import Bookings from "./charts/Bookings"
import Incomes from "./charts/Incomes"

Dashboard.propTypes = {
  params: PropTypes.object.isRequired,
  onChangeFilters: PropTypes.func.isRequired
}

export default function Dashboard({ params, onChangeFilters }) {
  return (
    <Row className="justify-content-start gap-y-30 position-relative">
      <Col xs={12}>
        <TotalYear />
      </Col>
      <Col xs={12}>
        <RangeSelector params={params} onChangeFilters={onChangeFilters} />
      </Col>
      <Col xs={12} md={6} lg={6} xl={3}>
        <Profit params={params} />
      </Col>
      <Col xs={12} md={6} lg={6} xl={3}>
        <Expenses params={params} />
      </Col>
      <Col xs={12} md={6} lg={6} xl={3}>
        <Bookings params={params} />
      </Col>
      <Col xs={12} md={6} lg={6} xl={3}>
        <Incomes params={params} />
      </Col>
    </Row>
  )
}
