import React, { memo } from "react"
import PropTypes from "prop-types"
import { DropdownToggle, Label } from "reactstrap"
import FilterWrapper from "components/common/FilterWrapper"
import SortButton from "components/common/SortButton"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"

import { CLIENT_FILTERS, CLIENT_FILTERS_HELP_ATTRIBUTES } from "constants/clients"

function Sort({ buttonText, sortBy }) {
  const { t } = useTranslation()

  return (
    <FilterWrapper
      title={t("client.filters.sorting")}
      fields={[sortBy]}
      accessAttributes={CLIENT_FILTERS}
      exceptDependencies={CLIENT_FILTERS_HELP_ATTRIBUTES}
      end
      toggleButton={(_, isActive, filters) => (
        <DropdownToggle
          tag={SortButton}
          text={buttonText || "Sort"}
          className={`btn-ghost hstack gap-2 p-1 my-n2 rounded-1 ${isActive ? "text-primary-second" : "text-dark"}`}
          sort={filters[sortBy]}
        />
      )}
    >
      {(form, changeHandler) => (
        <>
          <Label className="fs-5 mb-3">{t(`client.filters.${sortBy}`)}</Label>
          <div className="vstack gap-2">
            <Label check className="hstack gap-10 p-10 pe-15 bg-white border border-gray-lightest rounded fs-7 fw-normal">
              <Input type="radio" name={sortBy} value="asc" checked={form[sortBy] === "asc"} onChange={changeHandler} className="mt-0" />
              <span className="text-dark text-opacity-50">{t(`client.filters.${sortBy}_asc`)}</span>
            </Label>
            <Label check className="hstack gap-10 p-10 pe-15 bg-white border border-gray-lightest rounded fs-7 fw-normal">
              <Input type="radio" name={sortBy} value="desc" checked={form[sortBy] === "desc"} onChange={changeHandler} className="mt-0" />
              <span className="text-dark text-opacity-50">{t(`client.filters.${sortBy}_desc`)}</span>
            </Label>
          </div>
        </>
      )}
    </FilterWrapper>
  )
}

Sort.propTypes = {
  buttonText: PropTypes.string,
  sortBy: PropTypes.string.isRequired
}

export default memo(Sort)
