import React from "react"
import styles from "./Sidebar.module.css"
import { MenuGroup } from "./MenuGroup"
import { useDispatch, useSelector } from "react-redux"
import { Avatar, Button } from "antd"
import { colors } from "themes/variables"
import classNames from "classnames"
import { Icon } from "ui/Icon"
import { setProHomeMenuClose } from "store/pro-home-menu"
import { signOut } from "store/auth"
import { useTranslation } from "react-i18next"

export const Sidebar = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { menuItems, open } = useSelector((state) => state.proHomeMenu)
  const { avatar, guide } = useSelector((state) => state.user)

  const handleCloseBtnClick = () => {
    dispatch(setProHomeMenuClose())
  }

  const handleLogoutBtnClick = () => {
    dispatch(signOut())
  }

  return (
    <div className={classNames(styles.sidebar, open ? styles.open : styles.close)}>
      <div className={styles.header}>
        <div className={styles.headerTitle}>
          <Avatar
            src={avatar?.includes("localhost") ? avatar.replace("https://", "http://") : avatar}
            style={{
              border: `1.5px solid ${colors.lines}`,
              flexShrink: 0
            }}
            className={styles.avatar}
          />
          <div className={styles.guideName}>{guide.display_name}</div>
        </div>
        <div onClick={handleCloseBtnClick} className={styles.closeIconWrapper}>
          <Icon width={32} height={32} type="feather" name="X" color="currentColor" />
        </div>
      </div>
      <div className={styles.sidebarBody}>
        {menuItems.map((menuItem) => (
          <MenuGroup key={menuItem.id} menuItem={menuItem} />
        ))}

        <Button className={styles.logoutButton} color="primary" variant="outlined" size="large" onClick={handleLogoutBtnClick}>
          {t("header.log_out")}
        </Button>
      </div>
    </div>
  )
}
