import React from "react"
import { Container, Row } from "reactstrap"
import PropTypes from "prop-types"

import testimonial1Image from "assets/images/landing/testimonial-1.png"
import testimonial2Image from "assets/images/landing/testimonial-2.png"
import testimonial3Image from "assets/images/landing/testimonial-3.png"

import TestimonialCard from "./TestimonialCard"

const testimonials = [
  {
    image: testimonial1Image,
    text: "My clients love that there are no booking fees and I am thrilled that I can offer online bookings without accepting credit cards. Finally, a platform built for how I run my business.",
    author: "Captain Bud Bishop"
  },
  {
    image: testimonial2Image,
    text: "Digital Sportsman has transformed how I run my business, allowing me to focus more on providing great experiences for my clients.",
    author: "Captain Greg DeVault"
  },
  {
    image: testimonial3Image,
    text: "I have already increased my repeat bookings with a few simple marketing campaigns. It took just a few minutes to set up using the DS templates and tools.",
    author: "Captain Stephen Hamilton"
  }
]

const Testimonials = ({ classes = "", bg = "rgb(0, 82, 180, 0.04)" }) => {
  return (
    <div className={`position-relative overflow-hidden py-60 py-sm-100 ${classes}`} style={{ backgroundColor: bg }}>
      <Container className="vstack align-items-center px-sm-70">
        <h1 className="lh-1 text-center mb-40">Guides are Raving About Digital Sportsman</h1>
        <Row className="gap-40 gap-sm-50 gap-lg-0">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard key={index} image={testimonial.image} text={testimonial.text} author={testimonial.author} />
          ))}
        </Row>
      </Container>
    </div>
  )
}

Testimonials.propTypes = {
  classes: PropTypes.string,
  bg: PropTypes.string
}

export default Testimonials
