import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { toaster } from "components/common/Toast"
import i18n from "i18n"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("textGenerator/requested")
const received = createAction("textGenerator/received")
const failed = createAction("textGenerator/failed")
const cleaned = createAction("textGenerator/cleaned")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  content: ""
}

const textGenerateReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.content = action.payload.paragraph_text
  },
  [failed.type]: (state) => {
    setFailed(state)
    toaster.error({ title: i18n.t("text_generate.error") })
  },
  [cleaned.type]: () => initialState
})
export default textGenerateReducer

export const textGenerate = (params) =>
  api({
    url: "/text_generator/generate_text",
    onStart: requested.type,
    onSuccess: received.type,
    onError: failed.type,
    params,
    props: { showToast: false }
  })

export const cleanTextGenerator = () => cleaned()
