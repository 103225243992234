import React from "react"
import { Outlet } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Col, Container, Row } from "reactstrap"
import SidebarNavigation from "components/guide/wizard/SidebarNavigation"
import Header from "components/layout/Header"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import { useWizardRedirects } from "hooks/wizard"
import { useScrollToActive } from "hooks/useScrollToActive"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

// Redux
import { useSelector } from "react-redux"
import { isGuideCompletedSelector } from "store/user"

function WizardLayout() {
  useLoaderWatchers({ watchers: "user.guide" })
  const wizardNavigationRef = useScrollToActive()
  const redirect = useWizardRedirects({
    name: "user.guide",
    rootpath: oldRoutes.guideWizardRootPath(),
    exitpath: oldRoutes.guideRootPath(),
    skipLastStepAfterCompleted: true
  })
  const isWizardCompleted = useSelector(isGuideCompletedSelector)

  return (
    redirect || (
      <Row className="min-vh-100 gx-0 align-content-start bg-light">
        <Col xs={12} md={{ size: 5, order: 1 }} lg={4} className="vstack vh-md-100 bg-white sticky-top">
          {isWizardCompleted && (
            <div className="py-md-20 border-bottom border-gray-lightest px-30 px-md-40 px-lg-60">
              <Header noPadding fluid />
            </div>
          )}
          <div
            className="flex-grow-1 d-flex flex-md-column overflow-auto px-30 px-md-40 px-lg-60 py-20 py-sm-30 py-md-60 py-lg-80 position-relative"
            ref={wizardNavigationRef}
          >
            <SidebarNavigation />
          </div>
        </Col>
        <Col xs={12} md={7} lg={8}>
          <FirstLoading name="user.guide">
            <Container className="py-40 py-md-60 py-lg-80 px-30 px-md-40 px-lg-60">
              <DefaultErrorBoundary>
                <Outlet />
              </DefaultErrorBoundary>
            </Container>
          </FirstLoading>
        </Col>
      </Row>
    )
  )
}

export default withDefaultErrorBoundary(WizardLayout)
