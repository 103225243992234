import React, { useEffect } from "react"
import { useMatch } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import OffcanvasWindow from "modules/offcanvas/OffcanvasWindow"
import Form from "components/posts/Form"

// Redux
import { useDispatch } from "react-redux"
import { cleanPost, getGuidePost } from "store/posts"

export default function PostWindow() {
  const dispatch = useDispatch()
  const isPersisted = useMatch(oldRoutes.guideMarketingPostEditPath())
  const postId = isPersisted?.params?.id

  useEffect(() => {
    if (postId) dispatch(getGuidePost(postId))
    return () => dispatch(cleanPost())
  }, [postId]) //eslint-disable-line

  return (
    <OffcanvasWindow
      container={document.getElementById("marketing")}
      loadingName="posts.post"
      exitRedirect={oldRoutes.guideMarketingPath()}
    >
      {({ setChanged, closeHandler }) => <Form closeHandler={closeHandler} onChanged={setChanged} />}
    </OffcanvasWindow>
  )
}
