import { Flex } from 'antd';
import { CircleFlag } from 'react-circle-flags'

export const PhoneCodeOption = ({ country }) => {
    return (
        <Flex align='center' gap={10}>
            <CircleFlag countryCode={country.code.toLowerCase()} height="18" />
            <div>{country.name}</div>
            <div>{country.dialCode}</div>
        </Flex>
    );
};
