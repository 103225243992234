import React from "react"
import { Link } from "react-router-dom";
import { Paragraph, Text, Title } from "ui/Typography";
import styles from "./TermsAndConditions.module.css"

export const PrivacyPolicy = () => {
    return (
        <div 
            className={styles.termsModal}
        >
            <Title>Privacy Policy</Title>
            <Paragraph>Last updated: April 22, 2024</Paragraph>
            <Paragraph>
                This Privacy Policy describes the information that Digital Sportsman, LLC, and its subsidiaries and affiliates (“Digital
                Sportsman”, “we,” “our” or “us”) collects through Digital Sportsman website and Digital Sportsman application (the “Service”) and
                how we use it, how we protect it and the choices you can make about your information.
            </Paragraph>
            <Paragraph>
                By accessing the Service, you expressly consent to the collection, use, disclosure and storage of your information and other
                information received by us, as a result of such use, in accordance with this Privacy Policy. If you do not agree with the terms of
                this Privacy Policy, you should immediately stop using or visiting the Service.
            </Paragraph>
            <Paragraph>
                We appreciate the trust you place in Digital Sportsman, LLC and we are committed to respecting your privacy and the security of
                your personal information. This Privacy Policy is subject to change as our website content, applications, and features evolve. We
                encourage you to read this Policy each time you visit the Digital Sportsman website.
            </Paragraph>
            <Paragraph>
                We always welcome your questions and feedback. For privacy-related questions or comments, please contact us at:
                <Text className="ml-xs" link>
                    <Link>cx@ds.guide.</Link>
                </Text>
            </Paragraph>

            <Title level={4}>Personally Identifying Information</Title>
            <Paragraph>
                We collect the personally identifying information you or a third party provide to us. Personally Identifying information is any
                information connected to a specific individual that can be used to uncover that individual's identity, and may include, for
                example: your name, billing and shipping addresses, payment card information, telephone number, email address, password, survey
                and user poll responses, employment-related information (such as that provided in a cover letter or resume), information related
                to your interaction with our social media accounts, and optional identifying demographic information.
            </Paragraph>
            <Paragraph>
                The personally identifying information we collect is necessary for the function of the Service, including but not limited to the
                functions to connect user guides with users clients of the site and to facilitate payment for any solicited services through the
                service between users.
            </Paragraph>

            <Title level={4}>Types of Website Data We Collect</Title>
            <Paragraph>
                We may also collect data related to your visit to our website. This information may include your browser type and version,
                operating system, internet service provider, IP address, and domain type. We use this information to understand how visitors
                access the Site. This helps us build and optimize the best website for our audience.
            </Paragraph>

            <Title level={4}>Information Collected from Third Parties</Title>
            <Paragraph>
                We may receive information about you from third parties. For example, we may obtain information from companies to enhance our
                existing customer information, such as improving the accuracy of the information we have about our customers, or we may collect
                information about you when you post content to our pages or feeds on third party social media sites. This Privacy Policy does not
                apply to information collected through or by any third party. If you have any questions about such third parties’ privacy
                practices, you should review their privacy policies.
            </Paragraph>

            <Title level={4}>Use of Personal Information</Title>
            <Paragraph>
                We may use the information we collect for various purposes related to our operations and your use of the Site. For example, we may
                use your information to:<br></br>
            </Paragraph>
            <ol>
                <li>
                    To carry-on our core business functions, such as: (i) providing you with products and services upon purchase or request; (ii) in
                    connection with your interest in using our software to promote, provide, or facilitate procurement of guide related services, ;
                    (iii) evaluating your personal interactions with us and other users to enhance your experience; (iv) improving our marketing and
                    promotional efforts; (v) maintaining appropriate records for internal administrative purposes; (vi) performing operational
                    functions, including data analysis, audits, and developing and improving products and services;
                </li>
                <li>
                    To communicate with you, such as: (i) to deliver marketing materials or other advertising content to you; or (ii) to process and
                    deliver a purchase, return or exchange transaction.
                </li>
                <li>
                    To provide you support, such as: (i) to respond to your comments, questions and requests; and (ii) to provide you customer
                    service.
                </li>
                <li>
                    To manage and enhance our services and the Site, such as: (i) sending technical notices, updates, security alerts and support
                    and administrative messages; (ii) monitoring and analyzing trends, usage and activities in connection with our Site; (iii) to
                    statistically analyze siteusage; to customize the Site content, layout or services; (vi) to improve our content and product
                    offerings; or (vii) to link or combine information we get from others to help understand your needs and to provide a better
                    experience.
                </li>
                <li>
                    To protect our users, such as: (i) to detect, investigate and prevent fraudulent transactions and other illegal activities and
                    protect the rights and property of the Company and others; (ii) to diagnose problems with our services;
                </li>
                <li>To comply with legal and regulatory requirements, required disclosures and law enforcement requests.</li>
                <li>To carry out any other purpose described to you at the time the information is collected.</li>
            </ol>
            <Paragraph>
                We may use or share non-personal, non-individual statistical or demographic information in aggregate form with our marketing
                partners, advertisers or other third parties for research purposes. Such aggregated and de-identified information cannot
                reasonably be used to identify you and, therefore, no longer constitutes personal information. Such de-identified and/or
                aggregated information which does not identify individuals is not subject to this Privacy Policy.
            </Paragraph>
            <Paragraph>
                When you submit content (such as a post or comment or review) to the Site, any visitors to and users of our Site will be able to
                see that content. Although some parts of the Site may be private, other parts of the Site are public and you should take that into
                consideration before posting to the Site.
            </Paragraph>

            <Title level={4}>Disclosure of Personal Information</Title>
            <Paragraph>We may share information about you as follows or as otherwise described in this Privacy Policy:</Paragraph>
                <ol>
                    <li>With your consent or at your direction or authorization.</li>
                    <li>
                        With vendors, consultants and other service providers who need access to such information to carry out work or perform services
                        on our behalf (including our third-party payment processor).
                    </li>
                    <li>
                        To comply with law, applicable regulations, court orders or subpoenas, to enforce our Terms of Use or other agreements, or to
                        protect our rights, property or safety, or the rights, property or safety of our users or others.
                    </li>
                    <li>
                        In connection with, or during negotiations of, any merger, acquisition, sale of assets or any business, other change of control
                        transaction or financing.
                    </li>
                    <li>
                        With third party businesses to provide you with information about goods or services that we believe may be of interest to you.
                    </li>
                    <li>Between and among us and any current or future parent, subsidiary and/or affiliated company.</li>
                </ol>
            <Paragraph>We also may share aggregated or de-identified information, which cannot reasonably be used to identify you.</Paragraph>

            <Title level={4}>Third Party Service Providers Used by Digital Sportsman Service</Title>
            <Paragraph>
                Stripe (payment processer), Google, AWS, IBM, Facebook, Instagram, X. The Privacy policy of each of these third party service
                providers are available at:<br></br>
                Stripe: https://stripe.com/privacy<br></br>
                Google: https://policies.google.com/privacy?hl=en-US#intro<br></br>
                AWS: https://aws.amazon.com/privacy/<br></br>
                IBM: https://www.ibm.com/us-en/privacy<br></br>
                Facebook: https://www.facebook.com/privacy/policy/<br></br>
                Instagram: https://privacycenter.instagram.com/policy<br></br>
                X: https://twitter.com/en/privacy<br></br>
            </Paragraph>

            <Title level={4}>How We Protect Personal Information</Title>
            <Paragraph>
                To the extent required by applicable law, we have implemented reasonable administrative, technical and physical safeguards in an
                effort to protect against loss, theft, and unauthorized or unlawful access, use, modification and disclosure of personal
                information in our custody and control.
            </Paragraph>
            <Paragraph>
                Your online access to certain of your personal information may be protected with a password you select. We strongly recommend that
                you do not disclose your password to anyone. You play a significant role in protecting your information. After you register on our
                Site you are responsible for protecting the security of your username and password. You are responsible for any transaction made
                using your username and password, even in the event of an unauthorized access of your account. The Company’s Terms of Use are
                hereby incorporated into this Privacy Notice by reference.
            </Paragraph>
            <Paragraph>
                Although we will take reasonable security precautions regarding your personal information collected from and stored on the Site,
                because of the open nature of the Internet, we cannot guarantee that any of your personal information stored on our services or on
                those of our third parties, or transmitted to or from us, will be free from unauthorized access or disclosure. Accordingly, we
                disclaim any liability for any unauthorized access to, disclosure or damage to, interception of, theft, or loss of any data
                communications and/or person information. By using the Site, you acknowledge that you understand and agree to assume these risks.
            </Paragraph>
            <Paragraph>
                We may keep your information for as long as is necessary for our business purposes and to protect ourselves in the event of a
                legal claim, subject to compliance with all applicable laws.
            </Paragraph>

            <Title level={4}>Why We Gather Information by Cookies & Pixel Tags</Title>
            <Paragraph>
                Cookies are pieces of information that our website transfers to your computer so that Digital Sportsman can enhance your shopping
                experience and tailor our website to your needs. For example, cookies can improve your experience on our website by identifying
                the types of browsers and operating systems used most by our customers, and how visitors found our website (via a search engine,
                directory, other website, etc.). We may use cookies on our website to let us know who you are and personalize the website in
                accordance with your preferences.
            </Paragraph>
            <Paragraph>
                We also use pixel tags - tiny graphic images - to tell us what parts of our website you have visited or to measure the
                effectiveness of any searches you may do on our site. Pixel tags also enable us to send you email in a format you can read and let
                us know when you have opened an email message from us. All of this information enables us to improve the website and tailor it to
                individual customer needs and preferences.
            </Paragraph>
            <Paragraph>
                We may also contract with other companies who use cookies or other online tools such as pixel tags to measure the performance of a
                marketing effort on our behalf.
            </Paragraph>
            <Paragraph>
                At any time, you can disable the use of cookies in your browser and still have access to most of the information on the Site.
                However, you may not be able to take full advantage of the Digital Sportsman website.
            </Paragraph>

            <Title level={4}>Do-Not-Call Registry</Title>
            <Paragraph>
                As part of your business relationship with us, you may be asked to supply us with your postal address and/or phone number.
                Regardless of whether this is a wired or wireless phone number or whether this number is listed on the Do-Not-Call Registry, you
                consent to being contacted by us (and/or its designated agents) at this number, for any purpose (including sales, marketing and
                promotional offers) and by any means (including autodialed or prerecorded voice calls and text messages made with an automatic
                telephone dialing system). In this regard, you specifically acknowledge and understand that you may incur, and will bear sole
                responsibility for, charges related to these incoming calls or messages. You may also receive periodic mailings from us or from
                other reputable companies.
            </Paragraph>

            <Title level={4}>How to Access or Correct Your Personal Information</Title>
            <Paragraph>
                To the extent required by applicable law, you may have the right to access, update, and correct inaccuracies in your personal
                information in our custody and control, subject to certain exceptions prescribed by law. You may request access, updating and
                corrections of inaccuracies in your personal information in our custody or control by emailing us at the contact information set
                out above. We may request certain personal information for the purposes of verifying the identity of the individual seeking access
                to their personal information records.
            </Paragraph>

            <Title level={4}>Compliance with the Children's Online Privacy Protection Act of 1998</Title>
            <Paragraph>
                Our website in not intended for use by children under the age of 13. Consistent with the Children's Online Privacy Protection Act
                of 1998, We do not knowingly collect personal information on children under the age of 13. We have no way of distinguishing the
                age of individuals who access our Site, and we ask no one under the age of 13 to use the Site or provide us with personal
                information. If we learn a child under the age of 13 has provided us with personal information without parental or guardian
                consent, the parent or guardian should contact us to remove the information and opt out of promotional opportunities. If you
                believe we might have any information from or about a child under 13, please contact us at: cx@ds.guide.
            </Paragraph>

            <Title level={4}>Compliance with California Law Governing the Privacy of California Residents</Title>
            <Paragraph>
                California Civil Code Section 1798.83 permits users that are California residents to request that we not share your personally
                identifiable information with third parties if we know or have reason to know that the third parties use the personally
                identifiable information for the third parties' direct marketing purposes. For their protection, we exercise this right on behalf
                of our California customers and require third parties hired by us to keep all personally identifiable information related to
                California customers secure and to not permit any use of personally identifiable information for direct marketing or any other
                purposes not authorized by us.
            </Paragraph>

            <Title level={4}>Interactions With Other Websites</Title>
            <Paragraph>
                Occasionally we provide links on our website to other sites to enhance the functionality and Service experience. These sites
                operate independently of the Digital Sportsman website and have established their own privacy and security policies. For the best
                online experience, we strongly encourage you to review these policies at any site you visit.
            </Paragraph>

            <Title level={4}>Updates to Privacy Policy</Title>
            <Paragraph>
                We reserve the right to change our Privacy Policy at any time without notice. We will alert you to these changes by noting the
                date that this Privacy Policy was last updated at the beginning of the Privacy Policy. We encourage you to review this Privacy
                Policy from time to time to make sure that you understand how we may gather, secure, and use your personal information. Your
                continued use of the Digital Sportsman website constitutes your agreement to and acceptance of the Privacy Policy and any changes
                to it.
            </Paragraph>
        </div>
    );
}