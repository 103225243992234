import React from "react"
import { Col, Row } from "reactstrap"
import Step2Form from "components/guide/wizard/Step2Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

export default function Step2() {
  useTitle("Guide Wizard — Step 2")
  const { t } = useTranslation()
  const title = t("guide.wizard.step_2.title")
  const subtitle = t("guide.wizard.step_2.subtitle")

  return (
    <>
      <div className="text-center">
        {title && <h1 className="h2 lh-1 mb-0">{title}</h1>}
        {subtitle && <p className="fs-5 mt-15 mb-40 text-dark text-opacity-50">{subtitle}</p>}
      </div>
      <DefaultErrorBoundary>
        <Row>
          <Col xs={12} lg={{ size: 10, offset: 1 }} xl={{ size: 8, offset: 2 }} xxl={{ size: 6, offset: 3 }}>
            <Step2Form />
          </Col>
        </Row>
      </DefaultErrorBoundary>
    </>
  )
}
