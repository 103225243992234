import i18n from "i18n"

const API_URL = process.env.REACT_APP_API_URL
const WS_URL = process.env.REACT_APP_API_WS_URL
const BASE_URL = API_URL + "/api/v1"
const REFRESH_TOKEN_URL = API_URL + "/oauth/token"
const DEFAULT_PARAMS = { lang: i18n.language }

const API = {
  API_URL,
  BASE_URL,
  REFRESH_TOKEN_URL,
  WS_URL,
  DEFAULT_PARAMS
}

export default API
