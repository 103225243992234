import React from "react"

export const Calendar = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.04171 1C5.54041 1 5.12504 1.41536 5.12504 1.91667V2.83333H2.37504C2.13513 2.83333 1.89164 2.92106 1.71977 3.09473C1.5461 3.2666 1.45837 3.51009 1.45837 3.75V22.0833C1.45837 22.3232 1.5461 22.5667 1.71977 22.7404C1.89164 22.9123 2.13513 23 2.37504 23H21.625C21.865 23 22.1084 22.9123 22.2821 22.7404C22.454 22.5667 22.5417 22.3232 22.5417 22.0833V3.75C22.5417 3.51009 22.454 3.2666 22.2821 3.09473C22.1084 2.92106 21.865 2.83333 21.625 2.83333H18.875V1.91667C18.875 1.41536 18.4597 1 17.9584 1H17.0417C16.5404 1 16.125 1.41536 16.125 1.91667V2.83333H7.87504V1.91667C7.87504 1.41536 7.45968 1 6.95837 1H6.04171ZM6.04171 1.91667H6.95837V4.66667H6.04171V1.91667ZM17.0417 1.91667H17.9584V4.66667H17.0417V1.91667ZM2.37504 3.75H5.12504V4.66667C5.12504 5.16797 5.54041 5.58333 6.04171 5.58333H6.95837C7.45968 5.58333 7.87504 5.16797 7.87504 4.66667V3.75H16.125V4.66667C16.125 5.16797 16.5404 5.58333 17.0417 5.58333H17.9584C18.4597 5.58333 18.875 5.16797 18.875 4.66667V3.75H21.625V6.95833H2.37504V3.75ZM2.37504 7.875H21.625V22.0833H2.37504V7.875ZM14.3776 14.2917C13.8298 14.2917 13.375 14.7464 13.375 15.2943V18.7891C13.375 19.3369 13.8298 19.7917 14.3776 19.7917H17.8724C18.4203 19.7917 18.875 19.3369 18.875 18.7891V15.2943C18.875 14.7464 18.4203 14.2917 17.8724 14.2917H14.3776ZM14.3776 15.2083H17.8724C17.9261 15.2083 17.9584 15.2406 17.9584 15.2943V18.7891C17.9584 18.841 17.9261 18.875 17.8724 18.875H14.3776C14.3257 18.875 14.2917 18.841 14.2917 18.7891V15.2943C14.2917 15.2406 14.3257 15.2083 14.3776 15.2083Z"
      fill={fill}
    />
  </svg>
)
