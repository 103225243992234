import React from "react"
import PropTypes from "prop-types"
import { Button, DropdownToggle } from "reactstrap"
import FilterWrapper from "components/common/FilterWrapper"
import Input from "components/form/Input"
import NavigationLink from "components/layout/NavigationLink"

import { useTranslation } from "react-i18next"

import { GUESTS, TRIP_FILTERS, TRIP_FILTERS_HELP_ATTRIBUTES } from "constants/trip"

const TempNavigationLink = ({ ...props }) => <NavigationLink tag={Button} {...props} />

Guests.propTypes = {
  onToggleClick: PropTypes.func.isRequired
}
export default function Guests({ onToggleClick }) {
  const { t } = useTranslation()

  return (
    <FilterWrapper
      title={t("trips.filters.guests")}
      fields={["guest_count"]}
      accessAttributes={TRIP_FILTERS}
      exceptDependencies={TRIP_FILTERS_HELP_ATTRIBUTES}
      toggleButton={(isOpen, isActive, filters) => (
        <DropdownToggle
          tag={TempNavigationLink}
          iconName="Users"
          label={t("trips.filters.guests")}
          onClick={onToggleClick}
          isActive={isOpen || isActive}
          badge={filters.guest_count || ""}
        />
      )}
    >
      {(form, changeHandler) => (
        <div className="hstack gap-2 justify-content-start w-100">
          {GUESTS.map((guests) => (
            <div key={guests}>
              <Input
                id={`guest_count_${guests}`}
                type="radio"
                name="guest_count"
                value={guests}
                checked={+guests === +form.guest_count}
                onChange={changeHandler}
                className="btn-check"
              />
              <label htmlFor={`guest_count_${guests}`} className="btn btn-outline-dark px-25 py-10">
                {guests}
              </label>
            </div>
          ))}
        </div>
      )}
    </FilterWrapper>
  )
}
