import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { NavLink } from "react-router-dom"
import { UncontrolledDropdown, DropdownToggle, DropdownMenu as BsDropdownMenu, DropdownItem as BsDropdownItem } from "reactstrap"
import Icon from "./Icon"

Dropdown.propTypes = {
  toggleButton: PropTypes.element,
  whiteToggle: PropTypes.bool,
  className: PropTypes.string,
  classNameMenu: PropTypes.string,
  menuProps: PropTypes.object,
  ellipsisVertical: PropTypes.bool,
  direction: PropTypes.oneOf(["down", "up", "left", "right"]),
  end: PropTypes.bool,
  container: PropTypes.element,
  onToggle: PropTypes.func,
  offset: PropTypes.array,
  children: PropTypes.node.isRequired
}

DropdownItem.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

DropdownItemIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  color: PropTypes.string,
  background: PropTypes.string
}

export function Dropdown({
  toggleButton: ToggleButton,
  whiteToggle = false,
  className,
  classNameMenu,
  menuProps,
  ellipsisVertical = false,
  direction = "down",
  end = false,
  container,
  onToggle,
  offset,
  children,
  ...rest
}) {
  const dropdownMenuModifier = {
    name: "offset",
    options: {
      offset: offset || [0, ToggleButton ? 0 : -25]
    }
  }

  return (
    <UncontrolledDropdown className={className} direction={direction} onToggle={onToggle} {...rest}>
      {ToggleButton || (
        <DropdownToggle
          className={`d-block btn-ghost rounded-circle border-0 m-n1 p-1 ${
            whiteToggle ? "text-white bg-white bg-opacity-0 bg-opacity-25-hover" : "text-gray-medium"
          }`}
        >
          <Icon iconName="Ellipsis" className={ellipsisVertical ? "rotate-90" : ""} size={17} block />
        </DropdownToggle>
      )}
      <DropdownMenu end={end} modifiers={[dropdownMenuModifier]} container={container} className={classNameMenu} {...menuProps}>
        {children}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
}

export const DropdownMenu = forwardRef(({ children, className, ...rest }, ref) => {
  return (
    <BsDropdownMenu {...rest} className={["rounded-1", className].join(" ")} ref={ref}>
      <div className="vstack">{children}</div>
    </BsDropdownMenu>
  )
})

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export function DropdownItem({ onClick, to, children, className, ...rest }) {
  const props = {
    ...rest,
    ...(to && { tag: NavLink })
  }

  return (
    <BsDropdownItem className={`fw-medium lh-1 ${className || ""}`} onClick={onClick} to={to} {...props}>
      {children}
    </BsDropdownItem>
  )
}

export function DropdownItemIcon({ iconName, color = "dark", background = "gray-lightest" }) {
  const iconSize = 24
  return (
    <div className={`d-flex ms-n10 me-10 p-1 rounded-circle bg-${background} text-${color}`} style={{ width: iconSize, height: iconSize }}>
      <Icon iconName={iconName} width={iconSize} height="0" className="d-block m-auto w-100 h-auto mh-100" />
    </div>
  )
}
