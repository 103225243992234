import React from "react"
import styles from "./MenuItem.module.css"
import { useDispatch, useSelector } from "react-redux"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { Icon } from "ui/Icon"
import { colors } from "themes/variables"
import { setProHomeMenuClose } from "store/pro-home-menu"

export const MenuItem = ({ item }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { activeMenu } = useSelector((state) => state.proHomeMenu)
  const { name, id, iconName } = item
  const isActive = activeMenu === id

  const onClickHandler = () => {
    if (item.path) {
      dispatch(setProHomeMenuClose())
      navigate(item.path)
    }
  }

  return (
    <div className={classNames(styles.menuGroupItem, isActive && styles.menuGroupItemActive)} onClick={onClickHandler}>
      <Icon name={iconName} className={styles.itemIcon} color={isActive ? "currentColor" : colors.textPrimary} />
      <div className={styles.itemName}>{name}</div>
    </div>
  )
}
