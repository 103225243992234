import axios from "axios"

import { toSearchParams } from "helpers/form"
import { pick } from "lodash"

import { receiveErrors, resetErrors } from "modules/errors/reducer"
import { errorsShownSelector } from "modules/errors/selectors"
import { loggedOut } from "store/auth"

import API from "constants/api"

export const authHeader = () => ({
  Authorization: `Bearer ${localStorage.getItem("assume_access_token") || localStorage.getItem("access_token")}`
})

export const api = (config) => async (dispatch, getState) => {
  const { url, method = "get", data, params, paramsOptions, onStart, onSuccess, onError, props = {}, refresh = true } = config
  if (onStart) {
    const res = await dispatch({ type: onStart, props })
    if (res.abort) return
  }

  return new Promise(async (resolve, reject) => {
    try {
      const defaultParams = toSearchParams(API.DEFAULT_PARAMS)
      const serializedParams = toSearchParams(params, paramsOptions, defaultParams)
      const response = await axios.request({
        baseURL: API.BASE_URL,
        params: serializedParams,
        url,
        method,
        data,
        headers: authHeader()
      })
      const state = getState()
      const hasErrors = errorsShownSelector(state)

      if (hasErrors) dispatch(resetErrors())
      if (onSuccess) dispatch({ type: onSuccess, payload: response.data, props })

      resolve(response.data)
    } catch (error) {
      if (refresh && config.onSuccess !== loggedOut.type && error?.response?.status === 401)
        return tokenRefresh().then(() => dispatch(api({ ...config, refresh: false })))

      const payload = {
        ...pick(error.response || {}, ["data", "status", "statusText"]),
        message: error.toString(),
        method,
        url
      }

      dispatch({ type: receiveErrors.type, payload, props })
      if (onError) dispatch({ type: onError, payload, props })
      reject(payload)
    }
  })
}

const tokenRefresh = async () => {
  const assumeRefreshToken = localStorage.getItem("assume_refresh_token")
  const refreshToken = assumeRefreshToken || localStorage.getItem("refresh_token")
  const prefix = assumeRefreshToken ? "assume_" : ""

  return new Promise(async (resolve, reject) => {
    try {
      const response = await axios.post(
        API.REFRESH_TOKEN_URL,
        {
          refresh_token: refreshToken,
          grant_type: "refresh_token"
        },
        authHeader()
      )

      localStorage.setItem(`${prefix}access_token`, response.data.access_token)
      localStorage.setItem(`${prefix}refresh_token`, response.data.refresh_token)
      resolve(response)
    } catch (error) {
      localStorage.removeItem(`${prefix}access_token`)
      localStorage.removeItem(`${prefix}refresh_token`)
      localStorage.removeItem(`${prefix}redirect_url`)
      reject(error)
    }
  })
}
