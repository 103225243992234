import React from "react"
import PropTypes from "prop-types"
import { useSearchParams } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Container } from "reactstrap"
import NavigationLink from "components/layout/NavigationLink"

import { useTranslation } from "react-i18next"
import { useScrollToActive } from "hooks/useScrollToActive"
import { scrollToActive } from "helpers/DOM"

Navigation.propTypes = {
  slug: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default function Navigation({ slug, className }) {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigationRef = useScrollToActive()

  const classes = []
  if (className) classes.push(className)

  const clickHandler = ({ currentTarget }) => scrollToActive(currentTarget)

  return (
    <nav className={classes.join(" ")}>
      <Container>
        <div className="full-window-scroll" ref={navigationRef}>
          <div className="hstack gap-20 py-10 py-sm-20">
            <NavigationLink
              to={oldRoutes.publicGuidePath({ slug }, searchParams)}
              end
              iconName="Home2"
              label={t("guides.title")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={oldRoutes.publicGuideTripsPath({ slug }, searchParams)}
              iconName="Trips2"
              label={t("trips.title")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={oldRoutes.publicGuideLocationPath({ slug }, searchParams)}
              iconName="Location"
              label={t("location.title")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={oldRoutes.publicGuideBoatsPath({ slug }, searchParams)}
              iconName="Boats"
              label={t("boats.title")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={oldRoutes.publicGuidePostsPath({ slug }, searchParams)}
              iconName="Feeder"
              label={t("posts.title")}
              onClick={clickHandler}
            />
            <NavigationLink
              to={oldRoutes.publicGuideReviewsPath({ slug }, searchParams)}
              iconName="Reviews"
              label={t("reviews.title")}
              onClick={clickHandler}
            />
          </div>
        </div>
      </Container>
    </nav>
  )
}
