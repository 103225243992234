import React from "react"
import ChangePassword from "components/settings/ChangePassword"

import { useTitle } from "hooks/useTitle"

export default function LoginAndSecurity() {
  useTitle("Settings — Login & Secuirity")

  return <ChangePassword />
}
