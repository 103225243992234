import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { formatPaginationParams } from "helpers/pagination"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("reviews/requested")
const received = createAction("reviews/received")
const failed = createAction("reviews/failed")
const cleaned = createAction("reviews/cleaned")

export const requestedReview = createAction("reviews/requestedReview")
export const createdReview = createAction("reviews/createdReview")
export const failedReview = createAction("reviews/failedReview")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  metadata: {
    page: null,
    total_pages: null,
    total_results: null,
    next: null,
    prev: null
  },
  review: {
    ...LOADING_INITIAL_STATE,
    id: null,
    body: "",
    rating: null,
    trip_id: ""
    // author_id: 1,
    // reviewable_id: 1,
    // reviewable_type: "Trip",
    // posted_at: "11:11, October 11, 2020",
    // created_at: "2020-10-11T11:11:11.000Z",
    // updated_at: "2020-10-11T11:11:11.000Z",
    // author: User:{}
  }
}

const reviewsReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = action.props.reset ? action.payload.data : state.items.concat(action.payload.data)
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedReview.type]: (state, action) => {
    setRequested(state.review)
  },
  [createdReview.type]: (state, action) => {
    setReceived(state.review)
    state.items = [action.payload, ...state.items]
  },
  [failedReview.type]: (state, action) => {
    setFailed(state.review)
  }
})
export default reviewsReducer

const getItems = (url, nextPage, params = {}, dispatch, getState) => {
  const state = getState()
  const paginationParams = formatPaginationParams(state.reviews.metadata, nextPage)

  return dispatch(
    api({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: failed.type,
      params: { ...paginationParams, ...params },
      props: { reset: !nextPage }
    })
  )
}

// PUBLIC ACTIONS

export const getClientGuideReviews = (id, nextPage, params) => (dispatch, getState) =>
  getItems(`/client/guides/${id}/reviews`, nextPage, params, dispatch, getState)

export const createReview = (tripId, data) =>
  api({
    url: `/trips/${tripId}/reviews`,
    method: "post",
    onStart: requestedReview.type,
    onSuccess: createdReview.type,
    onError: failedReview.type,
    data
  })

export const cleanReviews = () => (dispatch) => dispatch(cleaned())
