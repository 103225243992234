import { numberToCurrency } from "helpers/string"
import PropTypes from "prop-types"
import pluralize from "pluralize"
import React, { memo } from "react"

import { useTranslation } from "react-i18next"

const TotalPrice = ({ calculation, className }) => {
  const { t } = useTranslation()

  const classes = ["hstack gap-10 justify-content-between align-items-start"]
  if (className) classes.push(className)

  if (!calculation.pricingType) return null

  return (
    <div className={classes.join(" ")}>
      <div>
        <p className="h3 fw-bold lh-1 mb-0">Total</p>
        <p className="fs-7 lh-1 text-gray-light text-end m-0">
          <small>
            {t(`booking.calculation.total.${calculation.pricingType}`, {
              minimumPrice: numberToCurrency(calculation.minimumRate),
              minimumPersons: pluralize("person", calculation.minimumPersons, true),
              pricePerPerson: numberToCurrency(calculation.pricePerPerson)
            })}
          </small>
        </p>
      </div>
      <div>
        <p className="h3 fw-bold lh-1 mb-0">{numberToCurrency(calculation.totalPrice)}</p>
        <p className="fs-7 lh-1 text-gray-light text-end m-0">{/* <small>incl. tax</small> */}</p>
      </div>
    </div>
  )
}

TotalPrice.propTypes = {
  calculation: PropTypes.shape({
    pricingType: PropTypes.string.isRequired,
    minimumRate: PropTypes.number.isRequired,
    minimumPersons: PropTypes.number.isRequired,
    pricePerPerson: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired
  }).isRequired,
  className: PropTypes.string
}

export default memo(TotalPrice)
