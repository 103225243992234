import moment from "moment"
import { DateTime, Unit } from "@eonasdan/tempus-dominus"

import { DISPLAY_TYPE_FORMATS, FA_FIVE_ICONS, LOCALIZATION, MANIPULATION_TYPES, PICKER_FORMATS } from "modules/datepicker/constants"

export const dateTime = (value) => {
  if (value && value.length <= 10) value = value + 'T12:00:00'

  const date = value !== undefined ? (value ? new DateTime(value) : null) : new DateTime()
  if (!date) return date

  if (value && value.length <= 10) date.startOf("date")

  return DateTime.convert(date, "default", LOCALIZATION)
}

export const getPickerFormat = (type) => (Object.keys(PICKER_FORMATS).includes(type) ? PICKER_FORMATS[type] : PICKER_FORMATS.date)

export const getMomentValue = (value, format, defaultValue = null, shouldFormat = false) => {
  const parsedMomentValue = moment(value, format)
  return parsedMomentValue.isValid() ? (shouldFormat ? parsedMomentValue.format(format) : parsedMomentValue) : defaultValue
}

export const getManipulationType = (type) => MANIPULATION_TYPES[type] || MANIPULATION_TYPES.date
export const getDisplayFormat = (type) => DISPLAY_TYPE_FORMATS[type] || DISPLAY_TYPE_FORMATS.date

export const momentUnformatter = (value, format, defaultValue) => {
  if (value !== undefined) {
    const momentValue = getMomentValue(value, format)
    const isValid = momentValue?.isValid()
    return isValid ? global.dateTime(momentValue.toDate()) : undefined
  } else return defaultValue || value
}
export const momentFormatter = (value, format, emptyValue) => getMomentValue(value, format, emptyValue, true)

export const computedRangeStartEndDates = (date, type) => {
  const today = global.dateTime().startOf(MANIPULATION_TYPES.date)
  const currentDate = date || today
  const startDate = currentDate.clone.startOf(type, 1)
  const endDate = currentDate.clone.endOf(type, 1)

  return { date, startDate, endDate }
}

export const updateManipulationButton = (button, date, direction = 0, limitDate, type) => {
  if (direction === 0) return console.error("direction === 0")

  const forceDisable = !date
  if (forceDisable) button.disabled = forceDisable
  else {
    const { startDate, endDate } = computedRangeStartEndDates(date, type)
    const currentLimit = (direction > 0 && endDate) || (direction < 0 && startDate)
    const method = (direction > 0 && "isBefore") || (direction < 0 && "isAfter")

    button.disabled = limitDate ? limitDate.isSame(currentLimit) || limitDate[method](currentLimit) : false
  }
}

export const manipulateDate = (picker, direction = 0) => {
  if (!picker) return
  if (direction === 0) return console.error("direction === 0")

  const manipulationType = picker.optionsStore.options.meta.manipulationType
  const date = picker.dates.lastPicked
  const isWeek = manipulationType === "weekDay"

  if (!date) return null
  return date.clone.manipulate(isWeek ? direction * 7 : direction, isWeek ? "date" : manipulationType)
}

export const clampDate = (minDate, date, maxDate) => {
  let newDate = date
  if (minDate && newDate) newDate = global.dateTime(Math.max(minDate, newDate))
  if (maxDate && newDate) newDate = global.dateTime(Math.min(newDate, maxDate))
  return newDate
}

export const isSameDatetime = (dateTime1, dateTime2) => dateTime1?.format?.("L LTS") === dateTime2?.format?.("L LTS")

// Tempus-Dominus functions

export const formatInput = function (date) {
  return date ? moment(date).format(this.optionsStore.options.meta.format) : ""
}
export const parseInput = function (value) {
  const format = this.optionsStore.options.meta.format
  const momentValue = moment(value, format)
  if (momentValue.isValid()) return DateTime.convert(momentValue.toDate(), "default", LOCALIZATION)
  else return console.warn("Momentjs failed to parse the input date.")
}

export const paintSelectedWeek = (picker) => (unit, date, classes) => {
  const type = picker.optionsStore.options.meta.type
  if (type !== "week" || unit !== Unit.date) return

  const lastPicked = picker.dates?.lastPicked?.clone
  if (!lastPicked) return

  const dateStartDay = date.clone.startOf(Unit.date)
  const isSameWeek = lastPicked.week === dateStartDay.week || false
  if (!isSameWeek) return

  const isStartWeek = dateStartDay.weekDay === 1 || false
  const isEndWeek = dateStartDay.weekDay === 0 || false

  if (isStartWeek) classes.push("selected-week-start")
  if (isEndWeek) classes.push("selected-week-end")
  classes.push("selected-week")
}

export const loadFaIcons = (_, __, tdFactory) => {
  tdFactory.DefaultOptions.display.icons = FA_FIVE_ICONS
}
