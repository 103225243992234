import React, { memo } from "react"
import { Label, TabPane } from "reactstrap"
import PropTypes from "prop-types"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"

const UNAVAILABLE_TYPES = ["weekday", "range", "date"]
const UNAVAILABLE_DAYS = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]

const Weekdays = ({ form, changeHandler }) => {
  const { t } = useTranslation()

  return (
    <TabPane tabId={UNAVAILABLE_TYPES[0]}>
      <Label className="fs-6">{t("unavailable_day.labels.set_unavailable_week_days")}</Label>
      <div className="grid grid-cols-1 grid-cols-sm-2 gap-y-10 gap-x-20">
        {UNAVAILABLE_DAYS.map((day) => (
          <Label
            key={day}
            check
            className={`hstack gap-10 p-10 pe-15 bg-white border ${
              [true, "true"].includes(form.unavailable_weekdays[day]) ? "border-gray" : "border-gray-lightest"
            } rounded fs-7 fw-normal`}
          >
            <Input
              type="checkbox"
              name={`unavailable_weekdays.${day}`}
              checked={[true, "true"].includes(form.unavailable_weekdays[day])}
              onChange={changeHandler}
              className="mt-0"
            />
            <span className={`text-dark ${[true, "true"].includes(form.unavailable_weekdays[day]) ? "" : "text-opacity-50"}`}>
              {t(`unavailable_day.labels.unavailable_week_days.${day}`)}
            </span>
          </Label>
        ))}
      </div>
    </TabPane>
  )
}

Weekdays.propTypes = {
  form: PropTypes.shape({
    unavailable_weekdays: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.bool, PropTypes.string])).isRequired
  }).isRequired,
  changeHandler: PropTypes.func.isRequired
}
export default memo(Weekdays)
