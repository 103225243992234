import React from "react"
import { Button, Col, Row } from "reactstrap"
import PaymentMethodSelector from "components/stripe/payment/PaymentMethodSelector"
import StripeWrapper from "components/stripe/StripeWrapper"

import useForm from "hooks/useForm"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { paymentMethodsSelector, updateUser } from "store/user"

export default function PaymentDetailsForm() {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const user = useSelector((store) => store.user)
  const paymentMethods = useSelector(paymentMethodsSelector)
  const hasPaymentMethods = !!paymentMethods.length
  const [form, changeHandler, submitHandler, submitCallback, reset] = useForm(
    { default_payment_method_id: user.default_payment_method_id || (hasPaymentMethods ? null : {}) },
    ["default_payment_method_id"]
  )
  const isChanged = user.default_payment_method_id !== form.default_payment_method_id && !(form.default_payment_method_id instanceof Object)

  const paymentMethodChangeHandler = (value) => changeHandler({ target: { name: "default_payment_method_id", value } })

  submitCallback(() => {
    dispatch(updateUser(form))
  })

  return (
    <StripeWrapper>
      <form className="w-100" onSubmit={submitHandler}>
        <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
          <Col xs={12}>
            <PaymentMethodSelector active={form.default_payment_method_id} onChange={paymentMethodChangeHandler} client={user} create />
          </Col>
          <div className="hstack gap-20 justify-content-end mt-25">
            {isChanged && (
              <Button color="light" type="button" onClick={reset}>
                {t("cancel")}
              </Button>
            )}
            <Button color="primary" disabled={!isChanged}>
              {t("settings.update")}
            </Button>
          </div>
        </Row>
      </form>
    </StripeWrapper>
  )
}
