import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

function useAuthRedirectedToken(confirmAction, cb = () => {}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const userId = useSelector((state) => state.user.id)
  const searchParams = new URLSearchParams(window.location.search)
  const platform = searchParams.get("platform")
  const state = searchParams.get("state")
  const code = searchParams.get("code")
  const redirectPath = searchParams.get("redirect_path")

  useEffect(() => {
    console.log("platform", platform, { userId, platform, state, code, redirectPath })
    if (!userId) return
    if (platform || state || code || redirectPath)
      window.history.replaceState(window.history.state, document.title, window.location.href.replace(window.location.search, "")) || cb()
    if (redirectPath) navigate(redirectPath)
    if (state && code && platform) dispatch(confirmAction(platform, { state, code }))
  }, [userId, platform, state, code]) //eslint-disable-line
}

export default useAuthRedirectedToken
