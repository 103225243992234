import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Button } from "reactstrap"

import { useTranslation } from "react-i18next"
import { useWizardNavigation } from "hooks/wizard"

const ActionButtons = ({
  isChanged,
  action,
  name = "",
  stepsKey = name,
  withBack = true,
  withSkip = false,
  nextText = "",
  saveText = "",
  backText = "",
  skipText = "",
  submitText = "",
  showSubmitAfterCompleted = true,
  className,
  btnColor = "primary-second",
  nextClassName,
  backClassName,
  skipClassName,
  children,
  customNextStep,
  exitPath,
  showSaveAndExit = false
}) => {
  const { t } = useTranslation()
  const [isCurrentWizardStep, prevStepPath, nextStepPath, skipStep, submitWizard, wizardCompleted] = useWizardNavigation({
    name,
    stepsKey,
    action,
    customNextStep,
    exitPath
  })
  const classes = ["hstack gap-10 justify-content-center"]
  if (className) classes.push(className)
  const style = { minWidth: 100 }

  return nextStepPath ? (
    <>
      <div className={classes.join(" ")}>
        {withBack && prevStepPath && (
          <Button color={btnColor} className={backClassName} outline tag={Link} to={prevStepPath}>
            {backText || t("global.go_back")}
          </Button>
        )}
        {nextStepPath && (isChanged || isCurrentWizardStep) ? (
          <Button color={btnColor} className={nextClassName} type="submit" disabled={!isChanged} value={nextStepPath} style={style}>
            {nextText || t("global.next")}
          </Button>
        ) : (
          <Button color={btnColor} className={nextClassName} tag={Link} to={nextStepPath} style={style}>
            {nextText || t("global.next")}
          </Button>
        )}
        {showSaveAndExit &&
          (exitPath && (isChanged || isCurrentWizardStep) ? (
            <Button color={btnColor} className={nextClassName} type="submit" disabled={!isChanged} value={exitPath} style={style}>
              {saveText || t("global.save")}
            </Button>
          ) : (
            <Button color={btnColor} className={nextClassName} tag={Link} to={exitPath} style={style}>
              {saveText || t("global.save")}
            </Button>
          ))}
        {children}
      </div>
      {withSkip && nextStepPath && !wizardCompleted && (
        <div className="text-center mt-2">
          <Button color="link link-dark fs-6" className={skipClassName} onClick={skipStep}>
            {skipText || t("global.skip_step")}
          </Button>
        </div>
      )}
    </>
  ) : (
    <div className={classes.join(" ")}>
      {withBack && prevStepPath && (
        <Button color={btnColor} className={backClassName} outline tag={Link} to={prevStepPath}>
          {backText || t("global.go_back")}
        </Button>
      )}
      {(showSubmitAfterCompleted || !wizardCompleted) && (
        <Button color={btnColor} className={nextClassName} onClick={submitWizard} style={style}>
          {submitText || t("global.submit")}
        </Button>
      )}
      {children}
    </div>
  )
}

ActionButtons.propTypes = {
  isChanged: PropTypes.bool.isRequired,
  action: PropTypes.string.isRequired,
  name: PropTypes.string,
  stepsKey: PropTypes.string,
  withBack: PropTypes.bool,
  withSkip: PropTypes.bool,
  nextText: PropTypes.string,
  saveText: PropTypes.string,
  backText: PropTypes.string,
  skipText: PropTypes.string,
  submitText: PropTypes.string,
  showSubmitAfterCompleted: PropTypes.bool,
  className: PropTypes.string,
  btnColor: PropTypes.string,
  nextClassName: PropTypes.string,
  backClassName: PropTypes.string,
  skipClassName: PropTypes.string,
  children: PropTypes.node,
  customNextStep: PropTypes.func,
  exitPath: PropTypes.string,
  showSaveAndExit: PropTypes.bool
}

export default ActionButtons
