import { useEffect } from "react"

export function useTitle(title) {
  const ROOT_TITLE = "Digital Sportsman"

  useEffect(() => {
    const prevTitle = document.title || ROOT_TITLE
    document.title = `${title ? `${title} | ` : ""}${ROOT_TITLE}`
    return () => {
      document.title = prevTitle
    }
  })
}
