import React from "react"
import styles from "./SettingsCard.module.css"
import { Icon } from "ui/Icon"
import { useDevice } from "hooks/useDevice"
import { useNavigate } from "react-router-dom"

export const SettingsCard = ({ title, iconName, path }) => {
  const { isMobile } = useDevice()
  const iconSize = isMobile ? 24 : 44
  const navigate = useNavigate()
  const handleOnClick = () => {
    if (path) {
      navigate(path)
    }
  }
  return (
    <div className={styles.card} onClick={handleOnClick}>
      <div className={styles.cardIcon}>
        <Icon width={iconSize} height={iconSize} name={iconName} />
      </div>
      <div className={styles.cardTitle}>{title}</div>
    </div>
  )
}
