import { Form } from "antd"
import classNames from "classnames"
import "./Form.css"

export const CustomForm = ({ ...props }) => {
  const { className } = props
  return (
    <Form
      requiredMark={(label, { required }) => (
        <>
          {label}
          {required && " *"}
        </>
      )}
      {...props}
      className={classNames("custom-form", className)}
    />
  )
}
