import React, { memo, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Input } from "reactstrap"

SplittedInput.propTypes = {
  inputLength: PropTypes.number.isRequired,
  inputClass: PropTypes.string,
  containerClass: PropTypes.string,
  onCompleteHandler: PropTypes.func.isRequired
}
function SplittedInput(props) {
  const { inputLength, inputClass, containerClass, onCompleteHandler } = props

  const DEFAULT_VALUES = new Array(inputLength).fill("")
  const DEFAULT_REFS = new Array(inputLength).fill(null)
  const [value, setValue] = useState(DEFAULT_VALUES)
  const [ref] = useState(DEFAULT_REFS)

  const onChangeValue = (newValue, index) => {
    // We only update if it's a number.
    if (newValue.match(/(\d|^(?![\s\S]))/g) && newValue.length <= 2) {
      setValue([...value.slice(0, index), newValue.slice(-1), ...value.slice(index + 1)])
    }
    if (newValue.length > 0 && ref[index + 1]) {
      ref[index + 1].focus()
    }
  }

  const onRemove = (key, index, value) => {
    if (index === 0) return
    if (key === "Backspace" && value === "") {
      ref[index - 1].focus()
    }
  }

  useEffect(() => {
    if (value.every((elem) => elem !== "")) onCompleteHandler(value)
  }, [value, onCompleteHandler])

  const inputs = []

  for (let i = 0; i < inputLength; i++) {
    inputs.push(
      <Input
        key={i}
        name={`input-${i}`}
        innerRef={(input) => {
          ref[i] = input
        }}
        value={value[i]}
        className={inputClass}
        onChange={(e) => onChangeValue(e.target.value, i)}
        onKeyDown={(e) => onRemove(e.key, i, e.target.value)}
        required
      />
    )
  }
  return <div className={containerClass}>{inputs}</div>
}

export default memo(SplittedInput)
