import React from "react"
import styles from "./StripeAccountCard.module.css"
import stripeIcon from "assets/imagesV2/settings/stripe.svg"
import { Flex } from "antd"
import { spacings } from "themes/variables"
import { StripeActionButtons } from "./StripeActionButtons"
import { useDevice } from "hooks/useDevice"
import { StripeStatus } from "./StripeStatus"

export const StripeAccountCard = () => {
  const { isMobile } = useDevice()
  return (
    <div className={styles.wrapper}>
      <Flex gap={spacings.M} align="center">
        <img src={stripeIcon} alt={"Stripe icon"} className={styles.stripeLogo} />
        <Flex vertical gap={spacings.XXS}>
          <div className={styles.title}>Stripe</div>
          <div className={styles.account}>acct_34PgAgMrrfXIUdQ</div>
          {isMobile && <StripeStatus />}
        </Flex>
      </Flex>
      {!isMobile && (
        <Flex gap={spacings["3XL"]}>
          <StripeStatus />
          <StripeActionButtons />
        </Flex>
      )}
    </div>
  )
}
