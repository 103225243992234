import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { find, reject, capitalize } from "lodash"
import { formatPaginationParams } from "helpers/pagination"
import { toaster } from "components/common/Toast"
import { parseErrors } from "modules/errors/reducer"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("posts/requested")
const received = createAction("posts/received")
const failed = createAction("posts/failed")
const cleaned = createAction("posts/cleaned")

const requestedPost = createAction("posts/requestedPost")
const receivedPost = createAction("posts/receivedPost")
const failedPost = createAction("posts/failedPost")
const createdPost = createAction("posts/createdPost")
const updatedPost = createAction("posts/updatedPost")
const deletedPost = createAction("posts/deletedPost")
const cleanedPost = createAction("posts/cleanedPost")

const generatedPostText = createAction("posts/generatedPostText")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  metadata: {
    page: null,
    total_pages: null,
    total_results: null,
    next: null,
    prev: null
  },
  post: {
    ...LOADING_INITIAL_STATE,
    id: null,
    title: "",
    content: "",
    status: "",
    published_at: "",
    facebook_share: false,
    twitter_share: false,
    instagram_share: false,
    send_trip_photos: false,
    tags: [],
    photos: [],
    trip_id: ""
  }
}

const postsReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, { payload, props }) => {
    setReceived(state)
    state.items = props.reset ? payload.data : state.items.concat(payload.data)
    Object.assign(state.metadata, payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedPost.type]: (state) => {
    setRequested(state.post)
  },
  [receivedPost.type]: (state, action) => {
    setReceived(state.post)
    Object.assign(state.post, action.payload)
  },
  [createdPost.type]: (state, { payload }) => {
    setReceived(state.post)
    state.items = [payload.data, ...state.items]
    Object.assign(state.post, payload.data)

    socialResultToast(payload.social_publish_result)
  },
  [updatedPost.type]: (state, { payload }) => {
    setReceived(state.post)
    Object.assign(state.post, payload.data)

    const post = find(state.items, ["id", payload.data.id]) || {}
    Object.assign(post, payload.data)

    socialResultToast(payload.social_publish_result)
  },
  [generatedPostText.type]: (state, action) => {
    setReceived(state.post)
    Object.assign(state.post, { content: action.payload.paragraph_text })
  },
  [deletedPost.type]: (state, action) => {
    setReceived(state.post)
    state.items = reject(state.items, ["id", action.payload.id])
  },
  [failedPost.type]: (state) => {
    setFailed(state.post)
  },
  [cleanedPost.type]: (state) => {
    state.post = initialState.post
  }
})

export default postsReducer

const socialResultToast = (socialsResult) => {
  if (socialsResult && socialsResult instanceof Object) {
    Object.keys(socialsResult).forEach((key) => {
      if (!socialsResult[key]) return

      const parsedErrors = parseErrors(socialsResult[key].errors)
      if (socialsResult[key]?.["success?"] && parsedErrors)
        toaster.warn({ title: `${capitalize(key)} partially shared`, ...(parsedErrors && { text: parsedErrors }) })
      else if (socialsResult[key]?.["success?"])
        toaster.success({ title: `${capitalize(key)} has successfully shared`, ...(parsedErrors && { text: parsedErrors }) })
      else toaster.error({ title: `${capitalize(key)} sharing error`, ...(parsedErrors && { text: parsedErrors }) })
    })
  }
}

const getItems = (url, nextPage, params = {}, dispatch, getState) => {
  const state = getState()
  const paginationParams = formatPaginationParams(state.posts.metadata, nextPage)

  return dispatch(
    api({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: failed.type,
      params: { ...paginationParams, ...params },
      props: { reset: !nextPage }
    })
  )
}

const getItem = (url, id, dispatch, getState) => {
  const state = getState()
  const trip = find(state.posts.items, ["id", +id])

  if (trip) {
    dispatch(receivedPost(trip))
    return new Promise((resolve) => resolve(trip))
  }
  return dispatch(
    api({
      url,
      onStart: requestedPost.type,
      onSuccess: receivedPost.type,
      onError: failedPost.type
    })
  )
}

// PUBLIC ACTIONS

export const getGuidePosts = (nextPage, params) => (dispatch, getState) => getItems("/guide/posts", nextPage, params, dispatch, getState)
export const getClientGuidePosts = (id, nextPage, params) => (dispatch, getState) =>
  getItems(`/client/guides/${id}/posts`, nextPage, params, dispatch, getState)

export const getGuidePost = (id) => (dispatch, getState) => getItem(`/guide/posts/${id}`, id, dispatch, getState)

export const createPost = (data) =>
  api({
    url: "/guide/posts",
    method: "post",
    data,
    onStart: requestedPost.type,
    onSuccess: createdPost.type,
    onError: failedPost.type
  })

export const updatePost = (id, data) =>
  api({
    url: `/guide/posts/${id}`,
    method: "put",
    data: data,
    onStart: requestedPost.type,
    onSuccess: updatedPost.type,
    onError: failedPost.type
  })

export const savePost = (id, data) => (id ? updatePost(id, data) : createPost(data))

export const publishPostToggle = (id, published = true) =>
  api({
    url: `/guide/posts/${id}/update_status`,
    data: { status: published ? "published" : "draft" },
    method: "put",
    onStart: requestedPost.type,
    onSuccess: updatedPost.type,
    onError: failedPost.type
  })

export const deletePost = (id) =>
  api({
    url: `/guide/posts/${id}`,
    method: "delete",
    onStart: requestedPost.type,
    onSuccess: deletedPost.type,
    onError: failedPost.type
  })

export const cleanPosts = () => (dispatch) => dispatch(cleaned())
export const cleanPost = () => (dispatch) => dispatch(cleanedPost())
