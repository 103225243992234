import React from "react"
import { Container, Button } from "reactstrap"

const Transform = () => {
  const scrollToRequestDemo = () => {
    const demoRequestEl = document.querySelector("#demo-request")
    demoRequestEl.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Container>
      <Container
        className="vstack align-items-center text-center my-100 py-40 py-sm-70"
        style={{ border: "1px solid #0052B4", borderRadius: "24px" }}
      >
        <h2 className="lh-sm mb-25 fw-medium">Transform Your Guiding Business Today</h2>
        <p className="w-100 w-sm-75 w-lg-50 mb-20">
          Experience the future of guiding with Digital Sportsman. Discover how we can empower your operations, streamline your processes,
          and elevate client experiences—all on one integrated platform.
        </p>
        <Button color="primary-second fs-4 fw-medium" className="w-100 w-sm-auto" onClick={scrollToRequestDemo}>
          Contact Sales Today
        </Button>
      </Container>
    </Container>
  )
}

export default Transform
