import moment from "moment"
import { createTimeOfInterest } from "astronomy-bundle/time"
import { createSun } from "astronomy-bundle/sun"
import { createMoon } from "astronomy-bundle/moon"

const PHASE_CODES = {
  WNG: "waning_gibbous",
  WXG: "waxing_gibbous",
  FQ: "first_quarter",
  WXC: "waxing_crescent",
  WNC: "waning_crescent",
  LQ: "last_quarter",
  F: "full_moon",
  N: "null_moon"
}

const CODES_MAP = {
  0: null,
  1: null,
  2: null,
  3: 401,
  4: 401,
  5: 317,
  6: 410,
  7: 317,
  8: 411,
  9: 302,
  10: 411,
  11: 308,
  12: 308,
  13: 409,
  14: 409,
  15: 405,
  16: 409,
  17: 417,
  18: 416,
  19: 402,
  20: 402,
  21: 402,
  22: 402,
  23: 403,
  24: 404,
  25: 405,
  26: 119,
  27: 415,
  28: 413,
  29: 390,
  30: 374,
  31: 391,
  32: 113,
  33: 414,
  34: 407,
  35: 418,
  36: 113,
  37: 200,
  38: 200,
  39: 408,
  40: 263,
  41: 323,
  42: 338,
  43: 338,
  44: null,
  45: 392,
  46: 393,
  47: 394
}

export const weatherImage = (code) => {
  if (!code) return
  const iconCode = CODES_MAP[code]
  try {
    return require(`../assets/images/weather/${iconCode}.png`)
  } catch (e) {
    console.log(e)
    return ""
  }
}

export const moonPhaseImage = (illumination, waxing) => {
  const illuminationNumber = Math.round((illumination || 0) * 10)
  const phaseName =
    (illuminationNumber === 0 && PHASE_CODES.N) ||
    (illuminationNumber === 10 && PHASE_CODES.F) ||
    (waxing ? "waxing" : "waning") ||
    PHASE_CODES.N
  return require(`../assets/images/weather/moon_phase/${phaseName}_${illuminationNumber}.png`)
}

const timeOfInterestToMoment = (timeOfInterest) => {
  const { time } = timeOfInterest
  const offset = moment().utcOffset()
  const timeMoment = moment
    .utc({
      year: time.year,
      month: time.month - 1,
      day: time.day,
      hours: time.hour,
      minutes: time.min,
      seconds: time.sec
    })
    .utcOffset(offset)
  return timeMoment
}

export const sunRiseAndSet = async (location, date) => {
  if (!date || !location.lat || !location.lon) return {}
  const dateMomentUTC = moment(date).utc(date)
  if (!dateMomentUTC.isValid()) return

  const toi = createTimeOfInterest.fromDate(dateMomentUTC.toDate())
  const sunToday = createSun(toi)

  const data = {
    rise: null,
    set: null
  }

  try {
    data.rise = timeOfInterestToMoment(await sunToday.getRise(location))
  } catch (e) {
    console.log(e)
  }

  try {
    data.set = timeOfInterestToMoment(await sunToday.getSet(location))
  } catch (e) {
    console.log(e)
  }

  return data
}

export const sunRiseAndSetCycles = async (location, date) => {
  if (!date || !location.lat || !location.lon) return []
  const dateMoment = moment(date)
  const yesterdayMoment = dateMoment.clone().subtract(1, "day")
  const tomorrowMoment = dateMoment.clone().add(1, "day")

  const yesterday = await sunRiseAndSet(location, yesterdayMoment)
  const today = await sunRiseAndSet(location, dateMoment)
  const tomorrow = await sunRiseAndSet(location, tomorrowMoment)

  const cycles = [
    {
      overHorizon: false,
      start: yesterday.set,
      end: today.rise
    },
    {
      overHorizon: true,
      start: today.rise,
      end: today.set
    },
    {
      overHorizon: false,
      start: today.set,
      end: tomorrow.rise
    }
  ]
  return cycles
}

export const moonRiseAndSet = async (location, date) => {
  if (!date || !location.lat || !location.lon) return {}
  const dateMomentUTC = moment(date).utc(date)
  if (!dateMomentUTC.isValid()) return

  const toi = createTimeOfInterest.fromDate(dateMomentUTC.toDate())
  const moonToday = createMoon(toi)

  const data = {
    rise: null,
    set: null,
    illumination: await moonToday.getIlluminatedFraction(),
    waxing: await moonToday.isWaxing()
  }

  try {
    data.rise = timeOfInterestToMoment(await moonToday.getRise(location))
  } catch (e) {
    console.log(e)
  }

  try {
    data.set = timeOfInterestToMoment(await moonToday.getSet(location))
  } catch (e) {
    console.log(e)
  }

  return data
}

export const moonRiseAndSetCycles = async (location, date) => {
  if (!date || !location.lat || !location.lon) return []
  const dateMoment = moment(date)
  const yesterdayMoment = dateMoment.clone().subtract(1, "day")
  const tomorrowMoment = dateMoment.clone().add(1, "day")

  const yesterday = await moonRiseAndSet(location, yesterdayMoment)
  const today = await moonRiseAndSet(location, dateMoment)
  const tomorrow = await moonRiseAndSet(location, tomorrowMoment)

  const cycles =
    today.rise < today.set
      ? [
          {
            overHorizon: false,
            start: yesterday.set,
            end: today.rise || yesterday.rise,
            illumination: yesterday.illumination
          },
          {
            overHorizon: true,
            start: today.rise || yesterday.rise,
            end: today.set || tomorrow.set,
            illumination: today.illumination
          },
          {
            overHorizon: false,
            start: today.set || tomorrow.set,
            end: tomorrow.rise,
            illumination: tomorrow.illumination
          }
        ]
      : [
          {
            overHorizon: true,
            start: yesterday.rise,
            end: today.set || yesterday.set,
            illumination: yesterday.illumination
          },
          {
            overHorizon: false,
            start: today.set || yesterday.set,
            end: today.rise || tomorrow.rise,
            illumination: today.illumination
          },
          {
            overHorizon: true,
            start: today.rise || tomorrow.rise,
            end: tomorrow.set,
            illumination: tomorrow.illumination
          }
        ]
  return cycles
}

export const tooltip = (context) => {
  const tooltipModel = context.tooltip
  const position = context.chart.canvas.getBoundingClientRect()

  let tooltipEl = document.getElementById("chartjs-tooltip")
  if (!tooltipEl) {
    tooltipEl = document.createElement("div")
    tooltipEl.id = "chartjs-tooltip"
    tooltipEl.classList.add(
      "position-absolute",
      "z-1",
      "pointer-events-none",
      "bg-white",
      "border",
      "border-light",
      "shadow",
      "rounded",
      "px-10",
      "py-2",
      "ps-20"
    )
    document.body.appendChild(tooltipEl)
  }

  tooltipEl.style.opacity = tooltipModel.opacity
  tooltipEl.style.left = `${window.scrollX + position.left + tooltipModel.x}px`
  tooltipEl.style.top = `${window.scrollY + position.top + tooltipModel.y}px`
  tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`

  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || []
    const bodyLines = tooltipModel.body.map((body) => body.lines)

    const HTML = `
      <div class="text-center text-dark text-opacity-50 fs-7">
        ${titleLines.join("<br/>")}
      </div>
      <div class="hstack justify-content-center ms-n10">
        <div class="text-start fs-4 lh-sm">
          ${bodyLines.map(styleBodyLine).join("<br/>")}
        </div>
      </div>
    `
    tooltipEl.innerHTML = HTML
  }

  function styleBodyLine(body, i) {
    const colors = tooltipModel.labelColors[i]
    const pointStyle = `
      background-color: ${colors.backgroundColor};
      border: solid 2px ${colors.borderColor};
      border-radius: 10px;
      width: 10px;
      height: 10px;
    `
    const point = `<span style="${pointStyle}"></span>`
    return `<div class="hstack gap-2">${point}${body}</div>`
  }
}

export const removeTooltip = () => {
  const tooltipEl = document.getElementById("chartjs-tooltip")
  if (!tooltipEl) return
  tooltipEl.remove()
}
