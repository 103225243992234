import React, { memo, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Button, Col, Label, Row } from "reactstrap"
import { Accordion } from "react-bootstrap"
import Input from "components/form/Input"
import Icon from "components/common/Icon"
import Loading from "modules/loader-watchers/Loading"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import DynamicTag from "components/DynamicTag"

import useForm from "hooks/useForm"
import { useTranslation } from "react-i18next"
import { pick, omitBy } from "lodash"
import { hasEmptyFields } from "helpers/form"
import { toaster } from "components/common/Toast"
import { generateCountryOptions } from "components/bookings/helpers"
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js"

import { useDispatch, useSelector } from "react-redux"
import { receiveErrors, resetErrors } from "modules/errors/reducer"
import { attachPaymentMethod, failedPaymentMethods, requestedPaymentMethods, isGuideSelector } from "store/user"

const PaymentMethodNew = ({ active, saveCard, onChange, onSaveCard, client = {}, create = false, showPaymentMethods = false }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()
  const isGuide = useSelector(isGuideSelector)

  const [form, changeHandler, , , reset] = useForm({}, ["billing_details"])
  const userAddress = pick(client, ["address_line_1", "address_line_2", "city", "state", "country", "zip_code"])
  const disabledAddressAutoFill = !client.id || hasEmptyFields(userAddress, ["address_line_1", "city", "state", "country", "zip_code"])

  const [completedCardFields, setCompletedCardFields] = useState({})
  const isCardFieldsCompleted = completedCardFields.cardNumber && completedCardFields.cardExpiry && completedCardFields.cardCvc
  const isFullNameCompleted = useMemo(
    () => !hasEmptyFields(form.billing_details || {}, ["first_name", "last_name"]),
    [form.billing_details]
  )
  const isAddressCompleted = useMemo(
    () => !hasEmptyFields(form.billing_details?.address || {}, ["city", "country", "line1", "postal_code", "state"]),
    [form.billing_details]
  )
  const isCardCompleted = !!isCardFieldsCompleted && (isGuide || (!!isFullNameCompleted && !!isAddressCompleted))

  const collectedCardData = useMemo(() => {
    if (!stripe || !elements || !isCardCompleted) return {}
    const cardNumberElement = elements.getElement(CardNumberElement)
    return {
      type: "card",
      card: cardNumberElement,
      billing_details: {
        name: `${form.billing_details?.first_name} ${form.billing_details?.last_name}`,
        address: omitBy(form.billing_details?.address, (item) => item === "")
      }
    }
  }, [stripe, elements, form, isCardCompleted])

  const cardChangeHandler = (e) => setCompletedCardFields((prev) => ({ ...prev, [e.elementType]: e.complete }))

  const useAccountAddress = () => {
    changeHandler({ target: { name: "billing_details.first_name", value: client.first_name } })
    changeHandler({ target: { name: "billing_details.last_name", value: client.last_name } })
    changeHandler({ target: { name: "billing_details.address.line1", value: userAddress.address_line_1 } })
    changeHandler({ target: { name: "billing_details.address.line2", value: userAddress.address_line_2 } })
    changeHandler({ target: { name: "billing_details.address.city", value: userAddress.city } })
    changeHandler({ target: { name: "billing_details.address.state", value: userAddress.state } })
    changeHandler({ target: { name: "billing_details.address.postal_code", value: userAddress.zip_code } })
    changeHandler({ target: { name: "billing_details.address.country", value: userAddress.country } })
  }

  const onAttachHandler = (paymentMethodId) => () => {
    onChange(paymentMethodId)
    elements._elements.forEach((element) => element.clear())
    reset()
  }

  const createPaymentMethod = async () => {
    const isPaymentMethodBlank = !collectedCardData || (collectedCardData instanceof Object && !Object.keys(collectedCardData).length)
    if (!stripe || !elements || isPaymentMethodBlank) return false

    dispatch(requestedPaymentMethods())
    dispatch(resetErrors())

    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod(collectedCardData)
      dispatch(failedPaymentMethods())

      if (error) {
        if (error.type === "card_error")
          dispatch(
            receiveErrors({
              data: { message: { card_error: error.message } },
              showToast: false
            })
          )
        else toaster.error(error.message)
        return false
      }

      await dispatch(attachPaymentMethod(paymentMethod.id)).then(onAttachHandler(paymentMethod.id))
      return paymentMethod.id
    } catch (error) {
      toaster.error({ title: error.name, text: error.message })
      dispatch(failedPaymentMethods())
      return false
    }
  }

  useEffect(() => {
    if (!(active instanceof Object) || create) return
    onChange(collectedCardData)
  }, [isCardCompleted]) //eslint-disable-line

  const isOpen = active instanceof Object

  return (
    <DynamicTag
      tag={showPaymentMethods ? FirstLoading : "div"}
      {...(showPaymentMethods && { name: "user.payment_methods", className: "d-none" })}
    >
      <Loading
        tag={Accordion}
        onSelect={() => onChange(create ? {} : collectedCardData)}
        name="user.payment_methods"
        spinnerProps={{ className: "m-n2" }}
      >
        <Accordion.Item
          eventKey="new"
          className={["bg-white rounded border shadow overflow-hidden", isOpen ? "border-primary-second " : "border-gray-lightest"].join(
            " "
          )}
        >
          <Accordion.Button className="p-15 fs-5 lh-sm">
            <div className="p-0 rounded-circle border border-gray-lightest me-2">
              <Icon iconName="Close" size={24} className="rotate-45" block />
            </div>
            <span className="">{create ? "Add Payment Method" : "Add a new payment method"}</span>
          </Accordion.Button>
          <Accordion.Body className="vstack gap-20 p-15">
            <Row className="gap-y-15 gx-4">
              <Col xs={12}>
                <Label className="fs-7" for="credit_card_number">
                  {t("booking.wizard.step_3_checkout.labels.credit_card_number")} *
                </Label>
                <Input
                  id="credit_card_number"
                  tag={CardNumberElement}
                  options={{ showIcon: true, disableLink: true }}
                  onChange={cardChangeHandler}
                />
              </Col>
              <Col xs={12} md={6}>
                <Label className="fs-7" for="expiry_date">
                  {t("booking.wizard.step_3_checkout.labels.expiry_date")} *
                </Label>
                <Input id="expiry_date" tag={CardExpiryElement} onChange={cardChangeHandler} />
              </Col>
              <Col xs={12} md={6}>
                <Label className="fs-7" for="cvv">
                  {t("booking.wizard.step_3_checkout.labels.cvv")} *
                </Label>
                <Input id="cvv" tag={CardCvcElement} onChange={cardChangeHandler} />
              </Col>
              {!create && (
                <Col xs={12}>
                  <Label check className="hstack gap-10 d-inline-flex mt-2 fs-7 fw-normal">
                    <Input
                      type="checkbox"
                      name="save_card"
                      checked={saveCard}
                      onChange={() => onSaveCard?.(!saveCard)}
                      className="mt-0"
                      style={{ width: 12, height: 12 }}
                    />
                    <span className={`text-dark ${saveCard ? "" : "text-opacity-50"}`}>
                      {t(`booking.wizard.step_3_checkout.labels.save_card`)}
                    </span>
                  </Label>
                </Col>
              )}
            </Row>

            {!isGuide && (
              <Row className="gap-y-15 gx-4">
                <Col xs={12} className="hstack gap-20 justify-content-between">
                  <h3>{t("booking.wizard.step_3_checkout.billing_details")}</h3>
                  {!disabledAddressAutoFill && (
                    <Button
                      color="primary-second"
                      className="px-15 py-1 fs-7 fw-medium flex-shrink-0"
                      onClick={useAccountAddress}
                      disabled={disabledAddressAutoFill}
                    >
                      {t("booking.wizard.step_3_checkout.use_account_address")}
                    </Button>
                  )}
                </Col>
                <Col xs={12} md={6}>
                  <Label className="fs-7" for="first_name">
                    {t("settings.first_name")} *
                  </Label>
                  <Input
                    id="first_name"
                    type="text"
                    placeholder={t("settings.first_name")}
                    name="billing_details.first_name"
                    value={form.billing_details?.first_name || ""}
                    onChange={changeHandler}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Label className="fs-7" for="last_name">
                    {t("settings.last_name")} *
                  </Label>
                  <Input
                    id="last_name"
                    type="text"
                    placeholder={t("settings.last_name")}
                    name="billing_details.last_name"
                    value={form.billing_details?.last_name || ""}
                    onChange={changeHandler}
                  />
                </Col>
                <Col xs={12}>
                  <Label className="fs-7" for="address_line_1">
                    {t("settings.address.address_line_1")} *
                  </Label>
                  <Input
                    id="address_line_1"
                    type="text"
                    placeholder={t("settings.address.address_line_1")}
                    name="billing_details.address.line1"
                    value={form.billing_details?.address?.line1 || ""}
                    onChange={changeHandler}
                  />
                </Col>
                <Col xs={12}>
                  <Label className="fs-7" for="address_line_2">
                    {t("settings.address.address_line_2")}
                  </Label>
                  <Input
                    id="address_line_2"
                    type="text"
                    placeholder={t("settings.address.address_line_2")}
                    name="billing_details.address.line2"
                    value={form.billing_details?.address?.line2 || ""}
                    onChange={changeHandler}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Label className="fs-7" for="city">
                    {t("settings.address.city")} *
                  </Label>
                  <Input
                    id="city"
                    type="text"
                    placeholder={t("settings.address.city")}
                    name="billing_details.address.city"
                    value={form.billing_details?.address?.city || ""}
                    onChange={changeHandler}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Label className="fs-7" for="state">
                    {t("settings.address.state")} *
                  </Label>
                  <Input
                    id="state"
                    type="text"
                    placeholder={t("settings.address.state")}
                    name="billing_details.address.state"
                    value={form.billing_details?.address?.state || ""}
                    onChange={changeHandler}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <Label className="fs-7" for="country">
                    {t("settings.address.country")} *
                  </Label>
                  <Input
                    id="country"
                    type="select"
                    placeholder={t("settings.address.country")}
                    name="billing_details.address.country"
                    value={form.billing_details?.address?.country || ""}
                    onChange={changeHandler}
                    className={form.billing_details?.address?.country ? "" : "text-gray-light"}
                  >
                    {generateCountryOptions()}
                  </Input>
                </Col>
                <Col xs={12} md={6}>
                  <Label className="fs-7" for="postal_code">
                    {t("settings.address.zip_code")} *
                  </Label>
                  <Input
                    id="postal_code"
                    type="text"
                    placeholder={t("settings.address.zip_code")}
                    name="billing_details.address.postal_code"
                    value={form.billing_details?.address?.postal_code || ""}
                    onChange={changeHandler}
                  />
                </Col>
              </Row>
            )}

            {create && (
              <Button color="primary-second" onClick={createPaymentMethod} disabled={!isCardFieldsCompleted}>
                Create
              </Button>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Loading>
    </DynamicTag>
  )
}

PaymentMethodNew.propTypes = {
  active: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  saveCard: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onSaveCard: PropTypes.func,
  client: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip_code: PropTypes.string
  }),
  create: PropTypes.bool,
  showPaymentMethods: PropTypes.bool
}

export default memo(PaymentMethodNew)
