import React from "react"

export const Experiences = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_478_9435)">
      <path
        d="M11.76 0.47998C11.1037 0.47998 10.56 1.02373 10.56 1.67998V2.87998H4.79998C4.78498 2.87998 4.76998 2.87998 4.75498 2.87998C4.50748 2.90248 4.31811 3.11061 4.31998 3.35998V9.11998C4.31998 9.38436 4.53561 9.59998 4.79998 9.59998H10.56V11.04H2.87998C2.86498 11.04 2.84998 11.04 2.83498 11.04C2.70561 11.0531 2.58561 11.1169 2.50498 11.22L0.10498 14.1C-0.0356445 14.2762 -0.0356445 14.5237 0.10498 14.7L2.50498 17.58C2.59686 17.6944 2.73373 17.76 2.87998 17.76H10.56V24H11.52V17.76H12V24H12.96V17.76H19.2C19.4644 17.76 19.68 17.5444 19.68 17.28V11.52C19.68 11.2556 19.4644 11.04 19.2 11.04H12.96V9.59998H21.12C21.2662 9.59998 21.4031 9.53436 21.495 9.41998L23.895 6.53998C24.0356 6.36373 24.0356 6.11623 23.895 5.93998L21.495 3.05998C21.4031 2.94561 21.2662 2.87998 21.12 2.87998H12.96V1.67998C12.96 1.02373 12.4162 0.47998 11.76 0.47998ZM11.76 1.43998C11.8969 1.43998 12 1.54311 12 1.67998V2.87998H11.52V1.67998C11.52 1.54311 11.6231 1.43998 11.76 1.43998ZM5.27998 3.83998H20.895L22.89 6.23998L20.895 8.63998H5.27998V3.83998ZM11.52 9.59998H12V11.04H11.52V9.59998ZM3.10498 12H18.72V16.8H3.10498L1.10998 14.4L3.10498 12Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_478_9435">
        <rect width={width} height={height} fill="none" />
      </clipPath>
    </defs>
  </svg>
)
