import React, { useState } from "react"
import { FormGroup, Input, Label } from "reactstrap"
import { useTranslation } from "react-i18next"

export default function LanguageChanger() {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language)

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value)
    setLanguage(event.target.value)
  }

  return (
    <FormGroup noMargin>
      <Label for="lang">{t("settings.language")}</Label>
      <Input id="lang" type="select" name="lang" placeholder="" value={language} onChange={changeLanguage}>
        <option value="en">{t("settings.en")}</option>
        <option value="es">{t("settings.es")}</option>
      </Input>
    </FormGroup>
  )
}
