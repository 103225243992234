import React from "react"
import { createHashRouter, Navigate } from "react-router-dom"
import { routes } from "./routes.js"
import PrivateRoutes from "./PrivateRoutes"
import PublicRoutes from "./PublicRoutes"

import RouteChangeWatcher from "components/RouteChangeWatcher"
import RouteErrorBoundary from "modules/errors/RouteErrorBoundary"

import ScrollToTopLayout from "layouts/ScrollToTopLayout"
import DeviseLayout from "layouts/DeviseLayout"

// new routes
import { AntdComps } from "pages/AntdComps.js"

// authentication new routes
import { SignIn as SignInV2 } from "pagesV2/auth/SignIn"
import { SignUp as SignUpV2 } from "pagesV2/auth/SignUp"
import { SignUpOptions } from "pagesV2/auth/SignUp/Options"
import { NewPassword } from "pagesV2/auth/NewPassword"
import { ConfirmEmail } from "pagesV2/auth/SignUp/ConfirmEmail"
import { ConfirmPhone } from "pagesV2/auth/SignUp/ConfirmPhone"
import { ConfirmInfo } from "pagesV2/auth/SignUp/ConfirmInfo"
import { PasswordRecovery } from "pagesV2/auth/PasswordRecovery"
import { PasswordRecoveryEnterCode } from "pagesV2/auth/PasswordRecovery/EnterCode"
import { PasswordRecoveryCreatePassword } from "pagesV2/auth/PasswordRecovery/CreatePassword"
import { PasswordRecoverySuccess } from "pagesV2/auth/PasswordRecovery/Success"

// new layouts
import { PersonalInfoLayout } from "layoutsV2/pro/PersonalInfoLayout/PersonalInfoLayout.jsx"
import { ProfileInformation as PersonalInfoProfileInformation } from "ui/PersonalInfo/ProfileInformation"
import { PersonalInfoAddresses } from "ui/PersonalInfo/Addresses"
import { AdventureModeSelect } from "ui/AdventureModeSelect"

import { oldRouter } from "./old-router"
import { ProHeader } from "layoutsV2/pro/ProHeader"
import { ProHomeLayout } from "layoutsV2/pro/ProHomeLayout"
import { ProMessagesLayout } from "layoutsV2/pro/ProMessagesLayout"
import { ProSettingsLayout } from "layoutsV2/pro/ProSettingsLayout"
import { ProSettings } from "pagesV2/pro/ProSettings"
import { ProfileInformation } from "pagesV2/pro/ProfileInformation"
import { StripeAccount } from "pagesV2/pro/StripeAccount"
import { Messages } from "pagesV2/pro/Messages"
import LandingLayout from "layouts/LandingLayout.js"
import Landing from "pages/LandingPage.js"

export const router = createHashRouter([
  {
    element: (
      <RouteChangeWatcher>
        <ScrollToTopLayout />
      </RouteChangeWatcher>
    ),
    errorElement: (
      <DeviseLayout>
        <RouteErrorBoundary />
      </DeviseLayout>
    ),
    children: [
      ...oldRouter,
      {
        // Public only routes
        element: <PublicRoutes only />,
        children: [
          {
            element: <AntdComps />,
            path: routes.antd()
          },
          {
            element: <LandingLayout />,
            children: [{ path: routes.rootPath(), element: <Landing /> }]
          },
          // v2 paths start
          { path: routes.signInV2(), element: <SignInV2 /> },
          { path: routes.signUpV2(), element: <SignUpV2 /> },
          { path: routes.newPassword(), element: <NewPassword /> },
          { path: routes.signUpOptions(), element: <SignUpOptions /> },
          { path: routes.confirmEmail(), element: <ConfirmEmail /> },
          { path: routes.confirmPhone(), element: <ConfirmPhone /> },
          { path: routes.confirmInfo(), element: <ConfirmInfo /> },
          { path: routes.passwordRecovery(), element: <PasswordRecovery /> },
          { path: routes.passwordRecoveryEnterCode(), element: <PasswordRecoveryEnterCode /> },
          { path: routes.passwordRecoveryCreatePassword(), element: <PasswordRecoveryCreatePassword /> },
          { path: routes.passwordRecoverySuccess(), element: <PasswordRecoverySuccess /> }
          // v2 paths end
        ]
      },
      // V2 Guide routes
      {
        path: routes.proRootPath(),
        element: <PrivateRoutes accessRole="guide_user" />,
        children: [
          {
            path: routes.proRootPath(),
            element: <ProHeader />,
            children: [
              {
                path: routes.proHomeRootPath(),
                element: <ProHomeLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={routes.proSettingsRootPath()} />
                  },
                  {
                    path: routes.proSettingsRootPath(),
                    element: <ProSettingsLayout />,
                    children: [
                      { index: true, element: <ProSettings /> },
                      { path: routes.proSettingsStripeAccount(), element: <StripeAccount /> },
                      { path: routes.proSettingsProfileInformation(), element: <ProfileInformation /> }
                    ]
                  },
                  {
                    element: <ProMessagesLayout />,
                    path: routes.proMessagesRootPath(),
                    // element: <Messages />
                    children: [{ index: true, element: <Messages /> }]
                  }
                ]
              },
              {
                path: routes.proPersonalInfoRootPath(),
                element: <PersonalInfoLayout />,
                children: [
                  { index: true, element: <Navigate to={routes.proPersonalInfoProfileInfo()} /> },
                  { path: routes.proPersonalInfoProfileInfo(), element: <PersonalInfoProfileInformation /> },
                  { path: routes.proPersonalInfoAddressesPath(), element: <PersonalInfoAddresses /> },
                  { path: routes.proPersonalInfAdventureMode(), element: <AdventureModeSelect /> }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
])
