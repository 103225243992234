import { Text, Title } from "ui/Typography"

export const ContentHeader = ({ title, description }) => {
  return (
    <div>
      <div>{title && <Title level={3}>{title}</Title>}</div>
      {description && (
        <Text variant="body" size="md">
          {description}
        </Text>
      )}
    </div>
  )
}
