import React, { useState } from "react"
import { Input, Form } from "antd"
import { useTranslation } from "react-i18next"
import styles from "./CodeInput.module.css"

const initial = ["", "", "", "", ""]

export const CodeInput = ({ form, name = "code", initialValue = initial, help }) => {
  const { t } = useTranslation()
  const [code, setCode] = useState(initial)

  React.useEffect(() => {
    setCode(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    form.setFieldsValue({ [name]: code })
  }, [form, code, name])

  const handleChange = (value, index) => {
    const newCode = [...code]
    if (value === "") {
      newCode[index] = ""
      if (index > 0) {
        document.getElementById(`code-input-${index - 1}`).focus()
      }
    } else {
      newCode[index] = value

      if (value && index < 4) {
        document.getElementById(`code-input-${index + 1}`).focus()
      }
    }
    setCode(newCode)
  }

  const handlePaste = (e, index) => {
    const pastedData = e.clipboardData
      .getData("Text")
      .split("")
      .slice(0, 5 - index)
    const newCode = [...code]

    pastedData.forEach((digit, i) => {
      if (i + index < 5 && /^\d$/.test(digit)) {
        newCode[i + index] = digit
      }
    })

    setCode(newCode)
    if (pastedData.length > 0) {
      const nextIndex = Math.min(index + pastedData.length, 4)
      document.getElementById(`code-input-${nextIndex}`).focus()
    }
  }

  const validateCode = () => {
    if (code && code.length === 5 && code.every((digit) => /^\d$/.test(digit))) {
      return Promise.resolve()
    }
    return Promise.reject(new Error(t("form_validation.five_digit_code")))
  }

  return (
    <Form.Item name={name} rules={[{ validator: validateCode }]} help={help}>
      <div className={styles.codeInput}>
        {code.map((digit, index) => (
          <Input
            key={index}
            id={`code-input-${index}`}
            value={digit}
            placeholder="0"
            onChange={(e) => {
              const value = e.target.value
              // Allow only a single digit (0-9)
              if (/^\d?$/.test(value)) {
                handleChange(value, index)
              }
            }}
            maxLength={1}
            onPaste={(e) => handlePaste(e, index)}
          />
        ))}
      </div>
    </Form.Item>
  )
}

export default CodeInput
