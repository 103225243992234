import { createAction, createReducer, createSelector } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { receivedUser } from "./user"
import { collectionAnySelector, collectionSelector, metadataSelector } from "./selectors"

import { reject, find, orderBy, omit } from "lodash"
import { formatPaginationParams } from "helpers/pagination"

import { LOADING_INITIAL_STATE, isTimeout, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("conversations/requested")
const received = createAction("conversations/received")
const failed = createAction("conversations/failed")
const cleaned = createAction("conversations/cleaned")

export const receivedWSUnviewedCount = createAction("conversations/WS/receivedUnviewedCount")
export const receivedWSConversation = createAction("conversations/WS/receivedConversation")

const requestedConversation = createAction("conversations/requestedConversation")
const receivedConversation = createAction("conversations/receivedConversation")
const failedConversation = createAction("conversations/failedConversation")

const createdConversation = createAction("conversations/createdConversation")
const updatedConversation = createAction("conversations/updatedConversation")
const deletedConversation = createAction("conversations/deletedConversation")
const cleanedConversation = createAction("conversations/cleanedConversation")

const requestedMessage = createAction("conversations/requestedMessage")
const receivedMessage = createAction("conversations/receivedMessage")
const sentMessage = createAction("conversations/sentMessage")
const deletedMessage = createAction("conversations/deletedMessage")
const failedMessage = createAction("conversations/failedMessage")
const cleanedMessage = createAction("conversations/cleanedMessage")

const resetTotalUnviewedMessages = createAction("conversations/resetTotalUnviewedMessages")
const decriseTotalUnviewedMessages = createAction("conversations/decriseTotalUnviewedMessages")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  conversation: {
    ...LOADING_INITIAL_STATE,
    id: null,
    contact: {},
    last_message: {},
    unviewed: null,
    created_at: null
  },
  metadata: {
    page: null,
    total_pages: null,
    total_results: null,
    next_page: null,
    prev_page: null,
    total_unviewed_messages: 0
  },

  messages: {
    items: [],
    message: LOADING_INITIAL_STATE,
    metadata: {
      page: null,
      total_pages: null,
      total_results: null,
      next_page: null,
      prev_page: null
    }
  }
}

const conversationsReducer = createReducer(initialState, {
  [requested.type]: (state, action) => {
    if (isTimeout(state)) action.abort = true
    else setRequested(state, true)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = orderBy(action.payload.data, "updated_message_at", "desc")
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,

  [requestedConversation.type]: (state, action) => {
    setRequested(state.conversation, true)
    if (action.props?.reset) state.messages.items = []
  },
  [receivedConversation.type]: (state, action) => {
    setReceived(state.conversation)
    state.items.map((item) => Object.assign(item, { active: false }))

    const conversation = find(state.items, ["id", action.payload.id])
    Object.assign(conversation, omit(action.payload, "messages"), { active: true })

    state.items = orderBy(state.items, "updated_message_at", "desc")
    state.messages.items = action.props?.reset ? action.payload.messages.data : [...action.payload.messages.data, ...state.messages.items]
    Object.assign(state.messages.metadata, action.payload.messages.metadata)
  },
  [createdConversation.type]: (state) => {
    setReceived(state.conversation)
  },
  [updatedConversation.type]: (state, action) => {
    setReceived(state.conversation)
    const conversation = find(state.items, ["id", action.payload.id]) || {}
    const conversationId = conversation.id

    Object.assign(conversation, action.payload)
    state.items = orderBy(conversationId ? state.items : [action.payload, ...state.items], "updated_message_at", "desc")
  },
  [deletedConversation.type]: (state, action) => {
    setReceived(state.conversation)
    state.items = reject(state.items, ["id", action.payload.id])
  },
  [failedConversation.type]: (state) => {
    setFailed(state.conversation)
  },
  [cleanedConversation.type]: (state) => {
    state.conversation = initialState.conversation
  },
  [requestedMessage.type]: (state) => {
    setRequested(state.messages.message)
  },
  [receivedMessage.type]: (state, action) => {
    setRequested(state.messages.message)
    const activeConversation = find(state.items, "active") || {}
    if (activeConversation.id === action.payload.conversation_id) state.messages.items = [...state.messages.items, action.payload]
  },
  [sentMessage.type]: (state, action) => {
    setReceived(state.messages.message)
    state.messages.items = [...state.messages.items, action.payload]

    const conversation = find(state.items, ["id", action.payload.conversation_id]) || {}
    if (conversation) {
      conversation.updated_message_at = action.payload.created_at
      conversation.last_message = action.payload
      state.items = orderBy(state.items, "updated_message_at", "desc")
    }
  },
  [failedMessage.type]: (state) => {
    setFailed(state.messages.message)
  },
  [cleanedMessage.type]: (state) => {
    state.messages.message = initialState.messages.message
  },
  [receivedUser.type]: (state, action) => {
    const { unread_messages_count = 0 } = action.payload?.metadata || {}
    if (unread_messages_count) state.metadata.total_unviewed_messages = unread_messages_count
  },
  [receivedWSUnviewedCount.type]: (state, action) => {
    state.metadata.total_unviewed_messages = action.payload
  },
  [receivedWSConversation.type]: (state, action) => {
    const receivedConversation = action.payload.data || {}
    const receivedLastMessage = receivedConversation.last_message || {}

    if (receivedConversation.id) {
      const conversation = find(state.items, ["id", receivedConversation.id]) || {}
      const conversationId = conversation.id

      Object.assign(conversation, action.payload.data)
      state.items = orderBy(conversationId ? state.items : [action.payload.data, ...state.items], "updated_message_at", "desc")
    }

    if (receivedLastMessage.id) {
      const activeConversation = find(state.items, "active") || {}
      if (activeConversation.id === receivedLastMessage.conversation_id)
        state.messages.items = [...state.messages.items, receivedLastMessage]
    }

    Object.assign(state.metadata, action.payload.metadata)
  },

  [resetTotalUnviewedMessages.type]: (state) => {
    state.metadata.total_unviewed_messages = 0
  },

  [decriseTotalUnviewedMessages.type]: (state, { payload }) => {
    state.metadata.total_unviewed_messages = payload
  }
})
export default conversationsReducer

const getItems = (url, nextPage, params = {}, dispatch, getState) => {
  const state = getState()
  const paginationParams = formatPaginationParams(state.conversations.metadata, nextPage)

  return dispatch(
    api({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: failed.type,
      params: { ...paginationParams, ...params },
      props: { reset: !nextPage }
    })
  )
}

export const getConversations = (nextPage, params) => (dispatch, getState) =>
  getItems("/conversations", nextPage, params, dispatch, getState)

export const getConversation = (id, nextPage) => (dispatch, getState) =>
  dispatch(
    api({
      url: `/conversations/${id}`,
      onStart: requestedConversation.type,
      onSuccess: receivedConversation.type,
      onError: failedConversation.type,
      params: formatPaginationParams(getState().conversations.messages.metadata, nextPage),
      props: { reset: !nextPage }
    })
  )

export const createConversation = (data) =>
  api({
    url: "/conversations",
    method: "post",
    onStart: requestedConversation.type,
    onSuccess: createdConversation.type,
    onError: failedConversation.type,
    data
  })

export const updateConversation = (id, data) =>
  api({
    url: `/conversations/${id}`,
    method: "put",
    data: data,
    onStart: requestedConversation.type,
    onSuccess: updatedConversation.type,
    onError: failedConversation.type
  })

export const deleteConversation = (id) =>
  api({
    url: `/conversations/${id}`,
    method: "delete",
    onStart: requestedConversation.type,
    onSuccess: deletedConversation.type,
    onError: failedConversation.type
  })

export const cleanConversations = () => cleaned()
export const cleanConversation = () => cleanedConversation()

export const sendMessage = (conversationId, data) =>
  api({
    url: `/conversations/${conversationId}/messages`,
    method: "post",
    data,
    onStart: requestedMessage.type,
    onSuccess: sentMessage.type,
    onError: failedMessage.type
  })

export const deleteMessage = (conversationId, message_id) =>
  api({
    url: `/conversations/${conversationId}/delete_message`,
    method: "delete",
    data: { message_id },
    onStart: requestedMessage.type,
    onSuccess: deletedMessage.type,
    onError: failedMessage.type
  })

export const resetUnviewedMessagesCount = () => (dispatch) => {
  dispatch(resetTotalUnviewedMessages())
}

export const setViewedMessagesCount = (count) => (dispatch) => {
  dispatch(decriseTotalUnviewedMessages(count))
}

// SELECTORS
export const activeConversationSelector = createSelector(
  collectionSelector("conversations"),
  (conversations) => find(conversations, "active") || {}
)

export const messagesSelector = createSelector((state) => state.conversations, collectionSelector("messages"))
export const messagesAnySelector = createSelector((state) => state.conversations, collectionAnySelector("messages"))
export const messagesMetadataSelector = createSelector((state) => state.conversations, metadataSelector("messages"))
export const isMyMessageSelector = (message) =>
  createSelector(
    (state) => state.user.id,
    (userId) => message.author_id === userId
  )
