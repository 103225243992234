import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import { Input as BaseInput, FormText } from "reactstrap"
import PhoneInput from "react-phone-input-2"
import InputError from "./InputError"
import DynamicTag from "components/DynamicTag"

import { useSelector } from "react-redux"
import { errorFieldSelector } from "modules/errors/selectors"

function Input(
  {
    withError = false,
    hint = null,
    hintClassName,
    withWrapper = false,
    wrapperClassName = "",
    errorClassName,
    errorField,
    invalid,
    ...rest
  },
  ref
) {
  const field = errorField || rest.name || ""
  const error = useSelector(errorFieldSelector(field))

  return (
    <DynamicTag tag={withWrapper ? "div" : React.Fragment} {...(withWrapper ? { className: wrapperClassName } : {})}>
      {rest.type === "tel" ? (
        <PhoneInput
          country="us"
          countryCodeEditable={false}
          invalid={invalid || !!error}
          {...rest}
          value={rest.value.toString().replace("+", "")}
          onChange={(value) => rest.onChange?.({ target: { name: rest.name, value: `+${value}` } })}
          inputClass={[rest.className ? rest.className : "", invalid || !!error ? "is-invalid" : ""].join(" ")}
          innerRef={ref}
        />
      ) : (
        <BaseInput invalid={invalid || !!error} {...rest} innerRef={ref} />
      )}
      {hint && <FormText className={hintClassName}>{hint}</FormText>}
      {withError && <InputError field={field} className={`mt-1 ${errorClassName || "mb-n3"}`} />}
    </DynamicTag>
  )
}

Input.propTypes = {
  withError: PropTypes.bool,
  hint: PropTypes.node,
  hintClassName: PropTypes.string,
  withWrapper: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  errorField: PropTypes.string,
  invalid: PropTypes.bool
}

export default forwardRef(Input)
