import React, { useState, useEffect, useCallback } from "react"
import { TabContent } from "reactstrap"
import Header from "components/weather/Header"
import General from "components/weather/screens/General"
import Temperature from "components/weather/screens/Temperature"
import RealFeel from "components/weather/screens/RealFeel"
import Humidity from "components/weather/screens/Humidity"
import Pressure from "components/weather/screens/Pressure"
import Visibility from "components/weather/screens/Visibility"
import Sun from "components/weather/screens/Sun"
import Moon from "components/weather/screens/Moon"
import UVIndex from "components/weather/screens/UVIndex"
import Precipitation from "components/weather/screens/Precipitation"
import Wind from "components/weather/screens/Wind"
import Tides from "components/weather/screens/Tides"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"

import useSearchFilter from "hooks/useSearchFilter"
import { isEqual } from "lodash"
import { useTitle } from "hooks/useTitle"

import { useDispatch } from "react-redux"
import { guideForecast, cleanWeather } from "store/weather"

export default function Weather() {
  useTitle("Weather")
  useLoaderWatchers({ watchers: ["weather"] })
  const dispatch = useDispatch()
  const [params, updateSearchFilters] = useSearchFilter({ accessAttributes: ["range", "date", "type", "trip_id", "units"] })
  const [paramsState, setParamsState] = useState(params)

  const changeHandler = useCallback(
    ({ target }) =>
      setParamsState((prevParamsState) => ({
        ...prevParamsState,
        [target.name]: target.value
      })),
    []
  )

  const toNextDaysHandler = ({ target: { value = null } }) => {
    changeHandler({ target: { value: "7_days", name: "range" } })
    changeHandler({ target: { value, name: "type" } })
  }

  const fetchWeather = useCallback(() => {
    const data = {}
    if (params.range === "7_days" && params.date) data.forecast_date = params.date
    if (params.trip_id) data.trip_id = params.trip_id

    dispatch(guideForecast(data))
  }, [params.date, params.trip_id]) //eslint-disable-line

  useEffect(() => {
    if (isEqual(paramsState, params)) return
    updateSearchFilters(paramsState)
  }, [paramsState]) //eslint-disable-line

  useEffect(() => {
    fetchWeather()
  }, [fetchWeather])

  useEffect(() => () => dispatch(cleanWeather()), []) //eslint-disable-line

  return (
    <div className="flex-fill z-0 position-relative">
      <Header params={params} onChangeFilters={changeHandler} />
      <FirstLoading name="weather">
        <TabContent activeTab={params.type || "general"} className="w-100">
          <General onNextDays={toNextDaysHandler} />
          <Temperature params={params} onChangeFilters={changeHandler} />
          <RealFeel params={params} onChangeFilters={changeHandler} />
          <Humidity params={params} onChangeFilters={changeHandler} />
          <Pressure params={params} onChangeFilters={changeHandler} />
          <Precipitation params={params} onChangeFilters={changeHandler} />
          <Wind params={params} onChangeFilters={changeHandler} />
          <Visibility params={params} onChangeFilters={changeHandler} />
          <UVIndex params={params} onChangeFilters={changeHandler} />
          <Sun params={params} onChangeFilters={changeHandler} />
          <Moon params={params} onChangeFilters={changeHandler} />
          <Tides params={params} onChangeFilters={changeHandler} />
        </TabContent>
      </FirstLoading>
    </div>
  )
}
