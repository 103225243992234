import React from "react"

export const Tent = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_407_18221)">
      <path
        d="M6.73113 2.87624C6.51551 2.87436 6.32426 3.01686 6.26426 3.22499C6.26426 3.22499 6.26426 3.22499 6.26238 3.22499L0.048633 16.0237C-0.0132423 16.1494 -0.0169923 16.2975 0.0392578 16.425C0.0955079 16.5544 0.206133 16.6519 0.341133 16.6931C0.341133 16.6931 3.11051 17.535 3.76488 17.7469C3.98238 17.8162 4.21676 17.7262 4.33113 17.5275C5.20113 15.9919 5.79738 14.2969 6.21176 12.9825C6.59613 14.5837 7.23176 16.9612 8.36051 19.1756C8.41676 19.2881 8.51613 19.3744 8.63801 19.4137C9.39363 19.6612 13.4943 21.0056 13.6649 21.0619C13.7811 21.1275 13.9199 21.1425 14.0474 21.1031C14.0511 21.1031 14.053 21.1012 14.0549 21.1012C14.0605 21.0994 14.0661 21.0975 14.0718 21.0956C14.0736 21.0956 14.0755 21.0937 14.0774 21.0919L14.0999 21.0844L23.6774 17.7319C23.803 17.6887 23.9043 17.5969 23.9586 17.475C24.0111 17.355 24.013 17.2181 23.9624 17.0962L18.2024 3.17624C18.1536 3.05624 18.058 2.96249 17.9361 2.91374C17.8161 2.86686 17.6811 2.86874 17.563 2.92124C17.3586 3.01311 16.7136 3.18186 15.8924 3.31124C15.0711 3.43874 14.0474 3.54561 12.9655 3.59436C10.8018 3.69186 8.38676 3.53249 6.91676 2.91749C6.91488 2.91561 6.91113 2.91561 6.90738 2.91374C6.89613 2.90811 6.88488 2.90436 6.87176 2.90061C6.86988 2.89874 6.86613 2.89874 6.86238 2.89874C6.86051 2.89686 6.85863 2.89686 6.85488 2.89499C6.81551 2.88374 6.77426 2.87811 6.73113 2.87624ZM17.4693 3.91499L22.8824 16.995L14.1899 20.0381L7.57488 4.13811C9.19488 4.56374 11.1805 4.63499 13.0086 4.55249C14.1261 4.50374 15.1799 4.39311 16.0405 4.25999C16.6818 4.15874 17.0755 4.03874 17.4693 3.91499ZM6.69738 4.52811L13.0743 19.8581C12.0074 19.5075 9.94488 18.8325 9.14238 18.57C7.65551 15.555 6.73676 11.0737 6.73676 11.0737C6.69363 10.8544 6.50426 10.695 6.28113 10.6875C6.05801 10.6819 5.85926 10.8281 5.80301 11.0456C5.80301 11.0456 4.86738 14.3869 3.64301 16.7044C2.82926 16.4512 1.69113 16.1025 1.15676 15.9394L6.69738 4.52811Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_407_18221">
        <rect width={width} height={height} fill="none" />
      </clipPath>
    </defs>
  </svg>
)
