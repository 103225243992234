import React from "react"
import { Button, Flex, Switch } from "antd"
import { Text, Paragraph, Title } from "ui/Typography"
import { CustomInput, PhoneInput, TextArea } from "ui/Input"
import { Icon } from "ui/Icon"
import { Tag } from "ui/Tag"
import { useTheme } from "context/AntdThemeContext"
import { themes } from "constants/theme"

export const AntdComps = () => {
  const { switchTheme, theme } = useTheme()
  const onChange = (checked) => {
    switchTheme(checked ? themes.sportsman : themes.sportswoman)
  }

  return (
    <div style={{ padding: "20px 60px 200px", maxHeight: "100vh", overflowY: "scroll" }}>
      <h3>
        Toggle theme: <Switch defaultChecked onChange={onChange} />
        {"  active theme is: "} {theme}
      </h3>
      <div>
        <h3>Large buttons</h3>
        <Flex gap="small" wrap>
          <Button color="primary" variant="solid" size="large">
            Primary Button
          </Button>
          <Button color="primary" variant="outlined" size="large">
            Primary Outlined
          </Button>
          <Button color="primary" variant="link" size="large">
            Primary Text
          </Button>
          <Button color="danger" variant="solid" size="large">
            Danger button
          </Button>
          <Button color="danger" variant="outlined" size="large">
            Danger button
          </Button>
          <Button color="default" variant="outlined" size="large">
            Default Outlined
          </Button>
          <Button color="default" variant="text" size="large">
            Default Text
          </Button>
        </Flex>
        <br />
        <br />
        <h3>buttons</h3>
        <Flex gap="small" wrap>
          <Button color="primary" variant="solid">
            Primary Button
          </Button>
          <Button color="primary" variant="outlined">
            Primary Outlined
          </Button>
          <Button color="primary" variant="link">
            Primary Text
          </Button>
          <Button color="danger" variant="solid">
            Danger button
          </Button>
          <Button color="danger" variant="outlined">
            Danger button
          </Button>
          <Button color="default" variant="outlined">
            Default Outlined
          </Button>
          <Button color="default" variant="text">
            Default Text
          </Button>
        </Flex>
      </div>
      <br />
      <br />
      <h3>Switch</h3>
      <div>
        <Flex gap="14px 14px" wrap>
          <Switch defaultChecked />
          <Switch size="small" defaultChecked />
          <Switch defaultChecked disabled />
          <Switch disabled />
        </Flex>
      </div>
      <br />
      <br />
      <Flex vertical gap="large" style={{ width: 500 }}>
        <div>
          <h3>Bordered Inputs</h3>
          <Flex gap="small" wrap>
            <CustomInput placeholder="Bordered Input Empty" />
            <CustomInput value="Bordered Input with Text" />
            <CustomInput status="error" value="Bordered Input Error" />
            <CustomInput disabled value="Bordered Input Disabled" />
          </Flex>
        </div>
        <div>
          <h3>Line Inputs</h3>
          <Flex gap="small" wrap>
            <CustomInput borderless={true} placeholder="Line Input Empty" />
            <CustomInput borderless={true} value="Line Input with Text" />
            <CustomInput borderless={true} status="error" value="Line Input Error" />
            <CustomInput borderless={true} disabled value="Line Input Disabled" />
          </Flex>
        </div>
        <div>
          <h3>Bordered Textarea Inputs</h3>
          <Flex gap="small" wrap>
            <TextArea placeholder="Textarea Input Empty" />
            <TextArea value="Textarea Input with Text" />
            <TextArea status="error" value="Textarea Input Error" />
            <TextArea disabled value="Textarea Input Disabled" />
          </Flex>
        </div>
        <div>
          <h3>Line Textarea Inputs</h3>
          <Flex gap="small" wrap>
            <TextArea borderless={true} placeholder="Line Textarea Input Empty" />
            <TextArea borderless={true} value="Line Textarea Input with Text" />
            <TextArea borderless={true} status="error" value="Line Textarea Input Error" />
            <TextArea borderless={true} disabled value="Line Textarea Input Disabled" />
          </Flex>
        </div>
        <div>
          <h3>Phone Input</h3>
          <Flex gap="small" wrap>
            <PhoneInput />
            <PhoneInput initialPhoneNumber="XXX-XXX-XXXX"/>
            <PhoneInput disabled value="XXX-XXX-XXXX"/>
            <PhoneInput borderless={true} />
            <PhoneInput borderless={true} value="XXX-XXX-XXXX" />
          </Flex>
        </div>
      </Flex>
      <br />
      <br />
      <>
        <h3>Titles</h3>
        <Title>h1</Title>
        <Title level={2}>h2</Title>
        {/* could be Typography.Title */}
        <Title level={3}>h3</Title>
        <Title level={4}>h4</Title>
      </>
      <>
        <h3>Texts</h3>
        <h4>Subtitle Large</h4>
        <Text variant="sub" size="lg">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Text>
        <br />
        <br />
        <h4>Subtitle MD</h4>
        <Text variant="sub" size="md">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Text>
        <br />
        <br />
        <h4>Text Body Large</h4>
        <Text variant="body" size="lg">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Text>
        <br />
        <br />
        <h4>Text Body MD</h4>
        <Text variant="body" size="md">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Text>
        <br />
        <br />
        <h4>Text Caption</h4>
        <Text variant="caption">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Text>
        <br />
        <br />
        <h4>Paragraph Large</h4>
        <Paragraph size="lg">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Paragraph>
        <br />
        <h4>Paragraph MD</h4>
        <Paragraph size="md">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
        </Paragraph>
      </>
      <>
        <h3>Feather Icons</h3>
        <a href="https://feathericons.com/" target="_blank">
          Feather Icons List
        </a>{" "}
        can be found here. Use camel cased name as type property. For example arrow-up: name="ArrowUp" type="feather"
        <div>
          <Flex gap="small" wrap>
            <Icon type="feather" name="ArrowUp" />
            <Icon type="feather" name="Aperture" />
            <Icon type="feather" name="ArrowDownCircle" />
            <Icon type="feather" name="CheckSquare" />
            <Icon type="feather" name="Chrome" />
            <Icon type="feather" name="CloudRain" />
          </Flex>
        </div>
        <br />
        <h3>Custom Icons</h3>
        <div>
          <Flex gap="small" wrap>
            <Icon name="island" />
            <Icon name="divingMask" width={35} height={35} />
            <Icon name="island" width={45} height={45} color="red" />
            <Icon name="training" />
            <Icon name="fishingBoat" />
            <Icon name="canoeing" />
            <Icon name="fish" />
            <Icon name="paperMap" />
            <Icon name="yacht" />
            <Icon name="surfingBoard" />
            <Icon name="balloon" />
            <Icon name="surfing" />
            <Icon name="paddling" />
            <Icon name="horseRiding" />
            <Icon name="parachute" />
            <Icon name="chaise" />
            <Icon name="waterSkiing" />
            <Icon name="golfMobile" />
            <Icon name="tent" />
            <Icon name="dock" />
            <Icon name="booking" />
            <Icon name="gallery" />
            <Icon name="dashboard" />
            <Icon name="calendar" />
            <Icon name="marketing" color="purple" height={50} width={50} />
            <Icon name="finances" />
            <Icon name="boat" />
            <Icon name="notification" />
            <Icon name="weather" />
            <Icon name="experiences" />
            <Icon name="support" />
            <Icon name="faq" />
            <Icon name="qr" />
            <Icon name="eBike" />
            <Icon name="scooter" />
            <Icon name="settings" />
          </Flex>
        </div>
        <br />
        <h3>Tags (status component)</h3>
        <div>
          <Flex gap="4px 0" wrap>
            <Tag type="processing">processing</Tag>
            <Tag type="success">success</Tag>
            <Tag type="error">error</Tag>
            <Tag type="warning">warning</Tag>
            <Tag color="default">default</Tag>
          </Flex>
        </div>
      </>
    </div>
  )
}
