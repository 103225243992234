import React, { memo, useMemo } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Accordion } from "react-bootstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import { DropdownToggle } from "reactstrap"
import Datepicker from "modules/datepicker/Datepicker"
import Precipitation from "components/weather/widgets/Precipitation"
import Icon from "components/common/Icon"
import WindTable from "components/weather/widgets/WindTable"
import Spinner from "components/common/Spinner"

import { numberToCurrency } from "helpers/string"
import { useTranslation } from "react-i18next"
import { find } from "lodash"

import { useSelector } from "react-redux"

const Sidebar = ({ className, params, onChangeFilters }) => {
  const { t } = useTranslation()
  const { loading, metadata } = useSelector((store) => store.dashboard)
  const hasExpenses = !!metadata.expenses_last?.length

  const {
    loading: loadingWeather,
    metadata: { trip_locations }
  } = useSelector((state) => state.weather)
  const forecast = useSelector((state) => find(state.weather.forecast, ["forecast_date", params.date]) || state.weather.forecast?.at(0))
  const forecastLocation = forecast?.location || ""
  const selectedTrip = useMemo(() => find(trip_locations, ["address", forecastLocation]) || {}, [trip_locations, forecastLocation])
  const showWeather = !!params.weather

  const profitIsPositiveOrZero = parseFloat(metadata.profit_stats) >= 0

  const classes = ["overflow-y-auto flex-shrink-0 position-relative"]
  if (className) classes.push(className)

  return (
    <div className={classes.join(" ")} style={{ maxWidth: 310 }}>
      <Datepicker
        tag="div"
        name="date"
        value={params.date ? global.dateTime(params.date) : null}
        defaultValue={global.dateTime().startOf("date")}
        onChange={onChangeFilters}
        display={{ inline: true, buttons: {} }}
        inputProps={{ className: "" }}
      />

      {showWeather ? (
        <div className="vstack gap-20 mt-20 p-10 position-relative">
          {forecastLocation && trip_locations.length > 0 && (
            <Dropdown
              className="mx-n1"
              container="body"
              toggleButton={
                <DropdownToggle color="ghost" className="hstack gap-2 rounded-1 m-0 p-1 w-100 mw-100">
                  <span className="h4 fw-medium lh-1 text-truncate">{selectedTrip.address}</span>
                  <Icon iconName="SidebarToggle" size={14} className="flex-shrink-0 ms-auto" />
                </DropdownToggle>
              }
            >
              {trip_locations.map((location) => (
                <DropdownItem
                  key={location.id}
                  name="trip_id"
                  value={location.id}
                  onClick={onChangeFilters}
                  active={selectedTrip.address === location.address}
                >
                  {location.address}
                </DropdownItem>
              ))}
            </Dropdown>
          )}

          <div className="vstack gap-10">
            <h5>{t("weather.precipitation")}</h5>
            <Precipitation date={params.date} onlyBody small />
          </div>

          <div className="vstack gap-10">
            <h5>{t("weather.wind")}</h5>
            <WindTable date={params.date} onlyBody small />
          </div>

          {loadingWeather && <Spinner className="bg-white bg-opacity-0 backdrop-blur-3 w-100 h-100" absolute />}
        </div>
      ) : (
        <>
          <div className="vstack gap-15 p-10 mt-10">
            <div className="hstack gap-10 h5">
              <Icon iconName="Income" size={24} />
              {t("accounting.labels.incomes")}
              <span className="ms-auto">{numberToCurrency(metadata.incomes_stats)}</span>
            </div>
            <Accordion>
              <Accordion.Item eventKey="expenses">
                <div className="hstack gap-10 fs-7 fw-semibold">
                  {hasExpenses ? (
                    <Accordion.Button className="gap-1 w-auto p-0 fs-7 fw-semibold">{t("accounting.labels.expenses")}</Accordion.Button>
                  ) : (
                    t("accounting.labels.expenses")
                  )}
                  <span className="ms-auto">{numberToCurrency(metadata.expenses_stats)}</span>
                </div>
                {hasExpenses && (
                  <Accordion.Body className="vstack gap-1 mt-10 p-0 fs-7">
                    {metadata.expenses_last?.map((item) => (
                      <div className="hstack gap-10 justify-content-between small" key={item.id}>
                        <span>{item.merchant_name}</span>
                        <span>{numberToCurrency(item.amount)}</span>
                      </div>
                    ))}
                    <Link
                      to={oldRoutes.guideAccountingPath({}, { type: "expenses", date: params.date, range: params.range })}
                      className="link text-dark text-opacity-50 fs-7"
                    >
                      {t("global.see_more")}
                    </Link>
                  </Accordion.Body>
                )}
              </Accordion.Item>
            </Accordion>
            <div className="hstack gap-10 fs-7 fw-semibold">
              {t("accounting.labels.profit")}
              <span className={["ms-auto", profitIsPositiveOrZero ? "text-primary" : "text-danger"].join(" ")}>
                {numberToCurrency(metadata.profit_stats)}
              </span>
            </div>
          </div>
          <div className="vstack gap-15 p-10 mt-10">
            <div className="hstack gap-10 h5">
              <Icon iconName="ColorPalette" size={24} />
              {t("dashboard.labels.legend")}
            </div>
            <div className="hstack gap-10 fs-7 fw-semibold text-info">
              <div className="bg-info rounded p-1" />
              {t("dashboard.labels.available_slots")}
            </div>
            <div className="hstack gap-10 fs-7 fw-semibold text-primary">
              <div className="bg-primary rounded p-1" />
              {t("dashboard.labels.paid_in_full")}
            </div>
            <div className="hstack gap-10 fs-7 fw-semibold text-warning">
              <div className="bg-warning rounded p-1" />
              {t("dashboard.labels.outstanding_amount")}
            </div>
            <div className="hstack gap-10 fs-7 fw-semibold text-gray-light">
              <div className="bg-gray-light rounded p-1" />
              {t("dashboard.labels.cancellation")}
            </div>
          </div>
        </>
      )}

      {loading && <Spinner className="bg-light bg-opacity-75 w-100 h-100 z-1" absolute />}
    </div>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  params: PropTypes.shape({
    date: PropTypes.string.isRequired,
    weather: PropTypes.bool,
    range: PropTypes.string
  }).isRequired,
  onChangeFilters: PropTypes.func.isRequired
}
export default memo(Sidebar)
