import React from "react"
import { Button, Col, FormGroup, Label, Row } from "reactstrap"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"
import useForm from "hooks/useForm"

import { useDispatch } from "react-redux"
import { updatePassword } from "store/user"

export default function ChangePassword() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [data, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm({
    current_password: "",
    password: "",
    password_confirmation: ""
  })

  submitCallback(() => {
    dispatch(updatePassword(data)).then(reset)
  })

  return (
    <Row tag="form" className="" onSubmit={submitHandler}>
      <p className="fs-4 fw-semibold">{t("settings.reset_password.title")}</p>
      <Col xs={12} lg={10} xl={8}>
        <FormGroup noMargin>
          <Label for="current_password" className="fs-6">
            {t("settings.reset_password.current_password")}
          </Label>
          <Input
            autoFocus
            id="current_password"
            type="password"
            name="current_password"
            value={data.current_password}
            onChange={changeHandler}
            className="fs-6"
            withError
          />
        </FormGroup>
        <FormGroup noMargin>
          <Label for="password" className="fs-6">
            {t("settings.reset_password.new_password")}
          </Label>
          <Input id="password" type="password" name="password" value={data.password} onChange={changeHandler} className="fs-6" withError />
        </FormGroup>
        <FormGroup noMargin>
          <Label for="password_confirmation" className="fs-6">
            {t("settings.reset_password.password_confirmation")}
          </Label>
          <Input
            id="password_confirmation"
            type="password"
            name="password_confirmation"
            value={data.password_confirmation}
            onChange={changeHandler}
            className="fs-6"
            withError
          />
        </FormGroup>
        <div className="mt-30 text-center text-md-end">
          <Button color="primary" disabled={!isChanged}>
            {t("settings.reset_password.update_password")}
          </Button>
        </div>
      </Col>
    </Row>
  )
}
