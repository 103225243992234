import React from "react"
import Step3Form from "components/bookings/wizard/Step3Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import StripeWrapper from "components/stripe/StripeWrapper"

import { useTitle } from "hooks/useTitle"

export default function Step3() {
  useTitle("Booking Wizard — Step 3")

  return (
    <DefaultErrorBoundary>
      <StripeWrapper>
        <Step3Form />
      </StripeWrapper>
    </DefaultErrorBoundary>
  )
}
