import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS

const requested = createAction("favouriteTrips/requested")
const failed = createAction("favouriteTrips/failed")
const received = createAction("favouriteTrips/received")
const cleaned = createAction("favouriteTrips/cleaned")

export const createdFavouriteTrips = createAction("favouriteTrips/createdFavouriteTrip")
export const deletedFavouriteTrips = createAction("favouriteTrips/deletedFavouriteTrip")

const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  metadata: {
    page: null,
    total_pages: null,
    total_results: null,
    next: null,
    prev: null
  }
}

// REDUCER
const favouriteTripsReducer = createReducer(initialState, {
  [requested.type]: (state, action) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = action.payload.data
  },
  [failed.type]: (state, action) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [createdFavouriteTrips.type]: (state, action) => {
    state.items = [...state.items, action.payload]
  },
  [deletedFavouriteTrips.type]: (state, action) => {
    state.items = removeFavoriteTrip(state.items, action.payload.id)
  }
})

export default favouriteTripsReducer

//PUBLIC ACTIONS
export const getFavoriteTrips = () =>
  api({
    url: "/client/favorite_trips",
    onStart: requested.type,
    onSuccess: received.type,
    onError: failed.type
  })

export const createFavoriteTrip = (favoriteTripId) =>
  api({
    url: "/client/favorite_trips",
    method: "post",
    data: { trip_id: favoriteTripId },
    onSuccess: createdFavouriteTrips.type
  })

export const deleteFavouriteTrip = (id) =>
  api({
    url: `/client/favorite_trips/${id}`,
    method: "delete",
    onSuccess: deletedFavouriteTrips.type
  })

// HELPERS
const removeFavoriteTrip = (favoriteTrips, id) => favoriteTrips.filter((favoriteTrip) => favoriteTrip.id !== id)
