import React from "react"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"

import { useDispatch, useSelector } from "react-redux"
import {
  isStripeChargesEnabledSelector,
  isStripeConnectedSelector,
  isStripePayoutsEnabledSelector,
  stripeConnect,
  stripeDisconnect
} from "store/user"

import { ReactComponent as StripeIcon } from "assets/images/payment/stripe.svg"

const StripeForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()

  const isStripeConnected = useSelector(isStripeConnectedSelector)
  const isStripeChargesEnabled = useSelector(isStripeChargesEnabledSelector)
  const isStripePayoutsEnabled = useSelector(isStripePayoutsEnabledSelector)

  const connectStripeAccount = () => dispatch(stripeConnect)
  const disconnectStripeAccount = confirmModal(
    {
      title: `Are you sure you want to disconnect?`,
      submitText: t("global.disconnect"),
      cancelText: t("global.cancel"),
      color: "danger",
      props: { size: "md" }
    },
    () => dispatch(stripeDisconnect())
  )

  return (
    <div className="text-start vstack gap-y-3">
      <div>
        <div className="bg-white rounded p-20">
          <h2 className="h4 fw-medium">{t("guide.extras.stripe_account")}</h2>
          <div className="vstack gap-10 mt-15">
            <div className="hstack gap-30 justify-content-between">
              <StripeIcon className="" width={120} height={50} style={{ width: 80, height: "auto" }} />
              {isStripeConnected ? (
                <div className="hstack gap-10">
                  <div className="bg-primary bg-opacity-10 rounded-pill hstack gap-2 fs-4 fw-semibold py-2 px-30 text-primary">
                    Connected
                    <Icon iconName="Valid" />
                  </div>
                  <Button color="danger" outline className="p-1" onClick={disconnectStripeAccount}>
                    <Icon iconName="Close" size={30} />
                  </Button>
                </div>
              ) : (
                <Button color="primary-second" onClick={connectStripeAccount}>
                  Connect
                </Button>
              )}
            </div>
            {isStripeConnected && (
              <>
                <div className="hstack gap-10 fs-6 fw-semibold">
                  Charge:
                  <span
                    className={[
                      isStripeChargesEnabled ? "bg-primary text-primary" : "bg-danger text-danger",
                      "badge bg-opacity-10 py-1 px-10 rounded-pill"
                    ].join(" ")}
                  >
                    {(isStripeChargesEnabled ? "Enabled" : "Disabled").toUpperCase()}
                  </span>
                </div>
                <div className="hstack gap-10 fs-6 fw-semibold">
                  Payouts:
                  <span
                    className={[
                      isStripePayoutsEnabled ? "bg-primary text-primary" : "bg-danger text-danger",
                      "badge bg-opacity-10 py-1 px-10 rounded-pill"
                    ].join(" ")}
                  >
                    {(isStripePayoutsEnabled ? "Enabled" : "Disabled").toUpperCase()}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StripeForm
