import React, { useCallback, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import { Waypoint } from "react-waypoint"
import Trip from "components/trips/Trip"
import WithEmptyState from "components/common/WithEmptyState"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"
import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanTrips, getClientGuideTrips } from "store/trips"
import { collectionAnySelector, collectionSelector, metadataSelector, modelSelector } from "store/selectors"
import { isGuideSelector } from "store/user"

export default function Trips() {
  useLoaderWatchers({ watchers: "trips" })
  const dispatch = useDispatch()
  const isGuide = useSelector(isGuideSelector)
  const guide = useSelector(modelSelector("guide"))
  const trips = useSelector(collectionSelector("trips"))
  const metadata = useSelector(metadataSelector("trips"))
  const hasTrips = useSelector(collectionAnySelector("trips"))
  useTitle(`Trips | ${guide.display_name}`)

  const fetchTrips = useCallback((nextPage = false) => dispatch(getClientGuideTrips(guide.slug, nextPage)), [guide.slug, dispatch])

  useEffect(() => {
    fetchTrips()
    return () => dispatch(cleanTrips())
  }, []) //eslint-disable-line

  return (
    <Container>
      <FirstLoading name="trips">
        <WithEmptyState name="trip" hasItems={hasTrips}>
          <Row className="gap-y-20 gap-y-lg-30 gx-30">
            {trips.map((trip) => (
              <Col xs={12} lg={4} xl={3} key={trip.id}>
                <Trip trip={trip} className="h-100" guideView={isGuide} />
              </Col>
            ))}
          </Row>
          {!isLastPage(metadata) && <Waypoint onEnter={() => fetchTrips(true)} />}
        </WithEmptyState>
      </FirstLoading>
    </Container>
  )
}
