import React from "react"

import { DateTime, Unit } from "@eonasdan/tempus-dominus"
import { pull, orderBy } from "lodash"
import { getData } from "country-list"

import { WEEK_DAY_NUMBERS } from "modules/datepicker/constants"

export const getBookingDisabledDates = (trip, booked_date) => {
  const today = global.dateTime().startOf("date")
  const restrictions = {}
  if (!trip) return restrictions

  const nextDay = today.clone.manipulate(1, "date")
  const startDate = global.dateTime(trip.start_date)
  const endDate = global.dateTime(trip.end_date)
  const todayPlusOneYear = today.clone.manipulate(1, "year")

  restrictions.minDate = nextDay
  if (trip.seasonal && DateTime.isValid(startDate) && startDate.isAfter(nextDay)) restrictions.minDate = startDate
  if (trip.seasonal && DateTime.isValid(endDate))
    restrictions.maxDate = restrictions?.minDate?.isAfter(endDate) ? restrictions.minDate : endDate
  if (!restrictions.maxDate || todayPlusOneYear.isBefore(restrictions.maxDate)) restrictions.maxDate = todayPlusOneYear

  if (trip.booking_dates?.length) restrictions.disabledDates = trip.booking_dates
  if (trip.unavailable_dates?.length) restrictions.disabledDates = trip.unavailable_dates.concat(restrictions.disabledDates || [])
  if (restrictions.disabledDates?.length)
    restrictions.disabledDates = pull([...new Set(restrictions.disabledDates)], booked_date).map((d) => new DateTime(d + "T12:00:00"))

  restrictions.daysOfWeekDisabled = trip.unavailable_weekdays?.map((day) => WEEK_DAY_NUMBERS[day])

  return restrictions
}

export const bookingDateIsDisabled = (day, trip) => {
  const restrictions = getBookingDisabledDates(trip)
  if (restrictions?.minDate?.isAfter(day)) return true
  if (restrictions?.maxDate?.isBefore(day)) return true
  if (restrictions.daysOfWeekDisabled?.length && restrictions.daysOfWeekDisabled.includes(day.weekDay)) return true
  return restrictions.disabledDates?.length && restrictions.disabledDates.some((disabledDate) => disabledDate.isSame(day, Unit.date))
}

export const bookingDateStrIsDisabled = (day, trip) => {
  bookingDateIsDisabled(new DateTime(day + "T12:00:00"), trip)
}

export const generateGuestCountOptions = (selected = 0, max = 100) => [
  <option key={0} value={0}>
    0
  </option>,
  ...new Array(Math.max(0, max - selected)).fill().map((_, i) => (
    <option key={i + 1} value={i + 1}>
      {i + 1}
    </option>
  ))
]

export const generateCountryOptions = () => {
  const countryList = orderBy(getData(), "name")
  return [
    <option key="select" value="">
      Select country
    </option>,
    countryList.map(({ code, name }) => (
      <option key={code} value={code}>
        {name}
      </option>
    ))
  ]
}

export const brandImage = (brand, ext = "jpg") => {
  if (!brand) return

  try {
    return require(`../../assets/images/payment/${brand}.${ext}`)
  } catch (e) {
    console.log(e)
    return ""
  }
}

export const calcPayToday = (po, total, deposit_percentage = 0) => {
  if (po === "full_amount_today") return total
  if (["deposit_and_balance_later", "deposit_and_balance_in_cash"].includes(po)) return total * (deposit_percentage / 100)
  if (po === "full_amount_in_cash_on_day") return 0
  return null
}

export const calculateBookingTotal = (trip, guests = 0) => {
  if (!trip || !guests) return

  const {
    pricing_type = "charge_per_person",
    price_per_person,
    flat_rate,
    minimum_rate,
    minimum_rate_person_count,
    rate_per_additional_person
  } = trip || {}

  const guestsDiff = guests - +minimum_rate_person_count
  const guestsGreater = guestsDiff > 0
  const types = {
    charge_per_person: +price_per_person * guests,
    charge_flat_rate: +flat_rate,
    set_minimum_rate: +minimum_rate + (guestsGreater ? guestsDiff * +rate_per_additional_person : 0)
  }
  return types[pricing_type]
}

export const calculateChanges = (form, booking) => {
  const { trip, booked_amount, current_amount } = booking || {}
  const { pricing_type, max_guests, price_per_person, flat_rate, minimum_rate, minimum_rate_person_count, rate_per_additional_person } =
    trip || {}
  const calculation = {}

  calculation.pricingType = pricing_type

  calculation.pricePerPerson =
    (pricing_type === "set_minimum_rate" && +rate_per_additional_person) || (pricing_type === "charge_per_person" && +price_per_person) || 0
  calculation.minimumPersons =
    (pricing_type === "set_minimum_rate" && +minimum_rate_person_count) || (pricing_type === "charge_flat_rate" && +max_guests) || 0
  calculation.minimumRate = (pricing_type === "set_minimum_rate" && +minimum_rate) || 0
  calculation.flatRate = (pricing_type === "charge_flat_rate" && +flat_rate) || 0

  calculation.bookedAmount = booked_amount
  calculation.currentAmount = current_amount
  calculation.amountDiff = booked_amount - current_amount

  if (!form) return calculation

  calculation.guests = Math.max(+form?.child_count + +form?.adult_count, calculation.minimumPersons)
  calculation.totalPrice = calculateBookingTotal(trip, calculation.guests) - calculation.amountDiff

  calculation.adultOverCount = Math.max(+form?.adult_count, calculation.minimumPersons) - calculation.minimumPersons
  calculation.childOverCount = Math.min(
    Math.max(+form?.adult_count + +form?.child_count, calculation.minimumPersons) - calculation.minimumPersons,
    +form?.child_count
  )

  if (!booking || !form) return calculation

  calculation.adultCountDiff = calculation.minimumPersons ? calculation.adultOverCount : +form.adult_count - +booking?.adult_count
  calculation.childCountDiff = calculation.minimumPersons ? calculation.childOverCount : +form.child_count - +booking?.child_count

  calculation.prevGuests = Math.max(+booking.child_count + +booking.adult_count, calculation.minimumPersons)
  calculation.prevTotalPrice = calculateBookingTotal(trip, calculation.prevGuests) - calculation.amountDiff

  calculation.guestsDiff = calculation.guests - calculation.prevGuests
  calculation.adultPriceDiff = calculation.adultCountDiff * calculation.pricePerPerson
  calculation.childPriceDiff = calculation.childCountDiff * calculation.pricePerPerson
  calculation.totalPriceDiff = calculation.totalPrice - calculation.prevTotalPrice

  calculation.isDateChanged = form.booked_date !== booking.booked_date
  calculation.isGuestsChanged = calculation.guestsDiff !== 0

  return calculation
}
