import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Container } from "reactstrap"
import { useParams } from "react-router-dom"
import Preview from "components/trips/Preview"

import { useTitle } from "hooks/useTitle"

import { useDispatch, useSelector } from "react-redux"
import { getGuideTrip, getTrip } from "store/trips"
import { modelSelector } from "store/selectors"

Trip.propTypes = {
  guideView: PropTypes.bool
}
export default function Trip({ guideView }) {
  const params = useParams()
  const dispatch = useDispatch()
  const trip = useSelector(modelSelector("trip"))
  useTitle(trip.title)

  useEffect(() => {
    window.scrollTo(0, 0)
    if (guideView) dispatch(getGuideTrip(params.id))
    else dispatch(getTrip(params.id))
  }, [params.id, guideView]) //eslint-disable-line

  return (
    <div className="bg-light">
      <Container className="py-50">
        <Preview guideView={guideView} />
      </Container>
    </div>
  )
}
