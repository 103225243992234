import React from "react"
import { Divider } from "antd"
import { useTranslation } from "react-i18next"
import { FacebookLoginButton } from "ui/auth/FacebookLoginButton"
import { GoogleLoginButton } from "ui/auth/GoogleLoginButton"
import { AppleLoginButton } from "../AppleLoginButton"
import { Text } from "ui/Typography"

export const SocialButtons = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="mb-m">
        <FacebookLoginButton />
      </div>
      <div className="mb-m">
        <GoogleLoginButton />
      </div>
      <AppleLoginButton />
      <Divider />
      <Text className="justify-center mb-s" type="secondary" variant="body">
        {t("global.or")}
      </Text>
    </>
  )
}
