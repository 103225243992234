import React from "react"

export const Weather = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6.72004 1.91992C4.0746 1.91992 1.92004 4.07448 1.92004 6.71992V17.2799C1.92004 19.9254 4.0746 22.0799 6.72004 22.0799H17.28C19.9255 22.0799 22.08 19.9254 22.08 17.2799V6.71992C22.08 4.07448 19.9255 1.91992 17.28 1.91992H6.72004ZM6.72004 2.87992H17.28C19.4068 2.87992 21.12 4.5932 21.12 6.71992V17.2799C21.12 19.4066 19.4068 21.1199 17.28 21.1199H6.72004C4.59332 21.1199 2.88004 19.4066 2.88004 17.2799V6.71992C2.88004 4.5932 4.59332 2.87992 6.72004 2.87992ZM15.36 6.23992C13.8393 6.23992 12.5251 7.13157 11.9044 8.42055C11.4867 8.25825 11.0372 8.15992 10.56 8.15992C8.69929 8.15992 7.18284 9.50895 6.83536 11.2724C5.65539 11.7179 4.80004 12.8286 4.80004 14.1599C4.80004 15.7048 5.94458 16.9623 7.42411 17.2059C7.50072 17.2542 7.58945 17.2799 7.68004 17.2799H13.92C15.7701 17.2799 17.28 15.77 17.28 13.9199C17.28 13.7517 17.263 13.588 17.2388 13.4268C18.4092 12.7674 19.2 11.5137 19.2 10.0799C19.2 7.96483 17.4751 6.23992 15.36 6.23992ZM15.36 7.19992C16.9563 7.19992 18.24 8.48365 18.24 10.0799C18.24 11.0866 17.7232 11.9644 16.9454 12.479C16.4242 11.4093 15.3451 10.6599 14.0888 10.5937C13.8106 9.89904 13.342 9.30236 12.7482 8.87242C13.2051 7.88683 14.1976 7.19992 15.36 7.19992ZM10.56 9.11992C11.8713 9.11992 12.9682 9.99177 13.32 11.1834C13.3507 11.2868 13.4152 11.3769 13.5033 11.4392C13.5914 11.5014 13.6979 11.5322 13.8057 11.5265C13.869 11.5232 13.9057 11.5199 13.92 11.5199C15.2514 11.5199 16.32 12.5886 16.32 13.9199C16.32 15.2513 15.2514 16.3199 13.92 16.3199H8.10567L8.15536 16.7305C8.14227 16.6214 8.09212 16.52 8.01329 16.4434C7.93445 16.3668 7.83171 16.3196 7.72223 16.3096C6.62059 16.2093 5.76004 15.2915 5.76004 14.1599C5.76004 13.1599 6.43504 12.3286 7.35004 12.0787C7.44266 12.0534 7.52557 12.001 7.58807 11.9281C7.65057 11.8552 7.6898 11.7653 7.70067 11.6699C7.86453 10.2335 9.07576 9.11992 10.56 9.11992Z"
      fill={fill}
    />
  </svg>
)
