import React from "react"
import { oldRoutes } from "router/old-routes"
import { Col, Row } from "reactstrap"
import { useNavigate } from "react-router-dom"
import PhotoSelector from "components/form/PhotoSelector"
import ActionButtons from "components/wizards/ActionButtons"

import useForm, { cleanNestedAttributes } from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { updateTrip } from "store/trips"
import { modelSelector } from "store/selectors"

const Step2Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name: "trips.trip" })
  const trip = useSelector(modelSelector("trip"))
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(trip, ["photos"])

  submitCallback(({ nativeEvent }) => {
    const photos_attributes = cleanNestedAttributes(form.photos, ["file"])
    const tripAttributes = { ...updateStep(form), photos_attributes }
    delete tripAttributes.photos

    const nextPath = nativeEvent?.submitter?.value
    const formData = serialize({ trip: tripAttributes }, { indices: true })
    dispatch(updateTrip(trip.id, formData)).then(() => navigate(nextPath))
  })

  return (
    <Row tag="form" onSubmit={submitHandler}>
      <Col xs={12} xl={{ size: 10, offset: 1 }}>
        <PhotoSelector form={form} changeHandler={changeHandler} />
        <ActionButtons
          isChanged={isChanged}
          action={updateTrip}
          name="trips.trip"
          className="mt-50"
          showSaveAndExit={trip.wizard_completed}
          exitPath={oldRoutes.guideTripsPath()}
          nextText="Save & Next"
          saveText="Save & Exit"
        />
      </Col>
    </Row>
  )
}

export default Step2Form
