import React, { memo, useMemo } from "react"
import PropTypes from "prop-types"
import CircleImage from "./CircleImage"

const statusColorMap = {
  online: "primary",
  away: "warning",
  offline: "danger"
}

Avatar.propTypes = {
  user: PropTypes.shape({
    avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
    status: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  showStatus: PropTypes.bool,
  size: PropTypes.number,
  fit: PropTypes.bool,
  className: PropTypes.string,
  border: PropTypes.bool
}

function Avatar({ user, showStatus = false, size = 35, fit = true, className, border = true }) {
  const { avatar = null, status = "", first_name = "", last_name = "" } = user || {}
  const name = `${first_name} ${last_name}`

  const classes = ["h-auto"]
  fit && classes.push("mw-100")
  className && classes.push(className)

  const avatarUrl = useMemo(() => (avatar instanceof File ? URL.createObjectURL(avatar) : avatar), [avatar])

  return (
    <>
      <CircleImage src={avatarUrl} alt={name} className={classes.join(" ")} size={size} placeholderIconName="MyProfile" border={border} />
      {showStatus && status && <div className={`p-1 rounded-circle position-absolute bottom-0 end-0 bg-${statusColorMap[status]}`} />}
    </>
  )
}

export default memo(Avatar)
