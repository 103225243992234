export const DATE_SHORT_TEXT_FORMAT = "MMM DD, YYYY"

export const DATETIME_FORMAT = "YYYY-MM-DDTHH:mm"
export const DATE_FORMAT = "YYYY-MM-DD"
export const TIME_FORMAT = "HH:mm"
export const TIME_FORMAT_12 = "hh:mm A"
export const WEEK_FORMAT = "YYYY-[W]W"
export const WEEK_LONG_FORMAT = "YYYY-[W]w-[D]d"
export const MONTH_FORMAT = "YYYY-MM"
export const YEAR_FORMAT = "YYYY"

export const PICKER_FORMATS = {
  time: TIME_FORMAT,
  date: DATE_FORMAT,
  "datetime-local": DATETIME_FORMAT,
  week: WEEK_FORMAT,
  month: MONTH_FORMAT,
  year: YEAR_FORMAT
}

export const DISPLAY_TYPE_FORMATS = {
  date: "DD MMM, YYYY",
  weekDay: "[W]W YYYY",
  month: "MMMM YYYY",
  year: "YYYY"
}

export const MANIPULATION_TYPES = {
  date: "date",
  week: "weekDay",
  month: "month",
  year: "year"
}

export const WEEK_DAY_NUMBERS = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 0
}

export const LOCALIZATION = {
  locale: "default",
  startOfTheWeek: 1,
  hourCycle: "h12",
  dateFormats: {
    LTS: "H:mm:ss",
    LT: "H:mm",
    L: "yyyy-MM-dd",
    LL: "yyyy-MM-dd H:mm",
    LLL: "MMMM d, yyyy",
    LLLL: "MMMM d, yyyy H:mm"
  },
  format: "L"
}

export const FA_FIVE_ICONS = {
  type: "icons",
  time: "far fa-clock",
  date: "far fa-calendar",
  up: "fas fa-arrow-up",
  down: "fas fa-arrow-down",
  previous: "fas fa-chevron-left",
  next: "fas fa-chevron-right",
  today: "fas fa-crosshairs",
  clear: "fas fa-ban",
  close: "fas fa-times"
}
