import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { find, omit } from "lodash"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS

const requested = createAction("accounting/requested")
const failed = createAction("accounting/failed")
const received = createAction("accounting/received")
const cleaned = createAction("accounting/cleaned")

const receivedOverview = createAction("accounting/receivedOverview")

const requestedExpense = createAction("accounting/requestedExpense")
const receivedExpense = createAction("accounting/receivedExpense")
const failedExpense = createAction("accounting/failedExpense")
const createdExpense = createAction("accounting/createdExpense")
const updatedExpense = createAction("accounting/updatedExpense")
const cleanedExpense = createAction("accounting/cleanedExpense")
const deletedExpense = createAction("accounting/deletedExpense")

const receiptParseRequested = createAction("accounting/receiptParseRequested")
const receiptParsed = createAction("accounting/receiptParsed")

const initialState = {
  ...LOADING_INITIAL_STATE,
  receiptParsing: false,
  items: [],
  metadata: {
    bookings_stats: {},
    booking_client_stats: {},
    expense_categories: [],
    expense_category_stats: {},
    expenses_stats: {},
    incomes_stats: {},
    incomes_per_day_stats: {},
    profit_stats: {}
  },
  expense: {
    ...LOADING_INITIAL_STATE,
    id: null,
    guide_id: null,
    amount: 0,
    purchase_date: "",
    recurring_type: "",
    recurring: false,
    notes: "",
    created_at: "",
    updated_at: "",
    receipts: [],
    category: {
      id: null,
      title: "",
      slug: ""
    },
    merchant: {
      id: null,
      name: "",
      guide_id: null
    }
  }
}

// REDUCER
const accountingReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = action.payload.data
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [receivedOverview.type]: (state, action) => {
    setReceived(state)
    Object.assign(state.metadata, action.payload.data)
    Object.assign(state.metadata, action.payload.metadata)
  },
  [requestedExpense.type]: (state) => {
    setRequested(state.expense)
  },
  [receivedExpense.type]: (state, action) => {
    setReceived(state.expense)
    Object.assign(state.expense, action.payload)
  },
  [createdExpense.type]: (state, { payload }) => {
    setReceived(state.expense)
    state.items = [payload.metadata.formatted_expense, ...state.items]
    Object.assign(state.expense, payload.data)
    Object.assign(state.metadata, omit(payload.metadata, "formatted_expense"))
  },
  [updatedExpense.type]: (state, { payload }) => {
    setReceived(state.expense)
    const expense = find(state.items, ["id", payload.data?.id]) || {}
    Object.assign(expense, payload.metadata.formatted_expense)
    Object.assign(state.expense, payload.data)
    Object.assign(state.metadata, omit(payload.metadata, "formatted_expense"))
  },
  [failedExpense.type]: (state) => {
    setFailed(state.expense)
  },
  [cleanedExpense.type]: (state) => {
    state.expense = initialState.expense
  },
  [receiptParseRequested.type]: (state) => {
    state.receiptParsing = true
  },
  [receiptParsed.type]: (state) => {
    state.receiptParsing = false
  }
})

export default accountingReducer

//PUBLIC ACTIONS
export const getGuideAccounting = (params) =>
  api({
    url: `/guide/accounting`,
    params,
    onStart: requested.type,
    onSuccess: received.type,
    onError: failed.type
  })

export const getGuideAccountingOverview = (params) =>
  api({
    url: `/guide/accounting/overview`,
    params,
    onStart: requested.type,
    onSuccess: receivedOverview.type,
    onError: failed.type
  })

export const getExpense = (id) =>
  api({
    url: `/guide/expenses/${id}`,
    onStart: requestedExpense.type,
    onSuccess: receivedExpense.type,
    onError: failedExpense.type
  })

export const createExpense = (data) =>
  api({
    url: "/guide/expenses",
    method: "post",
    data: data,
    onStart: requestedExpense.type,
    onSuccess: createdExpense.type,
    onError: failedExpense.type
  })

export const updateExpense = (id, data) =>
  api({
    url: `/guide/expenses/${id}`,
    method: "put",
    data: data,
    onStart: requestedExpense.type,
    onSuccess: updatedExpense.type,
    onError: failedExpense.type
  })

export const saveExpense = (id, data) => (id ? updateExpense(id, data) : createExpense(data))

export const deleteExpense = (id) =>
  api({
    url: `/guide/expenses/${id}`,
    method: "delete",
    onSuccess: deletedExpense.type
  })
export const parseExpenseReceipt = (data) =>
  api({
    url: "guide/expenses/parse_receipt",
    data,
    method: "post",
    onStart: receiptParseRequested.type,
    onSuccess: receiptParsed.type
  })

export const cleanExpenses = () => (dispatch) => dispatch(cleaned())
export const cleanExpense = () => (dispatch) => dispatch(cleanedExpense())
