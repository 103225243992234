import { colors } from "themes/variables"
import styles from "./AddAvatar.module.css"
import { Icon } from "ui/Icon"
import { CustomInput } from "ui/Input"
import { Avatar } from "antd"

export const AddAvatar = ({ avatar, handleImageChange }) => {
  return (
    <div className={styles.avatarWrapper}>
      <Avatar
        src={avatar?.includes("localhost") ? avatar.replace("https://", "http://") : avatar}
        style={{
          border: `1.5px solid ${colors.lines}`,
          flexShrink: 0,
          background: colors.surface1
        }}
        size={100}
        icon={<Icon width={16} height={16} type="feather" name="User" color={colors.textTertiary} />}
      />
      <div className={styles.avatarAction}>
        <label className={styles.avatarLabel} htmlFor="avatarPhoto">
          <div className={styles.avatarActionIconWrapper}>
            <Icon width={16} height={16} type="feather" name={avatar ? "Edit2" : "Plus"} color={colors.textSecondary} />
          </div>
        </label>
        <CustomInput
          className={styles.avatarPhotoInput}
          id="avatarPhoto"
          type="file"
          accept="image/png, image/jpeg"
          onChange={handleImageChange}
        />
      </div>
    </div>
  )
}
