import React, { memo } from "react"
import PropTypes from "prop-types"
import DynamicTag from "components/DynamicTag"

import { useSelector } from "react-redux"
import { loadingSelector } from "./selectors"

Disable.propTypes = {
  name: PropTypes.string.isRequired,
  tag: PropTypes.elementType,
  className: PropTypes.string,
  additionalCondition: PropTypes.bool,
  children: PropTypes.node
}
function Disable({ name, tag = null, className = "", additionalCondition = false, children, ...rest }) {
  const loading = useSelector(loadingSelector(name))

  const classes = []
  if (className) classes.push(className)
  if (loading || additionalCondition) classes.push("opacity-50 pinter-events-none")

  return (
    <DynamicTag className={classes.join(" ")} {...rest} tag={tag}>
      {children}
    </DynamicTag>
  )
}

export default memo(Disable)
