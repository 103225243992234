import React, { useEffect, useMemo, useState } from "react"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { routes } from "router/routes"
import { BreadCrumbs } from "ui/BreadCrumbs"

export const NavigationPath = ({ className, subPages }) => {
  const [menu, setMenu] = useState()
  const location = useLocation()
  const { menuItems } = useSelector((state) => state.proHomeMenu)
  const menuItemsFlat = useMemo(() => menuItems.flatMap((menuItem) => menuItem.items), [menuItems])

  useEffect(() => {
    const menuPath = location.pathname.split(routes.proHomeRootPath()).filter(Boolean)[0]
    const menuId = menuPath?.split("/").filter(Boolean)[0]
    const menu = menuId && menuItemsFlat.find((menuItem) => menuItem.id === menuId)
    setMenu(menu)
  }, [location.pathname, menuItemsFlat])

  if (!menu) {
    return null
  }

  return <BreadCrumbs className={classNames(className)} iconName={menu.iconName} category={menu.name} subPages={subPages} />
}
