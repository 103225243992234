import { Flex } from 'antd';
import { countries, getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji } from 'country-codes-flags-phone-codes';
import { useState } from 'react';
import { PhoneCodeLabel } from './PhoneCodeLabel';
import { PhoneCodeOption } from './PhoneCodeOption';
import { CustomInput, CustomSelect } from '..';

const options = countries.map(country => {
    return { value: country.code, label: <PhoneCodeOption country={country} /> };
});

const isValid = (dialCode, phoneNumber) => {
    return !!(dialCode && phoneNumber);
}

export const PhoneInput = ( { initialPhoneNumber, disabled, borderless, onChange }) => {
    const [countryCode, setCountryCode] = useState('US');
    const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber);

    const handleCountryCodeSelect = (value) => {
        setCountryCode(value);
        const dialCode = getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji(value);
        const phone_number = isValid(dialCode, phoneNumber) ? `${dialCode}${phoneNumber}` : null;
        onChange?.(phone_number);
    };

    const handlePhoneNumberChange = (e) => {
        const value = e.target.value;
        setPhoneNumber(value);
        const dialCode = getCountryDialCodeFromCountryCodeOrNameOrFlagEmoji(countryCode);
        const phone_number = isValid(dialCode, value) ? `${dialCode}${value}` : null;
        onChange?.(phone_number);
    }

    return (
        <Flex gap={8}>
            <CustomSelect
                options={options}
                value={countryCode}
                onSelect={handleCountryCodeSelect}
                labelRender={({ value }) => <PhoneCodeLabel countryCode={value} />}
                popupMatchSelectWidth={false}
                disabled={disabled}
                borderless={borderless}
                style={{ width: 'auto' }}
            />
            <CustomInput
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="XXX-XXX-XXXX"
                disabled={disabled}
                borderless={borderless}
                onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                }}
                className="flex-grow-1"
            />
        </Flex>
    );
};
