import React, { useEffect, useState } from "react"
import Loading from "modules/loader-watchers/Loading"
import { Row } from "reactstrap"

export default function SalesForm() {
  const [hbLoading, setHBLoading] = useState(false)

  const mountHubSpotForm = () => {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "44114474",
      formId: "ecac8fa3-9815-440b-a545-bca7055746af",
      target: ".hubspotForm",
      onFormReady: () => setHBLoading(false)
    })
  }

  useEffect(() => {
    setHBLoading(true)

    const script = document.createElement("script")
    const container = document.getElementById("newsletterSubscription")
    script.src = "//js.hsforms.net/forms/embed/v2.js"
    script.addEventListener("load", mountHubSpotForm)
    container.appendChild(script)

    return () => {
      script.removeEventListener("load", mountHubSpotForm)
      container.removeChild(script)
    }
  }, []) //eslint-disable-line

  return (
    <Loading loading={hbLoading} className="w-100" style={{ maxWidth: "822px", minHeight: "150px" }}>
      <Row className="text-center d-flex justify-content-center align-items-center">
        <div className="hubspotForm"></div>
      </Row>
    </Loading>
  )
}
