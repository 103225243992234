import React, { memo, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Button, Label, DropdownToggle } from "reactstrap"
import { Dropdown } from "components/common/Dropdown"
import Icon from "components/common/Icon"
import Input from "components/form/Input"
import Spinner from "components/common/Spinner"

import { useTranslation } from "react-i18next"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"
import { serialize } from "object-to-formdata"
import { hexToRGB } from "helpers/color"

import { useDispatch, useSelector } from "react-redux"
import { updateGroup, removeGroup, createGroup } from "store/clients"

import { GROUP_COLORS, GROUP_COLOR_DEFAULT } from "constants/clients"

const GroupSelector = ({ value, onChange, onAdd, onRemove, buttonText = "Add to group", withCreate = true, toggleButton, ...rest }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirmModal = useConfirmModal()
  const editDropdownRef = useRef({})

  const {
    client: { loading },
    metadata: { groups_collection = [] }
  } = useSelector((store) => store.clients)

  const [selectedIds, setSelectedIds] = useState([])
  const [editGroup, setEditGroup] = useState({})
  const [newGroupName, setNewGroupName] = useState("")

  const selectedGroupIds = value !== undefined ? value : selectedIds
  const selectedGroups = useMemo(
    () => groups_collection.filter((group) => selectedGroupIds?.includes(group.id)) || [],
    [groups_collection, selectedGroupIds]
  )
  const hasSelectedGroups = !!selectedGroups?.length

  const createGroupHandler = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
      const formData = serialize({ group: { name: newGroupName, color: GROUP_COLOR_DEFAULT } })
      dispatch(createGroup(formData)).then(() => setNewGroupName(""))
    }
  }

  const deleteGroupHandler = confirmModal({ title: "Are you sure?", color: "danger", submitText: t("global.yes") }, () =>
    dispatch(removeGroup(editGroup.id))
  )

  const groupChangeHandler = ({ target }) => {
    setEditGroup((group) => ({ ...group, [target.name]: target.value }))
  }

  const updateGroupHandler = () => {
    const button = editDropdownRef.current?.[editGroup.id] || null
    dispatch(updateGroup(editGroup.id, { group: { name: editGroup.name, color: editGroup.color } })).then(() => button?.click?.())
  }

  const selectHandlrer = ({ currentTarget }) => {
    let ids = [...selectedGroupIds]
    if (currentTarget.name === "add") {
      ids.push(+currentTarget.value)
      if (typeof onAdd === "function") onAdd(+currentTarget.value)
    } else if (currentTarget.name === "remove") {
      ids = ids.filter((id) => id !== +currentTarget.value)
      if (typeof onRemove === "function") onRemove(+currentTarget.value)
    }
    setSelectedIds(ids)
    if (typeof onChange === "function") onChange(ids)
  }

  const groupClasses = "fs-7 fw-semibold lh-1 text-nowrap rounded-pill border-0 bg-gray-light bg-opacity-25 focus-ring-dark px-15 py-1"

  return (
    <Dropdown
      end
      classNameMenu="px-10"
      menuProps={{ style: { minWidth: 300, maxWidth: 320 } }}
      {...rest}
      toggleButton={
        toggleButton || (
          <DropdownToggle
            tag="div"
            className="hstack gap-2 flex-wrap cursor-pointer p-1 bg-gray-light bg-opacity-0 bg-opacity-10-hover rounded-3"
          >
            {hasSelectedGroups ? (
              selectedGroups.map((group) => (
                <div key={group.id} style={{ color: group.color, "--bs-gray-light-rgb": hexToRGB(group.color) }} className={groupClasses}>
                  {group.name}
                </div>
              ))
            ) : (
              <div className={["text-dark bg-dark text-opacity-50", groupClasses].join(" ")}>
                <Icon iconName="Plus" size={8} className="me-1" />
                {buttonText}
              </div>
            )}
          </DropdownToggle>
        )
      }
    >
      <div className="hstack gap-2 flex-wrap mb-10">
        {selectedGroups.map((group) => (
          <div
            key={group.id}
            style={{ color: group.color, "--bs-gray-light-rgb": hexToRGB(group.color) }}
            className={["hstack gap-2", groupClasses].join(" ")}
          >
            {group.name}
            <Button
              type="button"
              color="transparent"
              className="bg-gray-light bg-opacity-0 bg-opacity-25-hover border-0 shadow-none my-n1 me-n3 p-0 align-self-center"
              style={{ color: group.color, "--bs-gray-light-rgb": hexToRGB(group.color) }}
              onClick={selectHandlrer}
              value={group.id}
              name="remove"
            >
              <Icon iconName="Close" width={16} block />
            </Button>
          </div>
        ))}
        {withCreate && (
          <Input
            className={["w-auto text-dark bg-dark", groupClasses].join(" ")}
            type="text"
            onKeyDown={createGroupHandler}
            placeholder="Type to create group..."
            style={{ minWidth: 150 }}
            value={newGroupName}
            onChange={(event) => setNewGroupName(event.target.value)}
          />
        )}
      </div>

      <div className="text-dark text-opacity-50 fs-7 mb-2">{"Create a new group or select one"}</div>
      <div className="vstack gap-2">
        {groups_collection.map((group) => (
          <div key={group.id} className="hstack gap-1">
            <Dropdown
              direction="down"
              classNameMenu="px-10"
              toggleButton={
                <DropdownToggle
                  color="ghost"
                  className="p-1"
                  block
                  innerRef={(ref) => Object.assign(editDropdownRef.current, { [group.id]: ref })}
                >
                  <Icon iconName="More" className="d-block text-dark opacity-50" size={10} />
                </DropdownToggle>
              }
              onToggle={(_, isOpen) => setEditGroup(isOpen ? group : {})}
            >
              <div>
                <Label for="edit_group_name" className="fs-7 opacity-25">
                  {t("client.group_name")}
                </Label>
                <Input
                  type="text"
                  id="edit_group_name"
                  name="name"
                  value={editGroup.name || ""}
                  withError
                  onChange={groupChangeHandler}
                  className="fs-6 rounded border-gray-lightest"
                />
              </div>
              <div className="my-10">
                <Label className="fs-7 opacity-25">{t("client.group_color")}</Label>
                <div className="border border-gray-lightest p-2 grid grid-cols-6 gap-2 rounded">
                  {GROUP_COLORS.map((color, index) => (
                    <div
                      key={index}
                      className={[
                        "rounded-circle p-1 m-auto position-relative bg-gray-lightest-hover border border-2",
                        editGroup.color === color ? "" : "bg-transparent border-0"
                      ].join(" ")}
                      style={{ width: 22, height: 22, "--bs-border-color": color }}
                    >
                      <div className="rounded-circle w-100 h-100" style={{ background: color }} />
                      <Input
                        key={index}
                        name="color"
                        value={color}
                        type="radio"
                        onChange={groupChangeHandler}
                        className="position-absolute top-0 start-0 h-100 w-100 opacity-0 m-0 cursor-pointer"
                        selected={editGroup.color === color}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="hstack gap-2 justify-content-between py-0">
                <Button
                  color="link"
                  type="button"
                  className="p-0 fs-7 fw-semibold text-danger link link-underline-opacity-0 link-underline-opacity-75-hover"
                  onClick={deleteGroupHandler}
                >
                  Delete
                </Button>
                <Button
                  color="link"
                  type="button"
                  className="p-0 fs-7 fw-semibold text-dark link link-underline-opacity-0 link-underline-opacity-75-hover"
                  onClick={updateGroupHandler}
                >
                  Update
                </Button>
              </div>
            </Dropdown>

            <Button
              type="button"
              className={[groupClasses, "bg-opacity-50-hover"].join(" ")}
              onClick={selectHandlrer}
              style={{ minWidth: 100, color: group.color, "--bs-gray-light-rgb": hexToRGB(group.color) }}
              value={group.id}
              name="add"
              disabled={selectedGroupIds.includes(group.id)}
            >
              {group.name}
            </Button>
          </div>
        ))}
      </div>
      {loading && <Spinner className="bg-white bg-opacity-50 w-100 h-100" absolute />}
    </Dropdown>
  )
}

GroupSelector.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  buttonText: PropTypes.string,
  withCreate: PropTypes.bool,
  toggleButton: PropTypes.node
}

export default memo(GroupSelector)
