import i18n from "i18n"
import { findIndex, slice, map, isEqual } from "lodash"

import { DEFAULT_LOCATION } from "constants/map"

export const currentLocation = (cb) =>
  "geolocation" in navigator &&
  navigator.geolocation.getCurrentPosition(
    (position) => {
      const lat = position.coords.latitude
      const lng = position.coords.longitude
      cb({ lat, lng }, i18n.t("location.success"))
    },
    (error) => console.error(error.message) || cb(DEFAULT_LOCATION, error.message)
  )

export const formatAddressComponent = (addressComponent = [], from) => {
  const index = findIndex(addressComponent, ["types", from])
  const sliced = slice(addressComponent, index)

  return map(sliced, "long_name").join(", ")
}

export const getLocalStorageBrowserLocation = () => {
  try {
    const latLng = JSON.parse(localStorage.getItem("browserLocation"))
    if (latLng.lat && latLng.lng) return latLng
    else return DEFAULT_LOCATION
  } catch (error) {
    return DEFAULT_LOCATION
  }
}

export const updateLocalStorageBrowserLocation = (latLng, cb) => {
  const prevLatLng = getLocalStorageBrowserLocation()
  if (isEqual(prevLatLng, latLng)) return
  if (cb instanceof Function) cb(latLng)
  localStorage.setItem("browserLocation", JSON.stringify(latLng))
}
