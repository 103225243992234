import React from "react"
import PropTypes from "prop-types"
import { Button, DropdownToggle, Label } from "reactstrap"
import FilterWrapper from "components/common/FilterWrapper"
import Input from "components/form/Input"
import NavigationLink from "components/layout/NavigationLink"

import { useTranslation } from "react-i18next"

import { SKILL_LEVELS, TRIP_FILTERS, TRIP_FILTERS_HELP_ATTRIBUTES } from "constants/trip"

const TempNavigationLink = ({ ...props }) => <NavigationLink tag={Button} {...props} />

SkillLevel.propTypes = {
  onToggleClick: PropTypes.func.isRequired
}
export default function SkillLevel({ onToggleClick }) {
  const { t } = useTranslation()

  return (
    <FilterWrapper
      title={t("trips.filters.skill_level")}
      fields={["skill_level_eq"]}
      accessAttributes={TRIP_FILTERS}
      exceptDependencies={TRIP_FILTERS_HELP_ATTRIBUTES}
      toggleButton={(isOpen, isActive, filters) => (
        <DropdownToggle
          tag={TempNavigationLink}
          iconName="Users"
          label={t("trips.filters.skill_level")}
          onClick={onToggleClick}
          isActive={isOpen || isActive}
          badge={filters.skill_level_eq ? t(`trip.skill_levels.${filters.skill_level_eq}`) : ""}
        />
      )}
    >
      {(form, changeHandler) => (
        <div className="grid grid-cols-2 gap-2 justify-content-start w-100">
          {SKILL_LEVELS.map((level) => (
            <Label
              key={level}
              check
              className={`hstack gap-10 p-10 pe-15 bg-white border ${
                form.skill_level_eq === level ? "border-gray" : "border-gray-lightest"
              } rounded fs-7 fw-normal`}
            >
              <Input
                type="radio"
                name="skill_level_eq"
                value={level}
                checked={form.skill_level_eq === level}
                onChange={changeHandler}
                className="mt-0"
              />
              <span className={`text-dark ${form.skill_level_eq === level ? "" : "text-opacity-50"}`}>
                {t(`trip.skill_levels.${level}`)}
              </span>
            </Label>
          ))}
        </div>
      )}
    </FilterWrapper>
  )
}
