import React, { useEffect } from "react"
import { Col, Container, Row, Modal, ModalBody, Button } from "reactstrap"
import PhotoSlider from "components/common/PhotoSlider"
import TripsCalendar from "components/guides/Calendar"
import Trip from "components/trips/Trip"
import Icon from "components/common/Icon"

import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { hideModal, showModal } from "modules/modals/reducer"
import { modalSelector } from "modules/modals/selectors"
import { useDispatch, useSelector } from "react-redux"
import { cleanTrips } from "store/trips"
import { modelSelector } from "store/selectors"
import { isGuideSelector } from "store/user"

function renderPhotoGrid(photos, openHandler) {
  if (photos.length === 0) return null

  if (photos.length === 1)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-lg-12">
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
      </div>
    )

  if (photos.length === 2)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-md-12 g-col-lg-6" style={{ maxHeight: 420 }}>
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
        <div className="g-col-12 g-col-md-12 g-col-lg-6" style={{ maxHeight: 420 }}>
          <LazyLoadImage
            src={photos.at(1).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(1).placeholder_url || null}
          />
        </div>
      </div>
    )

  if (photos.length === 3)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-lg-6" style={{ maxHeight: 560 }}>
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
        <div className="g-col-12 g-col-lg-6 grid grid-cols-6 gap-2">
          <div className="g-col-6 grid grid-cols-1 grid-rows-8 gap-2" style={{ maxHeight: 560 }}>
            <div className="g-row-4">
              <LazyLoadImage
                src={photos.at(1).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(1).placeholder_url || null}
              />
            </div>
            <div className="g-row-4">
              <LazyLoadImage
                src={photos.at(2).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(2).placeholder_url || null}
              />
            </div>
          </div>
        </div>
      </div>
    )

  if (photos.length > 3)
    return (
      <div className="grid gap-2 mt-15">
        <div className="g-col-12 g-col-lg-6" style={{ maxHeight: 560 }}>
          <LazyLoadImage
            src={photos.at(0).url}
            alt=""
            className="object-fit-cover w-100 h-100 rounded"
            wrapperClassName="w-100 h-100"
            effect="blur"
            placeholderSrc={photos.at(0).placeholder_url || null}
          />
        </div>
        <div className="g-col-12 g-col-lg-6 grid grid-cols-6 gap-2">
          <div className="g-col-6 grid grid-cols-1 grid-rows-8 gap-2" style={{ maxHeight: 560 }}>
            <div className="g-row-4">
              <LazyLoadImage
                src={photos.at(1).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(1).placeholder_url || null}
              />
            </div>
            <div className="g-row-4 position-relative">
              <div className="position-absolute bottom-0 end-0 p-3 z-2">
                <Button color="ghost" className="hstack bg-white bg-opacity-75 py-0 ps-0 pe-10" onClick={openHandler}>
                  <Icon iconName="Grid" className="flex-shrink-0" size={26} block />
                  <div className="fs-7 lh-0">{"See more photos"}</div>
                </Button>
              </div>
              <LazyLoadImage
                src={photos.at(2).url}
                alt=""
                className="object-fit-cover w-100 h-100 rounded"
                wrapperClassName="w-100 h-100"
                effect="blur"
                placeholderSrc={photos.at(2).placeholder_url || null}
              />
            </div>
          </div>
        </div>
      </div>
    )
}

export default function Guide() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isGuide = useSelector(isGuideSelector)
  const guide = useSelector(modelSelector("guide"))
  const { description, expertises, popular_trips } = guide
  useTitle(guide.display_name)

  const hasPopularTrips = !!popular_trips?.length

  const lightbox = useSelector(modalSelector("lightbox"))
  const openHandler = () => dispatch(showModal("lightbox"))
  const closeHandler = () => dispatch(hideModal("lightbox"))

  useEffect(() => {
    return () => dispatch(cleanTrips())
  })

  return (
    <Container className="mb-n40">
      {guide.photos && guide.photos.length > 0 && (
        <>
          <div className="d-none d-lg-block">{renderPhotoGrid(guide.photos, openHandler)}</div>
          <div className="d-block d-lg-none">
            <PhotoSlider photos={guide.photos} ratio="16x9" className="w-100 h-100 mt-20" />
          </div>
          <Modal
            isOpen={lightbox.isOpen}
            size="xl"
            centered
            scrollable
            backdropClassName="backdrop-blur-3"
            contentClassName="rounded-sm-3"
            toggle={closeHandler}
          >
            <ModalBody className="p-0">
              <PhotoSlider photos={guide.photos} className="w-100" />
            </ModalBody>
          </Modal>
        </>
      )}
      {description && (
        <section className="my-60 text-prewrap">
          <h2 className="fw-semibold mb-30">{t("global.about")}</h2>
          {description}
        </section>
      )}
      {expertises.length > 0 && (
        <section className="my-80">
          <h2 className="fw-semibold mb-30">{t("guide.labels.experience_expertise")}</h2>
          <ul className="grid grid-cols-1 grid-cols-sm-2 grid-cols-md-3 grid-cols-lg-4 gap-15 list-unstyled m-0 mt-15">
            {expertises.length ? expertises.map((item) => <li key={item.id}>— {item.name}</li>) : "—"}
          </ul>
        </section>
      )}
      <section className="my-80">
        <h2 className="fw-semibold mb-30">{t("guide.labels.calendar", { guide_name: guide.display_name })}</h2>
        <TripsCalendar />
      </section>
      {hasPopularTrips ? (
        <section className="my-80">
          <h2 className="fw-semibold mb-30">{t("guides.popular_trips")}</h2>
          <Row className="gap-y-20 gx-25 gx-xxl-90 mt-35">
            {popular_trips.map((trip) => (
              <Col xs={12} lg={4} key={trip.id}>
                <Trip trip={trip} className="h-100" guideView={isGuide} />
              </Col>
            ))}
          </Row>
        </section>
      ) : null}
    </Container>
  )
}
