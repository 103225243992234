import React, { memo } from "react"
import { Nav, NavItem, NavLink } from "reactstrap"
import { NavLink as ReactNavLink } from "react-router-dom"
import { oldRoutes } from "router/old-routes"

import { useTranslation } from "react-i18next"

function Navigation() {
  const { t } = useTranslation()

  return (
    <Nav className="mx-n15 mt-40 mb-50">
      <NavItem>
        <NavLink tag={ReactNavLink} to={oldRoutes.guideSettingsRootPath()} end>
          {t("guide.settings.details")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={oldRoutes.guideSettingsPhotosPath()}>
          {t("guide.settings.photos")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={oldRoutes.guideSettingsExtrasPath()}>
          {t("guide.settings.extras")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={oldRoutes.guideSettingsDocumentsPath()}>
          {t("guide.settings.documents")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={oldRoutes.guideSettingsStripePath()}>
          {t("guide.settings.stripe")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={oldRoutes.guideSettingsSocialsPath()}>
          {t("guide.settings.socials")}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={ReactNavLink} to={oldRoutes.guideSettingsPasswordPath()}>
          {t("guide.settings.security")}
        </NavLink>
      </NavItem>
    </Nav>
  )
}

export default memo(Navigation)
