import React, { useRef, useState } from "react"
import { Button, Modal, ModalBody } from "reactstrap"
import Icon from "components/common/Icon"
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  XIcon,
  TwitterShareButton
} from "react-share"

import CopyToClipboard from "react-copy-to-clipboard"

import { useDispatch, useSelector } from "react-redux"
import { hideModal, resetModal } from "./reducer"
import { modalSelector } from "./selectors"

const TIMEOUT = 2000

export default function ShareModal() {
  const dispatch = useDispatch()
  const timeoutRef = useRef()
  const [copied, setCopied] = useState()
  const shareModal = useSelector(modalSelector("shareModal"))

  const closeHandler = () => dispatch(hideModal("shareModal"))
  const onClosedHandler = () => dispatch(resetModal("shareModal"))

  const onCopyHandler = () => {
    setCopied(true)
    clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => setCopied(false), TIMEOUT)
  }

  const url = `${global.location.origin}/#${shareModal.path}`
  const text = `${shareModal.title}\n\n${shareModal.description}\n\n`
  const textEllipsis = text.slice(0, 140) + (text.length > 140 ? "..." : "")

  return (
    <Modal
      size="md"
      centered
      scrollable
      backdropClassName="backdrop-blur-3"
      contentClassName="rounded-sm-3"
      isOpen={shareModal.isOpen}
      toggle={closeHandler}
      onClosed={onClosedHandler}
    >
      <ModalBody className="p-40 text-center">
        <h2 className="fs-2 lh-sm" dangerouslySetInnerHTML={{ __html: `Share This ${shareModal.name}!` }} />
        <div className="hstack gap-30 justify-content-center mt-30">
          <FacebookShareButton url={url} className="opacity-75-hover" hashtag="ds_guide">
            <FacebookIcon round size={60} bgStyle={{ fill: "#1877F2" }} />
          </FacebookShareButton>
          <LinkedinShareButton url={url} className="opacity-75-hover" title={text} source={global.location.origin}>
            <LinkedinIcon round size={60} bgStyle={{ fill: "#007AB9" }} />
          </LinkedinShareButton>
          <TwitterShareButton url={url + "\n"} className="opacity-75-hover" title={textEllipsis} hashtags={["ds_guide"]}>
            <XIcon round size={60} bgStyle={{ fill: "#24262C" }} />
          </TwitterShareButton>
          <EmailShareButton url={url} className="opacity-75-hover" subject={shareModal.title} body={text}>
            <EmailIcon round size={60} bgStyle={{ fill: "#FFC107" }} />
          </EmailShareButton>
        </div>
        <CopyToClipboard text={url} onCopy={onCopyHandler}>
          <Button
            color="light"
            className="hstack gap-2 justify-content-between py-4 px-3 rounded-1 border border-gray-lightest fw-medium lh-1 w-100 mt-40"
          >
            <span className="text-dark text-opacity-75">{copied ? "Share Link is copied to the clipboard" : "Copy Share Link"}</span>
            <Icon iconName="Link" size={18} block />
          </Button>
        </CopyToClipboard>
      </ModalBody>
    </Modal>
  )
}
