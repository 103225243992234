import React from "react"
import styles from "./Sidebar.module.css"
import { MenuGroup } from "./MenuGroup"
import { useSelector } from "react-redux"
import { Flex } from "antd"
import { spacings } from "themes/variables"
import { SidebarActionButtons } from "./SidebarActionButtons"

export const Sidebar = () => {
  const { menuItems } = useSelector((state) => state.guideUserInfo)

  return (
    <div className={styles.sidebar}>
      <Flex vertical gap={spacings.XL}>
        {menuItems.map((menuItem, index) => (
          <MenuGroup key={menuItem.id} index={index} menuItem={menuItem} />
        ))}
      </Flex>
      <SidebarActionButtons />
    </div>
  )
}
