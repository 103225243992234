import React, { useCallback, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import { Waypoint } from "react-waypoint"
import Review from "components/reviews/Review"
import WithEmptyState from "components/common/WithEmptyState"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"
import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanReviews, getClientGuideReviews } from "store/reviews"
import { collectionAnySelector, collectionSelector, metadataSelector, modelSelector } from "store/selectors"

export default function Reviews() {
  useLoaderWatchers({ watchers: "reviews" })
  const dispatch = useDispatch()
  const guide = useSelector(modelSelector("guide"))
  const reviews = useSelector(collectionSelector("reviews"))
  const metadata = useSelector(metadataSelector("reviews"))
  const hasReviews = useSelector(collectionAnySelector("reviews"))
  useTitle(`Reviews | ${guide.display_name}`)

  const fetchReviews = useCallback((nextPage = false) => dispatch(getClientGuideReviews(guide.slug, nextPage)), [guide.slug, dispatch])

  useEffect(() => {
    fetchReviews()
    return () => dispatch(cleanReviews())
  }, []) //eslint-disable-line

  return (
    <Container>
      <FirstLoading name="reviews">
        <WithEmptyState name="review" hasItems={hasReviews}>
          <Row className="gap-y-20 gap-y-lg-30 gx-30">
            {reviews.map((review) => (
              <Col xs={12} sm={6} md={4} xl={3} key={review.id}>
                <Review review={review} className="h-100" />
              </Col>
            ))}
          </Row>
          {!isLastPage(metadata) && <Waypoint onEnter={() => fetchReviews(true)} />}
        </WithEmptyState>
      </FirstLoading>
    </Container>
  )
}
