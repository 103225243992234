import React from "react"
import { Col, Row } from "reactstrap"
import SocialsForm from "components/guide/settings/SocialsForm"

import { useTitle } from "hooks/useTitle"

export default function Socials() {
  useTitle("Settings — Social Networks")

  return (
    <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
      <Col xs={12}>
        <SocialsForm />
      </Col>
    </Row>
  )
}
