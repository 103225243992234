import React from "react"
import PaymentDetailsForm from "components/settings/PaymentDetailsForm"

import { useTitle } from "hooks/useTitle"

export default function PaymentDetails() {
  useTitle("Settings — Payment Details")

  return <PaymentDetailsForm />
}
