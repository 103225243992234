import React from "react"
import { Container } from "reactstrap"

import { useTitle } from "hooks/useTitle"

export default function TermsAndConditions() {
  useTitle("Terms & Conditions")

  return (
    <div className="flex-fill bg-light py-100">
      <Container>
        <h1 className="h2 lh-sm text-start mx-auto mb-40">Terms & Conditions</h1>
        <p> Effective date: April 22, 2024 </p>
        <p>
          {" "}
          Please read these Terms and Conditions (“Terms”, “Terms and Conditions”) carefully before using the Digital Sportsman website and
          Digital Sportsman application (the “Service”) operated by Digital Sportsman, LLC, a North Carolina Limited Liability Company with
          a registered office at 747 Sandy Ridge Rd., Tyner, NC 27980 (also referred to herein as “the Company”, “Digital Sportsman”, “us”,
          “we”, or “our”). These Terms are a binding legal agreement between you and the Company.<br></br>
          Your access to and use of the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users, vendors and others who wish to access or use the Service.<br></br>
          By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you do not
          have permission to access the Service. From time to time, we may update the Service and these Terms in our sole discretion. You
          agree to review these Terms periodically to ensure that you are familiar with the most recent version.<br></br>
          You agree to use the Service only for lawful purposes and in accordance with these Terms.
        </p>
        <p>
          THESE TERMS CONTAIN A RELEASE IN SECTION 5.3 & 9, AN INDEMNITY PROVISION IN SECTION 7, DISCLAIMERS IN SECTIONS 1 & 6, LIMITATIONS
          OF LIABILITY IN SECTION 3, 8 & 9, A MADITORY ARBITRATION CLAUSE IN SECTION 11 & 12, AND A CLASS ACTION WAIVER IN SECTION 18.
        </p>
        <p>
          Please do not hesitate to contact us with any questions you may have. If you have any questions about these Terms, please contact
          us at: cx@ds.guide.
        </p>

        <h4>1. Service Provided by The Company.</h4>
        <p>
          The Service consists of a desktop web application, mobile applications, and related services that providers of guide related
          services (“Vendors”) and guests who may be interested in soliciting guide related services (“Guest Users”) can use to locate,
          connect with, and solicit the services of Vendors. The Service provided by the Company are not guide services, but rather, the
          Service is a forum for connection between Vendors and Guest Users. We are an intermediary between Guest Users and Vendors that
          provide the guide services. We do not employ, recommend, or endorse any Vendor, but may highlight certain Vendors from time to
          time with identifying badges related to further due diligence. By making a booking through our Service, Guest Users are
          contracting directly with the Vendor and Guest Users will be subject to the Vendor's terms and conditions. The delivery terms and
          conditions of a Vendor can be obtained from the relevant Vendor, if requested, prior to a booking being confirmed or along with
          the booking confirmation. WE MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE QUALITY OF GUIDE SERVICES PROVIDED BY VENDORS. A
          Guest User should research a Vendor (both within and outside of the Service), examine alternative Vendors, and exercise their own
          independent judgment when contracting with a Vendor. A GUEST USER OR VENDOR USE AND/OR PROVISION OF GUIDE SERVICES IS AT YOUR SOLE
          AND EXCLUSIVE RISK.
        </p>

        <h4>2. Access to the Service.</h4>
        <p>
          To register with Digital Sportsman, Vendors, Guest Users or other visitors (hereinafter collectively referred to as “Users”), may
          be asked to provide certain registration details or other information, all of which is governed by our Privacy Policy. By
          registering with Digital Sportsman, Users represent and warrant to Digital Sportsman that all registration information submitted,
          including, without limitation, all first and last names, email addresses, mobile telephone numbers, nationalities, payment
          information, ownership information, and all other information provided is completely accurate, and that Users will keep such
          information up to date.
        </p>
        <p>
          By registering with Digital Sportsman or providing information for its use, Users represent and warrant that they are of legal age
          to enter into a binding contract, or if they are a legal entity, that they are authorized to enter into and bind the entity to
          this Agreement and perform the terms and conditions of this Agreement. Digital Sportsman reserves the right to refuse service,
          terminate accounts, remove or edit content at its sole discretion and timing, without prior notice.
        </p>
        <p>Maintaining confidentiality of registration information including passwords is the sole responsibility of Users.</p>

        <h4> 3. Agreements are between Guest Users and Vendor and are subject to the Vendors Terms and Conditions.</h4>
        <p>
          The Service may be used to locate, solicit and/or offer guide services for Guest Users and Vendors, and to facilitate payment
          between Guest Users and Vendors, but all transactions conducted through Service are by and between Guest Users and Vendors, and
          are subject to the Vendors Terms and Conditions. BY USING THE SERVICE, YOU AGREE THAT DIGITAL SPORTSMAN HAS NO LIABILITY FOR
          DAMAGES ASSOCIATED WITH ANY SERVICES PROVIDED BY A VENDOR (WHICH MAY INCLUDE BODILY INJURY TO, OR DEATH) OR RESULTING FROM ANY
          OTHER TRANSACTIONS BETWEEN USERS OF THE SERVICE.
        </p>

        <h4> 4. Prohibited uses of the Service. </h4>
        <p>
          The Service may be used only for lawful purposes and in accordance with this Agreement. Users hereby agree not to use the service:
        </p>
        <p>
          (1) In any way that violates any applicable federal, state, local, or international law or regulation;<br></br>
          (2) For the purpose of exploiting, harming, or attempting to exploit any other person or entity, or to harm minors in any way by
          exposing them to inappropriate content;<br></br>
          (3) To transmit or procure the sending of any solicitation, or spam to Users;<br></br>
          (4) To engage in fraud, false advertising, or misrepresentation, or to impersonate or attempt to impersonate Digital Sportsman,
          another User or any other person or entity (including, without limitation, by using email addresses or User screen names
          associated with any of the aforementioned).<br></br>
          (5) To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Service, or which, as determined by
          Digital Sportsman, may harm Digital Sportsman or Users of the Service or expose them to liability, including to post content or
          materials that are pornographic, threatening, harassing, abusive, or defamatory, or that contain nudity or graphic violence,
          incite violence, violate intellectual property rights, or violate the law or the legal rights (for example, privacy rights) of
          others.<br></br>
          (6) To arrange for trips pursuing or involving: (a) exotic or inherently dangerous pets including, without limitation, venomous
          snakes or constrictors, primates, wolves or wolf hybrids, non- domesticated cats, alligators, horses or other livestock; (b) any
          animal prohibited under applicable law; or (c) any animal that has a history of, or which has been trained for, attacks on pets or
          people.
        </p>

        <h4> 5. Content Posted by Users of Service. </h4>
        <p>
          <br></br>
          5.1 User Content. We may require or allow you to submit or upload text, photographs, images, videos, reviews, information and
          materials to your profile on the Service or otherwise in connection with using the Service and/or participating in promotional
          campaigns we conduct on the Service (collectively, “User Content”). For example, Vendors are invited to create a profile page with
          a photograph and other information and to transmit photos of the guests and trips under their care to Guest Users and through the
          Service’s Feeder tab, while Guests Users may submit reviews of Vendors.
        </p>
        <p>
          5.2 License. Except for the limitations on our use and disclosure of personal information described in our Privacy Policy, to the
          maximum extent and duration permitted under any applicable law, you grant Digital Sportsman an irrevocable, perpetual,
          non-exclusive, fully paid worldwide license to use, copy, perform, publicly display, reproduce, adapt, modify, transmit,
          broadcast, prepare derivative works of, and/or distribute User Content in connection with providing and/or promoting the Service,
          and to sublicense these rights to third parties.
        </p>
        <p>
          5.3 Release. If your name, voice, image, persona, likeness, or performance is included in any User Content, you hereby waive, and
          release Digital Sportsman and its Users from, any claim or cause of action, whether known or unknown, for defamation, copyright
          infringement, invasion of the rights of privacy, publicity, or personality, or any similar claim arising out of the use of User
          Content in accordance with the license in Section 5.2 and the other provisions of these Terms.
        </p>
        <p>
          5.4 Your Representations and Warranties about User Content. You represent and warrant that with an submission, uploading, or
          publishing of User Content through the service, that (1) you are the owner or licensor of User Content, and that you have all
          rights, consents and permissions necessary to grant the license in Section 5.2 and make the release in Section 5.3 with respect to
          the User Content, (2) that you have any necessary consents and releases from individuals who appear in the User Content; and (3)
          the User Content does not violate the law or these Terms.
        </p>
        <p>
          5.5 Right to Remove or Screen Your Content. Though we are not obligated to do so, we reserve the right to monitor, screen, edit
          and/or remove User Content on the Service. Our enforcement of these Terms with respect to User Content is at our discretion, and
          failure to enforce the Terms in one instance does not create a waiver of our right to enforce them in another instance. We have no
          obligation to retain or provide you with copies of User Content, nor will we have any liability to you for any deletion,
          disclosure, loss or modification to User Content. It is your sole responsibility to maintain backup copies of User Content.
        </p>

        <h4> 6. Disclaimer of Warranty. </h4>
        <p>
          The information and materials found on the Service are provided “as is” and “as available.” Reviews, profiles, advice, opinions,
          statements, offers, or other information or content made available through the Service, but not directly by Digital Sportsman, are
          those of their respective authors, who are solely responsible for such content. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW,
          DIGITAL SPORTSMAN DOES NOT: (1) WARRANT THE ACCURACY, ADEQUACY OR COMPLETENESS OF INFORMATION AND MATERIALS ON THE SERVICE; (2)
          ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY
          OTHER THAN DIGITAL SPORTSMAN; (3) WARRANT THAT YOUR USE OF THE SERVICES WILL BE SECURE, FREE FROM COMPUTER VIRUSES, UNINTERRUPTED,
          ALWAYS AVAILABLE, ERROR-FREE OR WILL MEET YOUR REQUIREMENTS, OR THAT ANY DEFECTS IN THE SERVICE WILL BE CORRECTED. TO THE EXTENT
          PERMITTED BY APPLICABLE LAW, DIGITAL SPORTSMAN EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED OR STATUTORY, WITH
          RESPECT TO THE SERVICE, AND SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          NON-INFRINGEMENT, AND ACCURACY. IN ADDITION, AND WITHOUT LIMITING THE FOREGOING, WE MAKE NO REPRESENTATION OR WARRANTY OF ANY
          KIND, WHETHER EXPRESS OR IMPLIED, REGARDING THE SUITABILITY OF ANY VENDOR THAT OFFERS GUIDE SERVICES THROUGH THE SERVICE.
        </p>

        <h4> 7. INDEMNITY. </h4>
        <p>
          Each Guest User and Vendor, by making use of the Service agrees to release, defend, indemnify, and hold Digital Sportsman and our
          affiliates and subsidiaries, and their officers, directors, employees and agents, harmless from any and all claims, liabilities,
          damages, losses and expenses, including, without limitation, reasonable legal and accounting fees arising out of or in any way
          connected with their use the Service. Notwithstanding any United States or other law relating to the limitation of liability,
          Vendors and Guest Users agree to fully indemnify Digital Sportsman in the full amount of damages actually paid by Digital
          Sportsman pursuant to a dispute or controversy.
        </p>
        <p>
          Users (the "Indemnifying Party") will defend and indemnify Digital Sportsman (the "Indemnified Party") against any and all claims,
          losses, costs, and expenses, including reasonable attorneys' fees, that the Indemnified Party may incur as a result of claims in
          any form by third parties arising from:
        </p>
        <p>
          (1) The Indemnifying Party's intentional tortious or negligent acts or omissions (including, without limitation, noncompliance
          with any applicable legal and/or regulatory requirements);<br></br>
          (2) The Indemnifying Party's breach of any representation, warranty, or covenant set forth herein, or in any contract by and
          between the Indemnifying Party and another User of the Service of the Indemnified Party;<br></br>
          (3) The infringement of any third party's intellectual property by any trademarks or other intellectual property provided by the
          Indemnifying Party to the Indemnified Party;<br></br>
          (4) The Indemnifying Party's failure to collect all taxes, fees, and charges that may be applicable to the services being offered
          for sale by the Indemnifying Party;<br></br>
          (5) The Indemnifying Party's failure to provide accurate information related to their listing which results in legitimate claims
          of false advertising.
        </p>

        <h4> 8. Limitation of Liability. </h4>
        <p>
          In no event will Digital Sportsman, its affiliates, or any of their licensors, service providers, employees, agents, officers,
          managers, or members be liable for damages of any kind, under any legal theory arising out of or in connection with, a User’s use
          or inability to use the Service, any websites linked to it, any content on the Service, or Vendor provided services, including any
          direct, indirect, special, incidental, consequential, or punitive damages, including but not limited to, personal injury, pain and
          suffering, emotional distress, loss of revenue, loss of profits, death, disability, loss of business or anticipated savings, loss
          of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of contract or otherwise, even
          if foreseeable.
        </p>

        <h4> 9. No Liability for Digital Sportsman for User Actions (whether online or offline). </h4>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL DIGITAL SPORTSMAN BE LIABLE FOR ANY DAMAGES WHATSOEVER,
          WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, AND/OR CONSEQUENTIAL, ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU
          OR ANYONE ELSE IN CONNECTION WITH THE SERVICE, INCLUDING WITHOUT LIMITATION, BODILY INJURY, DEATH, EMOTIONAL DISTRESS, AND/OR ANY
          OTHER DAMAGES RESULTING FROM RELIANCE ON INFORMATION OR CONTENT POSTED ON OR TRANSMITTED THROUGH THE SERVICE, OR FOR ANY
          INTERACTIONS WITH OTHER USERS OF THE SERVICE, WHETHER ONLINE OR OFFLINE. THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM
          THE CONDUCT OF USERS WHO ATTEMPT TO DEFRAUD OR HARM YOU.
        </p>
        <p>
          IF YOU HAVE A DISPUTE WITH A VENDOR OR GUEST USER, YOU AGREE TO RELEASE DIGITAL SPORTSMAN FROM ALL CLAIMS, DEMANDS AND DAMAGES OF
          EVERY NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. IN NO EVENT WILL DIGITAL SPORTSMAN BE
          LIABLE FOR DIRECT OR INDIRECT CONSEQUENCES OF A GUEST USER OR VENDOR FAILING TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS.
        </p>

        <h4> 10. Miscellaneous. </h4>
        <p>
          Nothing in these Terms will be construed as making either party the partner, joint venturer, agent, legal representative,
          employer, worker, or employee of the other. Neither party will have, or hold itself out to any third party as having, any
          authority to make any statements, representations or commitments of any kind, or to take any action, that will be binding on the
          other, except as provided for herein or authorized in writing by the party to be bound. These Terms are non-exclusive and do not
          prohibit Vendors from offering guide services via other means or third parties. The invalidity, illegality or unenforceability of
          any term or provision of these Terms will in no way effect the validity, legality or enforceability of any other term or provision
          of these Terms. In the event a term or provision is determined to be invalid or unenforceable, the parties agree to replace such
          term or provision with a term or provision that is valid and enforceable and that comes closest to expressing the intention of the
          invalid or unenforceable term or provision, and these Terms will be enforceable as so modified. To the maximum extent possible
          under applicable local law, this Agreement will be binding on and will inure to the benefit of the legal representatives,
          successors and assigns of the parties hereto. Failure to enforce the Terms in one instance does not create a waiver of Digital
          Sportsman’s right to enforce them in another instance.
        </p>

        <h4> 11. Arbitration. </h4>
        <p>
          By using the Service, you agree to submit any disputes arising from these Terms or your use of the Service, including disputes
          arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding
          confidential arbitration under the Rules of Arbitration of the American Arbitration Association applying North Carolina law.
          Arbitration shall occur in Chowan County, North Carolina.
        </p>

        <h4> 12. Governing Law and Venue. </h4>
        <p>
          These Terms are governed by the laws of the state of North Carolina, in the United States of America, and are subject to all
          applicable federal, state and local laws and regulations. All issues and questions concerning the construction, validity,
          interpretation and enforceability of these Terms shall be governed by, and construed in accordance with, the laws of North
          Carolina, without giving effect to the conflict of laws rules thereof, and any matters or proceedings which are not subject to
          arbitration as set forth herein and/or for entering any judgment on an arbitration award, shall take place in the State of North
          Carolina. By using the Service, you hereby agree that any and all disputes regarding these Terms will be subject to the courts
          located in North Carolina.<br></br>
          YOU AGREE THAT ALL ACTIONS OR PROCEEDINGS, NOT SUBJECT TO ARBITRATION ARISING OUT OF OR IN CONNECTION WITH THESE TERMS SHALL BE
          TRIED AND LITIGATED ONLY IN THE STATE AND FEDERAL COURTS LOCATED IN PASQUOTANK COUNTY, NORTH CAROLINA.
        </p>

        <h4> 13. Links To Other Web Sites. </h4>
        <p>
          Our Service may contain links to third party web sites or services that are not owned or controlled by us. We have no control
          over, and assume no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do
          not warrant the offerings of any of these entities/individuals or their websites. You acknowledge and agree that we shall not be
          responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use
          of or reliance on any such content, goods or services available on or through any such third party web sites or services. We
          strongly advise you to read the terms and conditions and privacy policies of any third party web sites or services that you visit.
        </p>

        <h4> 14. Taxes. </h4>
        <p>
          Except for taxes on Digital Sportsman’s income and gross receipts or where Digital Sportsman is otherwise required to collect
          taxes, you acknowledge that you are solely responsible to pay any applicable taxes that arise as a result of your purchase,
          provision, or use of vendor’s guide services through the Service. This includes, without limitation, any form of sales tax, VAT,
          or income tax on fees paid or received by you through the Service.
        </p>

        <h4> 15. Changes or Amendment to Terms. </h4>
        <p>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any time. All changes are effective immediately
          when we post them and apply to all access to and use of the Service thereafter. Your use of the Service after we post any changes
          to these Terms constitutes your agreement to those changes. You agree to review these Terms periodically to ensure that you are
          familiar with the most recent version. By continuing to access or use our Service after any revisions become effective, you agree
          to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Service.
        </p>

        <h4> 16. Claim of Intellectual Property Infringement. </h4>
        <p>
          Digital Sportsman committed to complying with U.S. intellectual property laws and to respond to claims of copyright, trademark or
          patent infringement. The Company will promptly process and investigate notices of alleged infringement and will take appropriate
          actions as set forth herein. Notifications of claimed intellectual property infringement should be sent to us with the heading
          “Intellectual Property Infringement Notification”. Notification must be submitted us in the manner described below:
        </p>
        <p>
          By Mail: Digital Sportsman, LLC<br></br>
          747 Sandy Ridge Rd.<br></br>
          Tyner, N.C. 27909<br></br>
          By E-mail: cx@ds.guide
        </p>
        <p>You must provide all of the following information when providing notice of the claimed intellectual property infringement:</p>
        <ol>
          <li>A physical or electronic signature of a person authorized to act on behalf of the intellectual property owner.</li>
          <li>Identification of the intellectual property claimed to have been infringed.</li>
          <li>
            Identification of the material that is claimed to be infringing or to be the subject of the infringing activity, and that is to
            be removed or access to which is to be disabled, as well as information reasonably sufficient to permit the Company to locate
            the material.
          </li>
          <li>
            Information reasonably sufficient to permit the Company to contact the intellectual property owner, such as an address,
            telephone number, and, if available, an electronic mail address.
          </li>
          <li>
            A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the
            intellectual property owner, its agent, or law.
          </li>
          <li>
            A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act
            on behalf of the intellectual property owner.
          </li>
        </ol>
        <p>
          For more details on the information required for valid notification of copyright under the Digital Millennium Copyright Act, see
          17 U.S.C. 512(c)(3).<br></br>
          You should be aware that, under the Digital Millennium Copyright Act, claimants who make misrepresentations concerning copyright
          infringement may be liable for damages incurred as a result of the removal or blocking of the material, court costs, and
          attorneys’ fees.
        </p>

        <h4> 17. Intellectual Property. </h4>
        <p>
          The Service and its original content, features and functionality are and will remain the exclusive property of the Company, and
          its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries.
          Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of
          Digital Sportsman, LLC.
        </p>

        <h4>18. CLASS ACTION WAIVER.</h4>
        <p>
          BY USING THIS SERVICE, YOU AND DIGITAL SPORTSMAN AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
          CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless both you and Digital
          Sportsman agree, no arbitrator or judge may consolidate more than one person’s claims or otherwise preside over any form of a
          representative or class proceeding.
        </p>
        <p></p>
        <p></p>
        <p></p>
      </Container>
    </div>
  )
}
