import React from "react"
import ReactDOM from "react-dom"
import App from "App"
import * as serviceWorker from "serviceWorker"
import { Provider } from "react-redux"

import store from "store"
import { I18nextProvider } from "react-i18next"
import i18n from "i18n"
import { GoogleOAuthProvider } from "@react-oauth/google"

ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </I18nextProvider>
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
