import { scrollToActive } from "helpers/DOM"
import { useEffect, useRef } from "react"

export function useScrollToActive() {
  const ref = useRef(null)

  useEffect(() => {
    const activeLink = ref.current ? ref.current.querySelector("a.active-link") : null
    if (activeLink) scrollToActive(activeLink, ref.current)
  }, [ref])

  return ref
}
