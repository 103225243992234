import { createAction, createReducer, createSelector } from "@reduxjs/toolkit"
import { api } from "./helpers/api"

import moment from "moment"
import { find, get, mapValues } from "lodash"
import { convertToMetric } from "helpers/string"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"
import { DATE_FORMAT } from "modules/datepicker/constants"

const requested = createAction("weather/requested")
const received = createAction("weather/received")
const failed = createAction("weather/failed")
const cleaned = createAction("weather/cleaned")

export const initialState = {
  ...LOADING_INITIAL_STATE,
  forecast: [],
  hourlyForecasts: [],
  hourlyTideForecasts: [],
  metadata: {
    trip_locations: []
  }
}

// Helpers

const forecastHourlyFormat = (forecast, key) => {
  const { [key]: hourlyKey = {} } = forecast.forecast_json || {}
  if (!hourlyKey?.validTimeLocal?.length) return {}
  return hourlyKey.validTimeLocal.reduce((acc, dateTime, index) => {
    const hour = moment(dateTime).format("HH:mm")
    const hourData = Object.keys(hourlyKey).reduce(
      (hourlyAcc, key) => ({
        ...hourlyAcc,
        [key]: hourlyKey[key].at(index)
      }),
      {}
    )
    acc[hour] = hourData
    return acc
  }, {})
}

const weatherReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.forecast = action.payload.data
    Object.assign(state.metadata, action.payload.metadata)

    state.hourlyForecasts = state.forecast.reduce(
      (acc, item) => ({
        ...acc,
        [item.forecast_date]: forecastHourlyFormat(item, "hourly")
      }),
      {}
    )

    state.hourlyTideForecasts = state.forecast.reduce(
      (acc, item) => ({
        ...acc,
        [item.forecast_date]: forecastHourlyFormat(item, "tide")
      }),
      {}
    )
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState
})
export default weatherReducer

export const guideForecast = (params) =>
  api({
    url: "/guide/weather",
    method: "get",
    params,
    onStart: requested.type,
    onSuccess: received.type,
    onError: failed.type
  })

export const cleanWeather = () => (dispatch) => dispatch(cleaned())

// SELECTORS

export const firstForecastSelector = createSelector(
  (state) => state.weather.forecast || [],
  (forecast) => forecast.at(0) || {}
)

export const forecastDateSelector = createSelector(
  firstForecastSelector,
  (forecast) => forecast.forecast_date || moment().format(DATE_FORMAT)
)

export const dateForecastSelector = (date) =>
  createSelector([(state) => state.weather.forecast, forecastDateSelector], (forecast, forecast_date) =>
    find(forecast, ["forecast_date", date || forecast_date])
  )

export const dateForecastObjSelector = (name, date) =>
  createSelector(dateForecastSelector(date), (forecast) => get(forecast, ["forecast_json", name], {}))

export const dateHourlyForecastSelector = (date) =>
  createSelector(
    [(state) => state.weather.hourlyForecasts, forecastDateSelector],
    (hourlyForecasts, forecast_date) => hourlyForecasts[date || forecast_date]
  )

export const dateHourlyTideForecastSelector = (date) =>
  createSelector(
    [(state) => state.weather.hourlyTideForecasts, forecastDateSelector],
    (hourlyTideForecasts, forecast_date) => hourlyTideForecasts[date || forecast_date]
  )

export const dateHourlyFormattedForecastSelector = (name, value, digits, isConvert = false, date) =>
  createSelector(dateHourlyForecastSelector(date), (hourlyForecast) =>
    mapValues(hourlyForecast, (hourData) => convertToMetric(hourData[name], value, false, digits, isConvert))
  )
