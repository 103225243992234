import { useEffect } from "react"
import { useSelector } from "react-redux"
import { errorFieldsSelector } from "modules/errors/selectors"

// maps api errors to form to display messages below input fields
const useFormErrors = (form) => {
  const errors = useSelector(errorFieldsSelector())
  useEffect(() => {
    if (form && errors) {
      const errorFields = Object.keys(errors).map((field) => ({
        name: field,
        errors: errors[field]
      }))
      form.setFields(errorFields)
    }
  }, [form, errors])

  return { errors }
}

export default useFormErrors
