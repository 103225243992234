import React from "react"
import { Col, Form, Row } from "antd"
import useFormErrors from "hooks/useAntdFormErrors"
import { serialize } from "object-to-formdata"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { guideSelector, updateGuide, updateUser } from "store/user"
import { CustomForm } from "ui/Form"
import { CustomInput, PhoneInput, TextArea } from "ui/Input"
import { AddAvatar } from "ui/PersonalInfo/ProfileInformation/AddAvatar"
import { compressFile } from "helpers/compress"

export const ProfileInformation = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { id, display_name, company_name, website, description } = useSelector(guideSelector)
  const { avatar } = useSelector((store) => store.user)
  const requiredErrorMessage = t("form_validation.cannot_be_blank")

  const onFinish = (formData, navigateTo) => {
    const serializedFormData = serialize({ guide: formData }, { indices: true })
    dispatch(updateGuide(id, serializedFormData)).then(() => navigateTo())
  }

  const handleImageChange = async (e) => {
    const avatar = await compressFile(e.target.files[0])
    const formData = serialize({ avatar })
    if (avatar) dispatch(updateUser(formData))
  }

  useFormErrors(form)

  return (
    <CustomForm
      form={form}
      name="settings-profile-information"
      layout="vertical"
      initialValues={{
        display_name,
        company_name,
        website,
        description
      }}
    >
      <Form.Item
        name="avatarPhoto"
        label={t("guide.profile_image")}
        required={true}
        rules={[{ required: true, message: t("form_validation.cannot_be_blank") }]}
        className="mb-4xl"
      >
        <AddAvatar avatar={avatar} handleImageChange={handleImageChange} />
      </Form.Item>

      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            label={t("guide.display_name")}
            name="display_name"
            required={true}
            rules={[{ required: true, message: requiredErrorMessage }]}
          >
            <CustomInput placeholder={t("guide.display_name")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("guide.company_name")} name="company_name">
            <CustomInput placeholder={t("guide.company_name")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            label={t("sign_up.first_name")}
            name="first_name"
            required={true}
            rules={[{ required: true, message: requiredErrorMessage }]}
          >
            <CustomInput placeholder={t("sign_up.first_name")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("sign_up.last_name")}
            name="last_name"
            required={true}
            rules={[{ required: true, message: requiredErrorMessage }]}
          >
            <CustomInput placeholder={t("sign_up.last_name")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col span={12}>
          <Form.Item
            label={t("sign_up.email")}
            name="email"
            required={true}
            rules={[
              { required: true, message: requiredErrorMessage },
              { type: "email", message: t("form_validation.invalid_email") }
            ]}
          >
            <CustomInput disabled placeholder={t("sign_up.email")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("guide.phone_number")}
            name="phone_number"
            required={true}
            rules={[{ required: true, message: requiredErrorMessage }]}
          >
            <PhoneInput />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label={t("guide.website")} name="website">
        <CustomInput placeholder="https://" />
      </Form.Item>
      <Form.Item
        label={t("guide.description")}
        name="description"
        required={true}
        rules={[{ required: true, message: requiredErrorMessage }]}
      >
        <TextArea placeholder={t("guide.description_placeholder")} style={{ height: 147 }} />
      </Form.Item>

      <Form.Item
        label={t("pro.addresses.street_address")}
        name="street_address"
        required={true}
        rules={[{ required: true, message: requiredErrorMessage }]}
      >
        <CustomInput placeholder={t("pro.addresses.street_address")} />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("pro.addresses.country")}
            name="country"
            required={true}
            rules={[{ required: true, message: requiredErrorMessage }]}
          >
            <CustomInput placeholder={t("pro.addresses.country")} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t("pro.addresses.state")}
            name="state"
            required={true}
            rules={[{ required: true, message: requiredErrorMessage }]}
          >
            <CustomInput placeholder={t("pro.addresses.state")} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label={t("pro.addresses.city")}
            name="city"
            required={true}
            rules={[{ required: true, message: requiredErrorMessage }]}
          >
            <CustomInput placeholder={t("pro.addresses.city")} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label={t("pro.addresses.zip")}
            name="zip_code"
            required={true}
            rules={[{ required: true, message: requiredErrorMessage }]}
          >
            <CustomInput placeholder={t("pro.addresses.zip")} />
          </Form.Item>
        </Col>
      </Row>
    </CustomForm>
  )
}
