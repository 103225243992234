import React, { forwardRef, useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { Dropdown, DropdownToggle } from "reactstrap"
import { DropdownItem, DropdownMenu } from "components/common/Dropdown"
import Input from "components/form/Input"

import usePredictions from "modules/google/hooks/usePredictions"
import DynamicTag from "components/DynamicTag"

const GooglePlacesInput = (
  {
    tag,
    onInput,
    onChange,
    dropdownClassName,
    wrapperProps = null,
    dropDownMenuClassName,
    dropDownMenuProps = {},
    resetIfChanged = false,
    useDefaultValue = false,
    innerRef,
    name,
    ...rest
  },
  ref
) => {
  const inputRef = useRef(null)
  const [predictions, place, getPredictions, selectPlace] = usePredictions()

  const coordinates = useMemo(() => {
    if (!place) return
    const location = place?.geometry?.location
    if (!location) return
    const lat = location.lat()
    const lng = location.lng()
    return { lat, lng }
  }, [place])

  const handleKeyPress = (event) => event.key === "Enter" && event.preventDefault()
  const changeHandler = (event) => event.persist?.() || getPredictions(event.target.value)
  const clickHandler = (placeId) => (event) => {
    if (event.persit) event.persit()
    selectPlace(placeId, (place) => {
      if (useDefaultValue) return
      inputRef.current.value = resetIfChanged ? "" : place.formatted_address
    })
  }

  useEffect(() => {
    if (useDefaultValue && inputRef.current) inputRef.current.value = rest.defaultValue
  }, [rest.defaultValue]) //eslint-disable-line

  useEffect(() => {
    if (typeof onInput === "function") onInput({ target: { name, value: inputRef.current.value, predictions } })
  }, [predictions, onInput]) //eslint-disable-line

  useEffect(() => {
    if (!coordinates) return
    if (typeof onChange === "function") onChange({ target: { name, value: place.formatted_address, place, coordinates } })
  }, [coordinates?.lat, coordinates?.lng]) //eslint-disable-line

  useEffect(() => {
    if (ref) ref.current = inputRef.current
  }, [ref])

  return (
    <DynamicTag tag={wrapperProps ? "div" : React.Fragment} {...wrapperProps}>
      <DynamicTag {...rest} tag={tag || Input} type="text" onKeyPress={handleKeyPress} onChange={changeHandler} ref={inputRef} />
      <Dropdown direction="down" isOpen={!!predictions?.length} toggle={() => {}}>
        <DropdownToggle className="position-absolute p-0 border-0 opacity-0 pointer-events-none" />
        <DropdownMenu {...dropDownMenuProps} container="body">
          <div className="vstack">
            {predictions?.map((prediction) => (
              <DropdownItem className="text-wrap" onClick={clickHandler(prediction.place_id)} key={prediction.place_id}>
                {prediction.description}
              </DropdownItem>
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
    </DynamicTag>
  )
}

GooglePlacesInput.propTypes = {
  tag: PropTypes.elementType,
  onInput: PropTypes.func,
  onChange: PropTypes.func,
  dropdownClassName: PropTypes.string,
  wrapperProps: PropTypes.object,
  dropDownMenuClassName: PropTypes.string,
  dropDownMenuProps: PropTypes.object,
  resetIfChanged: PropTypes.bool,
  useDefaultValue: PropTypes.bool,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  name: PropTypes.string
}
export default forwardRef(GooglePlacesInput)
