import React from "react"

export const User = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M17.2184 23.7324V23.4835L17.0199 23.3335C15.0129 21.8169 13.6984 19.4035 13.6984 16.7199V11.4399C13.6984 6.8451 17.4036 3.13989 21.9984 3.13989C26.5932 3.13989 30.2984 6.8451 30.2984 11.4399V16.7199C30.2984 19.4035 28.9839 21.8169 26.977 23.3335L26.7784 23.4835V23.7324V27.7199C26.7784 27.8222 26.7902 27.9623 26.8761 28.1231C26.9482 28.2581 27.0573 28.3782 27.1836 28.4968C27.4655 28.7616 27.9353 29.0491 28.486 29.3227C29.0256 29.5908 29.6793 29.8661 30.3745 30.1589L30.4411 30.1869C31.1672 30.4927 31.9458 30.8219 32.7277 31.1959C34.2946 31.9455 35.872 32.8741 37.0823 34.2149C38.2793 35.541 39.0984 37.3242 39.0984 39.5999V39.9799H4.89844V39.5999C4.89844 37.3459 5.71683 35.5693 6.91459 34.2424C8.12636 32.9 9.70557 31.9541 11.2716 31.1948C12.0517 30.8166 12.828 30.4897 13.5535 30.1861L13.6473 30.1468C14.331 29.8608 14.9763 29.5908 15.512 29.3221C16.0848 29.0357 16.5365 28.7255 16.8105 28.4719C16.9358 28.356 17.0435 28.241 17.1154 28.115C17.2052 27.9576 17.2184 27.8206 17.2184 27.7199V23.7324ZM37.6734 39.2199H38.2382L38.1698 38.6593C37.978 37.0888 37.4472 35.768 36.504 34.7267C35.4043 33.5103 33.9017 32.6137 32.3895 31.889C31.6456 31.5325 30.8981 31.2187 30.1942 30.9231L30.1515 30.9052C29.4301 30.6023 28.7595 30.3197 28.161 30.0222C27.5532 29.72 27.0495 29.4306 26.6596 29.0617C26.2815 28.704 26.0184 28.2256 26.0184 27.7199V23.1097L26.1876 22.9889C28.1835 21.655 29.5384 19.3181 29.5384 16.7199V11.4399C29.5384 7.23469 26.2036 3.89989 21.9984 3.89989C17.7932 3.89989 14.4584 7.23469 14.4584 11.4399V16.7199C14.4584 19.3181 15.8134 21.655 17.8093 22.9889L17.9784 23.1097V27.7199C17.9784 28.217 17.7169 28.6782 17.3389 29.0327C16.9511 29.3964 16.4389 29.6932 15.835 29.9951C15.2371 30.2932 14.5657 30.5803 13.8437 30.8888L13.8408 30.8901C13.1243 31.1963 12.3624 31.5219 11.6048 31.8902C10.0918 32.6258 8.59056 33.5362 7.49201 34.7552L7.49159 34.7556C6.55481 35.7978 6.01946 37.1025 5.82721 38.6586L5.75786 39.2199H6.32344H37.6734Z"
      fill={fill}
      stroke={fill}
    />
  </svg>
)
