import * as loadImage from "blueimp-load-image"

export async function fixRotation(file, mediaType = "image") {
  // We do not rotate videos.
  if (mediaType !== "image") return file

  return new Promise((resolve) => {
    loadImage(
      file,
      (img) => {
        img.toBlob((blob) => {
          resolve(blob)
        }, "image/jpeg")
      },
      {
        canvas: true,
        orientation: true
      }
    )
  })
}
