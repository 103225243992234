import React, { useEffect } from "react"
import { useMatch, useSearchParams } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import Form from "components/clients/Form"
import Details from "components/clients/Details"
import OffcanvasWindow from "modules/offcanvas/OffcanvasWindow"

// Redux
import { useDispatch } from "react-redux"
import { cleanClient, getGuideClient } from "store/clients"

export default function ClientWindow() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const isNew = useMatch(oldRoutes.guideClientNewPath())
  const isShow = useMatch(oldRoutes.guideClientPath())
  const isEdit = useMatch(oldRoutes.guideClientEditPath())
  const isPersisted = !isNew && (isShow || isEdit)
  const clientId = isPersisted?.params?.id

  useEffect(() => {
    if (clientId) dispatch(getGuideClient(clientId))
    return () => dispatch(cleanClient())
  }, [clientId]) //eslint-disable-line

  return (
    <OffcanvasWindow
      container={document.getElementById("clients")}
      loadingName="clients.client"
      exitRedirect={oldRoutes.guideClientsPath({}, searchParams)}
    >
      {({ setChanged, closeHandler }) =>
        !isNew && isShow ? <Details /> : <Form onChanged={setChanged} closeHandler={closeHandler} edit={isEdit} />
      }
    </OffcanvasWindow>
  )
}
