import { Button, Flex, Form } from "antd";
import React from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { confirmEmail, resendConfirmEmailCode } from "store/auth";
import { CustomForm } from "ui/Form";
import { CodeInput } from "ui/Input";
import { Text, Title } from "ui/Typography";
import { routes } from "router"
import useFormErrors from "hooks/useAntdFormErrors";
import { CustomInputError } from "ui/CustomInputError";

const initialValue = ["", "", "", "", ""]

export const ConfirmEmail = () => {
    const { t } = useTranslation()
    const [initialCode, setInitialCode] = React.useState()
    const [searchParams] = useSearchParams()
    const email = searchParams.get("email")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { loading } = useSelector((state) => state.auth)
    const [form] = Form.useForm()

    const onFinish = ({ code }) => {
        const fullCode = code.join("")
        
        if (fullCode.length === 5) {
            dispatch(confirmEmail(fullCode)).then((res) => {
                navigate(routes.signInV2());
            })
        }
    }

    const handleResend = React.useCallback(() => {
        dispatch(resendConfirmEmailCode(email))
        setInitialCode(initialValue)
    }, [dispatch, email])

    const { errors } = useFormErrors(form)
    
    return (
        <>
            <Title className="justify-center mt-4xl mb-m">
                {t("verification.email_title")}
            </Title>
            <Text className="justify-center mb-4xl pb-s">
                {t("verification.email_details")}
            </Text>
            <CustomForm 
                name="email-confirmation"
                layout="vertical"
                onFinish={onFinish}
                form={form}
            >
                <CodeInput
                    form={form}
                    name="code"
                    initialValue={initialCode}
                    help={!!errors?.pin_code && 
                        <CustomInputError 
                            errorKey={errors.pin_code}
                            customHandler={
                                errors.pin_code.includes("user.attributes.pin_code.expired")
                                  ? () => (
                                      <Button
                                        className="pl-0 pb-0 mb-0 mt-0 pt-0 fw-normal"
                                        color="primary"
                                        variant="link"
                                        size="medium"
                                        onClick={handleResend}
                                      >
                                        {t("enter_code.resend_code")}
                                      </Button>
                                    )
                                  : null
                              }
                        />
                    }
                />
                <Form.Item>
                    <Button 
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                        disabled={loading}
                        className="mt-5xl"
                    >
                        {t("verification.verify")}
                    </Button>
                </Form.Item>
            </CustomForm>
            <Flex justify="center">
                <Text type="secondary" variant="body">
                    {t("enter_code.code_not_received")}
                </Text>
                <Text className="ml-xs" link>
                    <Link onClick={handleResend}>{t("enter_code.resend_code")}</Link>
                </Text>
            </Flex>
        </>
    );
}