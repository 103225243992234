export const findScrollParent = (element) => {
  let currentElement = element
  while (currentElement) {
    const computedStyle = window.getComputedStyle(currentElement)
    if (
      computedStyle.overflowX === "scroll" ||
      computedStyle.overflowX === "auto" ||
      computedStyle.overflowY === "scroll" ||
      computedStyle.overflowY === "auto"
    ) {
      return currentElement
    }
    currentElement = currentElement.parentElement
  }
  return null
}

export const scrollToActive = (element, scrollWrapper = null) => {
  const wrapper = scrollWrapper || findScrollParent(element)
  if (wrapper && element) {
    const left = element.offsetLeft + element.clientWidth / 2 - wrapper.clientWidth / 2 || 0
    const top = element.offsetTop + element.clientHeight / 2 - wrapper.clientHeight / 2 || 0
    wrapper.scrollTo({
      left,
      top,
      behavior: "smooth"
    })
  }
}
