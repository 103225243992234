import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Table, Input } from "reactstrap"
import Avatar from "./Avatar"
import GroupSelector from "components/clients/GroupSelector"

import { serialize } from "object-to-formdata"

import { useDispatch } from "react-redux"
import { updateClient } from "store/clients"
// import { addGroupClients, removeGroupClients } from "store/clients"

SortableTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      user_id: PropTypes.string.isRequired,
      user: PropTypes.shape({
        first_name: PropTypes.string.isRequired,
        last_name: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        phone_number: PropTypes.string.isRequired
      }).isRequired,
      client_groups: PropTypes.arrayOf(
        PropTypes.shape({
          group_id: PropTypes.string.isRequired
        })
      ).isRequired,
      last_trip_date: PropTypes.string,
      total_booking_amount: PropTypes.number
    })
  ).isRequired,
  selectRow: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      thProps: PropTypes.object,
      content: PropTypes.node.isRequired
    })
  ).isRequired
}

export default function SortableTable({ rows, selectRow, selectedIds, headers }) {
  const selectedClass = "bg-primary-second bg-opacity-5"
  const dispatch = useDispatch()

  const addGroupHandler = (client) => (group_id) => {
    const group_clients_attributes = [...client.client_groups, { group_id }]
    const data = serialize({ guide_client: { group_clients_attributes } }, { indices: true })
    dispatch(updateClient(client.id, data))
    // dispatch(
    //   addGroupClients(group_id, {
    //     group: { client_ids: [client.id] }
    //   })
    // )
  }

  const removeGroupHandler = (client) => (group_id) => {
    const group_clients_attributes = client.client_groups.map((group) => ({
      ...group,
      ...(group_id === group.group_id && { _destroy: true })
    }))
    const data = serialize({ guide_client: { group_clients_attributes } }, { indices: true })
    dispatch(updateClient(client.id, data))
    // dispatch(
    //   removeGroupClients(group_id, {
    //     group: { client_ids: [client.id] }
    //   })
    // )
  }

  return (
    <Table className="w-100 text-nowrap" hover responsive>
      <thead>
        <tr className="text-uppercase border-top border-bottom border-gray-lightest h6 fw-medium">
          {headers.map(({ thProps = {}, content }, index) => (
            <th key={index} {...thProps}>
              {content}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((client) => {
          const isSelected = selectedIds.includes(client.user_id)
          const props = { className: isSelected ? selectedClass : "" }
          return (
            <tr key={client.id} className="border-bottom border-gray-lightest fs-5 fw-normal link-target" valign="middle">
              <td {...props} valign="middle">
                <label
                  className="bg-white border border-3 border-primary-second border-opacity-0 border-opacity-25-hover d-inline-block lh-1"
                  style={{ borderRadius: 7 }}
                >
                  <Input
                    type="checkbox"
                    checked={isSelected}
                    onChange={() => selectRow(client.user_id)}
                    className="m-0 cursor-pointer border-gray-lightest border-primary-second-checked z-1 position-relative"
                  />
                </label>
              </td>
              <td className={[props.className, "py-10"].join(" ")}>
                <div className="d-flex align-items-center">
                  <Avatar user={client.user} size={32} border={false} fit={false} />
                  <Link
                    className="link link-dark text-primary-second-hover link-underline-opacity-0 fw-medium ms-10"
                    to={oldRoutes.guideClientPath({ id: client.id })}
                  >
                    {client.user.first_name} {client.user.last_name}
                  </Link>
                </div>
              </td>
              <td {...props}>
                <GroupSelector
                  value={client.client_groups.map((group) => group.group_id)}
                  onAdd={addGroupHandler(client)}
                  onRemove={removeGroupHandler(client)}
                  buttonText="Add group"
                  className="w-max-content"
                  style={{ maxWidth: 340 }}
                  container="body"
                />
              </td>
              <td {...props}>{client.user.email}</td>
              <td {...props}>{client.user.phone_number}</td>
              <td {...props}>{client.last_trip_date}</td>
              <td {...props}>{client.total_booking_amount}</td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
