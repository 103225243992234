import React from "react"
import { Offcanvas } from "react-bootstrap"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"
import SupportForm from "components/SupportForm"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { modalSelector } from "modules/modals/selectors"
import { hideModal, resetModal } from "modules/modals/reducer"

export default function SupportWindow() {
  const dispatch = useDispatch()
  const support = useSelector(modalSelector("support"))
  const headerHeight = useSelector((state) => state.window.headerHeight)

  const closeHandler = () => dispatch(hideModal("support"))
  const closedHandler = () => dispatch(resetModal("support"))

  return (
    <Offcanvas
      placement="end"
      className="border-0"
      backdropClassName="bg-transparent"
      style={{
        top: headerHeight,
        width: 850
      }}
      scroll
      show={support.isOpen}
      onHide={closeHandler}
      onExited={closedHandler}
    >
      <Offcanvas.Body className="vstack bg-light p-20 h-100 overflow-y-scroll">
        <DefaultErrorBoundary>
          <SupportForm onSubmit={closeHandler} />
        </DefaultErrorBoundary>
      </Offcanvas.Body>
    </Offcanvas>
  )
}
