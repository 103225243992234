import React from "react"
import PropTypes from "prop-types"
import { TabPane } from "reactstrap"
import Temperature from "../widgets/Temperature"
import Details from "../widgets/Details"
import Sun from "../widgets/Sun"
import Moon from "../widgets/Moon"
import Wind from "../widgets/Wind"
import Precipitation from "../widgets/Precipitation"
import Tide from "../widgets/Tide"

General.propTypes = {
  onNextDays: PropTypes.func.isRequired
}
export default function General({ onNextDays }) {
  return (
    <TabPane tabId="general">
      <div className="grid grid-cols-2 gap-20">
        <Temperature onNextDays={onNextDays} className="g-col-2" />
        <Sun onNextDays={onNextDays} className="g-col-1" />
        <Details className="g-col-1" />
        <Wind onNextDays={onNextDays} className="g-col-2" />
        <Precipitation onNextDays={onNextDays} className="g-col-1" maxHeight={420} />
        <Moon onNextDays={onNextDays} className="g-col-1" />
        <Tide onNextDays={onNextDays} className="g-col-2" />
      </div>
    </TabPane>
  )
}
