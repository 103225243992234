import React from "react"

export const Card = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M40.4817 14.5799H40.9817V14.0799V12.3199C40.9817 10.576 39.5857 9.17992 37.8417 9.17992H6.16172C4.41776 9.17992 3.02172 10.576 3.02172 12.3199V14.0799V14.5799H3.52172H40.4817ZM3.52172 17.9799H3.02172V18.4799V31.6799C3.02172 33.4239 4.41776 34.8199 6.16172 34.8199H37.8417C39.5857 34.8199 40.9817 33.4239 40.9817 31.6799V18.4799V17.9799H40.4817H3.52172ZM2.26172 12.3199C2.26172 10.1761 4.01786 8.41992 6.16172 8.41992H37.8417C39.9856 8.41992 41.7417 10.1761 41.7417 12.3199V31.6799C41.7417 33.8238 39.9856 35.5799 37.8417 35.5799H6.16172C4.01786 35.5799 2.26172 33.8238 2.26172 31.6799V12.3199ZM21.5017 22.3799H8.42172V21.6199H21.5017V22.3799Z"
      fill={fill}
      stroke={fill}
    />
  </svg>
)
