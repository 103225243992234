import React, { forwardRef, memo, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import { DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { DropdownItem, DropdownMenu } from "components/common/Dropdown"
import Input from "components/form/Input"
import Loading from "modules/loader-watchers/Loading"

import { get } from "lodash"

const suggestionValue = (item, valueKey) => (item instanceof Object ? get(item, valueKey, ">> set 'valueKey' <<") : item)

const AutofillInput = forwardRef(
  ({ name, onChange, addHandler, removeHandler, suggestions = {}, dropdownProps = {}, ...rest }, inputRef) => {
    const { list = [], selected = [], valueKey, withSearch = false, loading = false } = suggestions
    const value = rest.value || rest.defaultValue || ""

    const ref = useRef(null)
    const suggestionsFiltered = useMemo(
      () =>
        list.filter((item) => {
          const suggestion = suggestionValue(item, valueKey)
          const isSelected = selected.includes(suggestion) && suggestion !== value
          return (withSearch ? suggestion.toLowerCase().match(new RegExp(value.toLowerCase(), "g")) : true) && !isSelected
        }),
      [list, valueKey, selected, value, withSearch]
    )

    const openHandler = () => ref.current?.toggle()

    return (
      <UncontrolledDropdown direction="down" {...dropdownProps} ref={ref}>
        <Input name={name} onChange={onChange} {...rest} onClick={openHandler} ref={inputRef} />
        <DropdownToggle className="position-absolute top-0 start-0 h-100 w-100 p-0 border-0 opacity-0 pointer-events-none" />
        {(!!suggestionsFiltered.length || loading) && (
          <DropdownMenu container="body">
            <Loading loading={loading} spinnerProps={{ position: !suggestionsFiltered.length ? "static" : "absolute" }}>
              {suggestionsFiltered.map((autofillItem, index) => {
                const suggestion = suggestionValue(autofillItem, valueKey)
                const suggestionMatched = suggestion.match(new RegExp(value.toLowerCase(), "i"))?.[0] || ""
                const [first, second, third] = suggestion.replace(suggestionMatched, `%%${suggestionMatched}%%`).split("%%")
                return (
                  <DropdownItem
                    key={index}
                    name={name}
                    value={suggestion}
                    onClick={onChange}
                    className="text-wrap"
                    active={value === suggestion}
                  >
                    {first}
                    {second && (
                      <span
                        className={`bg-primary-second pointer-events-none ${
                          value === suggestion ? "text-white bg-opacity-0" : "text-dark bg-opacity-25"
                        }`}
                      >
                        {second}
                      </span>
                    )}
                    {third}
                  </DropdownItem>
                )
              })}
            </Loading>
          </DropdownMenu>
        )}
      </UncontrolledDropdown>
    )
  }
)

AutofillInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  addHandler: PropTypes.func,
  removeHandler: PropTypes.func,
  suggestions: PropTypes.shape({
    list: PropTypes.array,
    selected: PropTypes.array,
    valueKey: PropTypes.string,
    withSearch: PropTypes.bool,
    loading: PropTypes.bool
  }),
  dropdownProps: PropTypes.object
}

export default memo(AutofillInput)
