import { Input } from "antd"
import "./CustomInput.css"
import classNames from "classnames";

export const CustomInput = ({ ...props }) => {
    const { borderless, className } = props;
    return (
        <Input
            {...props}
            className={classNames('custom-input', borderless && 'custom-borderless-input', className)}
        />
    );
};
