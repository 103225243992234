import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { find, each, remove, pick } from "lodash"
import { formatPaginationParams } from "helpers/pagination"
import { toSearchParams } from "helpers/form"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"
import { PAGINATION_INITIAL_STATE } from "./constants"

// ACTIONS
const requested = createAction("clients/requested")
const received = createAction("clients/received")
const failed = createAction("clients/failed")
const cleaned = createAction("clients/cleaned")

const requestedClient = createAction("clients/requestedClient")
const receivedClient = createAction("clients/receivedClient")
const failedClient = createAction("clients/failedClient")

const createdClient = createAction("clients/createdClient")
const updatedClient = createAction("clients/updatedClient")
const deletedClient = createAction("clients/deletedClient")
const cleanedClient = createAction("clients/cleanedClient")

const editClientNote = createAction("clients/editClientNote")
const uploadFile = createAction("clients/uploadFile")
const recivedEmail = createAction("clients/recivedEmail")
const recivedSms = createAction("clients/recivedSms")
const recivedMessage = createAction("clients/recivedMessage")

const createdGroup = createAction("clients/createdGroup")
const updatedGroup = createAction("clients/updatedGroup")
const deletedGroup = createAction("clients/deletedGroup")

const addedGroupClients = createAction("clients/addedGroupClients")
const removedGroupClients = createAction("clients/removedGroupClients")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  items: [],
  metadata: {
    ...PAGINATION_INITIAL_STATE,
    groups_collection: [],
    email_templates_collection: [],
    text_template_collection: []
  },
  client: {
    ...LOADING_INITIAL_STATE,
    notes: [],
    user: {
      id: null,
      nickname: "",
      avatar: "",
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      birthday: "",
      address_line_1: "",
      address_line_2: "",
      city: "",
      state: "",
      country: "",
      zip_code: ""
    },
    client_groups: [],
    groups: [],
    group: []
  }
}

const clientsReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, { payload, props }) => {
    setReceived(state)
    state.items = props.reset ? payload.data : [...state.items, ...payload.data]
    Object.assign(state.metadata, payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedClient.type]: (state) => {
    setRequested(state.client)
  },
  [receivedClient.type]: (state, action) => {
    setReceived(state.client)
    state.client = action.payload
  },
  [createdClient.type]: (state, action) => {
    setReceived(state.client)
    state.items = [action.payload, ...state.items]
    Object.assign(state.client, action.payload)
  },
  [updatedClient.type]: (state, action) => {
    setReceived(state.client)
    Object.assign(find(state.items, ["id", action.payload.id]) || {}, action.payload)
    Object.assign(state.client, action.payload)
  },
  [deletedClient.type]: (state, action) => {
    setReceived(state.client)
    remove(state.items, ["id", action.payload.id])
  },
  [failedClient.type]: (state) => {
    setFailed(state.client)
  },
  [recivedEmail.type]: (state) => {
    setReceived(state.client)
  },
  [recivedSms.type]: (state) => {
    setReceived(state.client)
  },
  [recivedMessage.type]: (state) => {
    setReceived(state.client)
  },
  [cleanedClient.type]: (state) => {
    state.client = initialState.client
  },
  [editClientNote.type]: (state, { payload: note }) => {
    state.client.notes = state.client.notes.map((currentNote) => {
      if (currentNote.id === note.id) {
        return { ...currentNote, isEdit: true }
      }
      return currentNote
    })
  },
  [createdGroup.type]: (state, { payload }) => {
    setReceived(state.client)
    state.metadata.groups_collection.push(pick(payload, ["id", "name", "color"]))
  },
  [updatedGroup.type]: (state, { payload }) => {
    setReceived(state.client)
    Object.assign(find(state.metadata.groups_collection, ["id", payload.id]) || {}, payload)
    each(
      state.items,
      (client) =>
        client.client_groups.length &&
        Object.assign(find(client.client_groups, ["group_id", payload.id]) || {}, pick(payload, ["name", "color"]))
    )
  },
  [deletedGroup.type]: (state, { payload }) => {
    setReceived(state.client)
    remove(state.metadata.groups_collection, ["id", payload.id])
    each(state.items, (client) => client.client_groups.length && remove(client.client_groups, ["group_id", payload.id]))
  },
  [addedGroupClients.type]: (state, { payload }) => {
    setReceived(state)
    each(payload.data, ({ id, client_groups }) => Object.assign(find(state.items, ["id", id]) || {}, { client_groups }))
  },
  [removedGroupClients.type]: (state, { payload }) => {
    setReceived(state)
    each(payload.data, ({ id, client_groups }) => Object.assign(find(state.items, ["id", id]) || {}, { client_groups }))
  }
})
export default clientsReducer

const getItems = (url, nextPage, params, dispatch, getState) => {
  const state = getState()
  const paginationParams = toSearchParams(formatPaginationParams(state.clients.metadata, nextPage))
  const serializedParams = toSearchParams(params, null, paginationParams)

  return dispatch(
    api({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: failed.type,
      params: serializedParams,
      props: { reset: !nextPage }
    })
  )
}

const getItem = (url, id, dispatch, getState) => {
  const state = getState()
  const client = find(state.clients.items, ["id", +id])
  if (client) {
    dispatch(receivedClient(client))
    return new Promise((resolve) => resolve(client))
  }
  return dispatch(
    api({
      url,
      onStart: requestedClient.type,
      onSuccess: receivedClient.type,
      onError: failedClient.type
    })
  )
}

export const getGuideClients = (nextPage, params) => (dispatch, getState) =>
  getItems("/guide/clients", nextPage, params, dispatch, getState)

export const getGuideClient = (id) => (dispatch, getState) => getItem(`/guide/clients/${id}`, id, dispatch, getState)

export const getClientGuideClients = (id, nextPage, params) => (dispatch, getState) =>
  getItems(`/client/guides/${id}/clients`, nextPage, params, dispatch, getState)

export const createClient = (data) =>
  api({
    url: "/guide/clients",
    method: "post",
    onStart: requestedClient.type,
    onSuccess: createdClient.type,
    onError: failedClient.type,
    data
  })

export const updateClient = (id, data) =>
  api({
    url: `/guide/clients/${id}`,
    method: "put",
    data: data,
    onStart: requestedClient.type,
    onSuccess: updatedClient.type,
    onError: failedClient.type
  })

export const saveClient = (id, data) => (id ? updateClient(id, data) : createClient(data))

export const deleteClient = (id) =>
  api({
    url: `/guide/clients/${id}`,
    method: "delete",
    onStart: requestedClient.type,
    onSuccess: deletedClient.type,
    onError: failedClient.type
  })

export const uploadCSVFile = (data) =>
  api({
    url: "/guide/clients/import",
    method: "post",
    onSuccess: uploadFile.type,
    data
  })

export const sendEmail = (data) =>
  api({
    url: "/guide/clients/send_email",
    method: "post",
    onStart: requestedClient.type,
    onSuccess: recivedEmail.type,
    onError: failedClient.type,
    data
  })
export const sendSms = (data) =>
  api({
    url: "/guide/clients/send_sms",
    method: "post",
    onStart: requestedClient.type,
    onSuccess: recivedSms.type,
    onError: failedClient.type,
    data
  })

export const sendMessage = (data) =>
  api({
    url: "/guide/clients/send_message",
    method: "post",
    onStart: requestedClient.type,
    onSuccess: recivedMessage.type,
    onError: failedClient.type,
    data
  })

export const createGroup = (data) =>
  api({
    url: "/guide/groups",
    method: "post",
    onStart: requestedClient.type,
    onSuccess: createdGroup.type,
    onError: failedClient.type,
    data
  })

export const updateGroup = (id, data) =>
  api({
    url: `/guide/groups/${id}`,
    method: "put",
    data: data,
    onStart: requestedClient.type,
    onSuccess: updatedGroup.type,
    onError: failedClient.type
  })

export const removeGroup = (id) =>
  api({
    url: `/guide/groups/${id}`,
    method: "delete",
    onStart: requestedClient.type,
    onSuccess: deletedGroup.type,
    onError: failedClient.type
  })

export const addGroupClients = (id, data) =>
  api({
    url: `/guide/groups/${id}/add_clients`,
    method: "put",
    data: data,
    onStart: requested.type,
    onSuccess: addedGroupClients.type,
    onError: failed.type
  })

export const removeGroupClients = (id, data) =>
  api({
    url: `/guide/groups/${id}/remove_clients`,
    method: "put",
    data: data,
    onStart: requested.type,
    onSuccess: removedGroupClients.type,
    onError: failed.type
  })

export const cleanClients = () => (dispatch) => dispatch(cleaned())
export const cleanClient = () => (dispatch) => dispatch(cleanedClient())

export const editClientNoteTrigger = (note) => (dispatch) => dispatch(editClientNote(note))
