import React, { memo, useCallback, useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { Button } from "reactstrap"
import Avatar from "components/common/Avatar"
import Message from "components/messenger/Message"
import MessageBox from "components/old/Messenger/MessageBox"
import FirstLoading from "modules/loader-watchers/FirstLoading"
import Disable from "modules/loader-watchers/Disable"

import { Waypoint } from "react-waypoint"
import { useTranslation } from "react-i18next"
import { isLastPage } from "helpers/pagination"
import { createScorllSaver } from "helpers/scroll"

// Redux
import { useDispatch, useSelector } from "react-redux"
import {
  activeConversationSelector,
  getConversation,
  messagesAnySelector,
  messagesMetadataSelector,
  messagesSelector
} from "store/conversations"
import { showModal } from "modules/modals/reducer"
import { modalSelector } from "modules/modals/selectors"
import { collectionAnySelector } from "store/selectors"

const scrollSaver = createScorllSaver()

function ConversationDetails() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const conversationBox = useRef(null)
  const [saveScroll, scrollToSaved, scrollTo] = scrollSaver(conversationBox.current)

  const selectedConversationId = +searchParams.get("conversation_id") || null
  const conversationsModal = useSelector(modalSelector("conversations"))
  const hasConversations = useSelector(collectionAnySelector("conversations"))
  const messages = useSelector(messagesSelector)
  const metadata = useSelector(messagesMetadataSelector)
  const hasMessages = useSelector(messagesAnySelector)
  const activeConversation = useSelector(activeConversationSelector)
  const activeConversationId = activeConversation?.id || null

  const role = useSelector((state) => state.user.role)
  const isGuide = role === "guide_user"
  const user = isGuide ? activeConversation.contact : activeConversation.client
  const participant = isGuide ? activeConversation.client : activeConversation.contact

  const openConversationsHandler = () => dispatch(showModal("conversations"))

  const fetchMessages = useCallback(
    (nextPage = false) =>
      selectedConversationId &&
      (saveScroll() ||
        dispatch(getConversation(selectedConversationId, nextPage))
          .then(() => scrollToSaved("bottom"))
          .catch(() => setSearchParams({}))),
    [selectedConversationId] // eslint-disable-line
  )

  useEffect(() => {
    fetchMessages()
  }, [fetchMessages]) //eslint-disable-line

  return (
    <div className="vstack min-h-100 mh-100 border-start-lg border-gray-lightest">
      <div className="hstack gap-10 p-20 border-bottom border-gray-lightest">
        <div className="hstack gap-10 d-lg-none">
          <Button
            className="menu-btn my-n1 px-1 py-2 rounded-1 text-dark btn btn-light"
            aria-expanded={conversationsModal.isOpen ? "true" : "false"}
            onClick={openConversationsHandler}
          >
            <div className="menu-btn__burger full-w">
              <span>Conversations</span>
            </div>
          </Button>
          {activeConversationId && (
            <div className="hstack gap-0 align-items-center flex-shrink-0 my-n10">
              <Avatar user={user} size={30} className="border-white" />
              <Avatar user={participant} size={30} showStatus className="ms-n10 border-white" />
            </div>
          )}
        </div>
        <h4 className="fw-semibold lh-1 text-dark text-start flex-fill">
          {activeConversationId ? `You and ${participant?.full_name}` : "Conversation"}
        </h4>
      </div>
      <div className="flex-fill overflow-y-scroll position-relative" ref={conversationBox} id="message-list">
        <FirstLoading name="conversations.conversation" className="h-100">
          {hasConversations ? (
            !activeConversationId && <div className="m-20 px-15 py-10 rounded bg-light">{t("messenger.empty_conversation")}</div>
          ) : (
            <div className="m-20 px-15 py-10 rounded bg-light">{t("messenger.empty_conversations")}</div>
          )}
          {hasMessages ? (
            <>
              {!isLastPage(metadata) && <Waypoint onEnter={() => fetchMessages(true)} topOffset={-50} />}
              <div className="vstack">
                {messages.map((message, index) => (
                  <Message message={message} key={index} />
                ))}
              </div>
            </>
          ) : (
            <div className="m-20 px-15 py-10 rounded bg-light">{t("messenger.empty")}</div>
          )}
        </FirstLoading>
      </div>
      <Disable name="conversations.conversation" additionalCondition={!hasConversations || !activeConversationId}>
        <MessageBox conversation={activeConversation} messageSent={() => scrollTo(0, "bottom", "smooth")} />
      </Disable>
    </div>
  )
}

export default memo(ConversationDetails)
