export const ROUTES_LIST = {
  // temporary
  rootPath: "/",
  antd: "/antd",
  signInV2: "/auth/sign-in",
  signUpV2: "/auth/sign-up",
  newPassword: "/auth/new-password",
  signUpOptions: "/auth/sign-up/options",
  confirmEmail: "/auth/sign-up/confirm-email",
  confirmPhone: "/auth/sign-up/confirm-phone",
  confirmInfo: "/auth/sign-up/confirm-info",
  passwordRecovery: "/auth/password-recovery",
  passwordRecoveryEnterCode: "/auth/password-recovery/enter-code",
  passwordRecoveryCreatePassword: "/auth/password-recovery/create-password",
  passwordRecoverySuccess: "/auth/password-recovery/success",
  homePath: "/home",
  proPersonalInfoRootPath: "/pro/personal-info",
  proPersonalInfoProfileInfo: "/pro/personal-info/profile-information",
  proPersonalInfoAddressesPath: "/pro/personal-info/addresses",
  proPersonalInfAdventureMode: "/pro/personal-info/adventure-mode",
  proRootPath: "/pro",
  proHomeRootPath: "/pro/home",
  proSettingsRootPath: "/pro/home/settings",
  proSettingsStripeAccount: "/pro/home/settings/stripe-account",
  proSettingsProfileInformation: "/pro/home/settings/profile-information",
  proMessagesRootPath: "/pro/home/messages"
  // Common routes
}

export const dynamicRoute = (path) => {
  const requiredParams = path.match(/:\w+/g) || []
  const route = (params = {}, search = {}) => {
    const searchString = new URLSearchParams(search).toString()
    if (!Object.keys(params).length) return searchString ? `${path}?${searchString}` : path

    const errors = []
    const dynamicPath = requiredParams.reduce((link, param) => {
      const receivedParam = params[param.replace(":", "")]
      if (receivedParam !== undefined) return link.replace(param, receivedParam)
      else errors.push(`"${param}" param is required`)
      return link
    }, path)

    if (errors.length) {
      console.error(`For path "${path}" ${errors.join(", ")}`)
    }

    return searchString ? `${dynamicPath}?${searchString}` : dynamicPath
  }
  return route
}

export const routes = Object.keys(ROUTES_LIST).reduce(
  (acc, routeAlias) => ({ ...acc, [routeAlias]: dynamicRoute(ROUTES_LIST[routeAlias]) }),
  {}
)
