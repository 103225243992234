import React from "react"
import { Form, Button } from "antd"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { SocialButtons } from "../SocialButtons"
import { Title, Text } from "ui/Typography"
import { routes } from "router"

export const SignUpOptions = () => {
  const { t } = useTranslation()

  return (
    <>
      <Title className="justify-center mb-3xl">{t("sign_up.register")}</Title>
      <SocialButtons />
      <Form.Item>
        <Link to="/auth/sign-up">
          <Button color="primary" variant="outlined" block size="large">
            {t("sign_up.with_email")}
          </Button>
        </Link>
      </Form.Item>
      <div className="justify-center">
        <Text type="secondary" variant="body">
          {t("sign_up.already_have_account")}
        </Text>
        <Text className="ml-xs" link>
          <Link to={routes.signInV2()}>{t("global.sign_in")}</Link>
        </Text>
      </div>
    </>
  )
}
