import React, { useEffect, useState } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { toast } from "react-toastify"
import PhoneInput from "react-phone-input-2"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { verifyPhone } from "store/user"

import "react-phone-input-2/lib/bootstrap.css"

export default function VerifyPhone() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const verified = useSelector((store) => store.user.verified)
  const codeSent = useSelector((store) => store.user.codeSent)
  const [phone, setPhone] = useState(null)
  const { t } = useTranslation()

  useEffect(() => {
    if (codeSent) navigate("/verify-code", { replace: true })
  }, [codeSent, navigate])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (!phone || phone.length < 5) return toast.error(t("verification.phone_error"))

    const data = { phone_number: `+${phone}` }
    dispatch(verifyPhone(data))
  }

  if (verified) return <Navigate to="/" />

  return (
    <>
      <div className="text-dark text-center">
        <h1 className="fs-1 fw-bold lh-sm">{t("verification.phone_title")}</h1>
        <p className="lh-sm mt-30">{t("verification.phone_details")}</p>
      </div>
      <h2 className="fs-2 lh-1 fw-bold text-white mt-10">Verify</h2>
      <form className="mt-40" onSubmit={handleSubmit}>
        <PhoneInput
          country={"us"}
          value={phone}
          onChange={(value) => setPhone(value)}
          inputClass="invert"
          buttonClass="invert"
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true
          }}
        />
        <Button color="primary" type="submit" className="mt-30 w-100">
          {t("verification.phone_button")}
        </Button>
      </form>
      <div className="mt-15 fs-5 text-end">
        <Link className="small fw-bold text-white text-primary-hover text-decoration-none" to="/verify-code">
          {t("verification.phone_link")}
        </Link>
      </div>
    </>
  )
}
