import React from "react"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"
import useForm from "hooks/useForm"

import { useSelector, useDispatch } from "react-redux"
import { updateUser } from "store/user"

export default function Notifications() {
  useTitle("Settings — Notifications")
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { email_notifications = {}, text_notifications = {}, app_notifications = {} } = useSelector((store) => store.user)
  const prevNotificationSettings = { email_notifications, text_notifications, app_notifications }
  const [notification_settings, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm(prevNotificationSettings)

  submitCallback(() => {
    if (isChanged) dispatch(updateUser({ notification_settings }))
  })

  return (
    <Row tag="form" onSubmit={submitHandler}>
      <Col xs={12} md={4}>
        <p className="fs-5 fw-semibold">{t("settings.notifications.email")}</p>
        <div className="vstack gap-5 mt-30" style={{ maxWidth: 250 }}>
          {Object.keys(email_notifications).map((key) => {
            const group = "email_notifications"
            return (
              <FormGroup switch key={key} className="hstack justify-content-between m-0 p-0">
                <Label check for={`${group}.${key}`}>
                  <div className="fs-5 fw-normal">{t(`settings.notifications.${key}`)}</div>
                </Label>
                <Input
                  id={`${group}.${key}`}
                  name={`${group}.${key}`}
                  type="checkbox"
                  checked={notification_settings[group][key]}
                  onChange={changeHandler}
                  className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary"
                />
              </FormGroup>
            )
          })}
        </div>
      </Col>
      <Col xs={12} md={4}>
        <p className="fs-5 fw-semibold">{t("settings.notifications.text")}</p>
        <div className="vstack gap-5 mt-30" style={{ maxWidth: 250 }}>
          {Object.keys(text_notifications).map((key) => {
            const group = "text_notifications"
            return (
              <FormGroup switch key={key} className="hstack justify-content-between m-0 p-0">
                <Label check for={`${group}.${key}`}>
                  <div className="fs-5 fw-normal">{t(`settings.notifications.${key}`)}</div>
                </Label>
                <Input
                  id={`${group}.${key}`}
                  name={`${group}.${key}`}
                  type="checkbox"
                  checked={notification_settings[group][key]}
                  onChange={changeHandler}
                  className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary"
                />
              </FormGroup>
            )
          })}
        </div>
      </Col>
      <Col xs={12} md={4}>
        <p className="fs-5 fw-semibold">{t("settings.notifications.in_app")}</p>
        <div className="vstack gap-5 mt-30" style={{ maxWidth: 250 }}>
          {Object.keys(app_notifications).map((key) => {
            const group = "app_notifications"
            return (
              <FormGroup switch key={key} className="hstack justify-content-between m-0 p-0">
                <Label check for={`${group}.${key}`}>
                  <div className="fs-5 fw-normal">{t(`settings.notifications.${key}`)}</div>
                </Label>
                <Input
                  id={`${group}.${key}`}
                  name={`${group}.${key}`}
                  type="checkbox"
                  checked={notification_settings[group][key]}
                  onChange={changeHandler}
                  className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary"
                />
              </FormGroup>
            )
          })}
        </div>
      </Col>

      <div className="hstack gap-20 justify-content-end mt-25">
        {isChanged && (
          <Button color="light" type="button" onClick={reset}>
            {t("cancel")}
          </Button>
        )}
        <Button color="primary" disabled={!isChanged}>
          {t("settings.update")}
        </Button>
      </div>
    </Row>
  )
}
