import React from "react"
import ActionButtons from "components/wizards/ActionButtons"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

import { updateGuide } from "store/user"

export default function Step4() {
  useTitle("Guide Wizard — Step 4")
  const { t } = useTranslation()
  const title = t("guide.wizard.step_4.title")
  const subtitle = t("guide.wizard.step_4.subtitle")

  return (
    <div className="text-center">
      {title && <h1 className="h2 lh-1 mb-0">{title}</h1>}
      {subtitle && (
        <p
          className="fs-5 mt-15 mb-40 text-dark text-opacity-50 mx-auto"
          style={{ maxWidth: 540 }}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      <div className="text-center">
        <ActionButtons action={updateGuide} name="user.guide" submitText={t("guide.wizard.submit")} withBack={false} />
      </div>
    </div>
  )
}
