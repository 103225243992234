import React from "react"
import { Col, Row } from "reactstrap"
import Step4Form from "components/trips/wizard/Step4Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

export default function Step4() {
  useTitle("Trip Wizard — Step 4")
  const { t } = useTranslation()
  const title = t("trip.wizard.step_4.title")

  return (
    <Row>
      <Col xs={12} xl={{ size: 10, offset: 1 }}>
        <div className="mt-1 mb-35">{title && <h1 className="h3 lh-1 mb-0">{title}</h1>}</div>
        <DefaultErrorBoundary>
          <Step4Form />
        </DefaultErrorBoundary>
      </Col>
    </Row>
  )
}
