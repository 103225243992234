import React from "react"
import PropTypes from "prop-types"
import { Button, DropdownToggle, FormGroup, Label } from "reactstrap"
import FilterWrapper from "components/common/FilterWrapper"
import Input from "components/form/Input"
import NavigationLink from "components/layout/NavigationLink"

import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"

import { TRIP_FILTERS, TRIP_FILTERS_HELP_ATTRIBUTES } from "constants/trip"

const TempNavigationLink = ({ ...props }) => <NavigationLink tag={Button} {...props} />

Price.propTypes = {
  onToggleClick: PropTypes.func.isRequired
}
export default function Price({ onToggleClick }) {
  const { t } = useTranslation()
  const { price_ranges = {}, max_price_per_person } = useSelector((store) => store.trips.metadata)

  const priceRanges = Object.keys(price_ranges).sort((a, b) => a.localeCompare(b))
  const priceRangesValues = priceRanges.map((key) => price_ranges[key])
  const priceRangesMaxValue = Math.max(...priceRangesValues)

  const min = 0
  const step = 20

  const fillPercent = (value, min, max) => `${((value - min) / (max - min)) * 100}%`
  const fillIndexMin = (value, min, max, length) => Math.floor(((value - min) / (max - min)) * length)
  const fillIndexMax = (value, min, max, length) => Math.ceil(((value - min) / (max - min)) * length - 1)
  const minMaxChangeHandler = (handler, min, max) => (event) => {
    const e = { ...event }
    const { name, value } = e.target
    const limitedValue = Math.max(min, Math.min(value, max))
    const newValue = limitedValue === min || limitedValue === max ? null : limitedValue

    handler({ target: { name, value: newValue } })
  }

  return (
    <FilterWrapper
      title={t("trips.filters.price")}
      fields={["price_per_person_gteq", "price_per_person_lteq"]}
      accessAttributes={TRIP_FILTERS}
      exceptDependencies={TRIP_FILTERS_HELP_ATTRIBUTES}
      toggleButton={(isOpen, isActive, filters) => (
        <DropdownToggle
          tag={TempNavigationLink}
          iconName="Coin"
          label={t("trips.filters.price")}
          onClick={onToggleClick}
          isActive={isOpen || isActive}
          badge={
            filters.price_per_person_gteq || filters.price_per_person_lteq
              ? `${filters.price_per_person_gteq || "0"} — ${filters.price_per_person_lteq || "∞"}`
              : ""
          }
        />
      )}
    >
      {(form, changeHandler, filters) => {
        const max = Math.ceil(Math.max(filters.price_per_person_lteq || 0, max_price_per_person || 0) / step) * step
        const price_per_person_gteq = form.price_per_person_gteq || min
        const price_per_person_lteq = form.price_per_person_lteq || max
        const styleRange = {
          "--from": fillPercent(price_per_person_gteq, min, max),
          "--to": fillPercent(price_per_person_lteq, min, max)
        }
        const ranges = new Array(max / step || 0).fill(0)
        const startFilIndex = fillIndexMin(price_per_person_gteq, min, max, ranges.length)
        const endFilIndex = fillIndexMax(price_per_person_lteq, min, max, ranges.length)

        return (
          <>
            <div className="hstack gap-1 align-items-end w-100 mb-n2 px-2" style={{ height: 175 }}>
              {ranges.map((count, index) => (
                <div
                  key={index}
                  className={[
                    "rounded-1 flex-fill",
                    startFilIndex <= index && endFilIndex >= index ? "bg-primary-second" : "bg-gray-lightest"
                  ].join(" ")}
                  style={{ height: `${(priceRangesValues[index] / priceRangesMaxValue) * 100}%` }}
                />
              ))}
            </div>
            <div className="two-point-range-wrapper w-100">
              <Input
                id="price"
                type="range"
                name="price_per_person_gteq"
                value={price_per_person_gteq}
                onChange={minMaxChangeHandler(changeHandler, min, price_per_person_lteq)}
                className="fs-4 fw-medium two-point-range"
                style={styleRange}
                min={min}
                max={max}
                step={step}
              />
              <Input
                id="price"
                type="range"
                name="price_per_person_lteq"
                value={price_per_person_lteq}
                onChange={minMaxChangeHandler(changeHandler, price_per_person_gteq, max)}
                className="fs-4 fw-medium two-point-range"
                min={min}
                max={max}
                step={step}
              />
            </div>
            <div className="hstack gap-10 justify-content-start w-100">
              <FormGroup noMargin floating className="flex-fill">
                <Input
                  id="min_price"
                  type="number"
                  name="price_per_person_gteq"
                  placeholder={t("global.min")}
                  value={price_per_person_gteq}
                  onChange={minMaxChangeHandler(changeHandler, min, price_per_person_lteq)}
                  className="fs-4 fw-medium"
                  withError
                  min={min}
                  max={price_per_person_lteq}
                  step={step}
                  hint="Min: 0"
                />
                <Label for="min_price" className="fs-5 fw-normal text-uppercase">
                  {t("global.min")} $
                </Label>
              </FormGroup>
              <FormGroup noMargin floating className="flex-fill">
                <Input
                  id="max_price"
                  type="number"
                  name="price_per_person_lteq"
                  placeholder={t("global.max")}
                  value={price_per_person_lteq}
                  onChange={minMaxChangeHandler(changeHandler, price_per_person_gteq, max)}
                  className="fs-4 fw-medium"
                  withError
                  min={price_per_person_gteq}
                  max={max}
                  step={step}
                  hint={`Max: ${max}`}
                />
                <Label for="max_price" className="fs-5 fw-normal text-uppercase">
                  {t("global.max")} $
                </Label>
              </FormGroup>
            </div>
          </>
        )
      }}
    </FilterWrapper>
  )
}
