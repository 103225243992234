import React, { memo } from "react"
import PropTypes from "prop-types"
import { TabPane } from "reactstrap"
import DateTabs from "components/weather/DateTabs"
import Chart from "components/weather/Chart"

import moment from "moment"
import { convertToMetric, numberFormatToMetric } from "helpers/string"
import { weatherImage } from "helpers/weather"

import { useSelector } from "react-redux"
import {
  dateForecastObjSelector,
  dateHourlyForecastSelector,
  dateHourlyFormattedForecastSelector,
  forecastDateSelector
} from "store/weather"

Temperature.propTypes = {
  className: PropTypes.string,
  params: PropTypes.shape({
    units: PropTypes.string.isRequired // assuming units is required
  }).isRequired,
  onChangeFilters: PropTypes.func.isRequired
}

function Temperature({ className, params, onChangeFilters }) {
  const isConvertToMetric = params.units === "metric"

  const forecastDate = useSelector(forecastDateSelector)
  const hourlyForecast = useSelector(dateHourlyForecastSelector())
  const currentForecastDaily = useSelector(dateForecastObjSelector("daily"))
  const data = useSelector(dateHourlyFormattedForecastSelector("temperature", "fahrenheit", 0, isConvertToMetric))

  if (!hourlyForecast) return null
  const { calendarDayTemperatureMin, calendarDayTemperatureMax, daypart } = currentForecastDaily

  const today = moment()
  const dateMoment = moment(forecastDate || today)
  const isToday = today.isSame(dateMoment, "day")
  const isTomorrow = today.clone().add(1, "day").isSame(dateMoment, "day")
  const dateFormatted = dateMoment.format(`${(isToday && "[Today], ") || (isTomorrow && "[Tomorrow], ") || ""}MMM DD, YYYY`)

  const iconCode = daypart.iconCode.at(0) || daypart.iconCode.at(1)

  const classes = ["bg-white rounded px-40 py-30 position-relative overflow-hidden"]
  if (className) classes.push(className)

  return (
    <TabPane tabId="temperature" className={classes.join(" ")}>
      <div className="vstack gap-30">
        <DateTabs params={params} onChangeFilters={onChangeFilters} />
        <div className="mt-10">
          <p className="text-dark text-opacity-50 mb-1">{dateFormatted}</p>
          <p className="fs-3 m-0">
            {convertToMetric(calendarDayTemperatureMax, "fahrenheit", true, 0, isConvertToMetric)}
            <span className="text-dark text-opacity-50">
              {" | "}
              {convertToMetric(calendarDayTemperatureMin, "fahrenheit", true, 0, isConvertToMetric)}
            </span>
            <img src={weatherImage(iconCode)} alt="" height="40" className="mx-n2" />
          </p>
        </div>
        <Chart
          hourlyData={data}
          color="122, 174, 251"
          fill
          yAxisKey="value"
          yFormatOptions={(value) => numberFormatToMetric(value, "fahrenheit", 0, isConvertToMetric)}
        />
      </div>
    </TabPane>
  )
}

export default memo(Temperature)
