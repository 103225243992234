import React from "react"
import Conversations from "components/messenger/Conversations"
import ConversationDetails from "components/messenger/ConversationDetails"

import { useTitle } from "hooks/useTitle"

import { useSelector } from "react-redux"
import FirstLoading from "modules/loader-watchers/FirstLoading"

function MessagesPage() {
  useTitle("Messages")
  const headerHeight = useSelector((state) => state.window.headerHeight)

  return (
    <div className="vstack z-0" id="messages" style={{ height: `calc(100vh - ${headerHeight}px - 60px)` }}>
      <div className="hstack flex-fill mh-100 bg-white border border-gray-lightest rounded overflow-hidden">
        <Conversations />
        <FirstLoading name="conversations" className="h-100 w-100 border-start-lg border-gray-lightest">
          <ConversationDetails />
        </FirstLoading>
      </div>
    </div>
  )
}

export default MessagesPage
