import React from "react"
import { useNavigate } from "react-router-dom"
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap"

import { useTranslation } from "react-i18next"
import { isEmptyFields } from "helpers/form"
import useForm from "hooks/useForm"
import Logo from "components/common/Logo"

import { useDispatch, useSelector } from "react-redux"
import { sendEnquiry } from "store/contact-us"

export default function ContactUs() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, changeHandler, submitHandler, submitCallback] = useForm({
    contact: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    comments: ""
  })
  const isSportsman = useSelector((state) => state.user.is_sportsman)

  submitCallback(() => {
    if (!isEmptyFields(data)) {
      dispatch(sendEnquiry(data))
      navigate("/", { replace: true })
    }
  })

  return (
    <div className="mx-n15 mx-md-0 p-30 rounded-md bg-white">
      <div className="text-center text-sm-start">
        <h1 className="fs-2 fw-bold text-dark">{t("contact_us.title")}</h1>
        <p className="fs-5 text-dark">{t("contact_us.subtitle")}</p>
      </div>
      <Row tag="form" className="mt-25 w-md-100" onSubmit={submitHandler}>
        <Col xs={12} sm={6} className="order-1 order-md-0">
          <FormGroup noMargin>
            <Label for="contact">{t("contact_us.contact")}</Label>
            <Input autoFocus id="contact" type="text" name="contact" value={data.contact} onChange={changeHandler} required />
          </FormGroup>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup noMargin>
                <Label for="first_name">{t("settings.first_name")}</Label>
                <Input id="first_name" type="text" name="first_name" value={data.first_name} onChange={changeHandler} required />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup noMargin>
                <Label for="last_name">{t("settings.last_name")}</Label>
                <Input id="last_name" type="text" name="last_name" value={data.last_name} onChange={changeHandler} required />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <FormGroup noMargin>
                <Label for="email">{t("settings.email")}</Label>
                <Input id="email" type="email" name="email" value={data.email} onChange={changeHandler} required />
              </FormGroup>
            </Col>
            <Col xs={12} md={6}>
              <FormGroup noMargin>
                <Label for="phone">{t("settings.phone")}</Label>
                <Input id="phone" type="tel" name="phone" value={data.phone} onChange={changeHandler} required />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup noMargin>
            <Label for="comments">{t("contact_us.questions_or_comments")}</Label>
            <Input rows={5} id="comments" type="textarea" name="comments" value={data.comments} onChange={changeHandler} required />
          </FormGroup>
        </Col>
        <Col xs={12} sm={6} className="order-sm-1">
          <div className="vstack align-items-center justify-content-center h-100">
            {isSportsman ? (
              <Logo alt="Logo" style={{ filter: "grayscale(1)" }} />
            ) : (
              <Logo alt="Logo" style={{ filter: "grayscale(1)" }} is_sportswoman />
            )}

            <h2 className="fs-2 fw-bold text-dark mt-15">{t("contact_us.business_hours")}</h2>
            <p className="text-gray-medium mb-0">{t("contact_us.days")}</p>
            <p className="text-gray-medium mb-0">{t("contact_us.hours")}</p>
          </div>
        </Col>
        <Col xs={12} sm={6} className="order-2">
          <div className="mt-20 text-center text-sm-end">
            <Button color="primary">{t("submit")}</Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
