import React from "react"
import { useSearchParams } from "react-router-dom"
import { Col, Row } from "reactstrap"
import Widget from "components/accounting/Widget"
import DateDetails from "components/accounting/DateDetails"
import StickyBox from "react-sticky-box"
import WithEmptyState from "components/common/WithEmptyState"

import moment from "moment"
import { groupBy } from "lodash"

import { useSelector } from "react-redux"
import { collectionAnySelector, collectionSelector } from "store/selectors"

import { STATS_NAMES } from "constants/accounting"
import { DATE_FORMAT } from "modules/datepicker/constants"

export default function Details() {
  const [searchParams] = useSearchParams()
  const headerHeight = useSelector((state) => state.window.headerHeight)
  const items = useSelector(collectionSelector("accounting"))
  const hasItems = useSelector(collectionAnySelector("accounting"))

  const type = searchParams.get("type")
  const stats_name = STATS_NAMES[type]

  const groupedItems = groupBy(items, ({ date }) => moment(date).format(DATE_FORMAT))
  const groups = Object.keys(groupedItems).sort((a, b) => (a > b ? -1 : 1))

  return (
    <Row className="justify-content-start gap-y-30 position-relative">
      <Col md={5} lg={4} xl={3}>
        <StickyBox offsetTop={headerHeight + 70 + 30} offsetBottom={30}>
          <div className="vstack gap-15">
            <Widget stats_name={stats_name} isCurrency />
            <Widget stats_name="booking" />
          </div>
        </StickyBox>
      </Col>
      <Col md={7} lg={8} xl={7}>
        <WithEmptyState hasItems={hasItems} name="Operation" tag="div" className="vstack gap-20">
          {groups.map((groupedDate) => (
            <DateDetails key={groupedDate} date={groupedDate} items={groupedItems[groupedDate]} />
          ))}
        </WithEmptyState>
      </Col>
    </Row>
  )
}
