import React from "react"
import { Link, useNavigate, useOutletContext } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

import { useDispatch, useSelector } from "react-redux"
import { requestForgotPassword } from "store/password"
import { modelSelector } from "store/selectors"

export default function Step4() {
  useTitle("Booking Wizard — Step 4")
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isGuest } = useOutletContext()
  const booking = useSelector(modelSelector("booking"))
  const title = t("booking.wizard.step_4.title")
  const subtitle = t("booking.wizard.step_4.subtitle")

  const sendEmailInstructionHandler = () =>
    booking.client &&
    dispatch(requestForgotPassword({ email: booking.client.email })).then(() => navigate(oldRoutes.resetPasswordRequestSentPath()))

  return (
    <>
      <div className="">
        <Icon iconName="Checked" className="text-primary mb-20" size={80} />
        {title && <h1 className="h2 lh-sm mb-0">{title}</h1>}
        {subtitle && <p className="fs-4 mt-15 mb-40 text-dark">{subtitle}</p>}
      </div>
      <div className="hstack gap-10 justify-content-center mt-40">
        <Button tag={Link} to={oldRoutes.tripsPath()} color="primary" className="me-auto">
          {t("global.browse_more_trips")}
        </Button>
        {!isGuest && (
          <Button tag={Link} to={oldRoutes.clientBookingsPath()} color="primary" outline className="text-white-hover">
            {t("bookings.go_to_my_trips")}
          </Button>
        )}
      </div>
      {isGuest && (
        <>
          <p className="fs-4 fw-semibold mt-60 mb-20">
            You can view and manage your trips and get access to more features by creating an account.
          </p>
          <Button onClick={sendEmailInstructionHandler} color="primary" className="text-white-hover w-100">
            {t("bookings.send_email_instruction")}
          </Button>
        </>
      )}
    </>
  )
}
