import React from "react"
import { Col, Row } from "reactstrap"
import StripeForm from "components/guide/settings/StripeForm"

import { useTitle } from "hooks/useTitle"

export default function Stripe() {
  useTitle("Settings — Stripe")

  return (
    <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
      <Col xs={12}>
        <StripeForm />
      </Col>
    </Row>
  )
}
