import React from "react"
import { Layout } from "antd"
import styles from "./Index.module.css"
import { Outlet } from "react-router-dom"
import { NavigationPath } from "../ProHomeLayout/NavigationPath"

export const ProMessagesLayout = () => {
  return (
    <Layout className={styles.layout}>
      <NavigationPath className="pl-m pr-m" />
      <Outlet />
    </Layout>
  )
}
