import React from "react"
import { Outlet } from "react-router-dom"
import Navigation from "components/bookings/Navigation"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"

export default function Bookings() {
  const { t } = useTranslation()

  return (
    <>
      <h1 className="fs-2 fw-bold text-dark">{t("bookings.title")}</h1>
      <Navigation />
      <DefaultErrorBoundary>
        <Outlet />
      </DefaultErrorBoundary>
    </>
  )
}
