import React from "react"
import SettingsForm from "components/settings/SettingsForm"

import { useTitle } from "hooks/useTitle"

export default function MyDetails() {
  useTitle("Settings — My Details")

  return <SettingsForm />
}
