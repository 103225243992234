import React, { memo } from "react"
import PropTypes from "prop-types"
import Spinner from "components/common/Spinner"

import { useSelector } from "react-redux"
import { firstLoadingSelector } from "./selectors"

FirstLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
  name: PropTypes.string,
  new: PropTypes.bool,
  children: PropTypes.node
}
function FirstLoading({ loading, name = "user", new: newRecord, children, ...rest }) {
  const firstLoading = useSelector(firstLoadingSelector(name))

  return !newRecord && (loading || firstLoading) ? <Spinner {...rest} /> : children
}

export default memo(FirstLoading)
