export const GROUP_COLOR_DEFAULT = "#141414"
export const GROUP_COLORS = ["#399352", "#53D085", "#F09540", "#53B0D0", "#B67CFF", "#F36ABD", "#396C93", "#8750CE", "#D75F84", "#F6CC49"]

export const CLIENT_FILTERS_HELP_ATTRIBUTES = ["user_ids[]"]
export const CLIENT_FILTERS = [
  ...CLIENT_FILTERS_HELP_ATTRIBUTES,
  "user_first_name_or_user_last_name_cont",
  "sort_by_user_full_name",
  "groups_id_in[]",
  "sort_by_last_trip_date",
  "sort_by_total_booking_amount"
]
