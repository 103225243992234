import { Flex } from 'antd';
import { getCountryFromCountryCode } from 'country-codes-flags-phone-codes';
import { CircleFlag } from 'react-circle-flags'

export const PhoneCodeLabel = ({ countryCode }) => {
    const country = getCountryFromCountryCode(countryCode);
    return (
        <Flex align='center' gap={12}>
            <CircleFlag countryCode={countryCode.toLowerCase()} height="24" />
            <div>{country.dialCode}</div>
        </Flex>
    );
};
