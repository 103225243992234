export const getScroll = (scrollBox, direction, value) => {
  if (!scrollBox) return
  const { scrollHeight, scrollWidth, clientHeight, clientWidth, scrollTop, scrollLeft } = scrollBox || {}
  const directionMap = {
    top: scrollTop,
    left: scrollLeft,
    bottom: scrollHeight - ((value ?? scrollTop) + clientHeight),
    right: scrollWidth - ((value ?? scrollLeft) + clientWidth)
  }
  return directionMap[direction] ?? directionMap
}

const handleDirection = (direction, attrs) => {
  if (typeof direction === "function") return direction(attrs)
  if (!["top", "left", "roght", "bottom"].includes(direction))
    return console.log("'direction' must be one of 'top', 'left', 'roght', 'bottom'")
  return direction
}

export const createScorllSaver = () => {
  let savedScroll

  return (scrollBox = {}) => {
    savedScroll = getScroll(scrollBox)

    const scrollTo = (scroll, direction = "top", behavior = "instant") => {
      if (typeof scroll !== "number") return
      if (!scrollBox || typeof scrollBox.scrollTo !== "function") return
      const scrollToDirection = handleDirection(direction, savedScroll)
      const isVertical = ["top", "bottom"].includes(scrollToDirection)
      const isHorizontal = ["left", "right"].includes(scrollToDirection)
      const newScroll = getScroll(scrollBox, scrollToDirection, scroll)

      scrollBox.scrollTo({
        ...(isVertical && { top: newScroll, left: 0 }),
        ...(isHorizontal && { left: newScroll, top: 0 }),
        behavior
      })
    }

    const saveScroll = () => {
      savedScroll = getScroll(scrollBox)
    }

    const scrollToSaved = (direction, behavior) => {
      const scrollToDirection = handleDirection(direction, savedScroll)
      scrollTo(savedScroll[scrollToDirection], scrollToDirection, behavior)
    }

    return [saveScroll, scrollToSaved, scrollTo]
  }
}
