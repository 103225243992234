export const colorClasses = (key, map, { bg = true, border = true, text = true } = {}) => {
  const classes = []
  const color = map[key] || "dark"
  if (bg) classes.push(`bg-${color}`)
  if (border) classes.push(`border-${color}`)
  if (text) classes.push(`text-${color}`)

  return classes.join(" ")
}

export const hexToRGB = (hex) => {
  hex = "0x" + hex.replace("#", "")
  let r = (hex >> 16) & 0xff
  let g = (hex >> 8) & 0xff
  let b = hex & 0xff
  return [r, g, b]
}
