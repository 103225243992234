import React from "react"

export const Booking = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_478_9445)">
      <path
        d="M5.75996 0C5.23496 0 4.79996 0.435 4.79996 0.96V1.92H1.91996C1.66871 1.92 1.41371 2.01187 1.23371 2.19375C1.05184 2.37375 0.959961 2.62875 0.959961 2.88V22.08C0.959961 22.3312 1.05184 22.5863 1.23371 22.7681C1.41371 22.9481 1.66871 23.04 1.91996 23.04H16.3331C17.1337 23.64 18.1237 24 19.2 24C21.8475 24 24 21.8475 24 19.2C24 18.1238 23.64 17.1337 23.04 16.3331V2.88C23.04 2.62875 22.9481 2.37375 22.7681 2.19375C22.5862 2.01187 22.3312 1.92 22.08 1.92H19.2V0.96C19.2 0.435 18.765 0 18.24 0H17.28C16.755 0 16.32 0.435 16.32 0.96V1.92H7.67996V0.96C7.67996 0.435 7.24496 0 6.71996 0H5.75996ZM5.75996 0.96H6.71996V3.84H5.75996V0.96ZM17.28 0.96H18.24V3.84H17.28V0.96ZM1.91996 2.88H4.79996V3.84C4.79996 4.365 5.23496 4.8 5.75996 4.8H6.71996C7.24496 4.8 7.67996 4.365 7.67996 3.84V2.88H16.32V3.84C16.32 4.365 16.755 4.8 17.28 4.8H18.24C18.765 4.8 19.2 4.365 19.2 3.84V2.88H22.08V6.24H1.91996V2.88ZM1.91996 7.2H22.08V15.3694C21.2775 14.7638 20.2818 14.4 19.2 14.4C17.0531 14.4 15.2325 15.8156 14.6193 17.7638L14.5125 17.355L13.92 15.1294L16.8 12.7875L13.095 12.5869L11.76 9.12L10.425 12.5869L6.71996 12.7875L9.59996 15.1294L8.64559 18.72L11.76 16.7025L14.4637 18.4537C14.4262 18.6975 14.4 18.945 14.4 19.2C14.4 20.2819 14.7637 21.2775 15.3693 22.08H1.91996V7.2ZM11.76 11.79L12.1987 12.9319L12.4218 13.5131L13.0425 13.545L14.265 13.6125L13.3125 14.385L12.8325 14.775L12.9918 15.375L13.3068 16.56L12.2812 15.8944L11.76 15.5569L11.2368 15.8944L10.2131 16.56L10.5281 15.375L10.6875 14.775L10.2056 14.385L9.25496 13.6125L10.4775 13.545L11.0981 13.5131L11.3193 12.9319L11.76 11.79ZM19.2 15.36C21.3168 15.36 23.04 17.0831 23.04 19.2C23.04 21.3169 21.3168 23.04 19.2 23.04C17.0831 23.04 15.36 21.3169 15.36 19.2C15.36 17.0831 17.0831 15.36 19.2 15.36ZM21.2643 16.9931L18.6731 20.0156L17.0868 18.8156L16.5131 19.5844L18.7668 21.3131L21.9843 17.6156L21.2643 16.9931Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_478_9445">
        <rect width={height} height={width} fill="none" />
      </clipPath>
    </defs>
  </svg>
)
