import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { assume } from "store/auth"

function useAssume() {
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(window.location.search)
  const access_token = searchParams.get("access_token")
  const refresh_token = searchParams.get("refresh_token")
  const redirect_url = searchParams.get("redirect_url")

  useEffect(() => {
    if (!access_token || !refresh_token) return
    dispatch(assume(access_token, refresh_token, redirect_url))
  }, [access_token, refresh_token, redirect_url]) //eslint-disable-line
}

export default useAssume
