import React from "react"
import PropTypes from "prop-types"
import ExpensesCategoriesPie from "./ExpensesCategoriesPie"
import Spinner from "components/common/Spinner"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { numberToCurrency } from "helpers/string"

import { useSelector } from "react-redux"
import { metadataSelector } from "store/selectors"

Expenses.propTypes = {
  params: PropTypes.shape({
    overview_range: PropTypes.string.isRequired,
    date_gteq: PropTypes.string,
    date_lteq: PropTypes.string
  }).isRequired
}
export default function Expenses({ params }) {
  const { t } = useTranslation()
  const isCurrentMonth = params.overview_range === "current_month"

  const metadata = useSelector(metadataSelector("accounting"))
  const { expenses_stats } = metadata

  const totalForDates = {
    current_month: moment().format("MMMM YYYY"),
    last_month: moment().subtract(1, "months").format("MMMM YYYY"),
    last_30_days: `${moment().subtract(30, "days").format("MMM DD, YYYY")} — ${moment().format("MMM DD, YYYY")}`,
    total_by_scope: `${moment(params.date_gteq).format("MMM DD, YYYY")} — ${moment(params.date_lteq).format("MMM DD, YYYY")}`
  }
  const dates = totalForDates[params.overview_range]

  // Function to determine the message based on expense change
  const getExpenseChangeMessage = (monthly_diff) => {
    const numericValue = parseFloat(monthly_diff)
    const action = numericValue >= 0 ? "Increase" : "Decrease"
    return t(`accounting.values.value_change_from_last_month`, { action, value: monthly_diff })
  }

  return (
    <div className="bg-white rounded p-15 h-100 vstack position-relative">
      <h3 className="h4 fw-normal mb-10">{t(`accounting.labels.expenses`)}</h3>
      <p className="fs-7 mb-0">{t(`accounting.values.spending`, { dates })}</p>
      <p className="fw-bold mb-0">{numberToCurrency(expenses_stats[params.overview_range])}</p>
      {isCurrentMonth && <p className="fs-7 text-dark text-opacity-50">{getExpenseChangeMessage(expenses_stats.monthly_diff)}</p>}
      {expenses_stats[params.overview_range] ? (
        <ExpensesCategoriesPie range={params.overview_range} className="mt-25" />
      ) : (
        <Spinner className="flex-fill" />
      )}
    </div>
  )
}
