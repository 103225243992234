import React from "react"
import { useNavigate } from "react-router-dom"
import PhotoSelector from "components/form/PhotoSelector"
import ActionButtons from "./ActionButtons"

import useForm, { cleanNestedAttributes } from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { guideSelector, isGuideCompletedSelector, updateGuide } from "store/user"

const Step3Form = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const guide = useSelector(guideSelector)
  const wizardCompleted = useSelector(isGuideCompletedSelector)
  const updateStep = useWizardSteps({ name: "user.guide" })
  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm(guide, ["photos"])

  const updateStepIfIsntCompleted = (form) => (wizardCompleted ? form : updateStep(form))

  submitCallback(({ nativeEvent }) => {
    const photos_attributes = cleanNestedAttributes(form.photos, ["file", "file_type"])
    const guideAttributes = { ...updateStepIfIsntCompleted(form), photos_attributes }

    const nextPath = nativeEvent?.submitter?.value
    const formData = serialize({ guide: guideAttributes }, { indices: true })
    dispatch(updateGuide(guide.id, formData)).then(() => nextPath && navigate(nextPath))
  })

  return (
    <form onSubmit={submitHandler}>
      <PhotoSelector form={form} changeHandler={changeHandler} max={6} />
      <ActionButtons isChanged={isChanged} onReset={reset} withSkip />
    </form>
  )
}

export default Step3Form
