import React, { memo, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Input from "./Input"

import { filterHashTags, splitHashTags } from "helpers/string"
import { find, omit } from "lodash"

TagsInput.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      _destroy: PropTypes.bool
    })
  ),
  addTagHandler: PropTypes.func.isRequired,
  removeTagHandler: PropTypes.func.isRequired,
  updateTagHandler: PropTypes.func.isRequired
}

function TagsInput({ tags = [], addTagHandler, removeTagHandler, updateTagHandler, ...rest }) {
  const inputRef = useRef(null)
  const formStringValue = useMemo(
    () =>
      tags
        ?.filter(({ _destroy }) => !_destroy)
        .map(({ title }) => title)
        ?.join(" ") || "",
    [tags]
  )
  const [stringValue, setStringValue] = useState(formStringValue)

  const onChangeHandler = (event) => {
    const { value, selectionStart } = event.target
    const addOrUpdate = value.length >= stringValue.length
    const filteredValue = filterHashTags(value, addOrUpdate)
    const diffCount = filteredValue.length - value.length
    const selectionPosition = selectionStart + Math.min(diffCount, 1)

    setStringValue(filteredValue)

    requestAnimationFrame(() => {
      if (inputRef.current) {
        inputRef.current.selectionStart = selectionPosition
        inputRef.current.selectionEnd = selectionPosition
      }
    })
  }

  const blurHandler = () => {
    const array = splitHashTags(stringValue)
    array.forEach((title) => {
      const item = find(tags, { title })
      if (item) updateTagHandler(null, omit(item, "_destroy"))
      else addTagHandler(null, { title })
    })
    tags.forEach((tag) => !array.includes(tag.title) && removeTagHandler(tag))
  }

  useEffect(() => {
    setStringValue(formStringValue)
  }, [formStringValue])

  return (
    <Input
      {...rest}
      value={stringValue}
      onChange={onChangeHandler}
      onBlur={blurHandler}
      className="bg-primary-checked border-primary-checked border-primary-focus focus-ring-primary"
      ref={inputRef}
    />
  )
}

export default memo(TagsInput)
