import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import en from "./locales/en.json"
import es from "./locales/es.json"

const options = {
  interpolation: {
    escapeValue: false
  },

  debug: true,

  resources: {
    en: {
      common: en.en
    },
    es: {
      common: es.es
    }
  },

  fallbackLng: "en",

  ns: ["common"],

  defaultNS: "common",

  react: {
    wait: false,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default"
  }
}

i18n.use(LanguageDetector).init(options)

export default i18n
