import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, Button, Radio } from "antd"
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Title, Text } from "ui/Typography"
import { routes } from "router"
import { EmailPasswordRecovery } from "./Email"
import { PhonePasswordRecovery } from "./Phone"
import { requestForgotPassword } from "store/password"
import useFormErrors from "hooks/useAntdFormErrors"

export const PasswordRecovery = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { loading } = useSelector((state) => state.auth)
  const [recoveryMethod, setRecoveryMethod] = useState("email")
  const [form] = Form.useForm()

  const { errors } = useFormErrors(form)

  const onChange = (e) => {
    setRecoveryMethod(e.target.value)
  }

  const onFinish = ({ email, phone_number }) => {
    const data = {
      email,
      redirect_url: routes.passwordRecoveryEnterCode()
    }

    const redirectQueryParams = {
      recoveryMethod
    }

    if (email) {
      data.email = email
      redirectQueryParams.email = email
    } else if (phone_number) {
      data.phone_number = phone_number
      redirectQueryParams.phone = email
    }

    dispatch(requestForgotPassword(data)).then(() => navigate(routes.passwordRecoveryEnterCode({}, redirectQueryParams)))
  }

  return (
    <>
      <Title className="flex justify-center mb-3xl">{t("password_recovery.title")}</Title>
      <Text type="body" className="flex justify-center text-center mb-m">
        {t("password_recovery.info")}
      </Text>
      <Radio.Group block value={recoveryMethod} onChange={onChange} className="mb-xl" size="large">
        <Radio.Button value="email">Email</Radio.Button>
        <Radio.Button value="phone">Phone</Radio.Button>
      </Radio.Group>
      <Form form={form} name="passwordRecovery" layout="vertical" onFinish={onFinish}>
        {recoveryMethod === "email" && <EmailPasswordRecovery errors={errors.email} />}
        {recoveryMethod === "phone" && <PhonePasswordRecovery errors={errors.phone_number} />}
        <Form.Item>
          <Button type="primary" htmlType="submit" block size="large" disabled={loading}>
            {t("password_recovery.send_code")}
          </Button>
        </Form.Item>
      </Form>
      <div className="flex justify-center">
        <Text type="secondary" variant="body">
          {t("password_recovery.recall_password")}
        </Text>
        <Text className="ml-xs" link>
          <Link to={routes.signInV2()}>{t("global.sign_in")}</Link>
        </Text>
      </div>
    </>
  )
}
