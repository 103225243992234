// src/components/Icon.js
import React from "react"
import * as FeatherIcons from "react-feather"
import { useTheme } from "context/AntdThemeContext"
import { DSIcon } from "./DSIcon"
import { colors } from "themes/variables"

export const Icon = ({ type = "feather", name = "", height = 24, width = 24, strokeWidth = 1.5, color, ...props }) => {
  const isFeatherIcon = type === "feather" && name in FeatherIcons
  const { isSportsman } = useTheme()

  if (color === undefined) {
    color = isSportsman ? colors.sportsmanBrand : colors.sportswomanBrand
  }

  if (isFeatherIcon) {
    const FeatherIconComponent = FeatherIcons[name]
    return <FeatherIconComponent height={height} width={width} color={color} strokeWidth={strokeWidth} {...props} />
  }

  return <DSIcon name={name} height={height} width={width} fill={color} {...props} />
}
