import React, { memo } from "react"
import { DropdownToggle, Label } from "reactstrap"
import FilterWrapper from "components/common/FilterWrapper"
import Icon from "components/common/Icon"
import Input from "components/form/Input"

import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"

import { CLIENT_FILTERS, CLIENT_FILTERS_HELP_ATTRIBUTES } from "constants/clients"

function Groups() {
  const { t } = useTranslation()
  const { groups_collection = [] } = useSelector((store) => store.clients.metadata)

  const toggleGroup =
    (form, changeHandler) =>
    ({ target }) => {
      const isChecked = target.checked
      const groupId = +target.value
      const name = target.name
      const prevValue = (Array.isArray(form[name]) && form[name]) || (form[name] && [form[name]]) || []
      const value = isChecked ? prevValue.concat(groupId) : prevValue.filter((id) => id !== groupId)
      changeHandler({ target: { name, value } })
    }

  return (
    <FilterWrapper
      title={t("client.filters.groups")}
      fields={["groups_id_in[]"]}
      accessAttributes={CLIENT_FILTERS}
      exceptDependencies={CLIENT_FILTERS_HELP_ATTRIBUTES}
      end
      toggleButton={(_, isActive, filters) => (
        <DropdownToggle
          color="ghost"
          className={`hstack gap-2 p-1 my-n2 rounded-1 ${isActive ? "text-primary-second" : "text-dark"}`}
          sort={filters.groups_id_in}
        >
          GROUP
          <Icon iconName="Filter" />
        </DropdownToggle>
      )}
    >
      {(form, changeHandler) => {
        const groups_id_in = form["groups_id_in[]"] || []
        return (
          <div className="vstack gap-2">
            {groups_collection.map((group) => (
              <Label check className="hstack gap-10 p-10 pe-15 bg-white border border-gray-lightest rounded fs-7 fw-normal" key={group.id}>
                <Input
                  type="checkbox"
                  name="groups_id_in[]"
                  value={group.id}
                  checked={groups_id_in.includes(group.id)}
                  onChange={toggleGroup(form, changeHandler)}
                  className="mt-0"
                />
                <span className={`text-dark ${groups_id_in.includes(group.id) ? "" : "text-opacity-50"}`}>{group.name}</span>
              </Label>
            ))}
          </div>
        )
      }}
    </FilterWrapper>
  )
}

export default memo(Groups)
