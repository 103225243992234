import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { find } from "lodash"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("unavailableDays/requested")
const received = createAction("unavailableDays/received")
const failed = createAction("unavailableDays/failed")
const cleaned = createAction("unavailableDays/cleaned")

const requestedUnavailableDay = createAction("unavailableDays/requestedUnavailableDay")
const receivedUnavailableDay = createAction("unavailableDays/receivedUnavailableDay")
const failedUnavailableDay = createAction("unavailableDays/failedUnavailableDay")
export const createdUnavailableDay = createAction("unavailableDays/createdUnavailableDay")
export const updatedUnavailableDay = createAction("unavailableDays/updatedUnavailableDay")
export const deletedUnavailableDay = createAction("unavailableDays/deletedUnavailableDay")
const cleanedUnavailableDay = createAction("unavailableDays/cleanedUnavailableDay")

//HELPERS

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  metadata: {},
  items: [],
  unavailableDay: {
    ...LOADING_INITIAL_STATE,
    id: null,
    unavailable_type: "", // "range|date",
    unavailable_date: "",
    start_datetime: "",
    end_datetime: "",
    all_day: false,
    recurring_yearly: false
  }
}

const unavailableDaysReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    state.items = action.payload.data
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedUnavailableDay.type]: (state) => {
    setRequested(state.unavailableDay)
  },
  [receivedUnavailableDay.type]: (state, action) => {
    setReceived(state.unavailableDay)
    Object.assign(state.unavailableDay, action.payload)
  },
  [createdUnavailableDay.type]: (state, action) => {
    setReceived(state.unavailableDay)
    Object.assign(state.unavailableDay, initialState.unavailableDay)
  },
  [deletedUnavailableDay.type]: (state, action) => {
    setReceived(state.unavailableDay)
  },
  [failedUnavailableDay.type]: (state) => {
    setFailed(state.unavailableDay)
  },
  [cleanedUnavailableDay.type]: (state) => {
    state.unavailableDay = initialState.unavailableDay
  }
})
export default unavailableDaysReducer

const getItem = (url, id, dispatch, getState) => {
  const state = getState()
  const unavailableDay = find(state.items, ["id", id])

  if (unavailableDay) {
    dispatch(receivedUnavailableDay(unavailableDay))
    return new Promise((resolve) => resolve(unavailableDay))
  }
  return dispatch(
    api({
      url,
      onStart: requestedUnavailableDay.type,
      onSuccess: receivedUnavailableDay.type,
      onError: failedUnavailableDay.type
    })
  )
}

// PUBLIC ACTIONS

export const getGuideUnavailableDay = (id) => (dispatch, getState) => getItem(`/guide/unavailable_days/${id}`, id, dispatch, getState)

export const createGuideUnavailableDay = (data) =>
  api({
    url: "/guide/unavailable_days",
    method: "post",
    onStart: requestedUnavailableDay.type,
    onSuccess: createdUnavailableDay.type,
    onError: failedUnavailableDay.type,
    data
  })

export const updateGuideUnavailableDay = (id, data) =>
  api({
    url: `/guide/unavailable_days/${id}`,
    method: "put",
    data: data,
    onStart: requestedUnavailableDay.type,
    onSuccess: updatedUnavailableDay.type,
    onError: failedUnavailableDay.type
  })

export const deleteGuideUnavailableDay = (id) =>
  api({
    url: `/guide/unavailable_days/${id}`,
    method: "delete",
    onStart: requestedUnavailableDay.type,
    onSuccess: deletedUnavailableDay.type,
    onError: failedUnavailableDay.type
  })

export const saveUnavailableDay = (id, data) => (id ? updateGuideUnavailableDay(id, data) : createGuideUnavailableDay(data))

export const cleanUnavailableDay = () => (dispatch) => dispatch(cleanedUnavailableDay())
export const cleanUnavailableDays = () => (dispatch) => dispatch(cleaned())
