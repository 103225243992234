import React from "react"
import styles from "./NavigateBack.module.css"
import { Icon } from "ui/Icon"
import { useNavigate } from "react-router-dom"
import { Title } from "ui/Typography"

export const NavigateBack = ({ path, text }) => {
  const navigate = useNavigate()
  const handleOnClick = () => {
    navigate(path || -1)
  }
  return (
    <div className={styles.navigateBack}>
      <div onClick={handleOnClick} className={styles.iconWrapper}>
        <Icon width={16} height={16} type="feather" name="ChevronLeft" color="currentColor" />
      </div>
      <Title className="mb-0" level={3}>
        {text}
      </Title>
    </div>
  )
}
