import { Flex } from "antd"
import { useSelector } from "react-redux"
import { isStripeConnectedSelector } from "store/user"
import { spacings } from "themes/variables"
import { Icon } from "ui/Icon"
import styles from "./StripeAccountCard.module.css"
import { useTranslation } from "react-i18next"

export const StripeStatus = () => {
  const { t } = useTranslation()
  const isStripeConnected = useSelector(isStripeConnectedSelector)

  return (
    <>
      {isStripeConnected && (
        <Flex align="center" gap={spacings.XXS}>
          <Icon type="feather" name="Check" width={16} height={16} />
          <div className={styles.statusText}>{t("profile.connected")}</div>
        </Flex>
      )}
    </>
  )
}
