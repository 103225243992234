import React from "react"
import { Col, Row } from "reactstrap"
import Step2Form from "components/guide/wizard/Step2Form"

import { useTitle } from "hooks/useTitle"

export default function MyDetails() {
  useTitle("Settings — My Details")

  return (
    <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
      <Col xs={12}>
        <Step2Form />
      </Col>
    </Row>
  )
}
