import React, { useEffect, useState } from "react"
import Loading from "modules/loader-watchers/Loading"
import { Container, Row } from "reactstrap"

export default function ReferralForm() {
  const [hbLoading, setHBLoading] = useState(false)

  const mountHubSpotForm = () => {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "44114474",
      formId: "c9892adc-801f-4c33-8dfc-76a9f56e797f",
      target: ".hubspotForm",
      onFormReady: () => setHBLoading(false)
    })
  }

  useEffect(() => {
    setHBLoading(true)

    const script = document.createElement("script")
    const container = document.getElementById("newsletterSubscription")
    script.src = "//js.hsforms.net/forms/embed/v2.js"
    script.addEventListener("load", mountHubSpotForm)
    container.appendChild(script)

    return () => {
      script.removeEventListener("load", mountHubSpotForm)
      container.removeChild(script)
    }
  }, []) //eslint-disable-line

  return (
    <Container className="h-100 w-100 pb-80 pb-sm-100" style={{ maxWidth: "822px", minHeight: "150px" }}>
      <Loading loading={hbLoading}>
        <Row className="text-center d-flex justify-content-center align-items-center">
          <div className="hubspotForm"></div>
        </Row>
      </Loading>
    </Container>
  )
}
