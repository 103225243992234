import React, { useCallback, useEffect, useMemo, useState } from "react"
import DateSwitcher from "modules/datepicker/DateSwitcher"
import CalendarDay from "./CalendarDay"
import Loading from "modules/loader-watchers/Loading"

import moment from "moment"

import { useDispatch, useSelector } from "react-redux"
import { getClientGuideTrips } from "store/trips"
import { modelSelector } from "store/selectors"

export default function Calendar() {
  const dispatch = useDispatch()
  const guide = useSelector(modelSelector("guide"))

  const today = global.dateTime().startOf("date")
  const nextDay = today.clone.manipulate(1, "date")
  const minDate = today.clone.startOf("month")

  const [monthRange, setMonthRange] = useState(() => [today.clone.startOf("month"), today.clone.endOf("month")])
  const [monthStartDate, monthEndDate] = monthRange

  const calendarStartDate = global.dateTime(monthStartDate).startOf("weekDay", 1)
  const calendarEndDate = global.dateTime(monthEndDate).endOf("weekDay", 1)

  const calendarDaysCount = moment(calendarEndDate).diff(moment(calendarStartDate), "days") + 1

  const calendarDays = useMemo(
    () => new Array(calendarDaysCount).fill().map((_, index) => moment(calendarStartDate).add(index, "days")),
    [calendarStartDate, calendarDaysCount]
  )
  const weekDayNames = useMemo(
    () => new Array(7).fill().map((_, index) => moment(calendarStartDate).add(index, "days").format("ddd")),
    [calendarStartDate]
  )

  const dateChangeHandler = useCallback(({ target }) => {
    const startDate = target.date.clone.startOf("month")
    const endDate = target.date.clone.endOf("month")
    setMonthRange([startDate, endDate])
  }, [])

  useEffect(() => {
    const minDate = calendarStartDate.isAfter(nextDay) ? calendarStartDate.format() : nextDay.format()
    const maxDate = calendarEndDate.format()
    const params = {
      "q[with_available_days]": true,
      "q[date_between_or_unseasonal]": [minDate, maxDate]
    }
    if (guide.slug) dispatch(getClientGuideTrips(guide.slug, null, params))
  }, [guide.slug, nextDay.format(), calendarStartDate.format(), calendarEndDate.format(), dispatch]) //eslint-disable-line

  const classes = ["bg-white rounded"]
  return (
    <div className={classes.join(" ")}>
      <div className="hstack align-items-stretch py-3 px-15 border-top border-start border-end border-gray-lightest rounded-top">
        <DateSwitcher onChange={dateChangeHandler} type="month" defaultValue={today} restrictions={{ minDate }} />
      </div>
      <div className="overflow-x-scroll">
        <div className="grid grid-cols-7 py-1 border border-gray-lightest" style={{ minWidth: '1000px' }}>
          {weekDayNames.map((dayName) => (
            <span key={dayName} className="fs-7 fw-medium text-center">
              {dayName}
            </span>
          ))}
        </div>
        <div className="border-start border-end border-bottom border-gray-lightest rounded-bottom" style={{ minWidth: '1000px' }}>
          <Loading
            name="trips"
            tag="div"
            className="grid grid-cols-7 gap-0 position-relative"
            style={{ marginRight: -1, marginBottom: -1, gridTemplateColumns: "repeat(var(--bs-columns, 12), minmax(0,1fr))" }}
          >
            {calendarDays.map((day) => (
              <CalendarDay
                key={day.dayOfYear()}
                day={day}
                today={moment(today)}
                thisMonth={moment(monthStartDate) <= day && day <= moment(monthEndDate)}
              />
            ))}
          </Loading>
        </div>
      </div>
    </div>
  )
}
