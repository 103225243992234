import React, { memo } from "react"
import PropTypes from "prop-types"
import { FormGroup, InputGroup, InputGroupText, Label } from "reactstrap"
import Icon from "components/common/Icon"
import DynamicTag from "components/DynamicTag"
import Datepicker from "modules/datepicker/Datepicker"
import InputError from "./InputError"

import { getPickerFormat } from "modules/datepicker/helpers"

const DatepickerInput = ({
  type = "date",
  label,
  name,
  placeholder,
  value,
  onChange,
  disabled = false,
  floating = false,
  noIcon = false,
  wrapperTag,
  className,
  labelClassName,
  inputProps: { withError, ...restInputProps } = {},
  inputGroupClassName,
  inputClassName,
  iconClassName,
  restrictions = {},
  display = {},
  ...rest
}) => {
  const format = getPickerFormat(type)

  let labelClasses = ""
  if (labelClassName) labelClasses = labelClasses.concat(" ", labelClassName)

  let inputGroupClasses = "flex-nowrap mb-auto"
  if (inputGroupClassName) inputGroupClasses = inputGroupClasses.concat(" ", inputGroupClassName)

  let inputClasses = ""
  if (inputClassName) inputClasses = inputClasses.concat(" ", inputClassName)
  if (!noIcon) inputClasses = inputClasses.concat(" ", "border-end-0")

  let iconClasses = "text-dark text-opacity-50 border-start-0"
  if (iconClassName) iconClasses = iconClasses.concat(" ", iconClassName)
  if (disabled) iconClasses = iconClasses.concat(" ", "bg-secondary-bg")
  else iconClasses = iconClasses.concat(" ", "bg-white")

  return (
    <DynamicTag tag={wrapperTag || React.Fragment} {...(wrapperTag && { className })}>
      {label && !floating && <Label className={labelClasses}>{label}</Label>}
      <InputGroup className={inputGroupClasses}>
        <DynamicTag tag={floating ? FormGroup : React.Fragment} {...(floating && { noMargin: true, floating: true })}>
          <Datepicker
            {...rest}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            display={{ buttons: { today: true, clear: true }, ...display }}
            restrictions={restrictions}
            disabled={disabled}
            inputProps={{ ...restInputProps, className: inputClasses, placeholder }}
            format={format}
          />
          {label && floating && <Label className={labelClasses}>{label}</Label>}
        </DynamicTag>
        {!noIcon && (
          <InputGroupText className={iconClasses}>
            <Icon iconName="Calendar" size={24} />
          </InputGroupText>
        )}
      </InputGroup>
      {withError && <InputError field={name} />}
    </DynamicTag>
  )
}

DatepickerInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  floating: PropTypes.bool,
  noIcon: PropTypes.bool,
  wrapperTag: PropTypes.elementType,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputProps: PropTypes.shape({
    withError: PropTypes.bool
  }),
  inputGroupClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  restrictions: PropTypes.object,
  display: PropTypes.object
}

export default memo(DatepickerInput)
