import { Input } from 'antd';
import "./TextArea.css"
import classNames from 'classnames';

export const TextArea = ({ ...props }) => {
    const { style, borderless } = props;
    return (
        <Input.TextArea
            {...props}
            style={{
                height: 86,
                resize: 'none',
                ...style
            }}
            className={classNames('custom-textarea-input', borderless && 'custom-borderless-textarea-input')}
        />
    );
};
