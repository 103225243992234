import React from "react"
import { Col, Row } from "reactstrap"
import Step3Form from "components/guide/wizard/Step3Form"

import { useTitle } from "hooks/useTitle"

export default function Photos() {
  useTitle("Settings — Photos")

  return (
    <Row className="gap-y-15 gx-4" style={{ maxWidth: 790 }}>
      <Col xs={12}>
        <Step3Form />
      </Col>
    </Row>
  )
}
