import { isString } from "lodash"

export const addressComponentsFindElement = (addressComponents, element, key) =>
  addressComponents.find((comp) => comp.types.includes(element))?.[key]

export const convertGoogleAddressComponentsToObj = (addressComponents, config) =>
  Object.keys(config).reduce((acc, key) => {
    const itemConfig = config[key] || {}
    const elements = itemConfig.elements || []
    const value = elements
      .map((element) => addressComponentsFindElement(addressComponents, element, element === "country" ? "short_name" : "long_name"))
      .filter(isString)
      .join(itemConfig.divider || "")
    acc[key] = value
    return acc
  }, {})
