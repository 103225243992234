import React from "react"
import { Container, Row, Col, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import RegisterForm from "components/devise/RegisterForm"

import { MetaTags } from "react-meta-tags"

import heroImage from "assets/images/guide/hero.jpg"

import imageCustomise from "assets/illustrations/customise.svg"
import imageCalendar from "assets/illustrations/calendar.svg"
import imageManage from "assets/illustrations/manage.svg"
import imageTracking from "assets/illustrations/tracking.svg"
import imageCommunity from "assets/illustrations/community.svg"
import imageIntegrations from "assets/illustrations/integrations.svg"
import Testimonials from "components/landing/Testimonials"

export default function GuideLanding() {
  return (
    <>
      <MetaTags>
        <title>Become a DS Guide</title>
        <meta
          name="description"
          content="By signing up as a guide, you gain access to a powerful management CRM tool, simplifying the process of handling bookings, schedules, and payments. Curate exceptional fishing trips or hunting excursions, and connect with a community of like-minded individuals."
        />
        <meta property="og:title" content="Empowering Guides & Igniting Outdoor Adventures!" />
        <meta
          property="og:description"
          content="By signing up as a guide, you gain access to a powerful management CRM tool, simplifying the process of handling bookings, schedules, and payments. Curate exceptional fishing trips or hunting excursions, and connect with a community of like-minded individuals."
        />
        <meta property="og:image" content={global.location.origin + heroImage} />
      </MetaTags>
      <div className="position-relative overflow-hidden py-60 py-sm-100">
        <img src={heroImage} className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover" alt="Become a DS Guide Hero" />
        <Container className="py-20">
          <div className="position-relative z-1">
            <Row className="align-items-center gap-y-50">
              <Col xs={12} md={6} xl={7}>
                <h1 className="text-white text-center text-sm-start lh-1" style={{ maxWidth: 540 }}>
                  Empowering Guides & Igniting Outdoor Adventures!
                </h1>
                <div className="mt-45">
                  <Button tag={Link} color="white" to="#" className="w-100 w-sm-auto">
                    Learn More
                  </Button>
                </div>
              </Col>
              <Col xs={12} md={6} xl={5}>
                <div className="bg-white rounded p-20">
                  <p className="fs-3 fw-semibold lh-1 mb-35">Become a DS Guide Today</p>
                  <RegisterForm role="guide_user" />
                  <div className="mt-15 fs-6 fw-medium lh-1">
                    <div className="hstack d-inline-flex gap-1 small link-target">
                      Already have an account?{" "}
                      <Link className="text-primary-hover text-decoration-none" to={oldRoutes.signInPath()} state={{ scrollToTop: true }}>
                        Log In
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="bg-light">
        <Container>
          <div className="my-100">
            <h2 className="h2 lh-1 text-center mx-auto mb-40" style={{ maxWidth: 640 }}>
              Harness the Full Power of Our All-In-One App
            </h2>
            <div className="bg-white rounded p-40 p-sm-60 p-lg-80">
              <Row className="gx-70 gap-y-45">
                <Col xs={12} md={6}>
                  <div className="hstack gap-4 align-items-start flex-column flex-sm-row">
                    <img src={imageCustomise} className="flex-shrink-0" alt="Customise Your Trips" />
                    <div>
                      <p className="fs-5 fw-semibold mb-3 mb-sm-20">Customise Your Trips</p>
                      <p className="mb-0 lh-sm">
                        Create your trips, set your price and add all the relevant details you want your customers find out.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="hstack gap-4 align-items-start flex-column flex-sm-row">
                    <img src={imageCalendar} className="flex-shrink-0" alt="Powerful Calendar" />
                    <div>
                      <p className="fs-5 fw-semibold mb-3 mb-sm-20">Powerful Calendar</p>
                      <p className="mb-0 lh-sm">
                        Easily keep track of bookings with our built in calendar. Avoid double booking through linking your iCal or Google
                        Cal.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="hstack gap-4 align-items-start flex-column flex-sm-row">
                    <img src={imageManage} className="flex-shrink-0" alt="Manage Your Business" />
                    <div>
                      <p className="fs-5 fw-semibold mb-3 mb-sm-20">Manage Your Business</p>
                      <p className="mb-0 lh-sm">
                        Keep track of clients, manage the onboarding process from start to finish & keep track of analytics all within the
                        app.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="hstack gap-4 align-items-start flex-column flex-sm-row">
                    <img src={imageTracking} className="flex-shrink-0" alt="Adventure Tracking" />
                    <div>
                      <p className="fs-5 fw-semibold mb-3 mb-sm-20">Adventure Tracking</p>
                      <p className="mb-0 lh-sm">
                        Keep track of adventures with our built in journal, log your catches, sightings, save locations & automatically link
                        the relevant weather data.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="hstack gap-4 align-items-start flex-column flex-sm-row">
                    <img src={imageCommunity} className="flex-shrink-0" alt="Community" />
                    <div>
                      <p className="fs-5 fw-semibold mb-3 mb-sm-20">Community</p>
                      <p className="mb-0 lh-sm">
                        Get access to a large community of professional guides. View and share your guide experiences.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="hstack gap-4 align-items-start flex-column flex-sm-row">
                    <img src={imageIntegrations} className="flex-shrink-0" alt="Seamless Integrations" />
                    <div>
                      <p className="fs-5 fw-semibold mb-3 mb-sm-20">Seamless Integrations</p>
                      <p className="mb-0 lh-sm">Email marketing, accounting software integrations and more! </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-light">
        <Container>
          <Testimonials bg="white" classes="rounded" />
        </Container>
      </div>
    </>
  )
}
