import React, { forwardRef, memo } from "react"
import PropTypes from "prop-types"

const DynamicTag = ({ tag, defaultTag = "div", ...rest }, ref) => React.createElement(tag || defaultTag, { ...rest, ref })

DynamicTag.propTypes = {
  tag: PropTypes.string,
  defaultTag: PropTypes.string
}

export default memo(forwardRef(DynamicTag))
