import { useEffect } from "react";

const disablePinchZoom = (e) => {
  if (e.scale > 1) {
    e.preventDefault();
    e.stopImmediatePropagation();
  }
}

export const useDisablePinchZoom = () => {
  useEffect(() => {
    document.addEventListener("touchmove", disablePinchZoom, { passive: false })
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom)
    }
  }, [])
}
