import { createAction, createReducer } from "@reduxjs/toolkit"
import { pull, uniq } from "lodash"

const added = createAction("loaderWatchers/added")
const removed = createAction("loaderWatchers/removed")
const reseted = createAction("loaderWatchers/reseted")

const initialState = {
  // default: []
}

const loaderWatchersReducer = createReducer(initialState, {
  [reseted.type]: () => initialState,
  [added.type]: (state, { payload }) => {
    const containerName = payload.container || "default"
    const container = state[containerName] || []

    state[containerName] = uniq(container.concat(payload.watchers))
  },
  [removed.type]: (state, { payload }) => {
    const containerName = payload.container || "default"
    const container = state[containerName] || []

    state[containerName] = pull(container, payload.watchers)
    if (state[containerName] && !state[containerName]?.length) delete state[containerName]
  }
})

export default loaderWatchersReducer
export { added as addLoaderWatcher, removed as removeLoaderWatcher, reseted as resetLoaderWatchers }
