import React from "react"
import { Label } from "reactstrap"
import { useNavigate, useOutletContext } from "react-router-dom"
import Input from "components/form/Input"
import ActionButtons from "components/wizards/ActionButtons"

import useForm from "hooks/useForm"
import { useWizardSteps } from "hooks/wizard"
import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"
import { updateBooking } from "store/bookings"
import { modelSelector } from "store/selectors"

const Step2Form = () => {
  const { t } = useTranslation()
  const { name, stepsKey } = useOutletContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const updateStep = useWizardSteps({ name, stepsKey })
  const booking = useSelector(modelSelector("booking"))
  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(
    booking,
    ["accessibility_enabled", "accessibility_notes"],
    { force: booking.wizard_step === "accessibility" }
  )

  submitCallback(({ nativeEvent }) => {
    const nextPath = nativeEvent?.submitter?.value

    const formData = serialize({ booking: updateStep(form) })
    dispatch(updateBooking(booking.uuid, formData)).then(() => navigate(nextPath))
  })

  return (
    <form onSubmit={submitHandler} className="vstack">
      <div className="hstack gap-15">
        <Label check className="hstack gap-10 d-inline-flex mt-2 fs-7 fw-normal">
          <Input
            type="radio"
            name="accessibility_enabled"
            value={true}
            checked={[true, "true"].includes(form.accessibility_enabled)}
            onChange={changeHandler}
            className="mt-0"
          />
          <span className={`text-dark ${[true, "true"].includes(form.accessibility_enabled) ? "" : "text-opacity-50"}`}>
            {t(`global.yes`)}
          </span>
        </Label>
        <Label check className="hstack gap-10 d-inline-flex mt-2 fs-7 fw-normal">
          <Input
            type="radio"
            name="accessibility_enabled"
            value={false}
            checked={[false, "false"].includes(form.accessibility_enabled)}
            onChange={changeHandler}
            className="mt-0"
          />
          <span className={`text-dark ${[false, "false"].includes(form.accessibility_enabled) ? "" : "text-opacity-50"}`}>
            {t(`global.no`)}
          </span>
        </Label>
      </div>
      {form.accessibility_enabled && (
        <div className="pt-20">
          <Input
            type="textarea"
            name="accessibility_notes"
            placeholder={t("booking.labels.accessibility_notes")}
            value={form.accessibility_notes || ""}
            onChange={changeHandler}
            className="fs-6"
            rows={10}
            withError
          />
        </div>
      )}
      <ActionButtons
        isChanged={isChanged}
        action={updateBooking}
        name={name}
        stepsKey={stepsKey}
        withBack={false}
        className="mt-50"
        btnColor="primary"
        nextClassName="flex-fill text-uppercase"
        nextText={t("global.next")}
      />
    </form>
  )
}

export default Step2Form
