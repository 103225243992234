import { useEffect, useRef } from "react"

function useEvent(name, handler, shouldHandle = true, target = document, mountedRun = false, unmountedRun = false) {
  const mounted = useRef(true)

  useEffect(() => {
    const handle = shouldHandle instanceof Function ? shouldHandle() : shouldHandle
    if (!handle) return

    const node = target instanceof Function ? target() : target

    if (mounted.current) {
      node.removeEventListener(name, handler)
      node.addEventListener(name, handler)
      if (mountedRun) handler()
    }

    mounted.current = false

    return () => {
      if (unmountedRun) handler()
      node.removeEventListener(name, handler)
    }
  })
}

export default useEvent
