const moment = require("moment")

export const groupMessagesByDateAndAuthor = (messages) => {
  const groupedMessages = messages.reduce((acc, message) => {
    const messageDate = new Date(message.created_at).toISOString().split("T")[0]

    if (!acc[messageDate]) {
      acc[messageDate] = []
    }
    acc[messageDate].push(message)

    return acc
  }, {})

  const result = []
  let previousMessageDate = null

  Object.keys(groupedMessages).forEach((date) => {
    const messagesForDay = groupedMessages[date]

    let currentAuthorMessages = []

    messagesForDay.forEach((message, index) => {
      const isFirstMessage = index === 0
      const isNewDay = date !== previousMessageDate

      const messageWithFlags = {
        ...message,
        isFirstMessageOfNewDay: isNewDay
      }

      previousMessageDate = date

      if (isFirstMessage || message.author.id !== messagesForDay[index - 1]?.author.id) {
        if (currentAuthorMessages.length > 0) {
          const firstMessage = currentAuthorMessages[0]
          result.push({
            ...firstMessage,
            nestedMessages: currentAuthorMessages.slice(1)
          })
          currentAuthorMessages = []
        }

        const groupedMessage = {
          ...messageWithFlags,
          nestedMessages: [],
          firstMessageOfTheDate: date
        }

        currentAuthorMessages.push(groupedMessage)
      } else {
        currentAuthorMessages.push(messageWithFlags)
      }
    })

    if (currentAuthorMessages.length > 0) {
      const firstMessage = currentAuthorMessages[0]
      result.push({
        ...firstMessage,
        nestedMessages: currentAuthorMessages.slice(1)
      })
    }
  })

  return result
}

export const normalizeSendDate = (dateString) => {
  const date = moment(dateString)
  const now = moment()

  if (now.diff(date, "days") >= 7) return date.format("MMM D")

  return date.format("dddd")
}

export const normalizeSendDateAndTime = (dateString) => {
  const date = moment(dateString)
  return date.format("M/D h:mm A")
}
