import React from "react"
import styles from "./MenuGroup.module.css"
import { MenuItem } from "./MenuItem"

export const MenuGroup = ({ index, menuItem }) => {
  const { groupName, items } = menuItem
  return (
    <div>
      <div className={styles.menuGroupHeader}>
        <div className={styles.menuGroupNumber}>{index + 1}</div>
        <div className={styles.menuGroupTitle}>{groupName}</div>
      </div>
      <div className={styles.menuGroupItems}>
        {items.map((item) => (
          <MenuItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}
