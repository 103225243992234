import React from "react"
import { useNavigate } from "react-router-dom"
import { routes } from "router"
import moment from "moment"
import { Text } from "ui/Typography"

import { useSelector } from "react-redux"
import { activeConversationSelector } from "store/conversations"
import { List } from "antd"
import { Avatar } from "ui/Avatar"

import styles from "./Conversation.module.css"

export const Conversation = ({ conversation }) => {
  const navigate = useNavigate()
  const role = useSelector((state) => state.user.role)
  const activeConversation = useSelector(activeConversationSelector)
  const active = activeConversation.id === conversation.id
  const isGuide = role === "guide_user"
  const url = isGuide
    ? routes.proMessagesRootPath({}, { conversation_id: conversation.id })
    : routes.clientMessagesPath({}, { conversation_id: conversation.id })
  const user = isGuide ? conversation.contact : conversation.client
  const participant = isGuide ? conversation.client : conversation.contact
  const lastMessage = conversation.last_message || {}
  const slicedMessage = conversation.last_message?.body?.slice(0, 100) || ""
  const isSlicedMessage = conversation.last_message?.body?.length > slicedMessage?.length

  if (!conversation.id) return null

  return (
    <List.Item className={styles.conversation} onClick={() => navigate(url)}>
      <List.Item.Meta
        avatar={<Avatar size={60} src={participant?.avatar} fullName={participant?.full_name} />}
        title={
          <div className="justify-between align-center">
            <Text variant="sub" size="lg">
              {participant?.display_name}
            </Text>
            <Text variant="caption" type="secondary">
              {moment.utc(conversation.updated_message_at).local().format("hh:mm A")}
            </Text>
          </div>
        }
        description={
          <div className="justify-between">
            <Text variant="body" type="secondary">
              {slicedMessage}
              {isSlicedMessage && "..."}
            </Text>
            {conversation.unviewed > 0 && !active && <div className={styles.unviewed}>{conversation.unviewed}</div>}
          </div>
        }
      />
    </List.Item>
  )
}
