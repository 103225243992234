import React from "react"
import ResetPasswordForm from "components/devise/ResetPasswordForm"

import { useTranslation } from "react-i18next"

export default function ResetPassword() {
  const { t } = useTranslation()
  return (
    <>
      <h1 className="h2 lh-1">{t("reset_password.title")}</h1>
      <p className="h3 fw-normal lh-sm mt-15 mb-40">{t("reset_password.instructions")}</p>
      <ResetPasswordForm />
    </>
  )
}
