import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Navigate, Outlet } from "react-router-dom"
import { oldRoutes } from "router/old-routes"

import { useDispatch, useSelector } from "react-redux"
import { cleanUser, getUser } from "store/user"

const PublicRoutes = ({ only = false }) => {
  const dispatch = useDispatch()
  const loggedIn = useSelector((state) => state.auth.loggedIn)
  const { id, role } = useSelector((store) => store.user)

  useEffect(() => {
    if (loggedIn && !id) dispatch(getUser())
    if (!loggedIn && id) dispatch(cleanUser())
    return () => !loggedIn && dispatch(cleanUser())
  }, [loggedIn, id]) //eslint-disable-line

  const REDIRECTS = {
    guide_user: oldRoutes.guideRootPath(),
    user: oldRoutes.clientRootPath()
  }

  return loggedIn && only ? <Navigate to={REDIRECTS[role]} replace /> : <Outlet />
}

PublicRoutes.propTypes = {
  only: PropTypes.bool
}

export default PublicRoutes
