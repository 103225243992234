import { Button } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import appleIcon from "assets/imagesV2/social/apple.svg"
import styles from "./AppleLoginButton.module.css"
import AppleSignInButton from "react-apple-signin-auth"
import { appleSignIn } from "store/auth"
import { useDispatch } from "react-redux"
import { routes } from "router"
import { useNavigate } from "react-router-dom"


export const AppleLoginButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSuccess = (response) => {
    const code = response.authorization?.id_token
    if (code) {
      dispatch(appleSignIn({ code })).then((res) => {
        navigate(routes.confirmInfo({},{...res.user, oauth_type: 'apple'}))
      })
    }
  }

  const onFail = (response) => {
    // implement error handling
    console.error(response)
  }

  return (
    <AppleSignInButton
      authOptions={{
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        scope: 'email name',
        redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URL,
        state: 'state',
        nonce: 'nonce',
        usePopup: true,
      }}
      onSuccess={onSuccess}
      onError={onFail}
      render={(props) => (
        <Button className={styles.appleLogin} block color="blank" variant="none" size="large" {...props}>
          <div className="align-center">
            <img className="mr-xs" src={appleIcon} alt={""} />
            {t("sign_in.apple_login")}
          </div>
        </Button>
      )}
    />
  )
}
