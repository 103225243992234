// ThemeProvider.js
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"

const ThemeProvider = ({ children }) => {
  const isSportsman = useSelector((state) => state.user.is_sportsman)

  useEffect(() => {
    if (!isSportsman) document.body.classList.add("theme-sportswoman")

    return () => {
      document.body.classList.remove("theme-sportswoman")
    }
  }, [isSportsman])

  return <>{children}</>
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default ThemeProvider
