import React, { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import Input from "components/form/Input"

import { isEqual } from "lodash"
import { TempusDominus, Namespace, extend } from "@eonasdan/tempus-dominus"
import {
  clampDate,
  formatInput,
  getDisplayFormat,
  getManipulationType,
  getPickerFormat,
  isSameDatetime,
  loadFaIcons,
  manipulateDate,
  momentFormatter,
  momentUnformatter,
  paintSelectedWeek,
  parseInput,
  updateManipulationButton
} from "modules/datepicker/helpers"

import { DATE_FORMAT, LOCALIZATION } from "modules/datepicker/constants"

extend(loadFaIcons)

const Datepicker = (
  {
    tag: Tag,
    wrapperTag: WrapperTag = React.Fragment,
    wrapperProps = {},
    incrementTag: IncrementTag,
    incrementProps = {},
    incrementRef: externalIncrementRef,
    decrementTag: DecrementTag,
    decrementProps = {},
    decrementRef: externalDecrementRef,
    type = "date",
    name,
    value,
    defaultValue,
    onChange,
    disabled = false,
    format: externalFormat = DATE_FORMAT,
    inputProps: { disabled: ipnutDisabled, ...restInputProps } = {},
    emptyValue,
    datepickerRef,
    ...rest
  },
  ref
) => {
  const unformatter = useCallback((value) => momentUnformatter(value, externalFormat), [externalFormat])
  const formatter = useCallback((value) => momentFormatter(value, externalFormat), [externalFormat])

  const containerRef = useRef(null)
  const pickerRef = useRef(null)
  const incrementRef = useRef(null)
  const decrementRef = useRef(null)

  const valueRef = useRef(null)
  const valueShouldUpdatedRef = useRef(false)
  const prevOptionsRef = useRef(false)
  const tabPressRef = useRef(false)

  const defaultDateTime = useMemo(() => unformatter(defaultValue), [defaultValue, unformatter])
  const formattedDefaultDateTime = defaultDateTime?.format?.("L LTS")

  const dateTime = useMemo(() => unformatter(value), [value, unformatter])
  const formattedDateTime = dateTime?.format?.("L LTS")

  const externalDateTime = useMemo(
    () => {
      valueShouldUpdatedRef.current = true
      return dateTime || defaultDateTime
    },
    [formattedDateTime, formattedDefaultDateTime] //eslint-disable-line
  )

  useImperativeHandle(ref, () => containerRef.current)
  useImperativeHandle(datepickerRef, () => ({
    getFormat: () => pickerRef.current?.optionsStore?.options?.meta?.format,
    getLastPicked: () => pickerRef.current?.dates?.lastPicked,
    setValue
  }))
  useImperativeHandle(externalIncrementRef, () => incrementRef.current)
  useImperativeHandle(externalDecrementRef, () => decrementRef.current)

  const hasCustomTag = Tag !== undefined
  const manipulationType = useMemo(() => getManipulationType(type), [type])
  const displayFormat = useMemo(() => getDisplayFormat(manipulationType), [manipulationType])
  const format = useMemo(() => (hasCustomTag ? displayFormat : getPickerFormat(type)), [hasCustomTag, displayFormat, type])

  const [internalDateTime, setInternalDateTime] = useState(externalDateTime || null)
  const internalValue = momentFormatter(internalDateTime, format, emptyValue !== undefined ? emptyValue : format)
  const displayIntenalValue = momentFormatter(internalDateTime, displayFormat, emptyValue !== undefined ? emptyValue : "—")

  const options = useMemo(
    () => ({
      useCurrent: false,
      ...rest,
      display: {
        buttons: {
          clear: true,
          today: true
        },
        keepOpen: true,
        ...rest.display,
        theme: "light",
        components: {
          calendar: "time" !== type,
          decades: true,
          year: true,
          month: "year" !== type,
          date: !["year", "month"].includes(type),
          clock: ["time", "datetime-local"].includes(type),
          hours: true,
          minutes: true,
          seconds: false
        }
      },
      localization: {
        ...LOCALIZATION,
        ...rest.localization
      },
      meta: { format, type, manipulationType }
    }),
    [type, format, manipulationType, rest]
  )

  const setDisabled = useCallback(() => {
    if (!pickerRef.current) return

    if (disabled) pickerRef.current.disable()
    else pickerRef.current.enable()
  }, [disabled])

  const validateDate = (date, updateInput = false) => {
    if (!pickerRef.current) return false

    const isValid = date ? pickerRef.current.dates.validation.isValid(date, "date") : true
    const input = pickerRef.current.optionsStore.input

    if (updateInput && input) input.classList.toggle("is-invalid", !isValid)
    return isValid
  }

  const setValue = useCallback((date, force = false) => {
    if (!pickerRef.current) return
    const { minDate, maxDate } = pickerRef.current.optionsStore.options.restrictions || {}
    const newDate = clampDate(minDate, date, maxDate)

    if (isSameDatetime(valueRef.current, newDate) && !force) return
    valueRef.current = newDate

    validateDate(newDate, true)
    pickerRef.current.dates.setValue(newDate, pickerRef.current.dates.lastPickedIndex)
  }, [])

  const changeHandler = useCallback(
    ({ date, isClear }) => {
      const newDate = isClear ? null : date
      setInternalDateTime(newDate)

      if (isSameDatetime(valueRef.current, newDate) && !valueShouldUpdatedRef.current) return
      if (valueShouldUpdatedRef.current) valueShouldUpdatedRef.current = false
      valueRef.current = newDate

      if (isClear) pickerRef.current.dates.updateInput("")
      if (typeof onChange === "function") {
        const format = pickerRef.current.optionsStore.options.meta.format
        const value = formatter(newDate)
        const valid = validateDate(newDate)

        onChange({ target: { name, value, date: newDate, valid, format } })
      }
    },
    [name, formatter, onChange]
  )

  const inputChangeHandler = useCallback(({ target }) => {
    const date = pickerRef.current.dates.parseInput(target.value)
    validateDate(date, true)

    if (target.value) pickerRef.current.viewDate = date
    else pickerRef.current.viewDate = global.dateTime()
  }, [])

  const manipulationButtonClickHandler =
    (direction = 0) =>
    () => {
      if (!pickerRef.current) return

      const date = manipulateDate(pickerRef.current, direction)
      if (!date) return

      valueShouldUpdatedRef.current = true
      setValue(date, true)
    }

  useEffect(() => {
    const container = containerRef.current
    const decrement = decrementRef.current
    const increment = incrementRef.current
    if (!container) return

    const picker = new TempusDominus(container, options)

    picker.display.paint = paintSelectedWeek(picker)

    picker.dates.formatInput = formatInput
    picker.dates.parseInput = parseInput

    const subscription = picker.subscribe(Namespace.events.change, changeHandler)
    container.addEventListener("change", inputChangeHandler)
    decrement?.addEventListener("click", manipulationButtonClickHandler(-1))
    increment?.addEventListener("click", manipulationButtonClickHandler(1))

    window.pickerRef = picker
    pickerRef.current = picker

    setDisabled()

    console.log("Datepicker inited!")
    return () => {
      subscription.unsubscribe()
      container.removeEventListener("change", inputChangeHandler)
      decrement?.removeEventListener("click", manipulationButtonClickHandler(-1))
      increment?.removeEventListener("click", manipulationButtonClickHandler(1))
      picker.dispose()
    }
  }, [inputChangeHandler, changeHandler]) //eslint-disable-line

  useEffect(() => {
    if (!pickerRef.current) return

    const { minDate, maxDate } = pickerRef.current.optionsStore.options.restrictions || {}
    const { manipulationType } = pickerRef.current.optionsStore.options.meta || {}

    if (decrementRef.current) updateManipulationButton(decrementRef.current, internalDateTime, -1, minDate, manipulationType)
    if (incrementRef.current) updateManipulationButton(incrementRef.current, internalDateTime, 1, maxDate, manipulationType)
  }, [internalDateTime])

  useEffect(() => {
    setValue(externalDateTime)
  }, [externalDateTime, setValue])

  useEffect(() => {
    setDisabled()
  }, [setDisabled])

  useEffect(() => {
    if (!pickerRef.current) return

    const isSame = isEqual(options, prevOptionsRef.current)
    if (isSame) return

    prevOptionsRef.current = options
    pickerRef.current.updateOptions(options)
    setInternalDateTime(pickerRef.current.dates.lastPicked)
  }, [options])

  const keyDownHandler = (e) => {
    if (e.key === "Tab") tabPressRef.current = true
    if (e.key === " ") e.preventDefault() || pickerRef.current.toggle()
  }
  const keyUpHandler = (e) => e.key === "Tab" && (tabPressRef.current = false)
  const clickHandler = (e) => e.preventDefault() || pickerRef.current.show()
  const focusHandler = (e) => e.preventDefault() || pickerRef.current.show()
  const blurHandler = () => tabPressRef.current && pickerRef.current.hide()

  const additionalProps = {}
  if (Tag === "button") additionalProps.type = "button"
  if (typeof Tag === "string") !rest.display?.inline && Object.assign(additionalProps, { children: internalDateTime })
  else
    Object.assign(additionalProps, {
      date: internalDateTime,
      value: internalValue,
      displayValue: displayIntenalValue,
      valid: validateDate(internalDateTime)
    })

  return (
    <WrapperTag {...(WrapperTag !== React.Fragment && wrapperProps)}>
      {DecrementTag && <DecrementTag {...decrementProps} ref={decrementRef} />}
      {(Tag !== undefined && (
        <Tag
          {...restInputProps}
          {...additionalProps}
          onKeyDown={keyDownHandler}
          onKeyUp={keyUpHandler}
          onClick={clickHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
          ref={containerRef}
        />
      )) || (
        <Input
          {...restInputProps}
          name={name}
          type={window.innerWidth < 992 ? "text" : type}
          onKeyDown={keyDownHandler}
          onKeyUp={keyUpHandler}
          onClick={clickHandler}
          onFocus={focusHandler}
          onBlur={blurHandler}
          readOnly={window.innerWidth < 992 ? "readonly" : false}
          ref={containerRef}
        />
      )}
      {IncrementTag && <IncrementTag {...incrementProps} ref={incrementRef} />}
    </WrapperTag>
  )
}

Datepicker.propTypes = {
  tag: PropTypes.elementType,
  wrapperTag: PropTypes.elementType,
  wrapperProps: PropTypes.object,
  incrementTag: PropTypes.elementType.isRequired,
  incrementProps: PropTypes.object,
  incrementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  decrementTag: PropTypes.elementType.isRequired,
  decrementProps: PropTypes.object,
  decrementRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  inputProps: PropTypes.object,
  emptyValue: PropTypes.string,
  datepickerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
}

export default memo(forwardRef(Datepicker))
