import classNames from "classnames"
import { Icon } from "ui/Icon"
import styles from "./BreadCrumbs.module.css"

export const BreadCrumbs = ({ className, iconName, category, subPages = [] }) => {
  return (
    <div className={classNames(className, styles.wrapper)}>
      <div className={styles.categoryWrapper}>
        <Icon name={iconName} className={styles.icon} color="currentColor" />
        <div>{category}</div>
      </div>
      {subPages.map((subPage, index) => {
        return (
          <div key={index} className={styles.subPages}>
            <div>·</div> <div>{subPage}</div>
          </div>
        )
      })}
    </div>
  )
}
