import { baseTheme } from "./base"
import { colors } from "./variables"

export const sportsmanTheme = {
  ...baseTheme,
  token: {
    ...baseTheme.token,
    colorPrimary: colors.sportsmanBrand,
    colorInfo: colors.sportsmanBrand,
    colorLink: colors.sportsmanBrand,
    Button: {
      ...baseTheme.token.Button,
      linkColor: colors.sportsmanBrand
    },
    Input: {
      ...baseTheme.token.Input,
      activeBorderColor: colors.sportsmanBrand,
      hoverBorderColor: colors.sportsmanBrand
    },
    Radio: {
      ...baseTheme.token.Radio,
      buttonCheckedBg: colors.blueSurface
    },
    Progress: {
      ...baseTheme.token.Progress,
      colorSuccess: colors.sportsmanBrand
    }
  }
}
