import { createAction, createReducer } from "@reduxjs/toolkit"

// ACTIONS
const sizeUpdated = createAction("window/sizeUpdated")

const initialState = {
  breakpoint: "",
  width: 0,
  height: 0,
  headerHeight: 0
}

// REDUCER
const windowReducer = createReducer(initialState, {
  [sizeUpdated.type]: (state, action) => {
    Object.assign(state, action.payload)
  }
})

export default windowReducer

export const updateWindowSize = (data) => (data.breakpoint && console.log("sizeUpdated:", data)) || sizeUpdated(data)
