import React from "react"
import { Modal as AntdModal } from "antd"

export const Modal = ({ open, onCancel, children, footer = null, width = 1022, style, ...rest }) => {
  return (
    <AntdModal open={open} onCancel={onCancel} footer={footer} width={width} style={style} {...rest}>
      {children}
    </AntdModal>
  )
}
