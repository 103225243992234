import useEvent from "hooks/useEvent"

import { useDispatch, useSelector } from "react-redux"
import { updateWindowSize } from "../reducer"

import { BREAKPOINTS, BREAKPOINT_NAMES } from "../constants"

export const useBreakpoints = () => {
  const windowBreakpoint = useSelector((store) => store.window.breakpoint)
  const dispatch = useDispatch()

  const onResize = () => {
    const breakpoint = [...BREAKPOINT_NAMES].reverse().find((key) => window.innerWidth >= BREAKPOINTS[key])
    const width = window.innerWidth
    const height = window.innerHeight
    const isBreakpointChanged = windowBreakpoint !== breakpoint

    dispatch(updateWindowSize({ ...(isBreakpointChanged && { breakpoint }), width, height }))
  }

  useEvent("resize", onResize, true, window, true)

  return windowBreakpoint
}
