import React from "react"
import PropTypes from "prop-types"
import { ErrorBoundary } from "react-error-boundary"
import ErrorBoundaryFallback from "./ErrorBoundaryFallback"

const DefaultErrorBoundary = (props) => (
  <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} {...props}>
    {props.children}
  </ErrorBoundary>
)

DefaultErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}
export default DefaultErrorBoundary
