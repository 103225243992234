import React from "react"
import { Form, Input, Button } from "antd"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { Title } from "ui/Typography"
import { resetPassword } from "store/password"
import { routes } from "router"

export const CreatePassword = () => {
  const { t } = useTranslation()
  const { loading } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const reset_password_token = useSelector((state) => state.password?.data?.reset_password_token)

  const onFinish = ({ password }) => {
    dispatch(
      resetPassword({
        password,
        password_confirmation: password,
        reset_password_token
      })
    ).then(() => navigate(routes.passwordRecoverySuccess()))
  }

  return (
    <>
      <Title className="justify-center mb-3xl">{t("create_password.title")}</Title>
      <Form name="login" initialValues={{ remember: true }} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("create_password.label")}
          name="password"
          required={false}
          rules={[
            { required: true, message: t("form_validation.password_required") },
            { min: 8, message: t("form_validation.password_length") }
          ]}
        >
          <Input.Password placeholder={t("create_password.placeholder")} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block size="large" disabled={loading}>
            {t("create_password.save_password")}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
