import React, { useEffect } from "react"
import { Container } from "reactstrap"
import { useParams } from "react-router-dom"
import Preview from "components/trips/Preview"
import Spinner from "components/common/Spinner"

import { useTitle } from "hooks/useTitle"

import { useDispatch, useSelector } from "react-redux"
import { cleanBooking, getBooking } from "store/bookings"
import { cleanTrip, receivedTrip } from "store/trips"

export default function BookingTrip() {
  const params = useParams()
  const dispatch = useDispatch()
  const { booking } = useSelector((store) => store.bookings)
  const { trip, loading } = booking
  useTitle(trip?.title)

  useEffect(() => {
    dispatch(getBooking(params.uuid)).then(({ trip }) => dispatch(receivedTrip(trip)))
    return () => dispatch(cleanBooking()) && dispatch(cleanTrip())
  }, [params.uuid]) //eslint-disable-line

  if (loading) return <Spinner />

  return (
    <div className="bg-light">
      <Container className="py-50">{booking.id && <Preview />}</Container>
    </div>
  )
}
