import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper/modules"
import { Modal, ModalBody } from "reactstrap"
import ReactPlayer from "react-player"
import Icon from "components/common/Icon"

import { useDispatch, useSelector } from "react-redux"
import { setActiveSlide } from "store/lightbox"
import ModalCloseButton from "components/common/ModalCloseButton"

function Lightbox() {
  const dispatch = useDispatch()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const activeSlide = useSelector((store) => store.lightbox.activeSlide)
  const slides = useSelector((store) => store.lightbox.slides)
  const isOpen = activeSlide !== null && activeSlide >= 0
  const [swiper, setSwiper] = useState(null)
  const currentSlideType = slides[activeSlide]?.type

  const closeHandler = () => dispatch(setActiveSlide(null))
  const activeIndexChangeHandler = ({ activeIndex }) => dispatch(setActiveSlide(activeIndex))
  const onInit = (swiper) => {
    swiper.params.navigation.prevEl = prevRef.current
    swiper.params.navigation.nextEl = nextRef.current
    swiper.navigation.init()
    swiper.navigation.update()
  }

  useEffect(() => {
    if (swiper && !swiper.destroyed && typeof activeSlide === "number") swiper.slideTo(activeSlide)
  }, [activeSlide, swiper])

  if (!slides?.length) return null

  return (
    <Modal isOpen={isOpen} toggle={closeHandler} centered fullscreen contentClassName="bg-transparent rounded-sm-3 pointer-events-none">
      <ModalBody className="vh-100 p-0">
        <ModalCloseButton toggle={closeHandler} className="m-3 mt-5 z-2 pointer-events-auto" />
        <Swiper
          onInit={onInit}
          onSwiper={setSwiper}
          onActiveIndexChange={activeIndexChangeHandler}
          centeredSlides
          slidesPerView={1}
          modules={[Navigation]}
          className="h-100 pointer-events-none"
        >
          {slides.map(({ type, userId, ...rest }, i) => (
            <SwiperSlide
              index={i}
              key={`swiper-slide-${userId}`}
              className="hstack align-items-center justify-content-center p-1 p-sm-50 lightbox-media"
            >
              {type === "image" && <LightboxImage {...rest} />}
              {type === "video" && <LightboxVideo {...rest} />}
            </SwiperSlide>
          ))}
          <div ref={prevRef} className={`swiper-button-prev ${currentSlideType === "story" ? "d-none d-sm-flex" : ""}`}>
            <Icon iconName="Dropdown" width={40} height={40} className="text-white rotate-90" />
          </div>
          <div ref={nextRef} className={`swiper-button-next ${currentSlideType === "story" ? "d-none d-sm-flex" : ""}`}>
            <Icon iconName="Dropdown" width={40} height={40} className="text-white rotate-270" />
          </div>
        </Swiper>
      </ModalBody>
    </Modal>
  )
}

export function LightboxImage({ url, alt, ...rest }) {
  return <img {...rest} src={url} className="mw-100 mh-100 rounded" alt={alt} />
}

LightboxImage.propTypes = {
  url: PropTypes.string.isRequired,
  alt: PropTypes.string
}

export function LightboxVideo({ url, poster }) {
  return (
    <ReactPlayer
      url={url}
      poster={poster}
      className={`react-player rounded object-fit-cover overflow-hidden mw-100 mh-100 w-auto h-auto`}
      controls
      playing
    />
  )
}

LightboxVideo.propTypes = {
  url: PropTypes.string.isRequired,
  poster: PropTypes.string
}

export default memo(Lightbox)
