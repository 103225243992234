import { createAction, createReducer } from "@reduxjs/toolkit"
import { merge } from "lodash"

const show = createAction("modals/shownModal")
const update = createAction("modals/updatedModal")
const hide = createAction("modals/hiddenModal")
const reset = createAction("modals/resetedModal")

const initialState = {
  // modal: { isOpen: false }
}

const modalsReducer = createReducer(initialState, {
  [show.type]: (state, action) => {
    state[action.payload.name] = {}
    Object.assign(state[action.payload.name], {
      ...action.payload.options,
      isOpen: true
    })
  },
  [update.type]: (state, action) => {
    merge(state[action.payload.name], action.payload.options)
  },
  [hide.type]: (state, action) => {
    Object.assign(state[action.payload] || {}, { isOpen: false })
  },
  [reset.type]: (state, action) => {
    state[action.payload] = initialState[action.payload]
  }
})

export default modalsReducer

export const showModal = (name, options = null) => show({ name, options })
export const updateModal = (name, options = null) => update({ name, options })

export { hide as hideModal, reset as resetModal }
