import React from "react"
import { Form } from "antd"
import { useTranslation } from "react-i18next"
import { Text } from "ui/Typography"
import { PhoneInput } from "ui/Input"
import { CustomInputError } from "ui/CustomInputError"

export const PhonePasswordRecovery = ({ errors }) => {
  const { t } = useTranslation()
  return (
    <>
      <Text type="sub" color="primary" className="justify-center mb-m">
        {t("password_recovery.phone_recovery_info")}
      </Text>
      <Form.Item
        label={t("password_recovery.phone")}
        name="phone_number"
        required={false}
        rules={[{ required: true, message: t("form_validation.phone_required") }]}
        help={!!errors ? <CustomInputError errorKey={errors} /> : null}
      >
        <PhoneInput placeholder={t("password_recovery.email_placeholder")} />
      </Form.Item>
    </>
  )
}
