import { useEffect } from "react"

import { useDispatch } from "react-redux"
import { addLoaderWatcher, removeLoaderWatcher } from "modules/loader-watchers/reducer"

function useLoaderWatchers({ watchers, container }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addLoaderWatcher({ watchers, container }))
    return () => dispatch(removeLoaderWatcher({ watchers, container }))
  }, []) //eslint-disable-line
}

export default useLoaderWatchers
