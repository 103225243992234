import React, { memo, useMemo } from "react"
import PropTypes from "prop-types"
import { Label, TabPane } from "reactstrap"
import Input from "components/form/Input"
import DatepickerInput from "components/form/DatepickerInput"

import { useTranslation } from "react-i18next"
import { filter, map, pull } from "lodash"

import { useSelector } from "react-redux"

const DateComp = ({ form, changeHandler }) => {
  const { t } = useTranslation()
  const { unavailable_days } = useSelector((state) => state.dashboard)
  const unavailableDates = useMemo(() => filter(unavailable_days, ["unavailable_type", "date"]), [unavailable_days])
  const disabledDates = useMemo(
    () => pull(map(unavailableDates, "unavailable_date"), form.unavailable_date).map((d) => global.dateTime(d)),
    [unavailableDates, form.unavailable_date]
  )

  return (
    <TabPane tabId="date">
      <Label className="fs-6">{t("unavailable_day.labels.date")}</Label>
      <div className="mb-20">
        <DatepickerInput
          type="date"
          name="unavailable_date"
          value={form.unavailable_date}
          onChange={changeHandler}
          restrictions={{ disabledDates }}
          labelClassName="fs-6 fw-normal text-dark text-opacity-50 text-uppercase"
          inputClassName="fs-6"
          noIcon
        />
      </div>
      <Label check className="hstack gap-10 fs-7 fw-normal">
        <Input
          type="checkbox"
          name="recurring_yearly"
          checked={[true, "true"].includes(form.recurring_yearly)}
          onChange={changeHandler}
          className="mt-0"
        />
        <span className={`text-dark ${[true, "true"].includes(form.recurring_yearly) ? "" : "text-opacity-50"}`}>
          {t(`unavailable_day.labels.recurring_yearly`)}
        </span>
      </Label>
    </TabPane>
  )
}

DateComp.propTypes = {
  form: PropTypes.shape({
    unavailable_date: PropTypes.string,
    recurring_yearly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  }).isRequired,
  changeHandler: PropTypes.func.isRequired
}
export default memo(DateComp)
