import React from "react"
import PropTypes from "prop-types"
import DynamicTag from "components/DynamicTag"

import { useTranslation } from "react-i18next"
import pluralize from "pluralize"

WithEmptyState.propTypes = {
  name: PropTypes.string.isRequired,
  tag: PropTypes.string,
  emptyProps: PropTypes.object,
  hasItems: PropTypes.bool,
  children: PropTypes.node,
  fallback: PropTypes.node,
  fallbackTitle: PropTypes.string,
  fallbackText: PropTypes.string
}

WithEmptyState.defaultProps = {
  tag: "div",
  emptyProps: {},
  hasItems: false,
  fallback: null,
  fallbackTitle: undefined,
  fallbackText: undefined
}

export default function WithEmptyState({
  name,
  tag = "div",
  emptyProps = {},
  hasItems = false,
  children,
  fallback,
  fallbackTitle,
  fallbackText,
  ...rest
}) {
  const { t } = useTranslation()

  const emptyClasses = ["bg-white p-20 rounded"]
  if (emptyProps.className?.match(/p-/g)) emptyClasses[0] = emptyClasses[0].replace("p-20", "")
  if (emptyProps.className) emptyClasses.push(emptyProps.className)

  return (
    <DynamicTag {...rest} tag={tag || React.Fragment}>
      {hasItems
        ? children
        : fallback || (
            <div className={emptyClasses.join(" ")}>
              <h2 className="h3 lh-sm">{fallbackTitle ?? t("empty_state.title", { name: pluralize(name) })}</h2>
              <p className="text-dark text-opacity-50 mb-0">{fallbackText ?? t("empty_state.text", { name })}</p>
            </div>
          )}
    </DynamicTag>
  )
}
