import { Flex } from "antd"
import styles from "./SettingsTitle.module.css"
import { Paragraph } from "ui/Typography"
import { spacings } from "themes/variables"

export const SettingsTitle = ({ title, subTitle }) => {
  return (
    <Flex vertical gap={spacings.M}>
      <div className={styles.title}>{title}</div>
      <Paragraph size="md">{subTitle}</Paragraph>
    </Flex>
  )
}
