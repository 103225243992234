import React from "react"
import { useSearchParams } from "react-router-dom"
import { Layout, Typography } from "antd"
import { ChatHistory } from "./ChatHistory"
import { ConversationList } from "./ConversationList"

const { Content } = Layout
const { Title } = Typography

export const Messages = () => {
  const [searchParams] = useSearchParams()
  const selectedConversationId = +searchParams.get("conversation_id") || null

  return (
    <div className="flex-column flex-grow-1">
      <Layout>
        <ConversationList selectedConversationId={selectedConversationId} />
        <Layout style={{ flex: 1 }}>
          {selectedConversationId ? (
            <ChatHistory selectedConversationId={selectedConversationId} />
          ) : (
            <Content style={{ textAlign: "center", padding: "20px" }}>
              <Title level={3}>Select a conversation to start chatting</Title>
            </Content>
          )}
        </Layout>
      </Layout>
    </div>
  )
}
