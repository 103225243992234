import React, { useCallback, useEffect } from "react"
import { Container } from "reactstrap"
import Post from "components/posts/Post"
import WithEmptyState from "components/common/WithEmptyState"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"
import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"
import { Waypoint } from "react-waypoint"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanPosts, getClientGuidePosts } from "store/posts"
import { collectionAnySelector, collectionSelector, metadataSelector, modelSelector } from "store/selectors"

export default function Feeder() {
  useLoaderWatchers({ watchers: "posts" })
  const dispatch = useDispatch()
  const guide = useSelector(modelSelector("guide"))
  const posts = useSelector(collectionSelector("posts"))
  const metadata = useSelector(metadataSelector("posts"))
  const hasPosts = useSelector(collectionAnySelector("posts"))
  useTitle(`Feeder | ${guide.display_name}`)

  const fetchPosts = useCallback((nextPage = false) => dispatch(getClientGuidePosts(guide.slug, nextPage)), [guide.slug, dispatch])

  useEffect(() => {
    fetchPosts()
    return () => dispatch(cleanPosts())
  }, []) //eslint-disable-line

  return (
    <Container className="vstack gap-30">
      <FirstLoading name="posts">
        <WithEmptyState name="post" hasItems={hasPosts}>
          {posts.map((post) => (
            <Post post={post} key={post.id} />
          ))}
          {!isLastPage(metadata) && <Waypoint onEnter={() => fetchPosts(true)} />}
        </WithEmptyState>
      </FirstLoading>
    </Container>
  )
}
