import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import Icon from "components/common/Icon"

import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"
import { oldRoutes } from "router/old-routes"

export default function ResetPasswordRequestSent() {
  useTitle("Reset Password — Request Sent")
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const loginUrl = searchParams.get("login_url") || oldRoutes.signInPath()

  return (
    <>
      <h1 className="h2 lh-1">{t("reset_password.request_sent")}</h1>
      <p className="h3 fw-normal lh-sm mt-15">{t("reset_password.request_sent_instructions")}</p>
      <div className="fs-6 fw-medium mt-30">
        <div className="hstack d-inline-flex gap-1 small link-target">
          <Icon iconName="ArrowLeft" width={18} height={10} />
          Back to{" "}
          <Link to={loginUrl} className="text-primary-hover text-decoration-none">
            Login
          </Link>
        </div>
      </div>
    </>
  )
}
