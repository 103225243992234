import { useEffect, useRef } from "react"
import { toaster } from "components/common/Toast"
import { useJsApiLoader } from "@react-google-maps/api"

import { API_OPTIONS } from "constants/map"

export default function useGeocoder() {
  const { isLoaded } = useJsApiLoader(API_OPTIONS)
  const geocoderRef = useRef(null)

  const geocoder = (options, cb) => {
    geocoderRef.current.geocode(options, (result, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        if (!result.length) return cb(null)
        const [place] = result
        if (typeof cb === "function") cb(place)
      } else {
        toaster.error("Geocode was not successful for the following reason: " + status)
      }
    })
  }

  useEffect(() => {
    if (isLoaded && !geocoderRef.current) geocoderRef.current = new window.google.maps.Geocoder()
  }, [isLoaded])

  return geocoder
}
