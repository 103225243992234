import React from "react"

export const FAQ = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_478_16791)">
      <path
        d="M12 0.959961C5.90842 0.959961 0.959961 5.90842 0.959961 12C0.959961 18.0915 5.90842 23.04 12 23.04C18.0915 23.04 23.04 18.0915 23.04 12C23.04 5.90842 18.0915 0.959961 12 0.959961ZM12 1.91996C17.5727 1.91996 22.08 6.42725 22.08 12C22.08 17.5727 17.5727 22.08 12 22.08C6.42725 22.08 1.91996 17.5727 1.91996 12C1.91996 6.42725 6.42725 1.91996 12 1.91996ZM12.1387 6.00371C10.3483 6.00371 9.16118 7.08857 8.90246 8.74121C8.88566 8.84489 8.93659 8.91331 9.04027 8.93059L10.125 9.1209C10.2286 9.13818 10.2971 9.08629 10.3143 8.98309C10.5207 7.93285 11.1402 7.34715 12.104 7.34715C13.0856 7.34715 13.7737 7.96728 13.7737 8.9484C13.7737 9.534 13.5675 9.92982 12.9656 10.7559L11.8115 12.3393C11.4501 12.839 11.2959 13.2007 11.2959 13.89V14.595C11.2959 14.6982 11.3643 14.7675 11.4675 14.7656H12.6037C12.7069 14.7656 12.7753 14.6972 12.7753 14.594V14.0437C12.7753 13.4586 12.8792 13.2174 13.2234 12.7528L14.3765 11.1684C14.9621 10.3591 15.254 9.75715 15.254 8.93059C15.254 7.22611 13.9973 6.00371 12.1387 6.00371ZM11.3821 16.0762C11.2785 16.0762 11.2096 16.1455 11.2096 16.2487V17.729C11.2096 17.8322 11.278 17.9006 11.3821 17.9006H12.6909C12.7936 17.9006 12.8625 17.8327 12.8625 17.729V16.2487C12.8625 16.146 12.7941 16.0762 12.6909 16.0762H11.3821Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_478_16791">
        <rect width={width} height={height} fill="none" />
      </clipPath>
    </defs>
  </svg>
)
