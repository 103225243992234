import React from "react"
import { Form, Input } from "antd"
import { Text } from "ui/Typography"
import { useTranslation } from "react-i18next"
import { CustomInputError } from "ui/CustomInputError"

export const EmailPasswordRecovery = ({ errors }) => {
  const { t } = useTranslation()
  return (
    <>
      <Text type="sub" color="primary" className="justify-center mb-m">
        {t("password_recovery.email_recovery_info")}
      </Text>
      <Form.Item
        label={t("sign_in.email")}
        name="email"
        required={false}
        rules={[
          { required: true, message: t("form_validation.email_required") },
          { type: "email", message: t("form_validation.email") }
        ]}
        help={!!errors ? <CustomInputError errorKey={errors} /> : null}
      >
        <Input placeholder={t("global.email_placeholder")} />
      </Form.Item>
    </>
  )
}
