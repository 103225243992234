import React, { memo } from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"
import WizardActionButtons from "components/wizards/ActionButtons"

import { useTranslation } from "react-i18next"

import { useSelector } from "react-redux"
import { isGuideCompletedSelector, updateGuide } from "store/user"

ActionButtons.propTypes = {
  isChanged: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired
}

function ActionButtons({ isChanged, onReset: resetHandler, ...rest }) {
  const { t } = useTranslation()
  const wizardCompleted = useSelector(isGuideCompletedSelector)

  return wizardCompleted ? (
    <div className="hstack justify-content-end gap-20 mt-20">
      {isChanged && (
        <Button color="light" type="button" onClick={resetHandler}>
          {t("global.cancel")}
        </Button>
      )}
      <Button color="primary" disabled={!isChanged}>
        {t("settings.update")}
      </Button>
    </div>
  ) : (
    <WizardActionButtons isChanged={isChanged} action={updateGuide} name="user.guide" className="mt-50" {...rest} />
  )
}

export default memo(ActionButtons)
