import { dynamicRoute } from "./routes"

export const ROUTES_LIST = {
  rootPath: "/old/",
  becomeAGuidePath: "/old/become-a-guide",
  faqPath: "/old/faq",
  termsAndConditionsPath: "/old/terms-and-conditions",
  privacyPolicyPath: "/old/privacy-policy",
  tripsPath: "/old/trips",
  tripPath: "/old/trips/:id",
  referralPath: "/old/referral",
  salesPath: "/old/sales",

  // Devise routes
  signInPath: "/old/sign-in",
  signUpPath: "/old/sign-up",
  signUpRequestSentPath: "/old/sign-up/request-sent",
  forgotPasswordPath: "/old/forgot-password",
  resetPasswordRequestSentPath: "/old/reset-password/request-sent",
  resetPasswordPath: "/old/reset-password",
  resetPasswordSuccessPath: "/old/reset-password/success",
  verifyAccountPath: "/old/verify-account",
  verifyCodePath: "/old/verify-code",
  splashPath: "/old/splash",

  // Booking routes
  bookingWizardPath: "/old/booking_trips/wizard",

  guestBookingWizardRootPath: "/old/guest/booking_trips/:uuid/wizard",
  guestBookingWizardStep1Path: "/old/guest/booking_trips/:uuid/wizard/booking",
  guestBookingWizardStep2Path: "/old/guest/booking_trips/:uuid/wizard/accessibility",
  guestBookingWizardStep3Path: "/old/guest/booking_trips/:uuid/wizard/payment",
  guestBookingWizardStep4Path: "/old/guest/booking_trips/:uuid/wizard/confirmation",

  // Client routes
  clientRootPath: "/old/client",
  clientFavoritesPath: "/old/client/favorites",
  clientMessagesPath: "/old/client/messages",
  clientProfileRootPath: "/old/client/profile/:id",
  clientSettingsRootPath: "/old/client/settings",
  clientSettingsPaymentPath: "/old/client/settings/payment",
  clientSettingsPasswordPath: "/old/client/settings/password",
  clientSettingsNotificationsPath: "/old/client/settings/notifications",

  clientBookingsPath: "/old/client/booking_trips",
  clientBookingsPastTripsPath: "/old/client/booking_trips/past",
  clientBookingPath: "/old/client/booking_trips/:uuid",
  clientBookingWizardRootPath: "/old/client/booking_trips/:uuid/wizard",
  clientBookingWizardStep1Path: "/old/client/booking_trips/:uuid/wizard/booking",
  clientBookingWizardStep2Path: "/old/client/booking_trips/:uuid/wizard/accessibility",
  clientBookingWizardStep3Path: "/old/client/booking_trips/:uuid/wizard/payment",
  clientBookingWizardStep4Path: "/old/client/booking_trips/:uuid/wizard/confirmation",

  publicGuidePath: "/old/guides/:slug",
  publicGuideTripsPath: "/old/guides/:slug/trips",
  publicGuideLocationPath: "/old/guides/:slug/location",
  publicGuideBoatsPath: "/old/guides/:slug/boats",
  publicGuidePostsPath: "/old/guides/:slug/posts",
  publicGuideReviewsPath: "/old/guides/:slug/reviews",

  // Guide routes
  guideRootPath: "/old/guide",
  guideDashboardPath: "/old/guide/dashboard",
  guideDashboardBookingNewPath: "/old/guide/dashboard/bookings/new",
  guideDashboardBookingEditPath: "/old/guide/dashboard/bookings/:id/edit",
  guideDashboardJournalNewPath: "/old/guide/dashboard/journal_notes/new",
  guideDashboardJournalEditPath: "/old/guide/dashboard/journal_notes/:id/edit",

  guideDashboardUnavailableDayManagePath: "/old/guide/dashboard/unavailable_days/manage",

  guideWizardRootPath: "/old/guide/wizard",
  guideWizardStep1Path: "/old/guide/wizard/choose-your-activities",
  guideWizardStep2Path: "/old/guide/wizard/your-profile",
  guideWizardStep3Path: "/old/guide/wizard/photos",
  guideWizardStep4Path: "/old/guide/wizard/extras",
  guideWizardStep5Path: "/old/guide/wizard/documents",
  guideWizardStep6Path: "/old/guide/wizard/submit",

  guideBookingsPath: "/old/guide/bookings",
  guideBookingNewPath: "/old/guide/bookings/new",
  guideBookingEditPath: "/old/guide/bookings/:id/edit",

  guideClientsPath: "/old/guide/clients",
  guideClientNewPath: "/old/guide/clients/new",
  guideClientPath: "/old/guide/clients/:id",
  guideClientEditPath: "/old/guide/clients/:id/edit",
  guideClientEmailActionPath: "/old/guide/clients/email_action",
  guideClientTextActionPath: "/old/guide/clients/text_action",
  guideClientMessageActionPath: "/old/guide/clients/message_action",

  guideTripsPath: "/old/guide/trips",
  guideTripPath: "/old/guide/trips/:id",
  guideTripWizardRootPath: "/old/guide/trips/:id/wizard",
  guideTripWizardStep1Path: "/old/guide/trips/:id/wizard/trip-details",
  guideTripWizardStep2Path: "/old/guide/trips/:id/wizard/photos",
  guideTripWizardStep3Path: "/old/guide/trips/:id/wizard/boats",
  guideTripWizardStep3AddNewBoatPath: "/old/guide/trips/:id/wizard/boats/new",
  guideTripWizardStep4Path: "/old/guide/trips/:id/wizard/location",
  guideTripWizardStep5Path: "/old/guide/trips/:id/wizard/preview",

  guideMessagesPath: "/old/guide/messages",
  guideAccountingPath: "/old/guide/accounting",
  guideAccountingAddExpancePath: "/old/guide/accounting/new",
  guideAccountingEditExpancePath: "/old/guide/accounting/:id/edit",

  guideBoatsPath: "/old/guide/boats",
  guideAddNewBoatPath: "/old/guide/boats/new",
  guideEditBoatPath: "/old/guide/boats/:id/edit",

  guideMarketingPath: "/old/guide/marketing",
  guideMarketingPostNewPath: "/old/guide/marketing/new",
  guideMarketingPostEditPath: "/old/guide/marketing/:id/edit",
  guideMarketingTemplatesPath: "/old/guide/marketing/templates",
  guideMarketingTemplatesParentsPath: "/old/guide/marketing/templates/parents",
  guideMarketingTemplateNewPath: "/old/guide/marketing/templates/new",
  guideMarketingTemplateEditPath: "/old/guide/marketing/templates/:id/edit",

  guideSettingsRootPath: "/old/guide/settings",
  guideSettingsPhotosPath: "/old/guide/settings/photos",
  guideSettingsExtrasPath: "/old/guide/settings/extras",
  guideSettingsDocumentsPath: "/old/guide/settings/documents",
  guideSettingsStripePath: "/old/guide/settings/stripe",
  guideSettingsSocialsPath: "/old/guide/settings/social-networks",
  guideSettingsPasswordPath: "/old/guide/settings/password",
  guideSettingsNotificationsPath: "/old/guide/settings/notifications",

  guideWeatherPath: "/old/guide/weather",

  guidePostPath: "/old/guide/posts/:id",
  guideProfileRootPath: "/old/guide/profile/:id",
  guideProfileContactsPath: "/old/guide/profile/:id/contacts",
  guideProfileMediaPath: "/old/guide/profile/:id/media",
  guideProfileTaggedPath: "/old/guide/profile/:id/tagged",
  guideProfileSettingsPath: "/old/guide/profile/:id/settings",
  guideContactsPath: "/old/guide/contacts",
  guideCommunitiesPath: "/old/guide/communities",
  guideCommunityPath: "/old/guide/communities/:id",
  guideNotificationsPath: "/old/guide/notifications",
  guideContactUsPath: "/old/guide/contact-us",
  guideChangePasswordPath: "/old/guide/change-password"
}

export const oldRoutes = Object.keys(ROUTES_LIST).reduce(
  (acc, routeAlias) => ({ ...acc, [routeAlias]: dynamicRoute(ROUTES_LIST[routeAlias]) }),
  {}
)
