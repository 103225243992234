import React, { useMemo } from "react"
import { Input, Label } from "reactstrap"
import ArrayInput from "components/form/ArrayInput"
import GooglePlacesInput from "modules/google/GooglePlacesInput"
import ActionButtons from "components/guide/wizard/ActionButtons"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { map } from "lodash"
import useForm, { useNestedFields } from "hooks/useForm"

import { useDispatch, useSelector } from "react-redux"
import { guideSelector, updateGuide } from "store/user"

const ExtrasForm = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const guide = useSelector(guideSelector)
  const operating_locations = useMemo(
    () => guide.operating_locations?.map((address, index) => ({ _id: index + 1, address })) || [],
    [guide.operating_locations]
  )

  const [form, changeHandler, submitHandler, submitCallback, reset, isChanged] = useForm({ ...guide, operating_locations }, [
    "upload_client_database",
    "operating_locations"
  ])
  const [, , addLocationHandler, removeLocationHandler] = useNestedFields(form, "operating_locations", [], changeHandler)

  submitCallback(() => {
    const operating_locations = map(form.operating_locations, "address")
    const formData = serialize({ guide: { ...form, operating_locations } })
    return dispatch(updateGuide(guide.id, formData))
  })

  return (
    <form onSubmit={submitHandler} className="vstack gap-y-3 text-start">
      <div className="d-none">
        <div className="bg-white rounded p-20">
          <h2 className="h4 fw-medium">1. {t("guide.extras.database_to_upload")}</h2>
          <div className="hstack gap-30 mt-15 ps-4">
            <Label check className="hstack align-items-center gap-1 fs-5 fw-normal">
              <Input
                type="radio"
                name="upload_client_database"
                value={"true"}
                checked={[true, "true"].includes(form.upload_client_database)}
                onChange={changeHandler}
                className=""
              />
              <span className="text-dark">{t("global.yes")}</span>
            </Label>
            <Label check className="hstack align-items-center gap-1 fs-5 fw-normal">
              <Input
                type="radio"
                name="upload_client_database"
                value={"false"}
                checked={[false, "false"].includes(form.upload_client_database)}
                onChange={changeHandler}
                className=""
              />
              <span className="text-dark">{t("global.no")}</span>
            </Label>
          </div>
        </div>
      </div>

      <div>
        <div className="bg-white rounded p-20">
          <h2 className="h4 fw-medium">1. {t("guide.extras.operate_from")}</h2>
          <div className="hstack gap-30 mt-15 ps-4">
            <ArrayInput
              form={form}
              name="operating_locations"
              field="address"
              changeHandler={changeHandler}
              addHandler={addLocationHandler}
              removeHandler={removeLocationHandler}
              placeholder="Search Location"
              className="w-100"
              ipnutTag={GooglePlacesInput}
              controlled={false}
            />
          </div>
        </div>
      </div>

      <ActionButtons isChanged={isChanged} onReset={reset} />
    </form>
  )
}

export default ExtrasForm
