import React, { useState } from "react"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"

import { useGoogleMap } from "@react-google-maps/api"

import { MAX_ZOOM, MIN_ZOOM } from "constants/map"

const ZoomButtons = () => {
  const map = useGoogleMap()
  const [zoom, setZoom] = useState(map.zoom)

  const zoomHandler = (step) => () => {
    const newZoom = Math.max(MIN_ZOOM, Math.min(zoom + step, MAX_ZOOM))
    map.setZoom(newZoom)
    setZoom(newZoom)
  }
  return (
    <div className="pointer-events-auto flex-grow-0 vstack gap-2 ms-auto mt-auto">
      <Button color="white" onClick={zoomHandler(1)} className="btn-sm p-2 fs-7 shadow" disabled={zoom >= MAX_ZOOM}>
        <Icon iconName="Plus" size={16} />
      </Button>
      <Button color="white" onClick={zoomHandler(-1)} className="btn-sm p-2 fs-7 shadow" disabled={zoom <= MIN_ZOOM}>
        <Icon iconName="Minus" size={16} />
      </Button>
    </div>
  )
}

export default ZoomButtons
