import React from "react"
import styles from "./MenuItem.module.css"
import { useSelector } from "react-redux"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"

export const MenuItem = ({ item }) => {
  const navigate = useNavigate()
  const { activeMenu } = useSelector((state) => state.guideUserInfo)
  const { name, id } = item
  const isActive = activeMenu === id

  const onClickHandler = () => {
    if (item.path) {
      navigate(item.path)
    }
  }

  return (
    <div className={classNames(styles.menuGroupItem, isActive && styles.menuGroupItemActive)} onClick={onClickHandler}>
      {name}
    </div>
  )
}
