import React from "react"
import { Navigate } from "react-router-dom"
import { oldRoutes } from "./old-routes"
import PrivateRoutes from "./PrivateRoutes"
import PublicRoutes from "./PublicRoutes"

import LandingLayout from "layouts/LandingLayout"
import DeviseLayout from "layouts/DeviseLayout"
import BookingLayout from "layouts/BookingLayout"
import DashboardLayout from "layouts/DashboardLayout"
import GuideLayout from "layouts/public/GuideLayout"
import GuideWizardLayout from "layouts/guide/WizardLayout"
import GuideTripWizardLayout from "layouts/guide/TripWizardLayout"
import GuideSettingsLayout from "layouts/guide/SettingsLayout"
import ClientSettingsLayout from "layouts/client/SettingsLayout"

import Landing from "pages/LandingPage"
import GuideLanding from "pages/GuideLandingPage"
import Faq from "pages/FaqPage"
import TermsAndConditions from "pages/TermsAndConditionsPage"
import PrivacyPolicy from "pages/PrivacyPolicyPage"
import Trips from "pages/public/trips/TripsPage"
import Trip from "pages/public/trips/TripPage"
import Referral from "pages/public/referral/ReferralPage"
import Sales from "pages/public/sales/SalesPage"

import SignIn from "pages/devise/SignInPage"
import SignUp from "pages/devise/SignUpPage"
import SignUpRequestSent from "pages/devise/SignUpRequestSent"
import ForgotPassword from "pages/devise/ForgotPasswordPage"
import ResetPasswordRequestSent from "pages/devise/ResetPasswordRequestSentPage"
import ResetPassword from "pages/devise/ResetPasswordPage"
import ResetPasswordSuccess from "pages/devise/ResetPasswordSuccessPage"
import VerifyPhone from "components/old/Verification/VerifyPhone"
import VerifyCode from "components/old/Verification/VerifyCode"

import PublicGuide from "pages/public/guides/GuidePage"
import PublicGuideTrips from "pages/public/guides/TripsPage"
import PublicGuideFeeder from "pages/public/guides/FeederPage"
import PublicGuideReviews from "pages/public/guides/ReviewsPage"
import PublicGuideLocation from "pages/public/guides/LocationPage"
import PublicGuideBoats from "pages/public/guides/BoatsPage"

import ClientSettingsDetails from "pages/client/settings/MyDetailsPage"
import ClientPaymentDetails from "pages/client/settings/PaymentDetailsPage"
import ClientSettingsLoginAndSecurity from "pages/client/settings/LoginAndSecurityPage"
import ClientSettingsNotifications from "pages/client/settings/NotificationsPage"

import ClientBookings from "pages/client/bookings/BookingsPage"
import ClientBookingsBooked from "pages/client/bookings/BookedTripsPage"
import ClientBookingsPast from "pages/client/bookings/PastTripsPage"
import ClientBooking from "pages/client/bookings/BookingTripPage"
import ClientBookingWizardAccount from "pages/client/bookings/wizard/AccountPage"
import ClientBookingWizardStep1 from "pages/client/bookings/wizard/Step1Page"
import ClientBookingWizardStep2 from "pages/client/bookings/wizard/Step2Page"
import ClientBookingWizardStep3 from "pages/client/bookings/wizard/Step3Page"
import ClientBookingWizardStep4 from "pages/client/bookings/wizard/Step4Page"

import FavoritesPage from "pages/client/favorites/FavoritesPage"

import GuideDashboardPage from "pages/guide/dashboard/DashboardPage"
import GuideJournalWindow from "pages/guide/dashboard/JournalWindow"
import GuideUnavailableDayWindow from "pages/guide/dashboard/UnavailableDayWindow"
import GuideDashboardBookingWindow from "pages/guide/dashboard/BookingWindow"

import GuideBookingsPage from "pages/guide/bookings/BookingsPage"
import GuideBookingWindow from "pages/guide/bookings/BookingWindow"

import GuideClients from "pages/guide/clients/ClientsPage"
import GuideClientWindow from "pages/guide/clients/ClientWindow"
import GuideClientActionWindow from "pages/guide/clients/ActionWindow"

import GuideWizardStep1 from "pages/guide/wizard/Step1Page"
import GuideWizardStep2 from "pages/guide/wizard/Step2Page"
import GuideWizardStep3 from "pages/guide/wizard/Step3Page"
import GuideWizardStep4 from "pages/guide/wizard/Step4Page"

import GuideTrips from "pages/guide/trips/TripsPage"
import GuideTripWizardStep1 from "pages/guide/trips/wizard/Step1Page"
import GuideTripWizardStep2 from "pages/guide/trips/wizard/Step2Page"
import GuideTripWizardStep3 from "pages/guide/trips/wizard/Step3Page"
import GuideTripWizardStep4 from "pages/guide/trips/wizard/Step4Page"
import GuideTripWizardStep5 from "pages/guide/trips/wizard/Step5Page"

import Boats from "pages/guide/boats/BoatsPage"
import BoatWindow from "pages/guide/boats/BoatWindow"

import MessagesPage from "pages/private/messages/MessagesPage"

import GuideAccounting from "pages/guide/accouting/Accounting"
import GuideAccountingExpenseWindow from "pages/guide/accouting/ExpenseWindow"

import GuideMarketing from "pages/guide/marketing/MarketingPage"
import GuideFeeder from "pages/guide/marketing/FeederPage"
import GuidePostWindow from "pages/guide/marketing/PostWindow"
import GuideTemplates from "pages/guide/marketing/TemplatesPage"
import GuideTemplatesParents from "pages/guide/marketing/TemplatesParentsPage"
import GuideTemplate from "pages/guide/marketing/TemplatePage"

import GuideSettingsDetails from "pages/guide/settings/MyDetailsPage"
import GuideSettingsPhotos from "pages/guide/settings/PhotosPage"
import GuideSettingsExtras from "pages/guide/settings/ExtrasPage"
import GuideSettingsDocuments from "pages/guide/settings/DocumentsPage"
import GuideSettingsStripe from "pages/guide/settings/StripePage"
import GuideSettingsSocials from "pages/guide/settings/SocialsPage"
import GuideSettingsLoginAndSecurity from "pages/guide/settings/LoginAndSecurityPage"
import GuideSettingsNotifications from "pages/guide/settings/NotificationsPage"
import GuideContactUs from "components/settings/ContactUs"

import GuideWeather from "pages/guide/weather/WeatherPage"

export const oldRouter = [
  {
    // Public only routes
    element: <PublicRoutes only />,
    children: [
      {
        element: <LandingLayout />,
        children: [
          { path: oldRoutes.rootPath(), element: <Landing /> },
          { path: oldRoutes.referralPath(), element: <Referral /> },
          { path: oldRoutes.salesPath(), element: <Sales /> }
        ]
      },
      {
        element: <DeviseLayout />,
        children: [
          { path: oldRoutes.signInPath(), element: <SignIn /> },
          { path: oldRoutes.signUpPath(), element: <SignUp /> },
          { path: oldRoutes.signUpRequestSentPath(), element: <SignUpRequestSent /> },
          { path: oldRoutes.forgotPasswordPath(), element: <ForgotPassword /> },
          { path: oldRoutes.resetPasswordRequestSentPath(), element: <ResetPasswordRequestSent /> },
          { path: oldRoutes.resetPasswordPath(), element: <ResetPassword /> },
          { path: oldRoutes.resetPasswordSuccessPath(), element: <ResetPasswordSuccess /> },
          { path: oldRoutes.verifyAccountPath(), element: <VerifyPhone /> },
          { path: oldRoutes.verifyCodePath(), element: <VerifyCode /> }
        ]
      },
      {
        element: <BookingLayout />,
        children: [{ path: oldRoutes.bookingWizardPath(), element: <ClientBookingWizardAccount /> }]
      },
      {
        element: <BookingLayout />,
        path: oldRoutes.guestBookingWizardRootPath(),
        children: [
          { path: oldRoutes.guestBookingWizardStep1Path(), element: <ClientBookingWizardStep1 /> },
          { path: oldRoutes.guestBookingWizardStep2Path(), element: <ClientBookingWizardStep2 /> },
          { path: oldRoutes.guestBookingWizardStep3Path(), element: <ClientBookingWizardStep3 /> },
          { path: oldRoutes.guestBookingWizardStep4Path(), element: <ClientBookingWizardStep4 /> }
        ]
      }
    ]
  },
  {
    // Public routes
    element: <PublicRoutes />,
    children: [
      {
        element: <LandingLayout />,
        children: [
          { path: oldRoutes.becomeAGuidePath(), element: <GuideLanding /> },
          { path: oldRoutes.faqPath(), element: <Faq /> },
          { path: oldRoutes.termsAndConditionsPath(), element: <TermsAndConditions /> },
          { path: oldRoutes.privacyPolicyPath(), element: <PrivacyPolicy /> },
          { path: oldRoutes.tripsPath(), element: <Trips /> },
          { path: oldRoutes.tripPath(), element: <Trip /> }
        ]
      },
      {
        element: <GuideLayout />,
        path: oldRoutes.publicGuidePath(),
        children: [
          { index: true, element: <PublicGuide /> },
          { path: oldRoutes.publicGuideTripsPath(), element: <PublicGuideTrips /> },
          { path: oldRoutes.publicGuidePostsPath(), element: <PublicGuideFeeder /> },
          { path: oldRoutes.publicGuideReviewsPath(), element: <PublicGuideReviews /> },
          { path: oldRoutes.publicGuideLocationPath(), element: <PublicGuideLocation /> },
          { path: oldRoutes.publicGuideBoatsPath(), element: <PublicGuideBoats /> }
        ]
      }
    ]
  },
  {
    // Client routes
    path: oldRoutes.clientRootPath(),
    element: <PrivateRoutes accessRole="user" />,
    children: [
      {
        element: <DashboardLayout />,
        children: [
          { index: true, element: <Navigate to={oldRoutes.clientBookingsPath()} /> },
          { path: oldRoutes.clientMessagesPath(), element: <MessagesPage /> },
          { path: oldRoutes.clientFavoritesPath(), element: <FavoritesPage /> },
          {
            path: oldRoutes.clientBookingsPath(),
            element: <ClientBookings />,
            children: [
              { index: true, element: <ClientBookingsBooked /> },
              { path: oldRoutes.clientBookingsPastTripsPath(), element: <ClientBookingsPast /> }
            ]
          },
          { path: oldRoutes.clientBookingPath(), element: <ClientBooking /> },
          {
            element: <ClientSettingsLayout />,
            children: [
              { path: oldRoutes.clientSettingsRootPath(), element: <ClientSettingsDetails /> },
              { path: oldRoutes.clientSettingsPaymentPath(), element: <ClientPaymentDetails /> },
              { path: oldRoutes.clientSettingsPasswordPath(), element: <ClientSettingsLoginAndSecurity /> },
              { path: oldRoutes.clientSettingsNotificationsPath(), element: <ClientSettingsNotifications /> }
            ]
          }
        ]
      },
      {
        element: <BookingLayout />,
        path: oldRoutes.clientBookingWizardRootPath(),
        children: [
          { path: oldRoutes.clientBookingWizardStep1Path(), element: <ClientBookingWizardStep1 /> },
          { path: oldRoutes.clientBookingWizardStep2Path(), element: <ClientBookingWizardStep2 /> },
          { path: oldRoutes.clientBookingWizardStep3Path(), element: <ClientBookingWizardStep3 /> },
          { path: oldRoutes.clientBookingWizardStep4Path(), element: <ClientBookingWizardStep4 /> }
        ]
      }
    ]
  },
  {
    // Guide routes
    path: oldRoutes.guideRootPath(),
    element: <PrivateRoutes accessRole="guide_user" />,
    children: [
      { index: true, element: <Navigate to={oldRoutes.guideDashboardPath()} /> },
      {
        element: <DashboardLayout />,
        children: [
          {
            path: oldRoutes.guideDashboardPath(),
            element: <GuideDashboardPage />,
            children: [
              { path: oldRoutes.guideDashboardJournalNewPath(), element: <GuideJournalWindow /> },
              { path: oldRoutes.guideDashboardJournalEditPath(), element: <GuideJournalWindow /> },
              { path: oldRoutes.guideDashboardBookingNewPath(), element: <GuideDashboardBookingWindow /> },
              { path: oldRoutes.guideDashboardBookingEditPath(), element: <GuideDashboardBookingWindow /> },
              { path: oldRoutes.guideDashboardUnavailableDayManagePath(), element: <GuideUnavailableDayWindow /> }
            ]
          },
          {
            path: oldRoutes.guideBookingsPath(),
            element: <GuideBookingsPage />,
            children: [
              { path: oldRoutes.guideBookingEditPath(), element: <GuideBookingWindow /> },
              { path: oldRoutes.guideBookingNewPath(), element: <GuideBookingWindow /> }
            ]
          },
          {
            path: oldRoutes.guideClientsPath(),
            element: <GuideClients />,
            children: [
              { path: oldRoutes.guideClientNewPath(), element: <GuideClientWindow /> },
              { path: oldRoutes.guideClientPath(), element: <GuideClientWindow /> },
              { path: oldRoutes.guideClientEditPath(), element: <GuideClientWindow /> },
              { path: oldRoutes.guideClientEmailActionPath(), element: <GuideClientActionWindow type="email" /> },
              { path: oldRoutes.guideClientTextActionPath(), element: <GuideClientActionWindow type="text" /> },
              { path: oldRoutes.guideClientMessageActionPath(), element: <GuideClientActionWindow type="message" /> }
            ]
          },
          { path: oldRoutes.guideTripsPath(), element: <GuideTrips /> },
          { path: oldRoutes.guideTripPath(), element: <Trip guideView /> },
          {
            path: oldRoutes.guideTripWizardRootPath(),
            element: <GuideTripWizardLayout />,
            children: [
              { path: oldRoutes.guideTripWizardStep1Path(), element: <GuideTripWizardStep1 /> },
              { path: oldRoutes.guideTripWizardStep2Path(), element: <GuideTripWizardStep2 /> },
              {
                path: oldRoutes.guideTripWizardStep3Path(),
                element: <GuideTripWizardStep3 />,
                children: [{ path: oldRoutes.guideTripWizardStep3AddNewBoatPath(), element: <BoatWindow /> }]
              },
              { path: oldRoutes.guideTripWizardStep4Path(), element: <GuideTripWizardStep4 /> },
              { path: oldRoutes.guideTripWizardStep5Path(), element: <GuideTripWizardStep5 /> }
            ]
          },
          {
            path: oldRoutes.guideBoatsPath(),
            element: <Boats />,
            children: [
              { path: oldRoutes.guideAddNewBoatPath(), element: <BoatWindow /> },
              { path: oldRoutes.guideEditBoatPath(), element: <BoatWindow /> }
            ]
          },
          { path: oldRoutes.guideMessagesPath(), element: <MessagesPage /> },
          {
            path: oldRoutes.guideAccountingPath(),
            element: <GuideAccounting />,
            children: [
              { path: oldRoutes.guideAccountingAddExpancePath(), element: <GuideAccountingExpenseWindow /> },
              { path: oldRoutes.guideAccountingEditExpancePath(), element: <GuideAccountingExpenseWindow /> }
            ]
          },
          {
            element: <GuideMarketing />,
            children: [
              {
                path: oldRoutes.guideMarketingPath(),
                element: <GuideFeeder />,
                children: [
                  { path: oldRoutes.guideMarketingPostNewPath(), element: <GuidePostWindow /> },
                  { path: oldRoutes.guideMarketingPostEditPath(), element: <GuidePostWindow /> }
                ]
              },
              {
                path: oldRoutes.guideMarketingTemplatesPath(),
                element: <GuideTemplates />,
                children: [
                  {
                    path: oldRoutes.guideMarketingTemplatesParentsPath(),
                    element: <GuideTemplatesParents />
                  },
                  { path: oldRoutes.guideMarketingTemplateNewPath(), element: <GuideTemplate /> },
                  { path: oldRoutes.guideMarketingTemplateEditPath(), element: <GuideTemplate /> }
                ]
              }
            ]
          },

          {
            element: <GuideSettingsLayout />,
            children: [
              { path: oldRoutes.guideSettingsRootPath(), element: <GuideSettingsDetails /> },
              { path: oldRoutes.guideSettingsPhotosPath(), element: <GuideSettingsPhotos /> },
              { path: oldRoutes.guideSettingsExtrasPath(), element: <GuideSettingsExtras /> },
              { path: oldRoutes.guideSettingsDocumentsPath(), element: <GuideSettingsDocuments /> },
              { path: oldRoutes.guideSettingsStripePath(), element: <GuideSettingsStripe /> },
              { path: oldRoutes.guideSettingsSocialsPath(), element: <GuideSettingsSocials /> },
              { path: oldRoutes.guideSettingsPasswordPath(), element: <GuideSettingsLoginAndSecurity /> },
              { path: oldRoutes.guideSettingsNotificationsPath(), element: <GuideSettingsNotifications /> }
            ]
          },
          { path: oldRoutes.guideWeatherPath(), element: <GuideWeather /> },
          { path: oldRoutes.guideContactUsPath(), element: <GuideContactUs /> }
        ]
      },
      {
        path: oldRoutes.guideWizardRootPath(),
        element: <GuideWizardLayout />,
        children: [
          { path: oldRoutes.guideWizardStep1Path(), element: <GuideWizardStep1 /> },
          { path: oldRoutes.guideWizardStep2Path(), element: <GuideWizardStep2 /> },
          { path: oldRoutes.guideWizardStep3Path(), element: <GuideWizardStep3 /> },
          { path: oldRoutes.guideWizardStep4Path(), element: <GuideWizardStep4 /> }
        ]
      }
    ]
  }
]
