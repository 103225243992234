import React, { useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import { Link, NavLink, useMatch } from "react-router-dom"
import PropTypes from "prop-types"
import { oldRoutes } from "router/old-routes"
import SupportWindow from "pages/SupportWindow"

import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { showModal } from "modules/modals/reducer"

import DSBlueLogo from "assets/images/landing/DS-blue.svg"
import DSPinkLogo from "assets/images/landing/DS-pink.svg"
import veteranBadge from "assets/images/landing/veteran-badge.png"
import FacebookIcon from "assets/images/landing/facebook.svg"
import InstagramIcon from "assets/images/landing/instagram.svg"
import LinkedInIcon from "assets/images/landing/linkedin.svg"
import XIcon from "assets/images/landing/XIcon.svg"

Footer.propTypes = {
  dark: PropTypes.bool,
  noPadding: PropTypes.bool,
  fluid: PropTypes.bool,
  className: PropTypes.string
}

export default function Footer({ dark = false, noPadding = false, fluid = false, className }) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const isTripsPage = useMatch(oldRoutes.tripsPath())
  const isSportsman = useSelector((state) => state.user.is_sportsman)

  const openHandler = () => dispatch(showModal("support"))

  const mountHubSpotForm = () => {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "44114474",
      formId: "048bc452-aea2-44f2-8f07-176b0f582e0c",
      target: ".hbspt-newsletter-form"
    })
  }

  useEffect(() => {
    const script = document.createElement("script")
    const container = document.getElementById("newsletterSubscription")
    script.src = "//js.hsforms.net/forms/embed/v2.js"
    script.addEventListener("load", mountHubSpotForm)
    container.appendChild(script)

    return () => {
      script.removeEventListener("load", mountHubSpotForm)
      container.removeChild(script)
    }
  }, []) //eslint-disable-line

  const classes = ["mt-auto pb-sm-80 z-1 pt-100 bg-light"]
  if (dark) classes.push("bg-dark text-bg-dark ")
  if (className) classes.push(className)
  if (isTripsPage) classes.push("shadow")

  const scrollToRequestDemo = () => {
    const demoRequestEl = document.querySelector("#demo-request")
    demoRequestEl.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <footer className={classes.join(" ")}>
      <Container className={noPadding ? "p-0" : ""} fluid={fluid}>
        <Row className="gap-y-40 gx-20 gx-md-60">
          <Col xs={12} sm={{ size: "auto" }} className="me-auto order-3 order-md-1">
            <div className="position-relative mb-4" style={{ maxWidth: 200 }}>
              <div className="p-2 mx-n2 mt-n2 mb-25">
                <NavLink to={oldRoutes.rootPath()} state={{ scrollToTop: true }} title="Home">
                  {isSportsman ? (
                    <img src={DSBlueLogo} alt="DS Logo" width={42} height={41} className="me-3" />
                  ) : (
                    <img src={DSPinkLogo} alt="DS Logo" width={42} height={41} className="me-3" />
                  )}
                </NavLink>
                <img src={veteranBadge} alt="Veteran Badge" width={52} height={51} />
              </div>
              <h5 className="h6">{t("footer.title")}</h5>
              <p className="fs-7 mt-10 mb-0">
                <small>{t("footer.desc")}</small>
              </p>
            </div>
            <a href="https://www.facebook.com/DigitalSportsman?mibextid=LQQJ4d">
              <img src={FacebookIcon} alt="Facebook Icon" width={27} height={27} className="me-3" />
            </a>
            <a href="https://www.instagram.com/digitalsportsman/?hl=en">
              <img src={InstagramIcon} alt="Instagram Icon" width={27} height={27} className="me-3" />
            </a>
            <a href="https://www.linkedin.com/company/digitalsportsman/">
              <img src={LinkedInIcon} alt="Linked InIcon" width={27} height={27} className="me-3" />
            </a>
            <a href="/#">
              <img src={XIcon} alt="X Icon" width={27} height={27} className="me-3" />
            </a>
            <p className="mt-4">
              <small>Call us on 252-DS-Guide</small>
            </p>
          </Col>
          <Col xs={12} md={5} lg={4} className="order-2">
            <div className="hstack gap-20 gap-md-40 fs-7 align-items-start w-100 w-sm-auto">
              <div className="flex-grow-1 flex-grow-sm-0">
                <p className="fs-6 text-uppercase fw-medium" style={{ color: "#B7B7B7" }}>
                  Company
                </p>
                <ul className="vstack gap-3 mt-15 list-unstyled mb-0 fs-6">
                  <li>
                    <Link onClick={scrollToRequestDemo} state={{ scrollToTop: true }} className="link text-current">
                      Get a Demo
                    </Link>
                  </li>
                  <li>
                    <a href="https://go.ds.live/blog" className="link text-current">
                      Blog
                    </a>
                  </li>
                  <li>
                    <Link to={oldRoutes.referralPath()} state={{ scrollToTop: true }} className="link text-current">
                      Referral Program
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="flex-grow-1 flex-grow-sm-0">
                <p className="fs-6 text-uppercase fw-medium" style={{ color: "#B7B7B7" }}>
                  Help
                </p>
                <ul className="vstack gap-3 mt-15 list-unstyled mb-0 fs-6">
                  <li>
                    <Link to={oldRoutes.salesPath()} state={{ scrollToTop: true }} className="link text-current">
                      Contact Sales
                    </Link>
                  </li>
                  <li>
                    <button className="link text-current" onClick={openHandler}>
                      Customer Support
                    </button>
                  </li>
                  <li>
                    <Link to={oldRoutes.faqPath()} state={{ scrollToTop: true }} className="link text-current">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to={oldRoutes.termsAndConditionsPath()} state={{ scrollToTop: true }} className="link text-current">
                      Terms & Conditions
                    </Link>
                  </li>
                  <li>
                    <Link to={oldRoutes.privacyPolicyPath()} state={{ scrollToTop: true }} className="link text-current">
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} id="newsletterSubscription" className="order-1 order-md-3">
            <div className="hbspt-newsletter-form p-2 py-0" />
          </Col>
        </Row>
      </Container>

      <SupportWindow />
    </footer>
  )
}
