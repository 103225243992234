import React from "react"
import { Outlet } from "react-router-dom"
import Header from "components/layout/Header"
import Footer from "components/layout/Footer"
import withDefaultErrorBoundary from "modules/errors/HOCs/withDefaultErrorBoundary"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

function LandingLayout() {
  return (
    <div className="vstack min-vh-100">
      <Header />
      <main className="flex-fill vstack">
        <DefaultErrorBoundary>
          <Outlet />
        </DefaultErrorBoundary>
      </main>
      <Footer />
    </div>
  )
}

export default withDefaultErrorBoundary(LandingLayout)
