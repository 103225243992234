import React, { useCallback, useEffect, useRef, memo } from "react"
import { Layout, List, Row, Col } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { Avatar } from "ui/Avatar"
import { useTranslation } from "react-i18next"
import { Message } from "../Message"
import { MessageForm } from "./MessageForm"
import { Text } from "ui/Typography"
import { Waypoint } from "react-waypoint"
import { isLastPage } from "helpers/pagination"

// Redux
import { activeConversationSelector, getConversation, messagesMetadataSelector, messagesSelector } from "store/conversations"
import { collectionAnySelector } from "store/selectors"
import { groupMessagesByDateAndAuthor } from "../utils"

import styles from "./ChatHistory.module.css"

const { Header, Content } = Layout

const History = ({ selectedConversationId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const conversationBox = useRef(null)
  const conversationBoxScrollHeight = useRef(0)
  const hasConversations = useSelector(collectionAnySelector("conversations"))

  const messages = useSelector(messagesSelector)
  const metadata = useSelector(messagesMetadataSelector)
  const activeConversation = useSelector(activeConversationSelector)

  const role = useSelector((state) => state.user.role)
  const isGuide = role === "guide_user"
  const user = isGuide ? activeConversation?.contact : activeConversation?.client
  const participant = isGuide ? activeConversation?.client : activeConversation?.contact

  const scrollToBottom = () => conversationBox?.current?.scrollIntoView({ block: "end" })

  const fetchMessages = useCallback(
    (nextPage = false) => {
      conversationBoxScrollHeight.current = conversationBox.current?.scrollHeight
      return (
        selectedConversationId &&
        dispatch(getConversation(selectedConversationId, nextPage))
          .then(() => {
            if (!nextPage) {
              scrollToBottom()
            } else {
              conversationBox.current.parentNode?.scrollTo({
                top: conversationBox.current.parentNode.scrollHeight - conversationBoxScrollHeight.current
              })
            }
          })
          .catch(() => setSearchParams({}))
      )
    },
    [selectedConversationId] // eslint-disable-line
  )

  useEffect(() => {
    if (selectedConversationId && hasConversations) fetchMessages()
  }, [fetchMessages, hasConversations]) //eslint-disable-line

  const groupedMessages = groupMessagesByDateAndAuthor(messages)

  return (
    <div className={styles.history}>
      <Header className={styles.header}>
        {participant?.id && (
          <Row justify="start" align="middle">
            <Col>
              <Avatar src={participant.avatar} fullName={participant.full_name} size={52} />
            </Col>
            <Col className="ml-m">
              <div className="flex-column">
                <Text variant="sub" size="lg">
                  {participant.full_name}
                </Text>
                <Text variant="caption" type="secondary">
                  Was active recently
                </Text>
              </div>
            </Col>
          </Row>
        )}
      </Header>
      <Content className={styles.content}>
        {!isLastPage(metadata) && <Waypoint onEnter={() => fetchMessages(true)} topOffset={-50} />}
        <List
          dataSource={groupedMessages}
          ref={conversationBox}
          renderItem={(msg) => <Message key={msg.id} message={msg} isOwnMessage={user.id === msg.author.id} />}
        />
      </Content>
      <MessageForm conversation={activeConversation} messageSent={scrollToBottom} />
    </div>
  )
}

export const ChatHistory = memo(History)
