import React from "react"
import { Container, Row, Col } from "reactstrap"

import landing_screenImage from "assets/images/landing_screen.png"
import landing_vanImage from "assets/images/landing_van.png"
import landing_fishingImage from "assets/images/landing_fishing.png"

export default function Landing() {
  return (
      <Container className="my-100">
        <Row className="align-items-center gx-50 gx-lg-80 gx-xl-100 gy-30" style={{marginBottom: "35px"}}>
          <Col md={6}>
            <img src={landing_fishingImage} className="w-100" alt="fishing"/>
          </Col>
          <Col md={6}>
            <h4 className="fw-medium lh-1 mb-30" style={{ color: "#0052B4", lineHeight: "51px", fontSize: "24px" }}>Direct Your Business</h4>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Straightforward pricing.</strong> Digital Sportsman is the first commission-free booking platform.</p>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Payments for how you operate.</strong> Reservations can be booked with credit cards or cash</p>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Business Insights.</strong> Advanced financial tools for tracking expenses and managing finances with an intuitive dashboard. </p>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Branded Experience.</strong> Customize your business profile and seamlessly integrate booking modules. </p>
          </Col>
        </Row>
        <Row className="align-items-center gx-50 gx-lg-80 gx-xl-100 gy-30" style={{ marginBottom: "35px", marginTop: "35px"}}>
          <Col md={6} className="order-2 order-md-1">
            <h4 className="fw-medium lh-1 mb-30" style={{ color: "#0052B4", lineHeight: "51px", fontSize: "24px" }}>Streamline Your Operations with AI</h4>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Achieve operational efficiency.</strong> AI automation simplifies business management tasks, workflows, and content creation.</p>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Work from anywhere.</strong> Manage your business on the go with our mobile app. Available on the App</p>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Streamline booking interactions.</strong> Streamline booking interactions. Automate client communication through text, email, and internal messaging systems, with automated emails. Publish trip photos with one-click with our Social Feeder tool.</p>
          </Col>
          <Col md={6} className="d-flex justify-content-center order-1 order-md-2">
            <img src={landing_screenImage} className="w-100" alt="screen" />
          </Col>
        </Row>
        <Row className="align-items-center gx-50 gx-lg-80 gx-xl-100 gy-30" style={{marginTop: "35px"}}>
          <Col md={6} className="d-flex justify-content-center">
            <img src={landing_vanImage} className="w-100" alt="van"/>
          </Col>
          <Col md={6}>
            <h4 className="fw-medium lh-1 mb-30" style={{ color: "#0052B4", lineHeight: "51px", fontSize: "24px" }}>Enhance Client Experiences</h4>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Deliver exceptional experiences.</strong> No booking fees for your clients. </p>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Frictionless booking.</strong> Online trip reservations can be made with credit cards or cash.</p>
            <p style={{ color: "rgba(20, 20, 20, 0.9)" }}><strong>Expand & engage your customer base.</strong> Utilize our marketing and communication tools to effectively manage and expand your customer base. Use our Social Feeder to share trip pictures and updates on social media to engage clients and build lasting relationships.</p>
          </Col>
        </Row>
      </Container>
  )
}
