import React from "react";

export default function Gradient() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style={{ position: "absolute", visibility: "hidden" }}>
      <linearGradient id="gradient" x2="1" y2="0">
        <stop offset="0%" stopColor="#64BC1C" />
        <stop offset="100%" stopColor="#0052B4" />
      </linearGradient>
    </svg>
  );
}
