import React, { useEffect } from "react"
import { Layout, Input, List } from "antd"
import { SearchOutlined } from "@ant-design/icons"
import { useSearchParams } from "react-router-dom"
import { Conversation } from "../Conversation"
import { useTranslation } from "react-i18next"
import { sumBy } from "lodash"

import { useSelector, useDispatch } from "react-redux"
import { getConversations, setViewedMessagesCount } from "store/conversations"
import { collectionFirstSelector, collectionSelector } from "store/selectors"
import { firstLoadingSelector } from "modules/loader-watchers/selectors"
import styles from "./ConversationList.module.css"

const { Sider } = Layout

export const ConversationList = ({ selectedConversationId }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [searchQuery, setSearchQuery] = React.useState("")
  const role = useSelector((state) => state.user.role)
  const firstLoading = useSelector(firstLoadingSelector("conversations"))
  const conversationsFirst = useSelector(collectionFirstSelector("conversations"))
  const conversationsFirstId = conversationsFirst?.id
  const conversations = useSelector(collectionSelector("conversations"))
  const isGuide = role === "guide_user"
  const filteredConversationsById = conversations.filter((convresation) => convresation.id === selectedConversationId)
  const unviewed = sumBy(conversations, "unviewed")
  let totalUnviewed = null

  if (unviewed > 0 && filteredConversationsById.lenght > 0) totalUnviewed = unviewed - Number(filteredConversationsById.at(0).unviewed)

  useEffect(() => {
    if (!selectedConversationId && conversationsFirstId) searchParams.set("conversation_id", conversationsFirstId)
  }, [selectedConversationId, conversationsFirstId]) //eslint-disable-line

  useEffect(() => {
    if (firstLoading) dispatch(getConversations(null))
  }, [firstLoading]) //eslint-disable-line

  useEffect(() => {
    dispatch(setViewedMessagesCount(totalUnviewed))
  }, [totalUnviewed]) //eslint-disable-line

  const filteredConversationsByName = conversations.filter((conversation) => {
    const name = isGuide ? conversation.client.display_name : conversation.contact
    return name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
  })

  return (
    <Sider width={428} className={styles.sider}>
      <div className={styles.searchBox}>
        <Input
          className={styles.searchInput}
          placeholder="Search conversations"
          prefix={<SearchOutlined />}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
      <List
        className={styles.list}
        dataSource={filteredConversationsByName}
        renderItem={(item) => <Conversation key={item.id} conversation={item} />}
      />
    </Sider>
  )
}
