import React from "react"

export const Marketing = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_478_9443)">
      <path
        d="M12 0.959961C5.9126 0.959961 0.959961 5.9126 0.959961 12C0.959961 18.0873 5.9126 23.04 12 23.04C18.0873 23.04 23.04 18.0873 23.04 12C23.04 9.7934 22.387 7.73731 21.2671 6.01027L20.8387 6.43871L20.565 6.71246C21.5192 8.25182 22.08 10.0593 22.08 12C22.08 17.5584 17.5584 22.08 12 22.08C6.44156 22.08 1.91996 17.5584 1.91996 12C1.91996 6.44156 6.44156 1.91996 12 1.91996C14.3584 1.91996 16.5217 2.74433 18.24 4.10715V5.08121L17.0709 6.25027C15.7171 5.05311 13.9449 4.31996 12 4.31996C7.7654 4.31996 4.31996 7.7654 4.31996 12C4.31996 16.2345 7.7654 19.68 12 19.68C16.2345 19.68 19.68 16.2345 19.68 12C19.68 10.402 19.1882 8.91676 18.3496 7.68652L17.654 8.38215C18.3251 9.42807 18.72 10.6675 18.72 12C18.72 15.7056 15.7056 18.72 12 18.72C8.29436 18.72 5.27996 15.7056 5.27996 12C5.27996 8.29436 8.29436 5.27996 12 5.27996C13.6806 5.27996 15.2136 5.90479 16.3921 6.92902L14.6896 8.63152C13.9511 8.03914 13.0183 7.67996 12 7.67996C9.6182 7.67996 7.67996 9.6182 7.67996 12C7.67996 14.3817 9.6182 16.32 12 16.32C14.3817 16.32 16.32 14.3817 16.32 12C16.32 11.3342 16.1641 10.7063 15.8943 10.1428L15.1621 10.874C15.2879 11.2263 15.36 11.6049 15.36 12C15.36 13.8528 13.8528 15.36 12 15.36C10.1472 15.36 8.63996 13.8528 8.63996 12C8.63996 10.1472 10.1472 8.63996 12 8.63996C12.7535 8.63996 13.4472 8.89246 14.0081 9.31309L12.2475 11.0737C12.1668 11.0517 12.0836 11.0404 12 11.04C11.7454 11.04 11.5012 11.1411 11.3211 11.3211C11.1411 11.5012 11.04 11.7454 11.04 12C11.04 12.2546 11.1411 12.4987 11.3211 12.6788C11.5012 12.8588 11.7454 12.96 12 12.96C12.2546 12.96 12.4987 12.8588 12.6788 12.6788C12.8588 12.4987 12.96 12.2546 12.96 12C12.9599 11.9161 12.9489 11.8325 12.9271 11.7515L18.9187 5.75996H20.16L22.56 3.35996H20.64V1.43996L18.7809 3.29902C16.9079 1.83565 14.5556 0.959961 12 0.959961Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_478_9443">
        <rect width={width} height={height} fill="none" />
      </clipPath>
    </defs>
  </svg>
)
