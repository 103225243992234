import imageCompression from "browser-image-compression"

export async function compressFile(file, mediaType = "image") {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1200,
    useWebWorker: true
  }

  // We do not compress video.
  if (mediaType !== "image") return file

  try {
    const compressedBlob = await imageCompression(file, options)
    // If you want to work with the File. Let's convert it here, by adding a couple of attributes
    compressedBlob.lastModifiedDate = new Date()

    // Convert the blob to file
    const convertedBlobFile = new File([compressedBlob], file.name, {
      type: file.type,
      lastModified: Date.now()
    })

    return convertedBlobFile
  } catch (error) {
    console.log(error)
  }
}
