import React from "react"

export const Chaise = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_407_18208)">
      <path
        d="M9.99004 0.959922C9.91879 0.974922 9.85316 1.00492 9.79504 1.04992L1.15504 7.28992C0.990039 7.40617 0.916914 7.6143 0.969414 7.8093C1.02379 8.0043 1.19441 8.14492 1.39504 8.15992L6.67504 8.63992C6.69004 8.63992 6.70504 8.63992 6.72004 8.63992H9.60004V17.2799H10.56V8.63992H13.44C13.455 8.63992 13.47 8.63992 13.485 8.63992L18.765 8.15992C18.9657 8.14492 19.1363 8.0043 19.1907 7.8093C19.2432 7.6143 19.17 7.40617 19.005 7.28992L10.365 1.04992C10.2582 0.969297 10.1213 0.935547 9.99004 0.959922ZM10.08 2.51992L12.66 7.67992H7.50004L10.08 2.51992ZM8.77504 2.96992L6.43504 7.64992L2.77504 7.31992L8.77504 2.96992ZM11.4 2.98492L17.385 7.31992L13.725 7.64992L11.4 2.98492ZM22.05 13.4249C22.0088 13.4305 21.9694 13.4399 21.93 13.4549C21.8888 13.4643 21.8475 13.4793 21.81 13.4999L13.32 17.7599H1.44004C1.42504 17.7599 1.41004 17.7599 1.39504 17.7599C1.14754 17.7824 0.958164 17.9905 0.960039 18.2399V20.1599C0.960039 20.4243 1.17566 20.6399 1.44004 20.6399H2.40004V22.5599C2.40004 22.8243 2.61566 23.0399 2.88004 23.0399H4.80004C5.06441 23.0399 5.28004 22.8243 5.28004 22.5599V20.6399H18.72V22.5599C18.72 22.8243 18.9357 23.0399 19.2 23.0399H21.12C21.3844 23.0399 21.6 22.8243 21.6 22.5599V20.6399H22.56C22.8244 20.6399 23.04 20.4243 23.04 20.1599V18.2399C23.04 17.9755 22.8244 17.7599 22.56 17.7599H21.48L20.73 15.1199L22.23 14.3699C22.4813 14.3099 22.6407 14.0643 22.5919 13.8112C22.5432 13.558 22.305 13.3874 22.05 13.4249ZM19.845 15.5699L20.475 17.7599H15.48L19.845 15.5699ZM1.92004 18.7199H22.08V19.6799H1.92004V18.7199ZM3.36004 20.6399H4.32004V22.0799H3.36004V20.6399ZM19.68 20.6399H20.64V22.0799H19.68V20.6399Z"
        fill="#272A34"
      />
    </g>
    <defs>
      <clipPath id="clip0_407_18208">
        <rect width={width} height={height} fill="none" />
      </clipPath>
    </defs>
  </svg>
)
