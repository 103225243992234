import React from "react"
import { Col, Row } from "reactstrap"
import DocumentsForm from "components/guide/settings/DocumentsForm"

import { useTitle } from "hooks/useTitle"

export default function Documents() {
  useTitle("Settings — Documents")

  return (
    <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
      <Col xs={12}>
        <DocumentsForm />
      </Col>
    </Row>
  )
}
