import { Avatar as AntdAvatar } from "antd"

function getAvatarAlt(fullName) {
  const nameParts = fullName.split(" ")
  const firstName = nameParts[0]
  const lastName = nameParts[1]
  const avatarAlt = firstName.charAt(0).toUpperCase() + "" + lastName.charAt(0).toUpperCase()

  return avatarAlt
}

export const Avatar = ({ src, fullName, ...rest }) => {
  return (
    <AntdAvatar src={src} {...rest}>
      {getAvatarAlt(fullName)}
    </AntdAvatar>
  )
}
