import { createAction, createReducer } from "@reduxjs/toolkit";

// ACTIONS
const slides = createAction("lightbox/setSlides");
const activeSlide = createAction("lightbox/setActiveSlide");

// REDUCER
const initialState = {
  activeSlide: null,
  slides: []
};

const lightboxReducer = createReducer(initialState, {
  [slides.type]: (state, action) => {
    state.slides = action.payload;
  },
  [activeSlide.type]: (state, action) => {
    state.activeSlide = action.payload
  },
});

export default lightboxReducer;

// PUBLIC ACTIONS
export const setSlides = (items) => slides(items);

export const setActiveSlide = (index) => activeSlide(index);
