import React from "react"
import { Link, isRouteErrorResponse, useNavigate, useRouteError } from "react-router-dom"
import Icon from "components/common/Icon"

export default function RouteErrorBoundary() {
  const error = useRouteError()
  const isRouteError = isRouteErrorResponse(error)
  const navigate = useNavigate()

  const routeError404 = (
    <>
      <h1 className="h3 lh-1 text-danger">
        {error.status} {error.statusText}
      </h1>
      <p className="h2 lh-sm mt-15">Oh no! We can’t find the page you’re looking for.</p>
      <p className="h5 fw-normal lh-1 mt-15">{error?.error?.message}</p>
      <div className="fs-6 fw-medium mt-50">
        <div className="hstack gap-1 small link-target">
          <Icon iconName="ArrowLeft" width={18} height={10} />
          Back to{" "}
          <Link to="/" onClick={() => navigate(-1)} className="text-primary-hover text-decoration-none">
            Previous Page
          </Link>
        </div>
      </div>
    </>
  )

  const routeError = (
    <>
      <h1 className="h3 lh-sm text-danger">
        {error.status} {error.statusText}
      </h1>
      <p className="h2 lh-1 mt-15">{error?.error?.message}</p>
      <div className="fs-6 fw-medium mt-50">
        <div className="hstack gap-1 small link-target">
          <Icon iconName="ArrowLeft" width={18} height={10} />
          Back to{" "}
          <Link to="/" onClick={() => navigate(-1)} className="text-primary-hover text-decoration-none">
            Previous Page
          </Link>
        </div>
      </div>
    </>
  )

  const otherError = (
    <>
      <p className="h2 lh-sm">Something went wrong!</p>
      <div className="fs-6 fw-medium mt-50">
        <div className="hstack gap-1 small link-target">
          <Icon iconName="ArrowLeft" width={18} height={10} />
          Back to{" "}
          <Link to="/" onClick={() => navigate(-1)} className="text-primary-hover text-decoration-none">
            Previous Page
          </Link>
        </div>
      </div>
    </>
  )

  if (isRouteError) return (error.status === 404 && routeError404) || routeError

  return otherError
}
