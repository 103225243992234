import React, { useCallback, useEffect } from "react"
import { Container } from "reactstrap"
import WithEmptyState from "components/common/WithEmptyState"
import Preview from "components/boats/Preview"
import FirstLoading from "modules/loader-watchers/FirstLoading"

import useLoaderWatchers from "modules/loader-watchers/hooks/useLoaderWatchers"
import { useTitle } from "hooks/useTitle"
import { isLastPage } from "helpers/pagination"
import { Waypoint } from "react-waypoint"

import { useDispatch, useSelector } from "react-redux"
import { cleanBoats, getClientGuideBoats } from "store/boats"
import { collectionAnySelector, collectionSelector, metadataSelector, modelSelector } from "store/selectors"

export default function Boats() {
  useLoaderWatchers({ watchers: "boats" })
  const guide = useSelector(modelSelector("guide"))
  const boats = useSelector(collectionSelector("boats"))
  const hasBoats = useSelector(collectionAnySelector("boats"))
  const metadata = useSelector(metadataSelector("boats"))
  const dispatch = useDispatch()
  useTitle(`Boats | ${guide.display_name}`)

  const fetchBoats = useCallback((nextPage = false) => dispatch(getClientGuideBoats(guide.slug, nextPage)), [dispatch, guide.slug])

  useEffect(() => {
    fetchBoats()
    return () => dispatch(cleanBoats())
  }, []) //eslint-disable-line

  return (
    <Container className="vstack gap-30">
      <FirstLoading name="boats">
        <WithEmptyState name="boat" hasItems={hasBoats}>
          {boats.map((boat, index) => (
            <Preview key={boat.id} isOdd={(index + 1) % 2 !== 0} boat={boat} />
          ))}
          {!isLastPage(metadata) && <Waypoint onEnter={() => fetchBoats(true)} />}
        </WithEmptyState>
      </FirstLoading>
    </Container>
  )
}
