import Loading from "modules/loader-watchers/Loading"
import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"

const SupportForm = ({ onSubmit }) => {
  const mounted = useRef(false)
  const [hbLoading, setHBLoading] = useState(false)

  const mountHubSpotForm = () => {
    window.hbspt.forms.create({
      region: "na1",
      portalId: "44114474",
      formId: "131d5a1a-6953-4e50-977a-ca477975ac09",
      target: ".hbspt-form",
      onFormReady: () => setHBLoading(false),
      onFormSubmitted: onSubmit
    })
  }
  useEffect(() => {
    if (mounted.current) return
    setHBLoading(true)
    mounted.current = true
    const script = document.createElement("script")

    script.src = "//js.hsforms.net/forms/embed/v2.js"
    script.addEventListener("load", mountHubSpotForm)
    document.body.appendChild(script)

    return () => {
      script.removeEventListener("load", mountHubSpotForm)
      document.body.removeChild(script)
    }
  }, []) //eslint-disable-line

  return (
    <Loading loading={hbLoading} className="h-100">
      <div className="p-20 bg-white rounded">
        <h3 className="mb-2">Customer Support</h3>
        <div className="hbspt-form p-2" />
      </div>
    </Loading>
  )
}

SupportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
}
export default SupportForm
