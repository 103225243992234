import React, { useEffect, useMemo, useState } from "react"
import { Button } from "reactstrap"
import Icon from "components/common/Icon"

import { useGoogleMap } from "@react-google-maps/api"
import { currentLocation, getLocalStorageBrowserLocation, updateLocalStorageBrowserLocation } from "helpers/location"
import { isEqual } from "lodash"

import { DEFAULT_ZOOM } from "constants/map"

const CurrentLocationButton = () => {
  const map = useGoogleMap()
  const prevBrowserLocation = useMemo(() => getLocalStorageBrowserLocation(), [])
  const [browserLocation, setBrowserLocation] = useState(prevBrowserLocation)
  const { center } = map
  const centerLatLng = useMemo(() => center && { lat: center.lat(), lng: center.lng() }, [center])

  const setBrowserLocationHandler = () => {
    if (!browserLocation.lat || !browserLocation.lng) return
    map.setZoom(browserLocation.zoom || DEFAULT_ZOOM)
    map.setCenter(browserLocation)
  }

  useEffect(() => {
    if (!center) setBrowserLocationHandler()
  }, [browserLocation?.lat, browserLocation?.lng]) //eslint-disable-line

  useEffect(() => {
    let mounted = true
    currentLocation((latLng) => mounted && updateLocalStorageBrowserLocation(latLng, setBrowserLocation))
    return () => (mounted = false)
  }, []) //eslint-disable-line

  return (
    browserLocation &&
    !browserLocation.default &&
    !isEqual(browserLocation, centerLatLng) && (
      <Button color="white" onClick={setBrowserLocationHandler} className="btn-sm p-2 fs-7 shadow">
        <Icon iconName="Home" size={16} />
      </Button>
    )
  )
}
export default CurrentLocationButton
