import React, { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"

function ScrollToTop() {
  const { pathname, state } = useLocation()
  const { scrollToTop } = state || {}

  useEffect(() => {
    if (scrollToTop) window.scrollTo(0, 0)
  }, [pathname, scrollToTop])

  return <Outlet />
}

export default ScrollToTop
