import React, { memo } from "react"
import { Link, useSearchParams } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import SidebarStepLink from "components/wizards/StepLink"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { Button } from "reactstrap"

function SidebarNavigation() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  return (
    <div className="flex-shrink-0 d-flex flex-md-column min-h-100" style={{ "--gap": `${40}px`, gap: "var(--gap)" }}>
      <div className="hstack gap-10 gap-md-15 mt-auto mt-md-0 mb-auto mb-md-n10 text-nowrap">
        <Button
          tag={Link}
          to={searchParams.has("back_url") ? searchParams.get("back_url") : oldRoutes.guideTripsPath()}
          color="ghost"
          className="p-2"
        >
          <Icon iconName="ArrowLeft" size={18} block />
        </Button>
        <h4 className="fs-md-4">Add New Trip</h4>
      </div>
      <SidebarStepLink stepNumber={1} name="trips.trip" label={t("trip.wizard.step_1.label")} />
      <SidebarStepLink stepNumber={2} name="trips.trip" label={t("trip.wizard.step_2.label")} />
      <SidebarStepLink stepNumber={3} name="trips.trip" label={t("trip.wizard.step_3.label")} />
      <SidebarStepLink stepNumber={4} name="trips.trip" label={t("trip.wizard.step_4.label")} />
      <SidebarStepLink stepNumber={5} name="trips.trip" label={t("trip.wizard.step_5.label")} />
    </div>
  )
}

export default memo(SidebarNavigation)
