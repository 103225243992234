import React, { useEffect, useState } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import SplittedInput from "components/old/Shared/SplittedInput"
import { Button } from "reactstrap"
import { toast } from "react-toastify"

import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { verifyCode } from "store/user"

export default function VerifyCode() {
  const CODE_LENGTH = 6
  const [code, setCode] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const verified = useSelector((store) => store.user.verified)

  const onComplete = (inputValues) => {
    setCode(inputValues.join(""))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (code.length < CODE_LENGTH) return toast.error(t("verification.code_error"))

    const data = { verification_code: code }
    dispatch(verifyCode(data))
  }

  useEffect(() => {
    if (verified) {
      // toast.success("Verified user")
      navigate("/", { replace: true })
    }
  }, [verified, navigate])

  if (verified) return <Navigate to="/" />

  return (
    <>
      <div className="text-dark text-center">
        <h1 className="fs-1 fw-bold lh-sm">{t("verification.code_title")}</h1>
        <p className="lh-sm mt-30">{t("verification.code_details")}</p>
      </div>
      <h2 className="fs-2 lh-1 fw-bold text-white mt-10">Verify</h2>
      <form className="mt-40" onSubmit={handleSubmit}>
        <SplittedInput inputLength={CODE_LENGTH} containerClass="hstack gap-20" inputClass="invert" onCompleteHandler={onComplete} />
        <Button color="primary" type="submit" className="mt-30 w-100">
          {t("verification.code_button")}
        </Button>
      </form>
      <div className="mt-15 fs-5 text-end">
        <Link className="small fw-bold text-white text-primary-hover text-decoration-none" to="/verify-phone">
          {t("verification.code_link")}
        </Link>
      </div>
    </>
  )
}
