import React from "react"
import { Container, Button } from "reactstrap"
import heroImage from "assets/images/landing/Heroicastimagereduced2.png"
import iPhoneImage from "assets/images/landing/iphone-mockup-heroicast.png"

const Hero = () => {
  const scrollToRequestDemo = () => {
    const demoRequestEl = document.querySelector("#demo-request")
    demoRequestEl.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className="position-relative overflow-hidden py-60 py-sm-100">
      <img src={heroImage} className="position-absolute bottom-0 start-0 w-100 h-100 object-fit-cover" alt="DS Hero" />
      <Container className="py-60 py-sm-100">
        <div className="position-relative z-1 d-flex justify-content-between align-items-center">
          <div style={{ maxWidth: 620 }}>
            <h1 className="lh-1 text-white text-center text-sm-start mb-20">
              A Booking System & Business Management Platform for Adventure Guides
            </h1>
            <p className="text-white w-100 w-lg-75 text-center text-sm-start fs-4">
              Increase Revenue and Take Control of Your Business No Booking or Commission Fees, Seamless Payments, and Advanced Automation  
            </p>
            <div className="hstack gap-20 mt-45 flex-wrap">
              <Button color="primary-second" className="w-100 w-sm-auto" onClick={scrollToRequestDemo}>
                See Demo
              </Button>
            </div>
          </div>
          <img src={iPhoneImage} className="d-none d-md-block" alt="iPhone App" style={{ height: "526px" }} />
        </div>
      </Container>
    </div>
  )
}

export default Hero
