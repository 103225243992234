import React from "react"
import styles from "./Header.module.css"
import bgMan from "assets/imagesV2/logo_man.svg"
import bgWoman from "assets/imagesV2/logo_woman.svg"
import { useTheme } from "context/AntdThemeContext"
import { themes } from "constants/theme"
import { Button, Layout } from "antd"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { useDispatch } from "react-redux"
import { signOut } from "store/auth"
import { Icon } from "ui/Icon"
import { setProHomeMenuOpen } from "store/pro-home-menu"

export const ProHeader = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const dispatch = useDispatch()
  const logoSrc = theme === themes.sportswoman ? bgWoman : bgMan

  const logoutBtnClickHandler = () => {
    dispatch(signOut())
  }

  const menuBtnClickHandler = () => {
    dispatch(setProHomeMenuOpen())
  }

  const logoBtnClickHandler = () => {}

  return (
    <Layout className={styles.layout}>
      <div className={styles.header}>
        <Icon onClick={menuBtnClickHandler} className={styles.menuIcon} name="menu" color="currentColor" />
        <img onClick={logoBtnClickHandler} className={styles.logoImage} src={logoSrc} alt="" />
        <Button className="pr-xl pl-xl" color="primary" variant="outlined" size="large" onClick={logoutBtnClickHandler}>
          {t("header.log_out")}
        </Button>
      </div>
      <Outlet />
    </Layout>
  )
}
