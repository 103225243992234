import React from "react"
import { Link, useLocation } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import Step1Form from "components/bookings/wizard/Step1Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

import { useSelector } from "react-redux"

export default function Step1() {
  useTitle("Booking Wizard — Step 1")
  const { t } = useTranslation()
  const back_url = useLocation().pathname
  const booking = useSelector((store) => store.bookings.booking)
  const title = t("booking.wizard.step_1.title")
  const { id: tripId, guide, guide_display_name, title: tripTitle } = booking.trip
  const guidePath = oldRoutes.publicGuidePath({ slug: guide.slug }, { back_url })
  const tripPath = oldRoutes.tripPath({ id: tripId })

  return (
    <>
      <div className="">
        {title && <h1 className="h2 lh-1 mb-3">{title}</h1>}
        <div className="border-top border-gray-lightest pt-15 mb-15">
          <Link to={tripPath} className="h3 text-dark text-primary-second-hover link">
            {tripTitle}
          </Link>
        </div>
        <p className="text-gray-light fs-7 fw-medium mb-25">
          Hosted by&nbsp;
          <Link to={guidePath} className="text-dark link fw-semibold">
            {guide_display_name}
          </Link>
          .
        </p>
      </div>
      <DefaultErrorBoundary>
        <Step1Form />
      </DefaultErrorBoundary>
      <p className="text-center m-0 mt-3 fs-7">You won’t be charged yet</p>
    </>
  )
}
