import React, { useEffect, useMemo, useRef } from "react"
import { Navigate, useLocation, useMatch, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { dynamicRoute } from "router/routes"

import { get, invert, map, mapValues, pick, isEqual } from "lodash"
import { serialize } from "object-to-formdata"

import { useDispatch, useSelector } from "react-redux"

import { WIZARD_ACCEPTED_PATHS, WIZARD_PATHS, WIZARD_STEP_NUMBERS } from "constants/wizard"

const useWizardOptions = ({ name = "", stepsKey }) =>
  useMemo(() => {
    const obj = {}
    const itemName = name?.split(".").at(-1)

    obj.pathsKey = stepsKey?.split(".").at(-1) || itemName
    obj.steps = Object.keys(WIZARD_STEP_NUMBERS[obj.pathsKey] || {})

    obj.stepNumbers = WIZARD_STEP_NUMBERS[obj.pathsKey] || {}
    obj.stepNumbersInverted = invert(obj.stepNumbers)
    obj.stepsCount = Object.keys(obj.stepNumbers).length || 1
    obj.wizardPaths = pick(WIZARD_PATHS[obj.pathsKey], obj.steps)

    return obj
  }, [name, stepsKey])

const useWizard = ({ name = "", stepsKey, params = {}, search = {}, currentPath, currentWizardStep, customPrevStep, customNextStep }) => {
  const prevParams = useRef(params)
  const isParamsChanged = !isEqual(params, prevParams.current)
  prevParams.current = params

  const options = useWizardOptions({ name, stepsKey })

  return useMemo(() => {
    const params = isParamsChanged ? prevParams.current : prevParams.current
    const obj = {}

    const wizardPaths = mapValues(options.wizardPaths, (path) => dynamicRoute(path)(params))
    const wizardPathsValues = Object.values(wizardPaths)

    obj.currentStepName = options.steps.find((stepName) => currentPath.match(new RegExp("^" + wizardPaths[stepName], "g")))
    obj.currentStepNumber = options.stepNumbers[obj.currentStepName] || 1
    obj.isCurrentWizardStep = currentWizardStep === obj.currentStepName

    obj.firstStepPath = dynamicRoute(wizardPathsValues.at(0) || "")({}, search)
    obj.lastStepPath = dynamicRoute(wizardPathsValues.at(-1) || "")({}, search)

    obj.prevStepNumber = customPrevStep || obj.currentStepNumber - 1
    obj.prevStepName = options.stepNumbersInverted[obj.prevStepNumber]
    obj.prevStepPath = obj.currentStepNumber > 1 ? dynamicRoute(wizardPaths[obj.prevStepName])({}, search) : null

    obj.nextStepNumber = customNextStep || obj.currentStepNumber + 1
    obj.nextStepName = options.stepNumbersInverted[obj.nextStepNumber]
    obj.nextStepPath = obj.nextStepNumber <= options.stepsCount ? dynamicRoute(wizardPaths[obj.nextStepName])({}, search) : null

    const lastAvailablePath = wizardPaths[currentWizardStep]
    obj.lastAvailablePath = lastAvailablePath && dynamicRoute(lastAvailablePath)({}, search)

    const availableSteps = options.steps.slice(0, options.steps.indexOf(currentWizardStep) + 1)
    obj.availableSteps = pick(wizardPaths, availableSteps)

    const acceptedPaths = WIZARD_ACCEPTED_PATHS[options.pathsKey] || []
    obj.acceptedPaths = map(acceptedPaths, (path) => dynamicRoute(path)(params))

    obj.currentStepIsAvailable = !!obj.availableSteps[obj.currentStepName] || obj.acceptedPaths.includes(currentPath)

    return obj
  }, [options, isParamsChanged, search, currentPath, currentWizardStep, customPrevStep, customNextStep])
}

export const useWizardSteps = ({ name = "", stepsKey, customNextStep }) => {
  if (!name) throw new Error("useWizardSteps require name!")
  const params = useParams()
  const currentPath = useLocation().pathname
  const item = useSelector((state) => get(state, name))
  const { wizard_step, wizard_completed } = item

  const options = useWizard({ name, stepsKey, params, currentPath, currentWizardStep: wizard_step, customNextStep })

  const updateStep = (object) => {
    const form = { ...object }
    if (options.isCurrentWizardStep && !wizard_completed) form.wizard_step = options.nextStepName
    return form
  }

  return updateStep
}

export const useWizardStep = ({ name = "", stepsKey, stepNumber }) => {
  if (!name) throw new Error("useWizardSteps require name!")
  const params = useParams()
  const [search] = useSearchParams()
  const item = useSelector((state) => get(state, name))
  const itemName = name?.split(".").at(-1)
  const pathsKey = stepsKey?.split(".").at(-1) || itemName
  const { wizard_step, wizard_completed } = item

  const options = useWizardOptions({ name, stepsKey })

  const stepName = options.stepNumbersInverted[stepNumber]
  const to = dynamicRoute(WIZARD_PATHS[pathsKey][stepName])(params, search)

  const disabled = useMemo(() => {
    if (wizard_completed) return false
    if (typeof stepNumber === "number") return stepNumber > (options.stepNumbers[wizard_step] || 0)
    return true
  }, [stepNumber, wizard_completed, wizard_step, options.stepNumbers])

  return [to, disabled]
}

export const useWizardRedirects = ({
  name = "",
  stepsKey,
  rootPath,
  exitPath,
  loadingFallback = null,
  skipLastStepAfterCompleted = false
}) => {
  if (!name) throw new Error("useWizardSteps require name!")

  const params = useParams()
  const [search] = useSearchParams()
  const item = useSelector((state) => get(state, name))
  const { loading, firstLoaded, id, wizard_step, wizard_completed } = item

  const wizardCompletedPrevState = useRef(wizard_completed)

  const currentPath = useLocation().pathname
  const options = useWizard({ name, stepsKey, params, search, currentPath, currentWizardStep: wizard_step })

  const currentPageIsRootWizard = useMatch(rootPath)
  const currentPageIsLast = useMatch(options.lastStepPath)

  useEffect(() => {
    wizardCompletedPrevState.current = wizard_completed
  }, [wizard_completed])

  if (firstLoaded) {
    if (!id) return <Navigate to={exitPath} replace />
    if (wizard_completed) {
      if (currentPageIsRootWizard) return <Navigate to={options.firstStepPath} replace />
      if (currentPageIsLast) {
        if (wizardCompletedPrevState.current === false) return <Navigate to={exitPath} replace />
        else if (skipLastStepAfterCompleted) return <Navigate to={options.firstStepPath} replace />
      }
    } else if (!options.currentStepIsAvailable) return <Navigate to={options.lastAvailablePath} replace />
  } else if (loading) return loadingFallback
}

export const useWizardNavigation = ({ name, stepsKey, action, customNextStep, exitPath }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [search] = useSearchParams()
  const item = useSelector((state) => get(state, name))
  const itemName = name?.split(".").at(-1)
  const { id, uuid, wizard_completed, wizard_step } = item

  const currentPath = useLocation().pathname
  const options = useWizard({ name, stepsKey, params, search, currentPath, currentWizardStep: wizard_step, customNextStep })

  const skipStep = (event) => {
    if (event.persist) event.persist()
    if (wizard_completed) return
    const form = serialize({ [itemName]: { wizard_step: options.nextStepName } })
    dispatch(action(id || uuid, form)).then(() => navigate(options.nextStepPath))
  }

  const submitWizard = (event) => {
    if (event.persist) event.persist()
    if (wizard_completed) return
    const form = serialize({ [itemName]: { wizard_completed: true } })
    dispatch(action(id || uuid, form)).then(() => exitPath && navigate(exitPath))
  }

  return [options.isCurrentWizardStep, options.prevStepPath, options.nextStepPath, skipStep, submitWizard, wizard_completed]
}
