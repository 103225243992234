import React from 'react'
import { Container, Row, Col } from "reactstrap"
import ReferralForm from '../../../components/referral/ReferralForm'
import hero_referralImage from 'assets/images/referral/hero_referral.jpeg'

import call_them from "assets/icons/referral_page/call_them.svg"
import discount from "assets/icons/referral_page/discount.svg"
import filling_form from "assets/icons/referral_page/filling_form.svg"
import loop from "assets/icons/referral_page/loop.svg"

export default function ReferralPage() {
  return (
    <>
      <div className="position-relative overflow-hidden py-60 py-sm-100">
        <img src={hero_referralImage} className="position-absolute top-0 start-0 w-100 h-100 object-fit-cover" alt="Referral Hero"/>
        <Container className="py-30 py-sm-100 mb-100">
          <div className="position-relative z-1 pb-100 mb-sm-100">
            <div className="lh-1 text-white mb-sm-20 position-relative">
              <h2 className="fw-medium">DS Referral Program</h2>
              <h1>It pays to make connections</h1>
            </div>
          </div>
        </Container>
      </div>
      <div className="position-relative overflow-hidden py-60 py-sm-100">
        <Container className="vstack align-items-center">
          <h1 className="fw-semibold lh-sm text-center mb-25 mb-sm-50">
            Earn money when you refer 
            <br/>guides to Digital Sportsman
          </h1>
          <Row className="justify-content-between align-items-start w-100 gx-xl-100 gy-20">
            <Col xs={12} md={6} lg={{ size: "auto" }} className="d-flex flex-column align-items-center align-items-lg-start text-center text-lg-start">
              <img src={filling_form} className="mb-3" alt="Filling Form Icon" width="60px" />
              <div>
                <h2 className="fw-medium">Step 1</h2>
                <p>Fill out the form.</p>
              </div>
            </Col>
            <Col xs={12} md={6} lg={{ size: "auto" }} className="d-flex flex-column align-items-start align-items-center align-items-lg-start text-center text-lg-start">
              <img src={call_them} className="mb-3" alt="Call Them Icon" width="60px" />
              <div>
                <h2 className="fw-medium">Step 2</h2>
                <p>DS will contact your referral.</p>
              </div>
            </Col>
            <Col xs={12} md={6} lg={3} className="d-flex flex-column align-items-start align-items-center align-items-lg-start text-center text-lg-start">
              <img src={discount} className="mb-3" alt="Discount Icon" width="60px" />
              <div>
                <h2 className="fw-medium">Step 3</h2>
                <p>Once they become a DS client you will receive a commission after 90 days.</p>
              </div>
            </Col>
            <Col xs={12} md={6} lg={{ size: "auto" }} className="d-flex flex-column align-items-center align-items-lg-start text-center text-lg-start">
              <img src={loop} className="mb-3" alt="Loop Icon" width="60px" />
              <div>
                <h2 className="fw-medium">Step 4</h2>
                <p>Refer another guide! </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    <ReferralForm />
  </>
  );
};