import React from "react"
import { Button, Flex } from "antd"
import { spacings } from "themes/variables"
import { useTranslation } from "react-i18next"

export const SidebarActionButtons = () => {
  const { t } = useTranslation()

  return (
    <Flex vertical gap={spacings.S}>
      <Button color="primary" variant="solid" size="large">
        {t("trip.wizard.submit")}
      </Button>
      <Button color="default" variant="text" size="large">
        {t("trip.wizard.save")}
      </Button>
    </Flex>
  )
}
