import React from "react"
import PropTypes from "prop-types"
import PaymentMethodList from "./PaymentMethodList"
import PaymentMethodNew from "./PaymentMethodNew"
import InputError from "components/form/InputError"

const PaymentMethodSelector = ({ active, create = false, onChange, saveCard, onSaveCardChange, client = {}, className }) => {
  let classes = "vstack gap-15"
  if (className) classes = classes.concat(" ", className)

  return (
    <div className={classes}>
      <PaymentMethodList active={active} onChange={onChange} client={client} className="vstack gap-15" />
      <PaymentMethodNew
        active={active}
        saveCard={saveCard}
        onChange={onChange}
        onSaveCard={onSaveCardChange}
        create={create}
        client={client}
      />
      <InputError field="card_error" />
    </div>
  )
}

PaymentMethodSelector.propTypes = {
  active: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  create: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  saveCard: PropTypes.bool.isRequired,
  onSaveCardChange: PropTypes.func.isRequired,
  client: PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    address_line_1: PropTypes.string,
    address_line_2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    country: PropTypes.string,
    zip_code: PropTypes.string
  }),
  className: PropTypes.string
}

export default PaymentMethodSelector
