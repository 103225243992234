import React from "react"
import PropTypes from "prop-types"

import logoSrc from "assets/images/logo.jpg"
import logoWideSrc from "assets/images/logo_wide.jpg"
import sportswomanlogoSrc from "assets/images/sportswoman_logo.png"
import sportswomanlogoWideSrc from "assets/images/sportswoman_logo_wide.png"

Logo.propTypes = {
  wide: PropTypes.bool,
  height: PropTypes.number,
  is_sportswoman: PropTypes.bool
}

export default function Logo({ wide = false, height: heightProp, is_sportswoman = false, ...rest }) {
  const height = heightProp || 60
  const style = { mixBlendMode: "darken", ...rest.style }

  let logoSrcToUse
  let width

  if (is_sportswoman) {
    logoSrcToUse = wide ? sportswomanlogoWideSrc : sportswomanlogoSrc
    width = height * (wide ? 5.637 : 1)
  } else {
    logoSrcToUse = wide ? logoWideSrc : logoSrc
    width = height * (wide ? 4.637 : 1)
  }

  return <img {...rest} src={logoSrcToUse} width={width} height={height} alt="Logo" style={style} />
}
