import React, { useEffect } from "react"
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { toaster } from "components/common/Toast"
import LoginForm from "components/devise/LoginForm"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

export default function SignIn() {
  useTitle("Sign In")
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const from = location.state?.from?.pathname || "/"
  const passwordChanged = !!searchParams.get("password_changed")

  const loggedInHandler = () => navigate(from, { replace: true })

  useEffect(() => {
    if (!passwordChanged) return
    toaster.success(t("password.changed"))
    setSearchParams((searchParams.delete("password_changed"), searchParams))
  }, [passwordChanged]) //eslint-disable-line

  return (
    <>
      <h1 className="h2 lh-1 mb-0">Hi again,</h1>
      <p className="h3 lh-1 mt-15 mb-40">Welcome back</p>

      <LoginForm onLoggedIn={loggedInHandler} />

      <div className="mt-30 fs-6 fw-medium lh-1">
        <div className="small mb-3">
          Don’t have an account?{" "}
          <Link className="text-primary-hover text-decoration-none" to={oldRoutes.signUpPath()}>
            Sign Up
          </Link>
        </div>
        <Link className="small text-primary-hover text-decoration-none" to={oldRoutes.forgotPasswordPath()}>
          Forgot My Password
        </Link>
      </div>
    </>
  )
}
