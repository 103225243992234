import React from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { useTranslation } from "react-i18next"
import { numberToCurrency } from "helpers/string"

import { useSelector } from "react-redux"
import { metadataSelector } from "store/selectors"

Profit.propTypes = {
  params: PropTypes.shape({
    overview_range: PropTypes.string.isRequired,
    date_gteq: PropTypes.string,
    date_lteq: PropTypes.string
  }).isRequired
}

export default function Profit({ params }) {
  const { t } = useTranslation()
  const isCurrentMonth = params.overview_range === "current_month"

  const metadata = useSelector(metadataSelector("accounting"))
  const { incomes_stats, expenses_stats, profit_stats } = metadata

  const totalForDates = {
    current_month: moment().format("MMMM YYYY"),
    last_month: moment().subtract(1, "months").format("MMMM YYYY"),
    last_30_days: `${moment().subtract(30, "days").format("MMM DD, YYYY")} — ${moment().format("MMM DD, YYYY")}`,
    total_by_scope: `${moment(params.date_gteq).format("MMM DD, YYYY")} — ${moment(params.date_lteq).format("MMM DD, YYYY")}`
  }
  const dates = totalForDates[params.overview_range]

  // Function to determine the message based on profit change
  const getProfitChangeMessage = (monthly_diff) => {
    const numericValue = parseFloat(monthly_diff)
    const action = numericValue >= 0 ? "Increase" : "Decrease"
    return t(`accounting.values.value_change_from_last_month`, { action, value: monthly_diff })
  }

  return (
    <div className="bg-white rounded p-15 h-100 vstack">
      <h3 className="h4 fw-normal mb-10">{t(`accounting.labels.profit`)}</h3>
      <p className="fs-7 mb-0">{t(`accounting.values.net_profit`, { dates })}</p>
      <p className="fw-bold mb-0">{numberToCurrency(profit_stats[params.overview_range])}</p>
      {isCurrentMonth && <p className="fs-7 text-dark text-opacity-50 mb-0">{getProfitChangeMessage(profit_stats.monthly_diff)}</p>}
      <div
        className="grid gap-x-20 gap-y-10 grid-rows-2 align-items-center flex-fill mt-25"
        style={{ gridTemplateColumns: "auto minmax(0, 1fr)" }}
      >
        <div>
          <div className="fs-5 fw-bold lh-1">{numberToCurrency(incomes_stats[params.overview_range])}</div>
          <div className="fs-7 text-dark text-opacity-50 lh-1">
            <small>{t(`accounting.labels.incomes2`)}</small>
          </div>
        </div>
        <div
          className="bg-primary rounded-1 py-10 my-auto"
          style={{
            width: `${
              (+incomes_stats[params.overview_range] * 100) /
              (+incomes_stats[params.overview_range] + +expenses_stats[params.overview_range])
            }%`
          }}
        />
        <div>
          <div className="fs-5 fw-bold lh-1">{numberToCurrency(expenses_stats[params.overview_range])}</div>
          <div className="fs-7 text-dark text-opacity-50 lh-1">
            <small>{t(`accounting.labels.expenses`)}</small>
          </div>
        </div>
        <div
          className="bg-primary-second rounded-1 py-10 my-auto"
          style={{
            width: `${
              (+expenses_stats[params.overview_range] * 100) /
              (+incomes_stats[params.overview_range] + +expenses_stats[params.overview_range])
            }%`
          }}
        />
      </div>
    </div>
  )
}
