import React from "react"
import PropTypes from "prop-types"

const ErrorBoundaryFallback = ({ error }) => {
  return (
    <div className="alert alert-danger">
      <div className="hstack justify-content-between gap-10">
        <h1 className="h6 lh-sm text-danger">{error.message}</h1>
      </div>
      <p className="fs-7 lh-sm mt-10 mb-0">{error.stack}</p>
    </div>
  )
}

ErrorBoundaryFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    stack: PropTypes.string
  }).isRequired
}

export default ErrorBoundaryFallback
