import React, { memo } from "react"
import PropTypes from "prop-types"
import { Button } from "reactstrap"

import moment from "moment"

import { DATE_FORMAT } from "modules/datepicker/constants"

DateTabs.propTypes = {
  params: PropTypes.shape({
    date: PropTypes.string
  }).isRequired,
  onChangeFilters: PropTypes.func.isRequired
}
function DateTabs({ params, onChangeFilters }) {
  const today = moment()
  const activeDate = params.date || today.format(DATE_FORMAT)

  const clickHandler = ({ currentTarget }) => {
    const value = currentTarget.value
    const isToday = today.isSame(value, "day")
    onChangeFilters({ target: { name: "date", value: isToday ? null : value } })
  }

  return (
    <div className="hstack gap-20 align-items-center w-100">
      {new Array(7).fill().map((_, index) => {
        const date = today.clone().add(index, "day")
        const isActive = date.isSame(activeDate, "day")
        return (
          <Button
            color={isActive ? "primary-second" : "light"}
            className="flex-fill py-10 px-15"
            key={index}
            value={date.format(DATE_FORMAT)}
            onClick={clickHandler}
          >
            {date.format("ddd DD")}
          </Button>
        )
      })}
    </div>
  )
}

export default memo(DateTabs)
