import { oldRoutes } from "router/old-routes"

export const WIZARD_STEP_NUMBERS = {
  guide: {
    activity: 1,
    profile: 2,
    photos: 3,
    submit: 4
  },
  trip: {
    trip_details: 1,
    photos: 2,
    boats: 3,
    location: 4,
    preview: 5
  },
  bookingClient: {
    booking_details: 1,
    accessibility: 2,
    payment: 3,
    confirm: 4
  },
  bookingGuest: {
    booking_details: 1,
    accessibility: 2,
    payment: 3,
    confirm: 4
  }
}

export const WIZARD_PATHS = {
  guide: {
    activity: oldRoutes.guideWizardStep1Path(),
    profile: oldRoutes.guideWizardStep2Path(),
    photos: oldRoutes.guideWizardStep3Path(),
    submit: oldRoutes.guideWizardStep4Path()
  },
  trip: {
    trip_details: oldRoutes.guideTripWizardStep1Path(),
    photos: oldRoutes.guideTripWizardStep2Path(),
    boats_new: oldRoutes.guideTripWizardStep3AddNewBoatPath(),
    boats: oldRoutes.guideTripWizardStep3Path(),
    location: oldRoutes.guideTripWizardStep4Path(),
    preview: oldRoutes.guideTripWizardStep5Path()
  },
  bookingClient: {
    booking_details: oldRoutes.clientBookingWizardStep1Path(),
    accessibility: oldRoutes.clientBookingWizardStep2Path(),
    payment: oldRoutes.clientBookingWizardStep3Path(),
    confirm: oldRoutes.clientBookingWizardStep4Path()
  },
  bookingGuest: {
    booking_details: oldRoutes.guestBookingWizardStep1Path(),
    accessibility: oldRoutes.guestBookingWizardStep2Path(),
    payment: oldRoutes.guestBookingWizardStep3Path(),
    confirm: oldRoutes.guestBookingWizardStep4Path()
  }
}

export const WIZARD_ACCEPTED_PATHS = {
  guide: [],
  trip: [oldRoutes.guideTripWizardStep3AddNewBoatPath()],
  bookingClient: [],
  bookingGuest: []
}
