import React, { memo } from "react"
import PropTypes from "prop-types"
import { Button, Label, Table } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"
import Input from "components/form/Input"
import Icon from "components/common/Icon"

import { useTranslation } from "react-i18next"
import { useNestedFields } from "hooks/useForm"
import moment from "moment"

import { DATE_SHORT_TEXT_FORMAT } from "modules/datepicker/constants"

const Notes = ({ form, changeHandler, isChanged, formActive, edit }) => {
  const { t } = useTranslation()
  const [formNote, noteChangeHandler, addNoteHandler, removeNoteHandler, editNoteHandler, updateNoteHandler, isNotePersisted] =
    useNestedFields(form, "notes", ["content"], changeHandler)

  const notes = form.notes.filter((note) => !note._destroy)
  const hasNotes = !!notes?.length

  return (
    <div className="vstack bg-white rounded p-20">
      <div>
        <div className="hstack gap-10 mb-2">
          <Label for="notes" className="fs-6 m-0">
            {t("client.notes")}
          </Label>
          {isChanged && edit && !formActive && (
            <Button color="primary-second" className="fs-7 px-4 py-2 ms-auto">
              {t("global.save")}
            </Button>
          )}
        </div>
        {hasNotes && (
          <Table hover className="mb-20">
            <thead>
              <tr className="border-bottom border-gray-lightest h6 ">
                <th className="text-dark text-opacity-50 fw-normal ps-0" width="1">
                  Date
                </th>
                <th className="text-dark text-opacity-50 fw-normal">Notes</th>
                <th className="pe-0" width="1" />
              </tr>
            </thead>
            <tbody>
              {notes.map((note, index) => (
                <tr key={index} className="border-bottom border-gray-lightest fs-5 fw-normal">
                  <td className="text-dark text-opacity-75 ps-0 text-nowrap">{note.date || moment().format(DATE_SHORT_TEXT_FORMAT)}</td>
                  <td className="text-dark text-opacity-75">
                    {[note.id || "", note._id || ""].includes(isNotePersisted) ? (
                      <div className="position-relative">
                        <Input
                          id="edit_note"
                          type="textarea"
                          name="content"
                          value={formNote.content || ""}
                          onChange={noteChangeHandler}
                          className="fs-6"
                          withError
                        />
                        <Button
                          color="link"
                          className="p-2 border-0 bg-primary-second-hover text-white-hover position-absolute end-0 top-50 translate-middle-y me-2 link-dark"
                          onClick={updateNoteHandler}
                        >
                          <Icon iconName="Update" className="d-block" size={14} />
                        </Button>
                      </div>
                    ) : (
                      note.content
                    )}
                  </td>
                  <td className="text-dark text-opacity-75 pe-0" valign="middle">
                    <Dropdown className="my-n1">
                      <DropdownItem onClick={() => editNoteHandler(note)}>{t("global.edit")}</DropdownItem>
                      <DropdownItem onClick={() => removeNoteHandler(note)}>{t("global.delete")}</DropdownItem>
                    </Dropdown>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
      {!isNotePersisted && (
        <div className="position-relative">
          <Input
            id="note"
            type="textarea"
            name="content"
            value={formNote.content || ""}
            onChange={noteChangeHandler}
            className="fs-6"
            withError
          />
          <Button
            color="link"
            className="p-1 border-0 bg-primary-second-hover text-white-hover position-absolute end-0 top-50 translate-middle-y me-2 link-dark"
            onClick={addNoteHandler}
          >
            <Icon iconName="Add" className="d-block" />
          </Button>
        </div>
      )}
    </div>
  )
}

Notes.propTypes = {
  form: PropTypes.shape({
    notes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        _id: PropTypes.string,
        date: PropTypes.string,
        content: PropTypes.string,
        _destroy: PropTypes.bool
      })
    ).isRequired
  }).isRequired,
  changeHandler: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired,
  formActive: PropTypes.bool.isRequired,
  edit: PropTypes.bool.isRequired
}

export default memo(Notes)
