import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { toaster } from "components/common/Toast"
import i18n from "i18n"

import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("contactUs/requested")
const sent = createAction("contactUs/sent")
const failed = createAction("contactUs/failed")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE
}

const contactUsReducer = createReducer(initialState, {
  [requested.type]: (state, action) => {
    setRequested(state)
  },
  [sent.type]: (state, action) => {
    setReceived(state)
  },
  [failed.type]: (state, action) => {
    setFailed(state)
    toaster.error(i18n.t(action.payload.message))
  }
})

export default contactUsReducer

// PUBLIC ACTIONS
export const sendEnquiry = (data) =>
  api({
    url: "/contact_us",
    method: "post",
    data,
    onStart: requested.type,
    onSuccess: sent.type,
    onError: failed.type
  })
