import { Select } from 'antd';
import './Select.css';
import classNames from 'classnames';

export const CustomSelect = ({ ...props }) => {
    const { borderless } = props;
    return (
        <Select
            {...props}
            className={classNames('custom-select', borderless && 'custom-borderless-select')}
        />
    );
};
