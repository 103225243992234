export const BREAKPOINTS = {
  xs: 0,
  xsm: 425,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1320,
  xxl: 1600
}

export const BREAKPOINT_NAMES = Object.keys(BREAKPOINTS)
