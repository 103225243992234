import React, { createContext, useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import actioncable from "actioncable"

import API from "constants/api"

const CableContext = createContext(null)

const Cable = ({ children }) => {
  const token = localStorage.getItem("access_token")
  const [cable, setCable] = useState(null)

  useEffect(() => {
    if (!token) return
    const cable_url = `${API.WS_URL}?access_token=${token}`
    if (token) setCable(actioncable.createConsumer(cable_url))
    return () => setCable(null)
  }, [token])

  return <CableContext.Provider value={cable}>{children}</CableContext.Provider>
}

Cable.propTypes = {
  children: PropTypes.node.isRequired
}

export const useCable = () => useContext(CableContext)
export default Cable
