import React from "react"
import classNames from "classnames"
import { List } from "antd"
import { Avatar } from "ui/Avatar"

import { normalizeSendDate, normalizeSendDateAndTime } from "../utils"

import styles from "./Message.module.css"
import { Text } from "ui/Typography"

export const Message = ({ message, isOwnMessage }) => {
  // const isMessageUnviewed = !message.viewed || message.nestedMessages.find((msg) => !msg.viewed)

  return (
    <List.Item className={styles.messageWrapper}>
      <List.Item.Meta
        description={
          <>
            {message.isFirstMessageOfNewDay && (
              <div className="justify-center mb-m">
                <Text variant="caption" type="secondary">
                  {normalizeSendDate(message.created_at)}
                </Text>
              </div>
            )}
            {/* {isMessageUnviewed && <div className={styles.unread}>Unread Messages</div>} */}
            <div className={classNames("flex flex-grow-1", isOwnMessage && "justify-end", "mb-xxs")}>
              <div className={styles.avatarWrapper} />
              <div className="align-center">
                {!isOwnMessage && (
                  <Text className="mr-xxs" variant="caption" type="secondary">
                    {message.author.full_name}
                  </Text>
                )}
                <Text variant="caption" type="secondary">
                  {normalizeSendDateAndTime(message.created_at)}
                </Text>
              </div>
            </div>
            <div
              className={classNames(
                "flex flex-grow-1",
                isOwnMessage && styles.ownMessage,
                message.sameAuthorMessage && styles.sameAuthorMessage
              )}
            >
              <div className={styles.avatarWrapper}>
                {isOwnMessage ? null : <Avatar src={message.author.avatar} fullName={message.author.full_name} size={52} />}
              </div>
              <div className={classNames(styles.messageBody)}>
                <div className={styles.messages}>
                  <Text variant="body" style={{ color: isOwnMessage && "#ffffff" }}>
                    {message.body}
                  </Text>
                  {message.nestedMessages?.length > 0 &&
                    message.nestedMessages.map((msg) => (
                      <Text variant="body" style={{ color: isOwnMessage && "#ffffff" }}>
                        {msg.body}
                      </Text>
                    ))}
                </div>
              </div>
            </div>
          </>
        }
      />
    </List.Item>
  )
}
