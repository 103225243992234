import { numberFormat } from "helpers/string"

export const DEFAULT_RANGE = "today"

export const RANGE_OPTIONS = {
  today: "Today",
  "7_days": "7 day forecast"
}
export const DEFAULT_TYPE = "temperature"

export const TYPE_OPTIONS = {
  temperature: "Temperature",
  real_feel: "Real Feel",
  humidity: "Humidity",
  pressure: "Pressure",
  precipitation: "Precipitation",
  wind: "Wind",
  visibility: "Visibility",
  uv_index: "UV Index",
  moon: "Moon",
  sun: "Sun",
  tides: "Tides"
}

export const DEFAULT_UNITS = "imperial"

export const UNITS_OPTIONS = ["metric", "imperial"]

const TEMPERATURE_LABELS = [
  numberFormat("", { style: "unit", unit: "celsius" }).slice(1),
  numberFormat("", { style: "unit", unit: "fahrenheit" }).slice(1)
]
const PRESSURE_LABELS = ["hPa", "inHg"]
const PRECIPITATION_LABELS = [
  numberFormat("", { style: "unit", unit: "millimeter" }).slice(1),
  numberFormat("", { style: "unit", unit: "inch" }).slice(1)
]
const WIND_LABELS = [
  numberFormat("", { style: "unit", unit: "kilometer-per-hour" }).slice(1),
  numberFormat("", { style: "unit", unit: "mile-per-hour" }).slice(1)
]
const VISIBILITY_LABELS = [
  numberFormat("", { style: "unit", unit: "kilometer" }).slice(1),
  numberFormat("", { style: "unit", unit: "mile" }).slice(1)
]

const TIDE_LABELS = [
  numberFormat("", { style: "unit", unit: "meter" }).slice(1),
  numberFormat("", { style: "unit", unit: "foot" }).slice(1)
]

export const SHOW_UNITS_FOR_TYPES = {
  temperature: TEMPERATURE_LABELS,
  real_feel: TEMPERATURE_LABELS,
  pressure: PRESSURE_LABELS,
  precipitation: PRECIPITATION_LABELS,
  wind: WIND_LABELS,
  tides: TIDE_LABELS,
  visibility: VISIBILITY_LABELS
}

export const TIDE_TYPES = {
  L: "Low",
  H: "High"
}
