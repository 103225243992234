import React from "react"
import { AutoComplete, Form } from "antd"
import { Icon } from "ui/Icon"
import { Text } from "ui/Typography"
import { CustomForm } from "ui/Form"
import { CustomInput } from "ui/Input"
import { useTranslation } from "react-i18next"
import styles from "./Search.module.css"
import { colors } from "themes/variables"

export const AddressesSearch = ({ setManualAddressEnabled, form, initialValues }) => {
  const { t } = useTranslation()
  const [options, setOptions] = React.useState([])
  const [autocompleteService, setAutocompleteService] = React.useState(null)
  const [formattedAddress, setFormattedAddress] = React.useState()

  const onSelect = (value) => {
    if (value === "enter-manually") {
      setManualAddressEnabled()
      return
    }

    const selectedPlace = options.find((option) => option.value === value)

    if (selectedPlace && selectedPlace.id) {
      fetchPlaceDetails(selectedPlace.id)
      setFormattedAddress(selectedPlace.value)
    }
  }

  const fetchPlaceDetails = (placeId) => {
    if (!placeId) return

    const service = new window.google.maps.places.PlacesService(document.createElement("div"))
    service.getDetails(
      {
        placeId: placeId
      },
      (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && place) {
          const streetNumber = place.address_components.find((component) => component.types.includes("street_number"))?.long_name || ""
          const streetAddress = place.address_components.find((component) => component.types.includes("route"))?.long_name || ""
          const city = place.address_components.find((component) => component.types.includes("locality"))?.long_name || ""
          const state =
            place.address_components.find((component) => component.types.includes("administrative_area_level_1"))?.long_name ||
            "State not available"
          const postalCode = place.address_components.find((component) => component.types.includes("postal_code"))?.long_name || ""
          const country = place.address_components.find((component) => component.types.includes("country"))?.long_name || ""

          form.setFieldsValue({
            street_address: `${streetNumber} ${streetAddress}`.trim(),
            city: city,
            state: state,
            zip_code: postalCode,
            country: country
          })
        } else {
          console.error("Failed to fetch place details:", status)
        }
      }
    )
  }

  React.useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (window?.google?.maps) {
        const service = new window.google.maps.places.AutocompleteService()
        setAutocompleteService(service)
        return
      }

      const script = document.createElement("script")
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`
      script.async = true
      script.defer = true

      script.onload = () => {
        const service = new window.google.maps.places.AutocompleteService()
        setAutocompleteService(service)
      }

      document.head.appendChild(script)
    }

    loadGoogleMapsScript()
  }, [])

  React.useEffect(() => {
    const { street_address, city, state, zip_code, country } = initialValues
    const address = `${street_address}, ${city}, ${state}, ${zip_code}, ${country}`
    setFormattedAddress(address)
  }, [initialValues])

  const handleSearch = (value) => {
    if (!value) {
      setOptions([])
      return
    }

    autocompleteService.getPlacePredictions(
      {
        input: value,
        types: ["address"]
      },
      (predictions, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
          const formattedOptions = predictions.map((prediction) => {
            const highlightedDescription = prediction.description.replace(
              new RegExp(`(${value})`, "gi"),
              (match) => `<span class="fw-500 pre-wrap">${match}</span>`
            )

            return {
              value: prediction.description,
              label: (
                <div className="align-center">
                  <Icon type="feather" name="MapPin" width={18} height={18} color={colors.textSecondary} />
                  <Text className="ml-xs">
                    <div className="align-center pre-wrap" dangerouslySetInnerHTML={{ __html: highlightedDescription }} />
                  </Text>
                </div>
              ),
              id: prediction.place_id
            }
          })

          formattedOptions.push({
            value: "enter-manually",
            label: (
              <div className="align-center">
                <Text className="ml-xs" variant="link">
                  {t("pro.addresses.enter_manually")}
                </Text>
              </div>
            ),
            id: "enter-manually"
          })

          setOptions(formattedOptions)
        } else {
          setOptions([])
        }
      }
    )
  }

  return (
    <div className={styles.searchInput}>
      <Icon className={styles.pinIcon} type="feather" name="MapPin" width={18} height={18} />
      <AutoComplete
        placeholder="Type your address here"
        options={options}
        allowClear={{ clearIcon: <Icon type="feather" name="XCircle" width={18} height={18} /> }}
        onSelect={onSelect}
        onSearch={handleSearch}
        onChange={(val) => setFormattedAddress(val)}
        value={formattedAddress}
      />
      <CustomForm form={form} name="profile-information" layout="vertical" initialValues={initialValues} style={{ display: "none" }}>
        <Form.Item label={t("pro.addresses.street_address")} name="street_address" required={true}>
          <CustomInput />
        </Form.Item>

        <Form.Item label={t("pro.addresses.country")} name="country" required={true}>
          <CustomInput />
        </Form.Item>

        <Form.Item label={t("pro.addresses.state")} name="state" required={true}>
          <CustomInput />
        </Form.Item>

        <Form.Item label={t("pro.addresses.city")} name="city" required={true}>
          <CustomInput />
        </Form.Item>
        <Form.Item label={t("pro.addresses.zip")} name="zip_code" required={true}>
          <CustomInput />
        </Form.Item>
      </CustomForm>
    </div>
  )
}
