import { Typography } from "antd"
import { fontSize as appFontSizes } from "themes/variables"

export const Paragraph = ({ children, size = "md", style, ...rest }) => {
  let fs = size === "md" ? appFontSizes.MD : size === "lg" ? appFontSizes.LG : undefined
  const styles = {
    ...style,
    fontSize: fs
  }
  return (
    <Typography.Paragraph style={styles} {...rest}>
      {children}
    </Typography.Paragraph>
  )
}
