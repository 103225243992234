import React from "react"
import { Link, useSearchParams } from "react-router-dom"
import Icon from "components/common/Icon"

import { useTitle } from "hooks/useTitle"
import { useTranslation } from "react-i18next"
import { oldRoutes } from "router/old-routes"

export default function ResetPasswordSuccess() {
  useTitle("Reset Password — Success")
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const loginUrl = searchParams.get("login_url") || oldRoutes.signInPath()

  return (
    <>
      <h1 className="h2 lh-1">{t("reset_password.success")}</h1>
      <div className="fs-6 fw-medium mt-20">
        <div className="hstack d-inline-flex gap-1 small link-target">
          Go to{" "}
          <Link to={loginUrl} className="text-primary-hover text-decoration-none">
            Login
          </Link>
          <Icon iconName="ArrowLeft" width={18} height={10} className="rotate-180" />
        </div>
      </div>
    </>
  )
}
