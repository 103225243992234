import React from 'react'
import { Container } from "reactstrap"
import SalesForm from '../../../components/sales/SalesForm'

export default function SalesPage() {
  return (
      <Container className="h-100 w-100 pb-80 pb-sm-100">
      <div className="position-relative overflow-hidden py-60 py-sm-100 d-flex flex-column justify-content-center align-items-center text-center">
        <h2 className="fw-medium" style={{ color: '#0052B4', fontFamily: 'Roboto' }}>Contact Sales</h2>
        <h1 className="fw-semibold" style={{ color: '#141414', fontFamily: 'Epilogue' }}>Experience the future of  guiding <br /> with Digital Sportsman.</h1>
        <p className="fw-normal " style={{ color: '#141414', fontFamily: 'Roboto' }}>Fill out this form and a sales person will reach out to you.</p>
        <SalesForm/>
      </div>
    </Container>
  )
}