import React from "react"
import styles from "./MenuGroup.module.css"
import { MenuItem } from "./MenuItem"

export const MenuGroup = ({ menuItem }) => {
  const { groupName, items } = menuItem
  return (
    <div>
      <div className={styles.menuGroupHeader}>{groupName}</div>
      <div className={styles.menuGroupItems}>
        {items.map((item) => (
          <MenuItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  )
}
