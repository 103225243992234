import React from "react"
import Step2Form from "components/bookings/wizard/Step2Form"
import DefaultErrorBoundary from "modules/errors/DefaultErrorBoundary"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"

export default function Step2() {
  useTitle("Booking Wizard — Step 2")
  const { t } = useTranslation()
  const title = t("booking.wizard.step_2.title")
  const subtitle = t("booking.wizard.step_2.subtitle")

  return (
    <>
      <div className="">
        {title && <h1 className="h2 lh-1 mb-0">{title}</h1>}
        {subtitle && <p className="fs-4 mt-15 mb-20 text-dark">{subtitle}</p>}
      </div>
      <DefaultErrorBoundary>
        <Step2Form />
      </DefaultErrorBoundary>
    </>
  )
}
