import useSearchFilter from "hooks/useSearchFilter"
import PropTypes from "prop-types"
import React from "react"
import CalendarMonth from "./CalendarMonth"
import CalendarWeek from "./CalendarWeek"
import CalendarDay from "./CalendarDay"

const calendars = {
  month: CalendarMonth,
  week: CalendarWeek,
  date: CalendarDay
}

const Calendar = ({ className }) => {
  const [params] = useSearchFilter({ accessAttributes: ["range", "date", "stats_date"] })

  if (!params.date || !params.range) return null
  const Calendar = calendars[params.range]

  const classes = []
  if (className) classes.push(className)

  return <Calendar date={params.date} className={classes.join(" ")} />
}

Calendar.propTypes = {
  className: PropTypes.string
}

export default Calendar
