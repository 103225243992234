import React from "react"

export const Dashboard = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.9925 1.91334C11.9692 1.91384 11.9461 1.91603 11.9231 1.9199H4.32C3.00023 1.9199 1.92 3.00013 1.92 4.3199V14.7993C1.91143 14.8511 1.91143 14.904 1.92 14.9558V19.6799C1.92 20.9997 3.00023 22.0799 4.32 22.0799H11.9194C11.9712 22.0885 12.0241 22.0885 12.0759 22.0799H19.68C20.9998 22.0799 22.08 20.9997 22.08 19.6799V9.20052C22.0886 9.14869 22.0886 9.0958 22.08 9.04396V4.3199C22.08 3.00013 20.9998 1.9199 19.68 1.9199H12.0741C12.0471 1.91539 12.0198 1.9132 11.9925 1.91334ZM4.32 2.8799H11.52V9.03927C11.5114 9.09111 11.5114 9.144 11.52 9.19584V14.3999H2.88V4.3199C2.88 3.51903 3.51913 2.8799 4.32 2.8799ZM12.48 2.8799H19.68C20.4809 2.8799 21.12 3.51903 21.12 4.3199V8.6399H12.48V2.8799ZM12.48 9.5999H21.12V19.6799C21.12 20.4808 20.4809 21.1199 19.68 21.1199H12.48V14.9605C12.4886 14.9087 12.4886 14.8558 12.48 14.804V9.5999ZM2.88 15.3599H11.52V21.1199H4.32C3.51913 21.1199 2.88 20.4808 2.88 19.6799V15.3599Z"
      fill={fill}
    />
  </svg>
)
