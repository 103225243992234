import { Col, Row, Button } from "antd"
import { useSelector, useDispatch } from "react-redux"
import React from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "context/AntdThemeContext"
import { Modal } from "ui/Modal"
import { Title, Text } from "ui/Typography"
import { Card } from "./Card"
import { themes } from "constants/theme"
import { updateUser } from "store/user"
import { useDevice } from "hooks/useDevice"

export const AdventureModeSelect = () => {
  const currentUser = useSelector((state) => state.user)
  const { loading } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { theme, switchTheme, saveThemeToStorage } = useTheme()
  const { isMobile } = useDevice()

  const [isAdventureModeModalOpen, setIsAdventureModeModalOpen] = React.useState(true)
  const [mode, setMode] = React.useState(theme)
  const onSelect = (theme) => {
    setMode(theme)
    switchTheme(theme)
  }
  const handleCancelModal = () => setIsAdventureModeModalOpen(false)
  const { t } = useTranslation()

  const submitAdventureMode = () => {
    dispatch(updateUser({ ...currentUser, adventure_mode: mode })).then(() => {
      setIsAdventureModeModalOpen(false)
      saveThemeToStorage(mode)
    })
  }

  return (
    <Modal open={isAdventureModeModalOpen} onCancel={handleCancelModal} bodyStyle={{ paddingTop: "60px" }} width={800}>
      <div className="flex-column pt-4xl">
        <Title className="text-center" level={3}>
          {t("select_adventure_mode.title")}
        </Title>
        <Text variant="sub" className="text-center mt-xxl mb-4xl">
          {t("select_adventure_mode.subtitle")}
        </Text>

        <div className="justify-center">
          <Row gutter={24} style={{ maxWidth: "700px", width: "100%" }}>
            <Col span={12}>
              <Card
                type={themes.sportsman}
                bordered={false}
                selected={mode === themes.sportsman}
                onSelect={() => onSelect(themes.sportsman)}
              />
            </Col>
            <Col span={12}>
              <Card
                type={themes.sportswoman}
                bordered={false}
                selected={mode === themes.sportswoman}
                onSelect={() => onSelect(themes.sportswoman)}
              />
            </Col>
          </Row>
        </div>

        <div className="flex-column text-center mt-4xl pb-4xl">
          <Button
            className="mb-xs"
            type={"primary"}
            style={{ width: isMobile ? "100%" : "300px" }}
            size="large"
            onClick={submitAdventureMode}
            disabled={loading}
          >
            {t("global.continue")}
          </Button>
          <Text variant="caption">{t("select_adventure_mode.info")}</Text>
        </div>
      </div>
    </Modal>
  )
}
