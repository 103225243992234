import React, { memo } from "react"
import PropTypes from "prop-types"
import { Label, Table } from "reactstrap"
import { Dropdown, DropdownItem } from "components/common/Dropdown"

import { useTranslation } from "react-i18next"
import { filter } from "lodash"
import moment from "moment"

import { DATETIME_FORMAT } from "modules/datepicker/constants"

const displayFormat = (allDay) => `YYYY-MM-DD${allDay ? "" : " HH:mm"}`

const ExistingUnavailableDays = ({ unavailableDays = [], type, onEdit, onDelete }) => {
  const { t } = useTranslation()
  const filteredUnavailableDays = filter(unavailableDays, ["unavailable_type", type])
  const hasUnavailableDays = !!filteredUnavailableDays?.length
  const isRange = type === "range"

  if (!hasUnavailableDays) return null
  return (
    <div className="bg-white rounded p-20">
      <Label className="fs-6">{t("unavailable_day.labels.existing")}</Label>
      <Table hover responsive className="m-0">
        <thead>
          <tr className="border-bottom border-gray-lightest">
            <th className="text-dark text-opacity-50 fw-normal lh-1 ps-0">{t("unavailable_day.labels.recurring_yearly")}</th>
            {isRange ? (
              <>
                <th className="text-dark text-opacity-50 fw-normal lh-1">{t("unavailable_day.labels.all_day")}</th>
                <th className="text-dark text-opacity-50 fw-normal">{t("unavailable_day.labels.start_date")}</th>
                <th className="text-dark text-opacity-50 fw-normal">{t("unavailable_day.labels.end_date")}</th>
              </>
            ) : (
              <th className="text-dark text-opacity-50 fw-normal">{t("unavailable_day.labels.date")}</th>
            )}
            <th className="text-dark text-opacity-50 fw-normal pe-0" width="1" />
          </tr>
        </thead>
        <tbody>
          {filteredUnavailableDays.map((unavailableDay, index) => (
            <tr key={index} className="border-bottom border-gray-lightest fs-5 fw-normal">
              <td className="text-dark text-opacity-75 ps-0 text-nowrap">{unavailableDay.recurring_yearly ? "Yes" : "No"}</td>
              {isRange ? (
                <>
                  <td className="text-dark text-opacity-75 text-nowrap">{unavailableDay.all_day ? "Yes" : "No"}</td>
                  <td className="text-dark text-opacity-75">
                    {unavailableDay.start_datetime
                      ? moment(unavailableDay.start_datetime, DATETIME_FORMAT).format(displayFormat(unavailableDay.all_day))
                      : "—"}
                  </td>
                  <td className="text-dark text-opacity-75">
                    {unavailableDay.end_datetime
                      ? moment(unavailableDay.end_datetime, DATETIME_FORMAT).format(displayFormat(unavailableDay.all_day))
                      : "—"}
                  </td>
                </>
              ) : (
                <td className="text-dark text-opacity-75">
                  {unavailableDay.unavailable_date
                    ? moment(unavailableDay.unavailable_date, DATETIME_FORMAT).format(displayFormat(unavailableDay.all_day))
                    : "—"}
                </td>
              )}
              <td className="text-dark text-opacity-75 pe-0" valign="middle">
                <Dropdown className="my-n1" container="body">
                  <DropdownItem onClick={onEdit} value={unavailableDay.id}>
                    {t("global.edit")}
                  </DropdownItem>
                  <DropdownItem onClick={onDelete} value={unavailableDay.id} className="text-danger">
                    {t("global.delete")}
                  </DropdownItem>
                </Dropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

ExistingUnavailableDays.propTypes = {
  unavailableDays: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      unavailable_type: PropTypes.string.isRequired,
      recurring_yearly: PropTypes.bool,
      all_day: PropTypes.bool,
      start_datetime: PropTypes.string,
      end_datetime: PropTypes.string,
      unavailable_date: PropTypes.string
    })
  ),
  type: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
}
export default memo(ExistingUnavailableDays)
