import React, { memo } from "react"
import PropTypes from "prop-types"
import Icon from "./Icon"

CircleImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  size: PropTypes.number,
  border: PropTypes.bool,
  className: PropTypes.string,
  placeholderIconName: PropTypes.string
}

function CircleImage({ src, alt, size = 35, border = true, className, placeholderIconName, ...rest }) {
  const classes = ["rounded-circle object-fit-cover aspect-ratio-1"]
  className && classes.push(className)
  border && classes.push("border")
  !src && classes.push("p-0 border-light bg-gray-lightest text-gray-light")

  return src ? (
    <img src={src} alt={alt} className={classes.join(" ")} width={size} height={size} {...rest} />
  ) : (
    <Icon iconName={placeholderIconName} className={classes.join(" ")} width={size} height={size} {...rest} />
  )
}

export default memo(CircleImage)
