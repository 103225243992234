import React, { useState } from "react"
import { Button, UncontrolledAccordion, AccordionBody, AccordionHeader, Container, AccordionItem, TabContent, TabPane } from "reactstrap"
import { useTitle } from "hooks/useTitle"

import { useDispatch } from "react-redux"
import { showModal } from "modules/modals/reducer"

export default function Faq() {
  useTitle("FAQ")
  const [activeAccordion, setActiveAccordion] = useState("client")

  const dispatch = useDispatch()
  const openHandler = () => dispatch(showModal("support"))

  return (
    <div className="flex-fill bg-light py-100">
      <Container>
        <h1 className="h2 lh-sm text-center mx-auto mb-80">Frequently Asked Questions</h1>
        <div className="hstack gap-3 justify-content-center mb-55 w-100">
          <Button
            onClick={() => setActiveAccordion("client")}
            color="primary-second"
            outline={activeAccordion === "guide"}
            className="rounded"
          >
            Client
          </Button>
          <Button
            onClick={() => setActiveAccordion("guide")}
            color="primary-second"
            outline={activeAccordion === "client"}
            className="rounded"
          >
            Guide
          </Button>
        </div>
        <TabContent activeTab={activeAccordion} className="w-100 mx-auto mb-35" style={{ maxWidth: 780 }}>
          <TabPane tabId="client">
            <UncontrolledAccordion className="vstack gap-15" defaultOpen="client_1">
              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="client_1">
                  <h2 className="h4 lh-sm">Can anybody create an account?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="client_1">
                  Yes, all you need is an email to{" "}
                  <strong>
                    <a href="https://app.ds.guide/#/sign-up">create an account</a>
                  </strong>
                  .
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="client_3">
                  <h2 className="h4 lh-sm">Where will I meet the Guide?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="client_3">
                  All guides will have at least one location where they meet you as you will see on the trip details once booked.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="client_4">
                  <h2 className="h4 lh-sm">What happens if I need to cancel my trip?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="client_4">
                  If you cancel your booking 48hrs before the trip in advance, you will receive a full refund automatically. Anything
                  canceled within that 48hr window will not be refunded automatically, please contact your guide to make other arrangements.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="client_5">
                  <h2 className="h4 lh-sm">How do I book a trip?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="client_5">
                  You can book a{" "}
                  <strong>
                    <u>trip</u>
                  </strong>{" "}
                  3 simple steps:
                  <ol>
                    <li>Chose the date you want to book and how many people are going</li>
                    <li>Enter payment details</li>
                    <li>Receive booking confirmation</li>
                  </ol>
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </TabPane>

          <TabPane tabId="guide">
            <UncontrolledAccordion className="vstack gap-15" defaultOpen="guide_1">
              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_1">
                  <h2 className="h4 lh-sm">Will Digital Sportsman help me get more bookings?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_1">
                  Digital Sportsman will simplify the process of handling bookings, schedules, and payments. DS also provides guides with
                  marketing tools using Al to blast out emails and text to your client base.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_2">
                  <h2 className="h4 lh-sm">What documents are required for me to sign up as a Guide?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_2">
                  -Guide Certification<br></br>
                  -Captains License<br></br>
                  -Proof of Charter Insurance<br></br>
                  -Vessel Registration<br></br>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_3">
                  <h2 className="h4 lh-sm">How will I receive my payouts?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_3">Direct Deposit/Cash</AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_4">
                  <h2 className="h4 lh-sm">Can I manually add my recurring/past customers?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_4">
                  Yes. After logging into your account, you can go to your{" "}
                  <strong>
                    <u>Client Tab</u>
                  </strong>
                  , there you will find an add client button in the upper right hand corner. From there you will be able to input all of a
                  clients details and add them to your client list.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_5">
                  <h2 className="h4 lh-sm">Will I be able to communicate with my customers from inside the app?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_5">
                  Yes. Not only do you have the option to Email or Text your clients, we have enabled you to access Generative Al in
                  creating better descriptions for your emails/texts. The emails are fully customizable with the ability to add photos. You
                  can also leave messages in app for your clients. Visit your{" "}
                  <strong>
                    <u>marketing tab</u>
                  </strong>{" "}
                  to see more.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_6">
                  <h2 className="h4 lh-sm">What if my customer cancels a trip?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_6">
                  The default mode of cancellation: If the client cancels more than 48 hours in advance of the trip, then their money is
                  automatically refunded. After the 48 hours, the trip is canceled and their money is not refunded.<br></br>
                  Customizable: If the client calls/emails/texts and you are the one to cancel the the trip, you have options as to how to
                  handle the refund.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_7">
                  <h2 className="h4 lh-sm">How do I make a day unavailable on my calendar?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_7">
                  Go into your "Guide Portal" and navigate to the{" "}
                  <strong>
                    <u>"Dashboard"</u>
                  </strong>{" "}
                  section and at the top right of the calendar there is a button labeled{" "}
                  <strong>
                    <u>"Manage Unavailability"</u>
                  </strong>
                  . Click that and you will be able to schedule unavailable days.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_8">
                  <h2 className="h4 lh-sm">How will I know when someone has booked a trip?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_8">
                  The booking will be displayed in your calendar as soon as it's been made. You also Will be notified immediately by email
                  that a trip has been booked.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_9">
                  <h2 className="h4 lh-sm">Can I merge my customer account and my guide profile?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_9">
                  At this time you will have to create two accounts. One to book a trip which is the client account and one to create trips
                  which is the guide account.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_10">
                  <h2 className="h4 lh-sm">Can I talk to a real person for support?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_10">
                  We have full support available, please&nbsp;
                  <button className="link link-primary-second fw-semibold text-decoration-none" onClick={openHandler}>
                    contact us.
                  </button>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_12">
                  <h2 className="h4 lh-sm">Do I need a website?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_12">
                  No, you don't need a separate website. Digital Sportsman provides a comprehensive solution for all your needs. Your Guide
                  Profile serves as your all-in-one page for clients to book trips, learn more about your company, and interact with you
                  through our in-app text messaging or via email. This centralized profile ensures a seamless experience for both you and
                  your clients, eliminating the need for a standalone website.
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="card overflow-hidden border border-gray-lightest">
                <AccordionHeader targetId="guide_13">
                  <h2 className="h4 lh-sm">What happens if a client fails to make payment?</h2>
                </AccordionHeader>
                <AccordionBody accordionId="guide_13">
                  Clients are obligated to make upfront payments at the time of booking their trip. Unless you have provided them with the
                  option to pay in cash, in which case it becomes your responsibility to collect the cash on the day of the trip.
                </AccordionBody>
              </AccordionItem>
            </UncontrolledAccordion>
          </TabPane>
        </TabContent>
        <p className="text-center mb-0">
          Didn’t find the answer you are looking for?&nbsp;
          <button className="link link-primary-second fw-semibold text-decoration-none" onClick={openHandler}>
            Contact our support
          </button>
        </p>
      </Container>
    </div>
  )
}
