import React, { useEffect } from "react"
import { useMatch, useSearchParams } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import OffcanvasWindow from "modules/offcanvas/OffcanvasWindow"
import Form from "components/bookings/Form"

// Redux
import { useDispatch } from "react-redux"
import { cleanBooking, getGuideBooking } from "store/bookings"

export default function BookingWindow() {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const isPersisted = useMatch(oldRoutes.guideBookingEditPath())
  const bookingId = isPersisted?.params?.id

  useEffect(() => {
    if (bookingId) dispatch(getGuideBooking(bookingId))
    return () => dispatch(cleanBooking())
  }, [bookingId]) //eslint-disable-line

  return (
    <OffcanvasWindow
      container={document.getElementById("bookings")}
      loadingName="bookings.booking"
      exitRedirect={oldRoutes.guideBookingsPath({}, searchParams)}
    >
      {({ setChanged, closeHandler }) => <Form onChanged={setChanged} closeHandler={closeHandler} edit={!!bookingId} />}
    </OffcanvasWindow>
  )
}
