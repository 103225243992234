import React from "react"
import { StripeAccountCard } from "./StripeAccountCard"
import { useTranslation } from "react-i18next"
import { SettingsTitle } from "../ProSettings/SettingsTitle"
import { useDevice } from "hooks/useDevice"
import { StripeActionButtons } from "./StripeActionButtons"
import { Flex } from "antd"
import { spacings } from "themes/variables"

export const StripeAccount = () => {
  const { t } = useTranslation()
  const { isMobile } = useDevice()

  return (
    <Flex vertical gap={spacings.M}>
      <SettingsTitle title={t("pro.settings.stripe.title")} subTitle={t("pro.settings.stripe.description")} />
      <StripeAccountCard />
      {isMobile && <StripeActionButtons />}
    </Flex>
  )
}
