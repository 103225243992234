import { toaster } from "components/common/Toast"
import { isEqual } from "lodash"
import { serialize } from "object-to-formdata"
import { parse } from "./string"
import i18n from "../i18n"

import { ACCEPTED_MEDIA_TYPES, MAX_MEDIA_SIZE } from "constants/form"

export const fieldIsEmpty = (field) => [undefined, null, "", [], {}].some((empty) => isEqual(field, empty))

export const hasEmptyFields = (object, ...fields) =>
  fields.length ? fields.flat().some((field) => fieldIsEmpty(object[field])) : Object.values(object).some(fieldIsEmpty)

export const isEmptyFields = (object, ...fields) =>
  fields.length ? fields.flat().every((field) => fieldIsEmpty(object[field])) : Object.values(object).every(fieldIsEmpty)

export const dataToObject = (formData) => {
  const object = {}
  formData.forEach((value, key) => {
    const arrRegex = /\[\]$/g
    const isArr = key.match(arrRegex)?.length

    if (isArr) {
      if (!object[key]) object[key] = []
      object[key].push(parse(value))
    } else {
      object[key] = parse(value)
    }
  })
  return object
}

export const toSearchParams = (params, options, existSearchParams) => {
  const searchParams = new URLSearchParams(existSearchParams)

  if (!params) return searchParams
  if (params instanceof FormData || params instanceof URLSearchParams) {
    params.forEach((value, key) => searchParams.append(key, value))
    return searchParams
  }
  if (params instanceof Object) {
    serialize(params, options || {}).forEach((value, key) => searchParams.append(key, value))
    return searchParams
  }
}

//File validators

const getMediaType = (file) => file.type.split("/").at(0)

export const maxSelectFile = (files, max = 0) => {
  if (max && files.length > max) {
    const skippedFiles = [...files]
    const filesAccess = skippedFiles.splice(0, max)
    const msg = i18n.t("errors.files.max", { max, fileNames: skippedFiles.map((file) => file.name).join(", ") })
    toaster.error(msg)
    console.log(msg, skippedFiles)
    return filesAccess
  }
  return files
}

export const checkMimeType = (files) => {
  const errors = []

  const filteredFiles = [...files].reduce((acc, file) => {
    const mediaType = getMediaType(file)
    const acceptedTypes = ACCEPTED_MEDIA_TYPES[mediaType] || []
    const fileAccess = acceptedTypes.includes(file.type)
    if (fileAccess) acc.push(file)
    else errors.push(`${file.name} — ${i18n.t("errors.files.format")}`)
    return acc
  }, [])

  if (errors.length) {
    toaster.error(errors.join(""))
    console.error(errors.join(""))
  }

  return filteredFiles
}

export const checkFileSize = (files) => {
  const errors = []

  const filteredFiles = [...files].reduce((acc, file) => {
    const mediaType = getMediaType(file)
    const maxSize = MAX_MEDIA_SIZE[mediaType] || 0
    const fileSize = (file.size / 1024 / 1024).toFixed(2)

    const fileAccess = fileSize <= maxSize
    if (fileAccess) acc.push(file)
    else errors.push(`${file.name} — ${i18n.t(`errors.files.${mediaType}_large`, { size: maxSize })}`)
    return acc
  }, [])

  if (errors.length) {
    toaster.error(errors.join(""))
    console.error(errors.join(""))
  }

  return filteredFiles
}

// DataUri <-> Blob

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataURI.split(",")[1])

  // separate out the mime component
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]

  // write the bytes of the string to an ArrayBuffer
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const _ia = new Uint8Array(arrayBuffer)
  for (let i = 0; i < byteString.length; i++) {
    _ia[i] = byteString.charCodeAt(i)
  }

  const dataView = new DataView(arrayBuffer)
  const blob = new Blob([dataView], { type: mimeString })
  return Promise.resolve(blob)
}

//**blob to dataURL**
export const blobToDataURL = (blob, callback) => {
  const a = new FileReader()
  a.onload = function (e) {
    callback(e.target.result)
  }
  a.readAsDataURL(blob)
}

export const prepareMediaToSend = (dataUri, origin) => {
  if (origin === "camera") return dataURItoBlob(dataUri)
  else if (origin === "file") {
    let blob = fetch(dataUri).then((r) => r.blob())
    return blob
  }
}
