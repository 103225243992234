import React, { useEffect } from "react"
import { Col, Row } from "reactstrap"
import { Waypoint } from "react-waypoint"
import Booking from "components/bookings/Booking"
import Spinner from "components/common/Spinner"

import { isLastPage } from "helpers/pagination"
import { useTitle } from "hooks/useTitle"

// Redux
import { useDispatch, useSelector } from "react-redux"
import { cleanBookings, getBookings } from "store/bookings"

export default function Booked() {
  useTitle("Booked Trips")
  const dispatch = useDispatch()
  const { items, loading, metadata } = useSelector((state) => state.bookings)
  const hasBookings = !!items?.length

  const fetchBookings = (nextPage = false) => {
    if (!nextPage) window.scrollTo(0, 0)
    dispatch(getBookings(nextPage))
  }

  useEffect(() => {
    fetchBookings()
    return () => dispatch(cleanBookings())
  }, []) //eslint-disable-line

  return (
    <Row className="gap-y-20">
      {hasBookings
        ? items.map((booking) => (
            <Col xs={12} sm={6} md={4} lg={3} key={booking.id}>
              <Booking booking={booking} />
            </Col>
          ))
        : !loading && (
            <div className="bg-light p-20 rounded">
              <h2 className="h3">No exact matches</h2>
              <p className="text-dark text-opacity-50 mb-0">Try changing or removing some filters or customizing your search area.</p>
            </div>
          )}
      {loading && <Spinner className="flex-fill my-20" />}
      {!isLastPage(metadata) && !loading && hasBookings && <Waypoint onEnter={() => fetchBookings(true)} />}
    </Row>
  )
}
