import React from "react"

export const Gallery = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 2.3999V21.5999H24V2.3999H0ZM0.96 3.3599H23.04V15.8399H17.9137L14.5538 13.4399H11.7188L9.7575 11.4787L7.42125 11.9474L5.355 8.84615L0.96 13.2412V3.3599ZM18 6.2399C16.8131 6.2399 15.84 7.21303 15.84 8.3999C15.84 9.58678 16.8131 10.5599 18 10.5599C19.1869 10.5599 20.16 9.58678 20.16 8.3999C20.16 7.21303 19.1869 6.2399 18 6.2399ZM18 7.1999C18.6675 7.1999 19.2 7.7324 19.2 8.3999C19.2 9.0674 18.6675 9.5999 18 9.5999C17.3325 9.5999 16.8 9.0674 16.8 8.3999C16.8 7.7324 17.3325 7.1999 18 7.1999ZM5.205 10.3537L6.97875 13.0124L9.4425 12.5212L11.3212 14.3999H14.2463L17.6062 16.7999H23.04V20.6399H0.96V14.5987L5.205 10.3537Z"
      fill={fill}
    />
  </svg>
)
