import React from "react"
import { Col, Row } from "reactstrap"
import ExtrasForm from "components/guide/settings/ExtrasForm"

import { useTitle } from "hooks/useTitle"

export default function Extras() {
  useTitle("Settings — Extras")

  return (
    <Row className="gap-y-15 gx-4" style={{ maxWidth: 670 }}>
      <Col xs={12}>
        <ExtrasForm />
      </Col>
    </Row>
  )
}
