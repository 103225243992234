import React from "react"
import PropTypes from "prop-types"
import { createSearchParams, useLocation, useNavigate, Link } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import { Button, Alert } from "reactstrap"
import Calculator from "components/bookings/Calculator"

import { useTranslation } from "react-i18next"
import { serialize } from "object-to-formdata"
import { omitBy } from "lodash"
import useForm from "hooks/useForm"
import { useConfirmModal } from "modules/modals/hooks/useConfirmModal"

import { useDispatch, useSelector } from "react-redux"
import { createBooking, deleteClientDraftBooking } from "store/bookings"
import { modelSelector } from "store/selectors"
import { isGuideSelector } from "store/user"

const BookingForm = ({ disabled = false, className }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { loggedIn } = useSelector((store) => store.auth)
  const isGuide = useSelector(isGuideSelector)
  const specificBooking = useSelector(modelSelector("booking"))
  const trip = useSelector(modelSelector("trip"))
  const confirmModal = useConfirmModal()
  const booking = specificBooking.id || !trip.existing_draft_booking ? specificBooking : trip.existing_draft_booking
  const preselectedDate = booking.booked_date || location.state?.date
  const existingBookingUuid = trip.existing_draft_booking?.uuid

  const [form, changeHandler, submitHandler, submitCallback, , isChanged] = useForm(
    {
      ...booking,
      booked_date: preselectedDate
    },
    ["booked_date", "booked_time", "adult_count", "child_count"]
  )
  const isDraft = booking.status === "draft"

  const removeDraftBookingHandler = confirmModal(
    {
      title: "Are you sure you want to delete previously started booking and start over?",
      color: "danger",
      submitText: t("global.delete"),
      cancelText: t("global.cancel")
    },
    (e) =>
      dispatch(deleteClientDraftBooking(e.currentTarget.id)).then(() =>
        dispatch(createBooking(trip.id, {})).then((booking) => navigate(oldRoutes.clientBookingWizardRootPath({ uuid: booking.uuid })))
      )
  )

  submitCallback(() => {
    const formData = serialize({ booking: omitBy({ ...form }, (value) => !value) })
    if (loggedIn)
      dispatch(createBooking(trip.id, formData)).then((booking) => navigate(oldRoutes.clientBookingWizardRootPath({ uuid: booking.uuid })))
    else
      navigate({
        pathname: oldRoutes.bookingWizardPath(),
        search: createSearchParams({ trip_id: trip.id, booking: createSearchParams(form) }).toString()
      })
  })

  if (!trip.id) return null

  const classes = ["bg-white rounded p-15 mt-15 vstack flex-fill"]
  if (className) classes.push(className)
  if (isGuide) classes.push("pointer-events-none opacity-50")

  return (
    <form onSubmit={submitHandler} className={classes.join(" ")} disabled={!isDraft}>
      {trip.existing_draft_booking && (
        <Alert
          color="primary-second"
          className="bg-primary-second bg-opacity-5 text-primary-second border-primary-second border border-opacity-5 p-10"
        >
          {t("global.draft_booking_exists")}
        </Alert>
      )}
      {trip.id && (
        <Calculator
          form={form}
          changeHandler={changeHandler}
          trip={trip}
          disabled={!isDraft || trip.existing_draft_booking}
          current_booked_date={booking.booked_date}
          className="vstack flex-fill"
        />
      )}
      {!trip.existing_draft_booking && (
        <Button color="primary" className="w-100 mt-20 text-uppercase" disabled={!isChanged || !isDraft}>
          {t("global.book_a_trip")}
        </Button>
      )}
      {trip.existing_draft_booking && (
        <div className="hstack gap-10 mt-20">
          <Button
            tag={Link}
            color="primary-second"
            className="px-5 py-3 fs-6 w-100"
            to={oldRoutes.clientBookingWizardRootPath({ uuid: existingBookingUuid })}
          >
            {t("global.continue_booking")}
          </Button>
          <Button
            color="link"
            className="link-danger link-underline-opacity-0 link-underline-opacity-75-hover p-0 ms-auto fs-7 px-10 w-25"
            id={existingBookingUuid}
            onClick={removeDraftBookingHandler}
          >
            <small>{t("global.start_over")}</small>
          </Button>
        </div>
      )}
      <p className="text-center m-0 mt-3 fs-7">You won’t be charged yet</p>
    </form>
  )
}

BookingForm.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string
}

BookingForm.defaultProps = {
  disabled: false,
  className: ""
}

export default BookingForm
