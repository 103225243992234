import React, { useEffect } from "react"
import { useMatch } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import OffcanvasWindow from "modules/offcanvas/OffcanvasWindow"
import Form from "components/boats/Form"

// Redux
import { useDispatch } from "react-redux"
import { cleanBoat, getGuideBoat } from "store/boats"

export default function BoatWindow() {
  const dispatch = useDispatch()
  const isPersisted = useMatch(oldRoutes.guideEditBoatPath())
  const boatId = isPersisted?.params?.id

  const isTripWizard = useMatch(oldRoutes.guideTripWizardStep3AddNewBoatPath())
  const exitRedirect = isTripWizard ? oldRoutes.guideTripWizardStep3Path(isTripWizard.params) : oldRoutes.guideBoatsPath()

  useEffect(() => {
    if (boatId) dispatch(getGuideBoat(boatId))
    return () => dispatch(cleanBoat())
  }, [boatId, dispatch])

  return (
    <OffcanvasWindow container={document.getElementById("boats")} loadingName="boats.boat" exitRedirect={exitRedirect}>
      {({ setChanged, closeHandler }) => <Form closeHandler={closeHandler} onChanged={setChanged} />}
    </OffcanvasWindow>
  )
}
