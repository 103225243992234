import React, { useState } from "react"
import { TabContent, TabPane } from "reactstrap"
import { Link, createSearchParams, useNavigate, useSearchParams } from "react-router-dom"
import { oldRoutes } from "router/old-routes"
import LoginForm from "components/devise/LoginForm"
import RegisterForm from "components/devise/RegisterForm"
import GuestForm from "components/devise/GuestForm"

import { useTranslation } from "react-i18next"
import { useTitle } from "hooks/useTitle"
import { serialize } from "object-to-formdata"

import { useDispatch } from "react-redux"
import { createBooking } from "store/bookings"

export default function Account() {
  useTitle("Booking Wizard — Account")
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams, setSearch] = useSearchParams()
  const tripId = +searchParams.get("trip_id")
  const bookingParams = createSearchParams(searchParams.get("booking") || {})
  const loginUrl = oldRoutes.bookingWizardPath({}, { trip_id: tripId, form: "sign_in", ...(bookingParams && { booking: bookingParams }) })
  const isSignInActive = searchParams.get("form") === "sign_in"
  const [activeForm, setActiveForm] = useState(isSignInActive ? "sign_in" : "sign_up")

  const loggedInHandler = (client_attributes) => {
    if (!tripId) return
    const bookingParams = createSearchParams(searchParams.get("booking") || {})
    const booking = [...bookingParams?.entries()]?.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

    if (client_attributes) booking.client_attributes = client_attributes
    const bookingForm = serialize({ booking })

    dispatch(createBooking(tripId, bookingForm)).then((booking) =>
      client_attributes?.email
        ? navigate(oldRoutes.guestBookingWizardRootPath({ uuid: booking.uuid }))
        : navigate(oldRoutes.clientBookingWizardRootPath({ uuid: booking.uuid }))
    )
  }

  const toggleFormHandler = (formName) => () => {
    setActiveForm(formName)
    if (formName === "sign_up" && searchParams.has("form")) {
      searchParams.delete("form")
      setSearch(searchParams)
    }
  }

  return (
    <TabContent activeTab={activeForm} className="w-100">
      <TabPane tabId="sign_up">
        <div className="hstack gap-2 justify-content-between mb-60">
          <h1 className="h2 lh-1 mb-0">{t("booking.account.sign_up")}</h1>
          <p className="text-dark opacity-50 m-0">
            {t("booking.account.have_an_account")}
            <br />
            <button className="link fw-semibold" onClick={toggleFormHandler("sign_in")}>
              {t("booking.account.switch_to_log_in")}
            </button>
          </p>
        </div>

        <RegisterForm role="user" loginUrl={loginUrl} />

        <button className="link fs-3 fw-semibold mt-50" onClick={toggleFormHandler("as_a_guest")}>
          {t("booking.account.switch_to_guest")}
        </button>
      </TabPane>

      <TabPane tabId="sign_in">
        <div className="hstack gap-2 justify-content-between mb-60">
          <h1 className="h2 lh-1 mb-0">{t("booking.account.sign_in")}</h1>
          <p className="text-dark opacity-50 m-0">
            {t("booking.account.dont_have_an_account")}
            <br />
            <button className="link fw-semibold" onClick={toggleFormHandler("sign_up")}>
              {t("booking.account.switch_to_sign_up")}
            </button>
          </p>
        </div>

        <LoginForm onLoggedIn={loggedInHandler} />

        <div className="mt-30 fs-6 fw-medium lh-1">
          <Link className="small text-primary-hover text-decoration-none" to={oldRoutes.forgotPasswordPath({}, { login_url: loginUrl })}>
            {t("booking.account.forgot_my_password")}
          </Link>
        </div>

        <button className="link fs-3 fw-semibold mt-50" onClick={toggleFormHandler("as_a_guest")}>
          {t("booking.account.switch_to_guest")}
        </button>
      </TabPane>

      <TabPane tabId="as_a_guest">
        <div className="hstack gap-2 justify-content-between mb-60">
          <h1 className="h2 lh-1 mb-0">{t("booking.account.as_a_guest")}</h1>
          <p className="text-dark opacity-50 m-0">
            {t("booking.account.have_an_account")}
            <br />
            <button className="link fw-semibold" onClick={toggleFormHandler("sign_in")}>
              {t("booking.account.switch_to_log_in")}
            </button>
          </p>
        </div>

        <GuestForm onSubmit={loggedInHandler} />
      </TabPane>
    </TabContent>
  )
}
