import React from "react"
import { Col, Row, Form } from "antd"
import { AddressesHeader } from "./Header"
import { AddressesSearch } from "./Search"
import { CustomAddress } from "./CustomAddress"
import { useDispatch, useSelector } from "react-redux"
import { serialize } from "object-to-formdata"
import { guideSelector, updateGuide } from "store/user"

import { ContentActionButtons } from "layoutsV2/pro/PersonalInfoLayout/ContentActionButtons"

export const PersonalInfoAddresses = () => {
  const [manualAddressEnabled, setManualAddressEnabled] = React.useState(false)
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { id, street_address, state, country, city, zip_code } = useSelector(guideSelector)
  const initialValues = {
    street_address,
    state,
    country,
    city,
    zip_code
  }

  const onFinish = (formData, navigateTo) => {
    const serializedFormData = serialize({ guide: formData }, { indices: true })
    dispatch(updateGuide(id, serializedFormData)).then(() => navigateTo())
  }

  return (
    <Row>
      <Col lg={16} md={24} xs={24} span={16}>
        <AddressesHeader />
        {manualAddressEnabled ? (
          <CustomAddress backToSearch={() => setManualAddressEnabled(false)} form={form} initialValues={initialValues} />
        ) : (
          <AddressesSearch setManualAddressEnabled={() => setManualAddressEnabled(true)} form={form} initialValues={initialValues} />
        )}
      </Col>
      <Col lg={16} md={24} xs={24} span={16}>
        <ContentActionButtons form={form} onFinish={onFinish} />
      </Col>
    </Row>
  )
}
