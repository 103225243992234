import React from "react"
import PropTypes from "prop-types"
import { Button, FormGroup, Label } from "reactstrap"
import Input from "components/form/Input"
import Spinner from "components/common/Spinner"

import useForm from "hooks/useForm"
import { useTranslation } from "react-i18next"

import { useDispatch, useSelector } from "react-redux"
import { signIn } from "store/auth"

LoginForm.propTypes = {
  onLoggedIn: PropTypes.func.isRequired
}

export default function LoginForm({ onLoggedIn }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loading } = useSelector((state) => state.auth)
  const [user, changeHandler, submitHandler, submitCallback] = useForm({
    email: "",
    password: "",
    remember_me: ""
  })
  submitCallback(() => {
    dispatch(signIn(user)).then((res) => typeof onLoggedIn === "function" && onLoggedIn(res))
  })

  return (
    <form onSubmit={submitHandler}>
      <div>
        <Label for="email">{t("sign_in.email")}</Label>
        <Input
          id="email"
          type="email"
          name="email"
          placeholder={t("sign_in.email")}
          value={user.email}
          onChange={changeHandler}
          withError
        />
      </div>
      <div className="mt-20">
        <Label for="password">{t("sign_in.password")}</Label>
        <Input
          id="password"
          type="password"
          name="password"
          placeholder={t("sign_in.password")}
          value={user.password}
          onChange={changeHandler}
          withError
        />
      </div>
      <FormGroup className="fs-6 fw-medium mt-30 mb-0" check>
        <Input id="remember_me" name="remember_me" type="checkbox" checked={user.remember_me} onChange={changeHandler} />
        <Label check for="remember_me" className="small">
          {t("sign_in.remember_me")}
        </Label>
      </FormGroup>
      <Button color="primary" type="submit" className="mt-30 w-100">
        {t("sign_in.log_in")}
      </Button>
      {loading && <Spinner className="bg-white bg-opacity-0 backdrop-blur-3 w-100 h-100 z-2" fixed />}
    </form>
  )
}
