import React from "react"

export const QR = ({ height = 24, width = 24, fill = "#272A34", ...props }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.92004 1.91992V2.39992V8.63992H8.64004V1.91992H1.92004ZM15.36 1.91992V2.39992V8.63992H22.08V1.91992H15.36ZM2.88004 2.87992H7.68004V7.67992H2.88004V2.87992ZM9.60004 2.87992V3.35992V5.75992H11.52V7.19992H9.60004V7.67992V9.59992H5.28004V10.0799V11.5199H3.36004V11.9999V14.3999H6.24004V12.4799H7.68004V14.3999H10.56V10.5599H11.52V12.4799H14.88V13.9199H17.28V15.3599H13.44V15.8399V17.2799H11.52V17.7599V20.1599H14.4V18.2399H17.28V22.0799H22.08V17.2799H18.24V16.3199H22.08V11.5199H19.2V11.9999V12.9599H18.24V9.59992H12.48V8.15992H14.4V4.79992H12.48V2.87992H9.60004ZM16.32 2.87992H21.12V7.67992H16.32V2.87992ZM3.84004 3.83992V4.31992V6.71992H6.72004V3.83992H3.84004ZM10.56 3.83992H11.52V4.79992H10.56V3.83992ZM17.28 3.83992V4.31992V6.71992H20.16V3.83992H17.28ZM4.80004 4.79992H5.76004V5.75992H4.80004V4.79992ZM18.24 4.79992H19.2V5.75992H18.24V4.79992ZM12.48 5.75992H13.44V7.19992H12.48V5.75992ZM10.56 8.15992H11.52V9.59992H10.56V8.15992ZM6.24004 10.5599H9.60004V13.4399H8.64004V11.5199H6.24004V10.5599ZM12.48 10.5599H17.28V12.9599H15.84V11.5199H12.48V10.5599ZM4.32004 12.4799H5.28004V13.4399H4.32004V12.4799ZM20.16 12.4799H21.12V15.3599H18.24V13.9199H20.16V12.4799ZM1.92004 15.3599V15.8399V22.0799H8.64004V15.3599H1.92004ZM2.88004 16.3199H7.68004V21.1199H2.88004V16.3199ZM14.4 16.3199H17.28V17.2799H14.4V16.3199ZM3.84004 17.2799V17.7599V20.1599H6.72004V17.2799H3.84004ZM4.80004 18.2399H5.76004V19.1999H4.80004V18.2399ZM12.48 18.2399H13.44V19.1999H12.48V18.2399ZM18.24 18.2399H21.12V21.1199H18.24V18.2399Z"
      fill={fill}
    />
  </svg>
)
