import iconLocation from "assets/icons/new/location.svg"
import i18n from "i18n"

export const MAP_STAYLES = [
  {
    featureType: "administrative.land_parcel",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: -20
      },
      {
        lightness: 20
      },
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        weight: 2
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#bcb6a9"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eeece8"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#bcb6a9"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "water",
    stylers: [
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        lightness: 70
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#eaf0fe"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels",
    stylers: [
      {
        color: "#0052b4"
      }
    ]
  }
]

export const DEFAULT_ZOOM = 10
export const MIN_ZOOM = 2
export const MAX_ZOOM = 20

export const DEFAULT_LOCATION = {
  lat: 46.40587964789026,
  lng: -101.08296847130168,
  zoom: 4,
  default: true
}

export const API_OPTIONS = {
  id: "google-map-script",
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  language: "us",
  libraries: ["maps", "places"]
}

export const MAP_OPTIONS = {
  disableDefaultUI: true,
  scrollwheel: false,
  styles: MAP_STAYLES,
  backgroundColor: "#eaf0fe"
}

export const MARKER_ICON = {
  url: iconLocation
}

const COUNTRY_CODES = ["us", "ca", "ua"]

export const PLACE_OPTIONS = {
  language: i18n.language,
  componentRestrictions: { country: COUNTRY_CODES }
}
