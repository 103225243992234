import React, { useState } from "react"
import { Form, Button, Checkbox, Flex, Modal } from "antd"
import { useDispatch, useSelector } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Title, Text } from "ui/Typography"
import { CustomInput, PhoneInput } from "ui/Input"
import { spacings } from "themes/variables"
import { CustomForm } from "ui/Form"
import { routes } from "router"
import { updateUser } from "store/user"
import { oAuthInfoConfirmed } from "store/auth"
import { TermsAndConditions } from "../SignUp/TermsAndConditions"
import { PrivacyPolicy } from "../SignUp/PrivacyPolicy"
import useFormErrors from "hooks/useAntdFormErrors"
import { CustomInputError } from "ui/CustomInputError"

export const ConfirmInfo = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const { email, first_name, last_name, phone_number, oauth_type } = Object.fromEntries(searchParams.entries())
    const { loading } = useSelector((state) => state.auth)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalType, setModalType] = useState(null)
    const [form] = Form.useForm()

    const showModal = (type) => {
        setModalType(type);
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setModalType(null);
        setIsModalOpen(false);
    };

    const onFinish = (user) => {
        dispatch(updateUser({...user, oauth_in_progress: false })).then(() => {
            dispatch({ type: oAuthInfoConfirmed })
        })
    }

    const { errors } = useFormErrors(form)
    
    return (
        <>
            <Title 
                className="text-center mb-3xl"
            >
                {t(`sign_in.${oauth_type}_confirm_title`)}
            </Title>
            <CustomForm 
                name="confirm-info"
                layout="vertical"
                onFinish={onFinish}
                initialValues={{
                    first_name: (first_name && (first_name !== 'null')) ? first_name : "",
                    last_name: (last_name && (last_name !== 'null')) ? last_name : "",
                    email,
                    phone_number: (phone_number && (phone_number !== 'null')) ? phone_number : null,
                }}
            >
                <Flex gap={spacings.S}>
                    <Form.Item
                        label={t("sign_up.first_name")}
                        name="first_name"
                        required={true}
                        rules={[
                            { required: true, message: t("form_validation.cannot_be_blank") }
                        ]}
                        className="flex-grow-1"
                    >
                        <CustomInput placeholder={t("sign_up.name")} />
                    </Form.Item>
                    <Form.Item
                        label={t("sign_up.last_name")}
                        name="last_name"
                        required={true}
                        rules={[
                            { required: true, message: t("form_validation.cannot_be_blank") }
                        ]}
                        className="flex-grow-1"
                    >
                        <CustomInput placeholder={t("sign_up.last_name")} />
                    </Form.Item>
                </Flex>
                <Form.Item
                    label={t("sign_up.email")}
                    name="email"
                    required={true}
                    hidden={email && email !== 'null'}
                    rules={[
                        { required: true, message: t("form_validation.cannot_be_blank") },
                        { type: "email", message: t("form_validation.invalid_email") }
                    ]}
                    help={!!errors?.email ? <CustomInputError errorKey={errors.email} /> : null}
                >
                    <CustomInput placeholder={t("sign_up.email_placeholder")} />
                </Form.Item>
                <Form.Item
                    label={t("sign_up.phone_number")}
                    name="phone_number"
                    hidden={phone_number && phone_number !== 'null'}
                    tooltip={{ title: t("sign_up.phone_number_tooltip") }}
                    help={!!errors?.phone_number ? <CustomInputError errorKey={errors.phone_number} /> : null}
                >
                    <PhoneInput />
                </Form.Item>
                <Form.Item
                    name="agreeCheck"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                            value ? Promise.resolve() : Promise.reject(new Error(t("form_validation.accept_terms_conditions"))),
                        },
                    ]}
                >
                    <Checkbox>
                        <Text type="secondary" variant="body">
                            {t("sign_up.agree_terms")}
                        </Text>
                        <Text className="ml-xs" link>
                            <Link onClick={() => showModal('TermsAndConditions')}>{t("sign_up.terms_conditions")}</Link>
                        </Text>
                        <Text className="ml-xs" link>
                            &
                        </Text>
                        <Text className="ml-xs" link>
                            <Link onClick={() => showModal('PrivacyPolicy')}>{t("sign_up.privacy_policy")}</Link>
                        </Text>
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button className="mt-xs mb-xs" type="primary" htmlType="submit" block size="large" disabled={loading}>
                        {t("sign_in.confirm_and_create")}
                    </Button>
                </Form.Item>
            </CustomForm>
            <Flex justify='center' >
                <Text type="secondary" variant="body">
                    {t("sign_up.already_have_account")}
                </Text>
                <Text className="ml-xs" link>
                    <Link to={routes.signInV2()}>{t("sign_in.log_in")}</Link>
                </Text>
            </Flex>
            <Modal
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                width={1022}
                style={{
                    top: 40
                }}
            >
                {modalType === 'TermsAndConditions' ? 
                    <TermsAndConditions /> :
                    <PrivacyPolicy />
                }
            </Modal>
        </>
    )
}
