import { useSelector } from "react-redux"

import { BREAKPOINT_NAMES } from "../constants"

export const useMedia = ({ from, to }) => {
  const windowBreakpoint = useSelector((store) => store.window.breakpoint)
  const fromIndex = BREAKPOINT_NAMES.indexOf(from)
  const toIndex = BREAKPOINT_NAMES.indexOf(to) + 1 || BREAKPOINT_NAMES.length
  const checkBreakpoints = [...BREAKPOINT_NAMES].slice(fromIndex < 0 ? 0 : fromIndex, toIndex)

  return checkBreakpoints.includes(windowBreakpoint)
}
