import { createAction, createReducer } from "@reduxjs/toolkit"
import { api } from "./helpers/api"
import { find, remove } from "lodash"
import { createdBooking, deletedBooking, updatedBooking } from "./bookings"
import { guideUnavailableWeekdaysUpdated } from "./user"
import { createdUnavailableDay, deletedUnavailableDay, updatedUnavailableDay } from "./unavailable-days"
import { LOADING_INITIAL_STATE, setFailed, setReceived, setRequested } from "modules/loader-watchers/helpers/loading"

// ACTIONS
const requested = createAction("dashboard/requested")
const received = createAction("dashboard/received")
const failed = createAction("dashboard/failed")
const cleaned = createAction("dashboard/cleaned")

const requestedJournal = createAction("posts/requestedJournal")
const receivedJournal = createAction("posts/receivedJournal")
const failedJournal = createAction("posts/failedJournal")
const createdJournal = createAction("posts/createdJournal")
const updatedJournal = createAction("posts/updatedJournal")
const deletedJournal = createAction("posts/deletedJournal")
const cleanedJournal = createAction("posts/cleanedJournal")

// REDUCER
const initialState = {
  ...LOADING_INITIAL_STATE,
  bookings: [],
  journal_notes: [],
  unavailable_days: [],
  metadata: {
    income_stats: "—",
    expense_stats: "—",
    expenses_last: [],
    profit_stats: "—",
    trip_collection: [],
    trip_locations: [],
    unavailable_weekdays: {}
  },
  journal_note: {
    ...LOADING_INITIAL_STATE,
    id: null,
    title: "",
    body: "",
    date: "",
    location: null
  }
}

const dashboardReducer = createReducer(initialState, {
  [requested.type]: (state) => {
    setRequested(state)
  },
  [received.type]: (state, action) => {
    setReceived(state)
    Object.assign(state, action.payload.data)
    Object.assign(state.metadata, action.payload.metadata)
  },
  [failed.type]: (state) => {
    setFailed(state)
  },
  [cleaned.type]: () => initialState,
  [requestedJournal.type]: (state) => {
    setRequested(state.journal_note)
  },
  [receivedJournal.type]: (state, action) => {
    setReceived(state.journal_note)
    Object.assign(state.journal_note, action.payload)
  },
  [createdJournal.type]: (state, action) => {
    setReceived(state.journal_note)
    state.journal_notes.push(action.payload)
    Object.assign(state.journal_note, action.payload)
  },
  [updatedJournal.type]: (state, action) => {
    setReceived(state.journal_note)
    const journalNote = find(state.journal_notes, ["id", action.payload.id]) || {}
    Object.assign(journalNote, action.payload)
    Object.assign(state.journal_note, action.payload)
  },
  [deletedJournal.type]: (state, action) => {
    setReceived(state.journal_note)
    remove(state.journal_notes, ["id", action.payload.id])
  },
  [failedJournal.type]: (state) => {
    setFailed(state.journal_note)
  },
  [cleanedJournal.type]: (state) => {
    state.journal_note = initialState.journal_note
  },
  [createdBooking.type]: (state, action) => {
    state.bookings = [action.payload, ...state.bookings]
  },
  [updatedBooking.type]: (state, action) => {
    const booking = find(state.bookings, ["id", action.payload.data?.id || action.payload.id]) || {}
    Object.assign(booking, action.payload.data || action.payload)
  },
  [deletedBooking.type]: (state, action) => {
    remove(state.bookings, ["id", action.payload.id])
  },
  [guideUnavailableWeekdaysUpdated.type]: (state, action) => {
    Object.assign(state.metadata.unavailable_weekdays, action.payload.unavailable_weekdays)
  },
  [createdUnavailableDay.type]: (state, action) => {
    state.unavailable_days = [action.payload, ...state.unavailable_days].sort((a, b) => (a.unavailable_date > b.unavailable_date ? 1 : -1))
  },
  [updatedUnavailableDay.type]: (state, action) => {
    const unavailableDay = find(state.unavailable_days, ["id", action.payload.id]) || {}
    Object.assign(unavailableDay, action.payload)
  },
  [deletedUnavailableDay.type]: (state, action) => {
    remove(state.unavailable_days, ["id", action.payload.id])
  }
})
export default dashboardReducer

const getItem = (url, id, dispatch, getState) => {
  const state = getState()
  const journalNote = find(state.dashboard.journal_notes, ["id", +id])

  if (journalNote) {
    dispatch(receivedJournal(journalNote))
    return new Promise((resolve) => resolve(journalNote))
  }
  return dispatch(
    api({
      url,
      onStart: requestedJournal.type,
      onSuccess: receivedJournal.type,
      onError: failedJournal.type
    })
  )
}

// PUBLIC ACTIONS

export const getDashboardData = (params) =>
  api({
    url: "/guide/dashboard",
    params: { ...params, include_unavailable_dates: true },
    onStart: requested.type,
    onSuccess: received.type,
    onError: failed.type
  })

export const getGuideJournal = (id) => (dispatch, getState) => getItem(`/guide/journal_notes/${id}`, id, dispatch, getState)

export const createGuideJournal = (data) =>
  api({
    url: "/guide/journal_notes",
    method: "post",
    onStart: requestedJournal.type,
    onSuccess: createdJournal.type,
    onError: failedJournal.type,
    data
  })

export const updateGuideJournal = (id, data) =>
  api({
    url: `/guide/journal_notes/${id}`,
    method: "put",
    data: data,
    onStart: requestedJournal.type,
    onSuccess: updatedJournal.type,
    onError: failedJournal.type
  })

export const deleteGuideJournal = (id) =>
  api({
    url: `/guide/journal_notes/${id}`,
    method: "delete",
    onStart: requestedJournal.type,
    onSuccess: deletedJournal.type,
    onError: failedJournal.type
  })

export const saveJournal = (id, data) => (id ? updateGuideJournal(id, data) : createGuideJournal(data))

export const cleanJournal = () => (dispatch) => dispatch(cleanedJournal())
export const cleanDashboardData = () => (dispatch) => dispatch(cleaned())
