import React from "react"
import { useDispatch } from "react-redux"
import { Form } from "antd"
import { sendMessage } from "store/conversations"
import { Input, Button, Upload } from "antd"
import { SendOutlined, SmileOutlined, PlusOutlined } from "@ant-design/icons"

import styles from "./ChatHistory.module.css"

export const MessageForm = ({ conversation, messageSent }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const onFinish = ({ message }) => {
    dispatch(sendMessage(conversation.id, { body: message })).then(messageSent)
    form.resetFields()
  }

  return (
    <div className={styles.footer}>
      <Form form={form} layout="inline" onFinish={onFinish} style={{ width: "100%" }}>
        <Button icon={<SmileOutlined />} onClick={() => alert("Emoji picker clicked")} />

        <Form.Item name="message" style={{ flex: 1, margin: "0 10px" }}>
          <Input placeholder="Type a message" />
        </Form.Item>

        <Upload beforeUpload={(file) => false} showUploadList={false}>
          <Button icon={<PlusOutlined />} />
        </Upload>

        <Form.Item>
          <Button className="ml-s" icon={<SendOutlined />} type="primary" htmlType="submit" />
        </Form.Item>
      </Form>
    </div>
  )
}
